/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

// We create this wrapper so that we only import react-hot-loader for a
// development build.  Small savings. :)
// should it just be for local?
const ReactHotLoader = process.env.IS_LOCAL
  ? require('react-hot-loader').AppContainer
  : ({ children }) => React.Children.only(children);

export default ReactHotLoader;

import { fromJS, Map } from "immutable";
import config from "config";
/**
 * selectToggle
 *
 * @description Selects/Deselects an item updating the selected array
 * @param state {Map}
 * @param id {string}
 * @returns {Map}
 */
export function selectToggle(state, id, customSelector) {
  const selector = customSelector || ["cardBrowserConfig"];
  const selectedSelector = selector.concat(["selected"]) || "";
  const lastSelectedSelector = selector.concat(["lastSelected"]);
  const selectModeSelector = selector.concat(["selectMode"]);

  if (typeof state !== "undefined" && id) {
    let newState = state;
    const existingSelected =
      typeof state.getIn(selectedSelector) !== "undefined"
        ? state.getIn(selectedSelector).toJS() || []
        : [];
    const index = existingSelected.indexOf(id);
    if (index === -1) {
      // It's not selected
      existingSelected.push(id);
      newState = newState.setIn(lastSelectedSelector, id);
    } else {
      existingSelected.splice(index, 1);
      newState = newState.setIn(lastSelectedSelector, null);
    }
    const selectMode = existingSelected.length > 0;
    return newState
      .setIn(selectedSelector, fromJS(existingSelected))
      .setIn(selectModeSelector, selectMode);
  }
  return state;
}

/**
 * selectToggle
 *
 * @description Selects/Deselects an item resetting the selected array
 * @param state {Map}
 * @param assetId {string}
 * @returns {Map}
 */
export function exclusiveSelectToggle(state, id, customSelector) {
  const selector = customSelector || ["cardBrowserConfig"];
  const selectedSelector = selector.concat(["selected"]);
  const lastSelectedSelector = selector.concat(["lastSelected"]);
  const selectModeSelector = selector.concat(["selectMode"]);

  if (typeof state !== "undefined" && state.getIn(selectedSelector)) {
    const tmp = state.getIn(selectedSelector).toJS();

    const removingOneAsset =
      tmp && tmp.length ? tmp.length === 1 && tmp[0] === id : false;
    const selected = id && !removingOneAsset ? [id] : [];
    return state
      .setIn(selectedSelector, fromJS(selected))
      .setIn(selectModeSelector, selected.length > 0)
      .setIn(lastSelectedSelector, id);
  }
  return state;
}

/**
 * setSortByFilter
 *
 * @description Changes state to set the sortBy filter
 *
 * @param state {Object}
 * @param filter {String}
 * @returns {*}
 */
export function setSortByFilter(state, filter) {
  const selector = filter.selector || ["cardBrowserConfig"];
  const sortByTypeSelector = selector.concat(["sortByType"]);
  const sortByOrderSelector = selector.concat(["sortByOrder"]);

  if (typeof state !== "undefined") {
    const sortByType = filter.sortByType || state.getIn(sortByTypeSelector);
    const sortByOrder = filter.sortByOrder || state.getIn(sortByOrderSelector);
    const newState = state.setIn(sortByTypeSelector, sortByType);
    return newState.setIn(sortByOrderSelector, sortByOrder);
  }
  return state;
}

/**
 * setStoryBrowserSearch
 *
 * @description Modifies state to set the keywordSearch attribute in the story browser state
 *              forcing refresh afterwards.
 *
 * @param state {Map}
 * @param search {String}
 * @returns {*}
 */
export function setStoryBrowserSearch(state, search, customSelector) {
  const selector = customSelector || ["cardBrowserConfig"];
  const keywordSearchSelector = selector.concat(["keywordSearch"]);

  if (typeof state !== "undefined" && typeof search !== "undefined") {
    return state.setIn(keywordSearchSelector, search);
  }
  return state;
}

/**
 * getSearchFilteredItems
 *
 * @description Applies search term on title for each item in the library
 * @param allItems {Array}
 * @param searchTerm {String}
 * @returns {*}
 */
export function getSearchFilteredItems(allItems, searchTerm) {
  return allItems.filter(val => {
    if (typeof val !== "undefined") {
      return typeof val.title !== "undefined"
        ? val.title.toLowerCase().search(searchTerm.toLowerCase()) > -1
        : false;
    }
    return false;
  });
}

/**
 * getSortedItems
 *
 * @description Returns the array of All Items sorted according to the 'By' and the 'Type'
 *
 * @param allItems {Array}
 * @param sortBy {string}
 * @param sortType {string}
 * @returns {Array}
 */
export function getSortedItems(allItems, sortBy, sortType) {
  // NOTE: if the title is an Object we need to go down a level to read
  const selector =
    sortBy === "modifiedDate"
      ? a => new Date(a[sortBy])
      : a => (typeof a[sortBy] !== "undefined" ? a[sortBy].toLowerCase() : 0); //
  if (sortType === "desc") {
    allItems.sort((a, b) =>
      selector(a) < selector(b) ? 1 : selector(b) < selector(a) ? -1 : 0
    );
  } else if (sortType === "asc") {
    allItems.sort((a, b) =>
      selector(a) > selector(b) ? 1 : selector(b) > selector(a) ? -1 : 0
    );
  }
  return allItems;
}

/**
 * Get Visible Items
 *
 * @description Takes all the data and the state and generates an array with the visible items
 * @param allItems {Object} All Data from Library
 * @param cardBrowserUiState {Object} All Library Card Browser UI Conf
 * @returns {Array} Populated array with visible items
 */
export function getVisibleItems(
  allItems,
  assets,
  cardBrowserUiState,
  uiTo,
  paginateIt
) {
  // ***************** 1. Apply search filtering
  const baseUrl = uiTo; // NOTE: this is "hardcoded" to stories rather relative/dynamic
  const searchFilter = cardBrowserUiState.keywordSearch;

  const searchFilteredItems = searchFilter
    ? getSearchFilteredItems(allItems, searchFilter)
    : allItems;

  // ***************** 2. Apply Sorting
  const sortedItems = getSortedItems(
    searchFilteredItems,
    cardBrowserUiState.sortByType,
    cardBrowserUiState.sortByOrder
  );

  // Apply pagination
  // const doPagination = typeof paginateIt !== 'undefined' ? paginateIt : true;

  // Get Visible Items Array
  // const pageSize = libraryUIstate.pageSize;
  const visibleItems = [];
  const BreakException = {};
  const cloudName = config("cloudName"); // would like this to be read in UI-core from the config, so its not have to be passed
  try {
    sortedItems.forEach(value => {
      if (!value) throw BreakException;
      // NOTE: we may need to "populate" the asset info if it is just a reference to an assetID
      // TEMP: conversion of the new data to look like old for now...
      // right now they are all objects
      // we will need access to the data.Asset store to resolve cmsThumb (assetID) when it is a string

      const tmp = value.asset ? value.asset : {};
      value = { ...value, ...tmp };
      value.url = `${baseUrl}/${value._id}`;
      // value.imgSrc = value.assetMedia ? value.assetMedia.full.url;
      visibleItems.push(value);
    });
  } catch (e) {}

  return visibleItems;
}

import { Map, fromJS } from "immutable";
import * as CONST from "actions/action_constants";

/**
 Account Reducer
*/
const accountusers = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.ACCOUNT_USERS_FETCH_SUCCESS:
      return state.merge(fromJS(action.data));

    case CONST.USER_MANAGER_ADD_SUCCESS:
      return state.set(action.userData._id, fromJS(action.userData));

    case CONST.USER_MANAGER_DELETE_SUCCESS:
      return state.remove(action.userID);

    case CONST.USER_EDITOR_SAVE_SUCCESS:
      const userData = state.get(action.userID).toJS();
      userData.password = "";
      const updatedUserData = fromJS(userData);
      return state
        .set(action.userID, updatedUserData)
        .set("revertCopy", updatedUserData)
        .setIn([action.userID, "isSaved"], true);

    case CONST.USER_EDITOR_DATA_INITIALIZED:
      return state.set("revertCopy", state.get(action.userID));

    case CONST.USER_EDITOR_DATA_CHANGED:
      return state
        .setIn([action.userID, action.property], action.value)
        .setIn([action.userID, "isSaved"], false);

    case CONST.USER_EDITOR_SAVE_SUCCESS:
      return state.setIn([action.userID, "password"], "");

    case CONST.USER_EDITOR_DATA_REVERT_REQUEST:
      const revertData = state.get("revertCopy").toJS();
      revertData.isSaved = true;
      const userID = revertData._id;
      return state.set(userID, fromJS(revertData));

    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props

    default:
      return state; // if action was not passed send state back
  }
};

export default accountusers;

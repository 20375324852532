import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants'; // fromJS,

/**
 * ANALYTICS REDUCER
 *
 */
const analytics = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.ANALYTICS_SUCCESS:
      const {
        analyticsData,
        analyticsDataPerLang,
        analyticsDataPerWeek,
        analyticsPopularContent,
      } = action;
      return state
        .set('data', fromJS({ ...analyticsData }))
        .set('dataPerLang', fromJS({ ...analyticsDataPerLang }))
        .set('dataPopularContent', fromJS({ ...analyticsPopularContent }))
        .set('dataPerWeek', fromJS({ ...analyticsDataPerWeek }));

    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props

    default:
      return state;
  }
};

export default analytics;

import * as CONST from "actions/action_constants";

export const requestStoryBrowser = account => ({
    type: CONST.STORY_BROWSER_REQUEST,
    account
  });

export const requestCollectionBrowser = account => ({
    type: CONST.COLLECTION_BROWSER_REQUEST,
    account
  });

export const requestProjectBrowser = username => ({
  type: CONST.PROJECT_BROWSER_REQUEST,
  username
});

export const requestStoryData = storyID => ({
  type: CONST.STORY_DATA_REQUEST,
  storyID
});

export const createNewStory = inputData => ({
  type: CONST.STORY_CREATE_REQUEST,
  inputData
});

export const createNewProject = inputData => ({
  type: CONST.PROJECT_CREATE_REQUEST,
  inputData
});

export const createStoryRevertCopy = storyID => ({
  type: CONST.STORY_CREATE_REVERT_COPY,
  storyID
});

export const revertStoryChanges = () => ({
  type: CONST.STORY_REVERT_CHANGES
});

// Doesn't appear to be used as of 11/26/2019
// export const updateStory = inputData => ({
//   type: CONST.STORY_UPDATE_REQUEST,
//   inputData,
// });
export const deleteStory = selected => ({
  type: CONST.STORY_DELETE_REQUEST,
  selected
});

export const deleteProject = selected => ({
  type: CONST.PROJECT_DELETE_REQUEST,
  selected
});

export function setStoryBrowserCategoryFilter(filter, selector) {
  // We can force URL navigation with the action below - uncomment dependency above
  // browserHistory.push('/library/' + filter);
  return {
    type: CONST.SET_STORY_BROWSER_CAT_FILTER,
    filter,
    selector
  };
}

export function unsetStoryBrowserCategoryFilter(filter, selector) {
  return {
    type: CONST.UNSET_STORY_BROWSER_CAT_FILTER,
    filter,
    selector
  };
}

export function setCardBrowserSearch(search, selector) {
  return {
    type: CONST.SET_STORY_BROWSER_SEARCH,
    search,
    selector
  };
}

export function setStoryBrowserIsLoading(value) {
  return {
    type: CONST.SET_STORY_BROWSER_LOADING,
    value
  };
}

export function setStoryBrowserIsSaved(value) {
  return {
    type: CONST.SET_STORY_BROWSER_ISSAVED,
    value
  };
}

export function selectToggle(id, selector) {
  return {
    type: CONST.STORY_BROWSER_SELECT_TOGGLE,
    id,
    selector
  };
}

export function exclusiveSelectToggle(id, selector) {
  return {
    type: CONST.STORY_BROWSER_EXCLUSIVE_SELECT_TOGGLE,
    id,
    selector
  };
}

export function shiftSelectToggle(id, items, first) {
  return {
    type: CONST.STORY_BROWSER_SHIFT_SELECT_TOGGLE,
    id,
    items,
    first
  };
}

export const setBrowsingMode = mode => ({
  type: CONST.STORY_BROWSER_SET_BROWSING_MODE,
  mode
});

export const librarySetTotalCount = totalCount => ({
  type: CONST.STORY_BROWSER_SET_TOTAL_COUNT,
  totalCount
});

// export const setActiveEditingElement = activeElement => ({
//   type: CONST.STORY_EDITOR_SET_ACTIVE_ELEMENT,
//   activeElement,
// });

export const activeEditingScene = activeSlide => ({
  type: CONST.STORY_EDITOR_SET_ACTIVE_SLIDE,
  activeSlide
});

// -----------------------------
// does this belong here?
export const requestLibrary = account => ({
    type: CONST.LIBRARY_FETCH_REQUEST,
    account
  });

export const setStoryProp = (storyID, prop, value, isLocalized, langID) => ({
  type: CONST.STORY_SET_PROP,
  storyID,
  prop,
  value,
  isLocalized,
  langID
});

export const setStoryDesignProp = (storyID, prop, value) => ({
  type: CONST.STORY_SET_DESIGN_PROP,
  storyID,
  prop,
  value
});

export const saveSettings = storyID => ({
  type: CONST.STORY_SETTINGS_SAVE_REQUEST,
  storyID
});

export const saveDesignSettings = storyID => ({
  type: CONST.STORY_DESIGN_SETTINGS_SAVE_REQUEST,
  storyID
});

export const saveQuestionsScene = (sceneID, inputData) => ({
  type: CONST.STORY_QUESTION_SCENE_SAVE_REQUEST,
  sceneID,
  inputData
});

export const changeLanguage = langID => ({
  type: CONST.CHANGE_LANGUAGE,
  langID
});

export const dispatchUserPreferenceChange = (selector, name, value) => ({
  type: CONST.USER_PREFERENCE_CHANGE,
  selector,
  name,
  value
});

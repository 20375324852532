import { combineReducers } from 'redux-immutable';
import Logger from "utils/logger";
import base from './base';
import data from './data';
import auth from './auth';
import ui from './ui';
// import * as CONST from 'actions/action_constants';

// Sub Reducers
// fromJS,

/** 
 * CMS APP REDUCER 
 * combining all sub-reducers
*/

const cmsApp = combineReducers({
  base,
  auth,
  data,
  ui,
});

/** 
 * MAP TO PROPS Functions
*/
export const getAppProps = (state, ownProps) => {
  const status = state.getIn(['base', 'status'], {}).toJS(); // (state.get('base'))? state.get('base').toJS(): {}
  const auth = state.get('auth', {}).toJS();
  const { isInit, isFetching } = status; //
  const { isAuthenticated, isAuthChecking } = auth;
  const isAuthFetching = auth.isFetching;
  return {
    isAuthenticated,
    isAuthFetching, // if we are in process of attempting login
    isAuthChecking, // if we are in process of checking refresh token from previous session
    isInit,
    isFetching,
  };
};

// TODO: may want to move to new file....
export const getAppLayoutProps = (state, ownProps) => {
  const base = state.get('base', {}).toJS();
  const { status, config, queue, mainSidebar } = base;

  const auth = state.get('auth', {}).toJS();
  const account = state.getIn(['data', 'account'], {}).toJS();

  // @TODO Store a generic isDirty or isSave flag - instead of this library-only one
  // @TODO Find a better way of fetching asset ID

  const locationPathname = ownProps.location.pathname.split('/');

  // Would be great to replace the code to obtain isSaved with
  // the following: const isSaved = state.getIn(['ui', 'isSaved']);
  // That would require altering code in lots of different contexts
  // that trigger an isSaved == false/true state.
  let isSaved = true;
  if (locationPathname[1] === 'library') {
    if (locationPathname[2] === 'view') {
      const asset = locationPathname[3]
        ? state.getIn(['data', 'assets']).toJS()[locationPathname[3]]
        : null;
      isSaved = asset ? (typeof asset.isSaved !== 'undefined' ? asset.isSaved : true) : true;
    
    }
  } else if (locationPathname[1] === 'stories') {
    isSaved = state.getIn(['ui', 'storyEditor', 'isSaved']);

  } else if (locationPathname[1] === 'account') {
    if (locationPathname[2] === 'users') {
      if (locationPathname[3]){
        isSaved = state.getIn(['data', 'accountusers', locationPathname[3], 'isSaved']) !== undefined
          ? state.getIn(['data', 'accountusers', locationPathname[3], 'isSaved'])
          : true
      }
    }
  }

  const { isInit, isFetching, error, isUIError, uiErrorMessage } = status; //

  const activeSidebar = mainSidebar; // TODO: have this change based on location params??
  // use the url to determine the menuto display
  const { isAuthenticated } = auth;

  let open = false,
    message = '',
    queId = '';

  // @NOTE: There was an assumption here that queue was an immutable object but it doesnt seem to be - all the code
  // below has been translated to treat objects as javascript arrays instead.
  if (queue && Object.keys(queue).length > 0) {
    // find any completed who have NOT ben shown //
    const queFilter = (q) => {
      const completed = q.completed;
      const shown = q.shown || false;
      Logger.debug({ completed, shown }, 'Filtering ');
      return completed && !shown;
    };
    const completedQueItems = [];
    for (const key in queue) {
      const completed = queue[key].completed;
      const shown = queue[key].shown || false;
      if (completed && !shown) {
        completedQueItems.push(queue[key]);
      }
    }
    if (completedQueItems.length > 0) {
      const queItem = completedQueItems[0];
      open = !!queItem;
      message = queItem && queItem.message ? queItem.message : 'Success';
      queId = queItem.id;
    }
  }

  return {
    isSaved,
    isInit,
    isFetching,
    activeSidebar,
    account,
    open,
    message,
    queId,
    isAuthenticated,
    error, // is base encounters an error we can report in layout
    isUIError, 
    uiErrorMessage
  };
};

export default cmsApp;

import { localizeTextProps2 } from "helpers/localizationHelper";
import _forEach from "lodash/forEach";

/**
 * NOTE: A similar version of this function exists in the sceneEditor
 * mapToProps file, but it takes an array of localized elements
 * rather than a pointer to a form to determine which elements are
 * localized.
 *
 * The ListItem form components require simple values, not element
 * objects (as they're stored in the database). Text input fields,
 * for example, require a simple string, not an element object. So
 * convert all the elements to simple values, localizing as necessary.
 *
 * Typical text element looks something like this before flattening:
 *
 *  title: {
 *    type: "text",
 *    use: "title",
 *    text: {
 *      en: "<p>My Title</p>"
 *    }
 *  }
 *
 * And then after flattening, the element will look like this:
 *  title: "<p>My Title</p>"
 *
 * @param  {object} elements - Object with elements to be flattened.
 * @param  {object} form - Object with an object representing each
 *                      element in the form. Used to determine if an
 *                      element needs to be localized.
 * @param  {string} langID - Two-character string representing current language.
 * @return {object} elements with form elements flattened.
 */
export const flattenElements = (elements, form, langID) => {
  const newElements = localizeTextProps2(langID, form, elements);

  _forEach(newElements, (element, key) => {
    switch (element.type) {
      case "text":
        /*
        If the text element hasn't been localized, it'll still
        have a text propery. Go ahead and flatten non-localized
        text elements. The localizeTextElements() method removes
        the text property.
        */
        if (typeof newElements[key].text !== "undefined") {
          newElements[key] = element.text;
        }
        break;

      // Why is the select list value being converted to a string?
      // Seems like it should be a number.
      case "select":
        newElements[key] = element.value.toString();
        break;

      case "checkbox":
      case "select-multiple":
        newElements[key] = element.value;
        break;
    }
  });

  return newElements;
};

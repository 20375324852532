import { put, call } from "redux-saga/effects";
import * as api from "api";
import * as CONST from "actions/action_constants";

import uuid from "uuid-v4";

// THEME EDITOR SAGAS

export function* themeEditorSaga() {
  try {
    const response = yield call(api.fetchThemeEditor);
    yield put({ type: CONST.THEME_EDITOR_SUCCESS, response });
  } catch (error) {
    yield put({ type: CONST.THEME_EDITOR_ERROR, error });
  }
}

export function* themeUpdateSaga(action) {
  const { themeIndex, themeData } = action; //could we get the QUEID from the action?
  // @ NOTE may have to have the queId set here in order to have access down the chain
  const queId = uuid(); //unique ID used for the saving queue -- will need this to access later
  try {
    // make sure we add to the que
    yield put({ type: CONST.QUEUE_REQUEST, queType: "theme", queId });
    const response = yield call(api.updateTheme, themeIndex, themeData);
    response.type = CONST.UPDATE_THEME_SUCCESS;
    yield put(response);

    // create a new copy --
    yield put({ type: CONST.COPY_THEME, force: true, themeData });
    // tell the queItem we are complete
    yield put({
      type: CONST.QUEUE_RESPONSE,
      success: true,
      message: response.message || "Operation Complete",
      queId
    });

    // update the preview object
    yield put({ type: CONST.PREVIEW_THEME_STYLE, themeIndex });
  } catch (error) {
    yield put({ type: CONST.UPDATE_THEME_FAILURE, themeIndex }); //
    yield put({
      type: CONST.QUEUE_RESPONSE,
      success: false,
      message: error,
      queId
    });
  }
}
//series of action for when a theme is reverted
export function* themeRevertSaga(action) {
  // reset the active theme to the revert copy
  // update the preview
  // make sure active theme is set to save false
}

export function* themeStyleAddSaga(action) {
  // @NOTE this saga holds up the ui so no need for it to go in the que
  const { styleName, styleSet } = action;
  //const queId = uuid();
  try {
    //yield put({type:CONST.QUEUE_REQUEST, queType:'style', queId});
    const addResponse = yield call(api.addNewStyle, styleName, styleSet);
    addResponse.type = CONST.ADD_STYLE_SUCCESS;
    yield put(addResponse);
    //yield put({type: CONST.QUEUE_RESPONSE, success:true, message:addResponse.message || 'Operation Complete', queId});
  } catch (error) {
    yield put({ type: CONST.ADD_STYLE_FAILURE, error });
    //yield put({type: CONST.QUEUE_RESPONSE, success:false, message: error, queId});
  }
}

// series of actions for when a new them is selected to be edited
export function* applyThemeSaga(action) {}

export function* resetEditorSaga(action) {
  // remove copy made
  yield put({ type: CONST.REMOVE_COPY_THEME }); // listeded by data
  // reset preview object
  yield put({ type: CONST.RESET_PREVIEW_DATA }); //linsitend by themeEditor
}

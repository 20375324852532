import { put, call, select } from "redux-saga/effects";
import { updateUserPreference } from "api";
import * as selectors from "./selectors";
import * as CONST from "actions/action_constants";

/**
 *
 * @param {?} action.selector - path to preference
 * @param {string} action.name - Name of preference, i.e., the prop to change
 *                                on the preference node indicated by selector.
 * @param {*} action.value - New value of preference.
 */
export function* handleUserPreferenceChangeSaga(action) {
  try {
    const userInfo = yield select(selectors.getUserInfo);

    const { response, error } = yield call(
      updateUserPreference,
      userInfo._id,
      action.selector,
      action.name,
      action.value
    );
    if (response) {
      yield put({
        type: CONST.USER_PREFERENCE_SAVE_SUCCESS
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.USER_PREFERENCE_SAVE_ERROR, message });
  }
}

import React from "react";
import { connect } from "react-redux";
import Logger from "utils/logger";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "material-ui/Tabs";
import { List, Dialog } from "@terraincognita/ui-core";
import RaisedButton from "material-ui/RaisedButton";
import _debounce from "lodash/debounce";
import _map from "lodash/map";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ContentContainer from "modules/ContentContainer";
import { gradedQuestionEditorMapStateToProps } from "mapToProps/sceneEditor";
import IconButton from "material-ui/IconButton";
import LeftChevron from "material-ui/svg-icons/navigation/chevron-left";
import RightChevron from "material-ui/svg-icons/navigation/chevron-right";
import SelectField from "material-ui/SelectField";
import _cloneDeep from "lodash/cloneDeep";
import MenuItem from "material-ui/MenuItem";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";

export class GradedQuestionEditor extends React.Component {
  constructor(props) {
    super(props);

    let data = this.props.activeEditingSubScene.elements;
    if (this.props.activeEditingSubScene.elements) {
      data = {
        ...data,
        showFeedback: this.props.activeEditingSubScene.showFeedback,
        shuffleOptionOrder: this.props.activeEditingSubScene.showFeedback,
        isRequired: this.props.activeEditingSubScene.isRequired
      };
    }
    this.state = {
      tab: "question",
      isDirty: false,
      data,
      confirmModal: false,
      tempLink: null,
      updatingOnlyAsset: false,
      activeLanguage: this.props.langID
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (
      formTab,
      formGroup,
      param,
      value
    ) {
      Logger.debug("GradedQuestionEditor: debounceInputChange");
      this.handleInputChanges.apply(this, [formTab, formGroup, param, value]);
    },
    500);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleRevertActionFromModal = this.handleRevertActionFromModal.bind(
      this
    );
    this.handleAddAsset = this.handleAddAsset.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  componentWillReceiveProps(nextProps) {
    let data = _cloneDeep(nextProps.activeEditingSubScene.elements);

    if (
      nextProps.activeEditingSubScene.elements ||
      nextProps.activeEditingSubScene.showFeedback !==
        this.state.data.showFeedback ||
      nextProps.activeEditingSubScene.shuffleOptionOrder !==
        this.state.data.shuffleOptionOrder ||
      nextProps.activeEditingSubScene.isRequired !== this.state.data.isRequired
    ) {
      data = {
        ...data,
        showFeedback: nextProps.activeEditingSubScene.showFeedback,
        shuffleOptionOrder: nextProps.activeEditingSubScene.shuffleOptionOrder,
        isRequired: nextProps.activeEditingSubScene.isRequired
      };
      Logger.debug({data}, "[GRADED QUESTION EDITOR] componentWillReceiveProps", );
      this.setState({
        data
      });
    }
    if (typeof nextProps.activeEditingSubScene.isSaved !== "undefined") {
      Logger.debug({ isSaved: nextProps.activeEditingSubScene.isSaved },
        "[GRADED QUESTION EDITOR] componentWillReceiveProps activeEditingSubScene.isSaved"
      );
      this.setState({ isDirty: !nextProps.activeEditingSubScene.isSaved });
      Logger.debug( { isDirty: !nextProps.activeEditingSubScene.isSaved },
        "[GRADED QUESTION EDITOR] triggering storySetIsSaved"       
      );
      this.props.storySetIsSaved(nextProps.activeEditingSubScene.isSaved);
    } else {
      // this.setState({ isDirty: false });
      // this.props.storySetIsSaved(true);
    }
  }

  proceedToLink(link) {
    Logger.debug({ link },"[GradedQuestionEditor] Proceed to Link");
    this.props.history.push(link);
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.changeLanguage(value);
  }

  render() {
    const { activeEditingScene, activeSlideAssetCloudId } = this.props;
    Logger.debug({ props: this.props }, "GradedQuestionEditor" );
    const topBar = (
      <div className="graded-quiz-editor-topbar">
        <div className="scene-navigation-component">
          {this.props.goPrevURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("prev")}
            >
              <LeftChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : null}
          <div className="slide-numbers-container">
            {this.props.curSlideNo ? this.props.curSlideNo : null}
            &nbsp; of &nbsp;
            {this.props.totalSlideNo ? this.props.totalSlideNo : null}
          </div>
          {this.props.goNextURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("next")}
            >
              <RightChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : (
            <div className="empty-arrow" />
          )}
        </div>

        <RaisedButton
          primary
          disabled={!this.state.isDirty}
          className="new-asset-button"
          label="SAVE"
          labelStyle={{ textTransform: "none" }}
          onClick={e => {
            e.stopPropagation();
            this.handleSave();
          }}
          style={{ float: "right", marginRight: 50, marginTop: 15 }}
        />

        <SelectField
          className="language-select"
          value={this.state.activeLanguage}
          onChange={this.handleLanguageChange}
        >
          {this.props.languages &&
            _map(this.props.languages, item => (
              <MenuItem
                key={item.id}
                value={item.id}
                primaryText={item.label}
              />
            ))}
        </SelectField>
      </div>
    );
    const tabStyle = {
      buttonStyle: {
        backgroundColor: "#d7d7d7",
        color: "rgb(72, 72, 72)",
        zIndex: 0
      }
    };

    const tabs = (
      <Tabs value={this.state.tab} onChange={this.handleTabChange}>
        <Tab label="QUESTION" value="question" {...tabStyle} />
        <Tab label="FEEDBACK" value="feedback" {...tabStyle} />
      </Tabs>
    );

    return (
      <div className="graded-quiz-editor">
        <Helmet>
          <title>Graded Quiz Question Editor</title>
        </Helmet>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="quiz-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.slugBase}
          handleCloseClicked={this.handleCloseClicked}
          headerTheme="light"
          confirmNavigation={this.state.isDirty}
          history={this.props.history}
          title={this.state.data.question}
          headerMode="extended-extra"
          topBar={topBar}
          headerTabs={tabs}
        >
          <div className="graded-quiz-editor-container">
            <div
              className="tab-form-container question-form-container"
              style={{
                visibility: this.state.tab === "question" ? "visible" : "hidden"
              }}
            >
              <div className="tab-column column-question column-right-border">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.question.question}
                    handleDeleteClick={this.handleDeleteAsset}
                    handleAddClick={() => this.handleAddAsset("questionAsset")}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(
                        "question",
                        "question",
                        param,
                        value
                      )
                    }
                  />
                ) : null}
              </div>
              <div className="tab-column column-options column-right-border">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.question.options}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(
                        "question",
                        "options",
                        param,
                        value
                      )
                    }
                  />
                ) : null}
              </div>
              <div className="tab-column column-settings">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.question.questionSettings}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(
                        "question",
                        "questionSettings",
                        param,
                        value
                      )
                    }
                  />
                ) : null}
              </div>
            </div>
            <div
              className="tab-form-container feedback-form-container"
              style={{
                visibility: this.state.tab === "feedback" ? "visible" : "hidden"
              }}
            >
              <div className="tab-column column-settings column-right-border">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.feedback.feedbackSettings}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(
                        "feedback",
                        "feedbackSettings",
                        param,
                        value
                      )
                    }
                  />
                ) : null}
              </div>
              <div className="tab-column column-answer column-right-border">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.feedback.correctAnswer}
                    handleDeleteClick={this.handleDeleteAsset}
                    handleAddClick={() =>
                      this.handleAddAsset("feedbackCorrectAsset")
                    }
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(
                        "feedback",
                        "correctAnswer",
                        param,
                        value
                      )
                    }
                  />
                ) : null}
              </div>
              <div className="tab-column column-answer">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.feedback.incorrectAnswer}
                    handleDeleteClick={this.handleDeleteAsset}
                    handleAddClick={() =>
                      this.handleAddAsset("feedbackIncorrectAsset")
                    }
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(
                        "feedback",
                        "incorrectAnswer",
                        param,
                        value
                      )
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
        </ContentContainer>
        <Dialog
          content={
            'If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          }
          handleConfirm={this.handleRevertActionFromModal}
          confirmLabel="Confirm"
          bodyStyle={{ paddingTop: 20 }}
          handleCancel={this.closeDisplayRevertCancel}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
      </div>
    );
  }

  handleAddAsset(param) {
    this.setState({ updatingOnlyAsset: true });
    this.props.openAssetSelectorFunc(param, false);
  }

  handleAssetSelected(assetID, elementID) {
    this.props.sceneSetAssetElement(elementID, assetID);
    this.props.storySetIsSaved(false);
  }

  handleDeleteAsset(assetData) {
    this.setState({ updatingOnlyAsset: true });
    this.props.sceneSetAssetElement(assetData.param, "");
    this.props.storySetIsSaved(false);
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.storySetIsSaved(true);
    this.props.updateScene();
  }

  handleCloseClicked() {
    this.handleSceneExit(this.props.slugBase);
  }

  handlePrevNextClicked(direction) {
    const targetLink =
      direction === "prev" ? this.props.goPrevURL : this.props.goNextURL;
    this.handleSceneExit(targetLink);
  }

  handleSceneExit(targetLink) {
    if (this.state.isDirty) {
      this.setState({ tempLink: targetLink }, () =>
        this.openDisplayRevertCancel()
      );
    } else {
      this.proceedToLink(targetLink);
    }
  }

  handleRevertActionFromModal() {
    this.props.storySetIsSaved(true);
    this.props.sceneSetIsSaved(true);
    this.props.revertScene();
    this.closeDisplayRevertCancel();
    const link = this.state.tempLink;
    this.setState({ tempLink: null, isDirty: false }, () =>
      this.proceedToLink(link)
    );
  }

  handleInputChanges(formTab, formGroup, param, value) {
    /*
    There are forms that have asset editors and text input fields.
    We had to define a handleInputChanges handler for those forms
    to handle text changes. However, changes in the asset editor
    are handled elsewhere. If this is an asset editor, exit.
    */
    const isAsset = param.indexOf("Asset") > -1;
    if (isAsset) {
      return;
    }

    /*
    The forms are bound to state.data. Therefore, we have to keep
    state.data up to date with changes made by the user in the forms.
    Otherwise, the user's changes will be overwritten.
    */
    const formData = _cloneDeep(this.state.data);

    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    but we do want to convert the Quill empty string to an actual empty 
    string.
    */
    if (value === "<p><br></p>") {
      if (formData[param] === "") {
        return;
      }
      value = "";
    }

    // The options list isn't setting isCorrect to false be default.
    // This fixes that issue.
    if (formTab === "question" && formGroup === "options") {
      _map(value, (option, key) => {
        if (option.isCorrect === null) {
          value[key].isCorrect = false;
        }
      });
    }

    // If the value of the altered form input doesn't equal the previous
    // form input value, update state.data and trigger an update to the store.
    if (formData[param] !== value) {
      formData[param] = value;
      this.setState({ formData });
      this.props.handleInputChange(param, value, formTab, formGroup);
    }
  }

  handleTabChange(value) {
    this.setState({
      tab: value
    });
  }
}

GradedQuestionEditor.contextTypes = {
  router: PropTypes.object
};

GradedQuestionEditor.defaultProps = {};

GradedQuestionEditor.propTypes = {
  activeEditingScene: PropTypes.object,
  activeEditingSubScene: PropTypes.object,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  elementID: PropTypes.string,
  scenes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugBase: PropTypes.string,
  type: PropTypes.string,
  openLayoutSelectorFunc: PropTypes.func,
  openAssetSelectorFunc: PropTypes.func,
  openSceneEditorModalFunc: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return gradedQuestionEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    storyID,
    sceneID,
    slugBase,
    activeEditingSubScene,
    forms,
    localizedElements,
    langID,
    dataReadyForSave
  } = stateProps;
  return Object.assign({}, stateProps, {
    handleInputChange: (property, value, formTab, formGroup) => {
      const isRoot =
        property === "shuffleOptionOrder" ||
        property === "showFeedback" ||
        property === "isRequired";
      dispatchProps.setElementInput(
        property,
        value,
        langID,
        activeEditingSubScene._id,
        isRoot,
        false,
        localizedElements.includes(property),
        forms[formTab][formGroup]
      );
    },
    openAssetSelectorFunc: (param, multiple) => {
      ownProps.openAssetSelectorFunc(param, multiple);
    },
    sceneSetIsSaved: isSaved => {
      dispatchProps.sceneSetIsSaved(activeEditingSubScene._id, isSaved);
    },
    storySetIsSaved: isSaved => {
      Logger.debug("GradedQuestionEditor: storySetIsSaved");
      dispatchProps.setStoryBrowserIsSaved(isSaved);
    },
    sceneSetAssetElement: (elementID, assetID) => {
      dispatchProps.sceneSetAssetElement(
        activeEditingSubScene._id,
        elementID,
        assetID
      );
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    updateScene: () => {
      const sceneType = "quiz-graded-questions";
      const subsceneType = "quiz-graded-question";
      Logger.debug({dataReadyForSave}, "[GRADED QUESTION EDITOR] - updateScene", );
      dispatchProps.updateScene(
        activeEditingSubScene._id,
        dataReadyForSave,
        forms,
        sceneType,
        subsceneType
      );
      // dispatchProps.setStoryBrowserIsSaved(true);
    },
    revertScene: () => {
      dispatchProps.revertScene();
    }
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps, { withRef: true })(
    GradedQuestionEditor
  )
);

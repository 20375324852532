/**
 * Stories Map To Props Functions
 *
 */
import { Map } from "immutable";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import _filter from "lodash/filter";

import {
  localizeTextProps,
  localizeTextProps2
} from "helpers/localizationHelper";
import { getVisibleItems } from "helpers/cardBrowserHelper";
import * as selectors from "sagas/selectors";

/**
 * MAP Props selectors
 * these functions are imported into the connected components and used to prepare props that come from
 * state
 */

/**
 * InstanceBrowserMapStateToProps
 * called on the init of the connected Module - StoryBrowser
 * @param {object} state
 * @param {object} ownProps
 */
export const instanceBrowserMapStateToProps = (state, ownProps) => {
  const account = state.getIn(["data", "account"], Map);
  const user = state.getIn(["data", "user"], Map);
  const stories = state.getIn(["data", "stories"], Map).toJS();
  const projects = state.getIn(["data", "projects"], Map).toJS();
  const assets = state.getIn(["data", "assets"], Map).toJS();
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const { cardBrowserConfig, isLoading, to } = storyEditor;
  const isCollections = ownProps.match.path.indexOf("/collections") > -1;
  const isProjects = ownProps.match.path.indexOf("/projects") > -1;
  const instanceType = isCollections
    ? "collection"
    : isProjects
    ? "project"
    : "story";

  const cardBrowserConfigUserPrefsSelector = [
    `${instanceType}Manager`,
    "browser",
    "cardBrowserConfig"
  ];
  const cardBrowserConfigUserPrefs = state
    .getIn(
      ["ui", "userPreferences", ...cardBrowserConfigUserPrefsSelector],
      Map({})
    )
    .toJS();
  if (cardBrowserConfigUserPrefs.cardSizeMultiplier) {
    cardBrowserConfig.cardSizeMultiplier =
      cardBrowserConfigUserPrefs.cardSizeMultiplier;
  }
  if (cardBrowserConfigUserPrefs.sortByType) {
    cardBrowserConfig.sortByType = cardBrowserConfigUserPrefs.sortByType;
  }
  if (cardBrowserConfigUserPrefs.sortByOrder) {
    cardBrowserConfig.sortByOrder = cardBrowserConfigUserPrefs.sortByOrder;
  }

  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const storyTypes = state.getIn(["base", "storyTypes"], Map({})).toJS();
  const projectTypes = state.getIn(["base", "projectTypes"], Map({})).toJS();
  const { langID } = baseConfig; //
  const localizedProps = storyEditor.localizedProps || ["title"]; // read from storyEditor state, is it based on type???
  // NOTE i think they arrive from the db as an array?
  // before getting visible Items need to resolve the title to be string....
  const allInstancesArray = [];
  const projectID =
    ownProps.match.params && ownProps.match.params.projectID
      ? ownProps.match.params.projectID
      : null;
  if (isProjects) {
    if (projectID) {
      const storyIDs =
        projects[projectID] && projects[projectID].storyIDs
          ? projects[projectID].storyIDs
          : {};
      _map(storyIDs, story => {
        const tempStory = stories[story] ? stories[story] : null;
        if (tempStory) {
          allInstancesArray.push(
            localizeTextProps(langID, localizedProps, tempStory)
          );
        }
      });
    } else {
      _map(projects, (project, projectId) => {
        if (projectId !== "revertCopy") {
          allInstancesArray.push(
            localizeTextProps(langID, localizedProps, project)
          );
        }
      });
    }
  } else {
    _map(stories, (story, storyId) => {
      if (storyId !== "revertCopy") {
        const storyTempType = selectors.getStoryTypeParam(state, story.type);
        if (storyTempType === "collection" && isCollections) {
          allInstancesArray.push(
            localizeTextProps(langID, localizedProps, story)
          );
        }
        if (storyTempType !== "collection" && !isCollections) {
          allInstancesArray.push(
            localizeTextProps(langID, localizedProps, story)
          );
        }
      }
    });
  }

  const section = isCollections ? "collections" : isProjects ? "projects" : to;
  const visibleItems = !isLoading
    ? getVisibleItems(allInstancesArray, assets, cardBrowserConfig, section)
    : [];

  return {
    accountID: account.get("_id", null),
    username: user.get("email", null),
    // isFetching: dataStatus.get("isFetching", false),
    // fetchMessage: dataStatus.get("fetchMessage", ""),
    isLoading,
    isCollections,
    instanceType,
    isProjects,
    projectID,
    projectData: projects ? projects[projectID] : undefined,
    selected: cardBrowserConfig.selected,
    minCardSize: cardBrowserConfig.cardSizeMultiplier
      ? cardBrowserConfig.cardSizeMultiplier
      : 210,
    settings: state.get("base", Map).toJS(), // need to see if this can just be passed down
    items: visibleItems,
    storyTypes,
    projectTypes,
    cardBrowserConfigUserPrefsSelector,
    storyBrowserUIState: cardBrowserConfig
  };
};

/**
 * StoryEditorMapStateToProps
 * called on the init of the connected Module - StoryEditor
 * @param {object} state
 * @param {object} ownProps
 */
export const storyEditorMapStateToProps = (state, ownProps) => {
  const { params, path } = ownProps.match;
  const isFeedbackList = path.indexOf("feedback") > -1;
  const { storyID, section, collectionID, subsceneID, sceneID } = params; // may want to change to sceneID

  const dataStoryID = storyID || collectionID;
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const { storyTypes, localizedProps } = storyEditor;

  let storyData = state.getIn(["data", "stories", dataStoryID], Map({})).toJS();
  let storyRevertCopy = state.getIn(["data", "stories", "revertCopy"]);
  if (typeof storyRevertCopy !== "undefined") {
    storyRevertCopy = Map(storyRevertCopy).toJS();
  }
  const storyType = storyData
    ? selectors.getStoryTypeParam(state, storyData.type)
    : null;

  // The data in state.base.storyTypes is different (less) than the data
  // in ui.storyEditor.storyTypes. We can't use the latter because it
  // doesn't have the title.
  const baseStoryType = storyType
    ? state.getIn(["base", "storyTypes", storyType])
    : null;
  const pageTitle = baseStoryType
    ? `${baseStoryType.get("title").replace("Story", "")} Editor`
    : null;

  const sceneData = state.getIn(["data", "scenes", sceneID], Map({})).toJS();

  const styles = state.getIn(["data", "styles"], Map({})).toJS(); // these are user editable  styles

  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const { langID } = baseConfig;
  // const localizedProps = storyEditor.localizedProps || []; // read from storyEditor state, is it based on type???

  // changed this to target the types in data node of store
  const storyTypeConfigNav =
    storyType && storyTypes[storyType]
      ? storyTypes[storyType].navigation || {}
      : {}; // target the type for active story

  const storyTypeLocalizedProps =
    storyType && storyTypes[storyType]
      ? storyTypes[storyType].localizedProps || localizedProps
      : localizedProps; // target the type for active story

  const slugBase = storyID ? "/stories/:storyID" : "/collections/:collectionID"; // used by Route Switch
  // storyData = localizeTextProps(langID, storyTypeLocalizedProps, storyData);
  storyData = !_isEqual(storyData, {})
    ? localizeTextProps2(
        langID,
        storyEditor.storyTypes[storyType].forms.settings,
        storyData
      )
    : storyData;
  //----
  // Configure sidebar
  let sideBarTitle = `${storyData.title}`;
  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}Extra`;
  let goBackAction = storyID ? `${storyEditor.to}` : "/collections";
  let showButtons = false;
  let sideBarSlugBase = `${goBackAction}/${dataStoryID}`;
  // merge the navigation object with the elements unique to this type...
  let navigation = {
      ...storyTypeConfigNav,
      ...storyEditor.navigation
    },
    sidebarData;

  // set special navigation based on StoryType
  // this seems a little hacky, but its a starting point
  // should have a regEx replace for :sceneID in the slugs

  if (navigation && storyData.sceneIDs) {
    // loop through run
    _forEach(navigation, (item, key) => {
      // TODO: run regEx on the slug to populate rather than hardcodeing
      // NOTE: THE KEYS are hardcoded!! so if we change in in json
      switch (key) {
        case "cover":
          navigation[key].slug += `/${storyData.sceneIDs[0]}`;
          break;
        case "assets":
          navigation[key].slug += `/${
            storyData.sceneIDs[1] || storyData.sceneIDs[0]
          }`;
          break;
        case "questions":
          navigation[key].slug += `/${
            storyData.sceneIDs[1] || storyData.sceneIDs[0]
          }`;
          break;
        case "results":
          navigation[key].slug += `/${
            storyData.sceneIDs[2] || storyData.sceneIDs[0]
          }`;
          break;
        case "events":
          navigation[key].slug += `/${
            storyData.sceneIDs[1] || storyData.sceneIDs[0]
          }`;
          break;
        case "places":
          navigation[key].slug += `/${
            storyData.sceneIDs[1] || storyData.sceneIDs[0]
          }`;
          break;
        case "basemap":
          navigation[key].slug += `/${
            storyData.sceneIDs[1] || storyData.sceneIDs[0]
          }`;
          break;
      }
    });
  }

  // properties, effected by url...
  if (section) {
    if (isFeedbackList) {
      sideBarTitle = "feedback";
      navigation = {};
      sideBarSlugBase += `/${section}/${sceneID}/subscenes/${subsceneID}`;
      goBackAction = sideBarSlugBase;
    } else {
      sideBarTitle = `${section}`;
      // may decide to have navigation read from somewhere else if section is different?
      navigation = {};
      sideBarSlugBase += `/${section}`;
      goBackAction += `/${storyID || collectionID}`;
    }
  }
  if (section === "settings") {
    // TODO: we should probably have a generic settings form to fall back.
    navigation =
      storyTypes[storyType] &&
      storyTypes[storyType].forms &&
      storyTypes[storyType].forms.settings
        ? storyTypes[storyType].forms.settings
        : null;
    // TODO: Move this to API data
    const settingsAsset = storyData.asset ? storyData.asset : null;
    sidebarData = { ...storyData, ...settingsAsset };
  } else if (section === "design") {
    navigation =
      storyTypes[storyType] &&
      storyTypes[storyType].forms &&
      storyTypes[storyType].forms.design
        ? storyTypes[storyType].forms.design
        : null;

    sidebarData = { ...storyData.layoutConfig };
  } else if (section === "questions" && !subsceneID) {
    navigation =
      storyTypes[storyType] &&
      storyTypes[storyType].forms &&
      storyTypes[storyType].forms.questions
        ? storyTypes[storyType].forms.questions
        : null;
    showButtons = true;
    sidebarData = {
      randomizeQuestions: sceneData.randomizeQuestions,
      limitNumberOfQuestions: sceneData.limitNumberOfQuestions,
      numberOfQuestions: sceneData.numberOfQuestions,
      randomizeAnswers: sceneData.randomizeAnswers
    };
  }

  // for some story types we need to add sceneID to the slug OF certain navigation elements....
  // for gallery the first sceneID is a cover, the second the gallery
  // the assumpotion is that at this point any default scenes will have been added to the sceneIDs array

  return {
    pageTitle,
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    sidebarMode,
    slugBase,
    styles,
    section,
    storyID,
    sceneID,
    collectionID,
    storyData,
    languages,
    langID,
    storyRevertCopy,
    sidebarProps: {
      title: sideBarTitle,
      navigation,
      sidebarData,
      slugBase: sideBarSlugBase,
      goBackAction,
      showButtons
    },
    storyType
  };
};

/**
 * layoutSelectorMapStateToProps
 * called on the init of the connected Module - LayoutSelector
 * @param {object} state
 * @param {object} ownProps
 */
export const layoutSelectorMapStateToProps = (state, ownProps) => {
  const { storyID, slideId } = ownProps;

  const userID = selectors.getUserInfo(state)._id;

  const account = state.getIn(["data", "account"], Map);
  let filterByType;
  if (storyID) {
    const storyType = state.getIn(["data", "stories", storyID, "type"]);
    const storyTypeParam = selectors.getStoryTypeParam(state, storyType);
    // Setting filtering logic dependant on story type
    // @TODO: This logic should be contained somewhere in the data/config
    if (storyTypeParam === "slideshow") {
      filterByType = "5ab2c6821d52ea3b596cadb2";
    }
  }

  if (slideId) {
    const sceneType = state.getIn(["data", "scenes", slideId, "type"]);
    const sceneTypeParam = selectors.getSceneTypeParam(state, sceneType);

    if (sceneTypeParam === "cover") {
      filterByType = "5ab2c6821d52ea3b596cadb4";
    }
  }

  const layouts = state.getIn(["base", "layouts"], Map([])).toJS();
  const layoutsArray = [];
  const layoutsArrayOrdered = [];
  let activeLayoutID = null;
  const accountID = account.get("_id", null);

  const activeLayouts = _filter(layouts, layout => !layout.deprecated);
  _map(activeLayouts, layout => {
    if (typeof filterByType === "undefined" || layout.typeID === filterByType) {
      if (layout.param === ownProps.activeLayout) {
        activeLayoutID = layout._id;
      }
      const { assets } = layout;
      const isCloudinary = !!(assets.cmsThumb && assets.cmsThumb.cloudPublicId);
      layoutsArray[layout.title] = {
        isCloudinary,
        ...assets.cmsThumb,
        _id: layout._id,
        title: layout.title
      };
    }
  });
  let index = 0;
  Object.keys(layoutsArray)
    .sort()
    .forEach(key => {
      layoutsArrayOrdered[index] = layoutsArray[key];
      index++;
    });

  // If there's a selected layout, put it first.
  if (activeLayoutID) {
    const activeLayoutIndex = layoutsArrayOrdered.findIndex(
      layout => layout._id === activeLayoutID
    );
    const firstLayout = { ...layoutsArrayOrdered[activeLayoutIndex] };
    layoutsArrayOrdered.splice(activeLayoutIndex, 1);
    layoutsArrayOrdered.unshift(firstLayout);
  }

  return {
    layouts: layoutsArrayOrdered,
    open: ownProps.open,
    storyID: ownProps.storyID,
    slideId: ownProps.slideId,
    isEditMode: ownProps.isEditMode,
    activeLayout: ownProps.activeLayout,
    activeLayoutID
  };
};

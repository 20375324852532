import { put, call } from 'redux-saga/effects';
import { fetchQuiz, updateQuizQuestion } from 'api';
import Logger from "utils/logger";
import { loadAMCState } from 'store/localStorage';
import * as CONST from 'actions/action_constants';
import uuid from 'uuid-v4';

// QUIZ EDITOR SAGA WORKERS

export function* fetchQuizSaga() {
  try {
    // const amcState = loadAMCState();
    const quizEditorData = yield call(fetchQuiz);
    yield put({ type: CONST.QUIZ_EDITOR_SUCCESS, quizEditorData });
  } catch (error) {
    Logger.error(error,'[SAGAS] fetchQuizSaga' );
    yield put({ type: CONST.QUIZ_EDITOR_ERROR, error });
  }
}

export function* questionUpdateSaga(action) {
  const { questionIndex, questionData } = action;
  const queId = uuid();

  try {
    // make sure we add to the que
    yield put({ type: CONST.QUEUE_REQUEST, queType: 'quiz', queId });

    const response = yield call(updateQuizQuestion, questionIndex, questionData);
    response.type = CONST.QUIZ_QUESTION_UPDATE_SUCCESS;
    yield put(response);

    // create a new copy --
    yield put({ type: CONST.COPY_QUESTION, force: true, questionData });
    // tell the queItem we are complete
    yield put({
      type: CONST.QUEUE_RESPONSE,
      success: true,
      message: response.message || 'Operation Complete',
      queId,
    });
  } catch (error) {
    yield put({ type: CONST.QUIZ_QUESTION_UPDATE_ERROR, questionIndex }); //
    yield put({
      type: CONST.QUEUE_RESPONSE,
      success: false,
      message: error,
      queId,
    });
  }
}

export function* resetAssetSaga(action) {
  // remove copy made
  yield put({ type: CONST.REMOVE_COPY_ASSET });
}

/**
 * Unified Configuration Reader
 *
 * This helper function allows you to use the same API in accessing configuration
 * values no matter where the code is being executed (i.e. browser/node).
 *
 * e.g.
 *   import config from '../config';
 *   config('welcomeMessage'); // => "Hello World!"
 */

/* eslint-disable no-console */
/* eslint-disable import/global-require */
/* eslint-disable no-underscore-dangle */

export const clientSideConfig = {
  // some values may end up deprecated....
  version: process.env.APP_VERSION || "",
  // The host on which the server should run.
  host: process.env.HOST || "localhost",
  // The port on which the server should run. should match whatever webpack config dev server is using
  port: process.env.PORT, //EnvVars.number("PORT", EnvVars.number("CMS_PORT", 3000)), // TODO may want to reference as MANAGE_PORT in future since that is the subdomain

  apiHost: process.env.CENTRAL_API_HOST || "localhost", //EnvVars.string("CENTRAL_API_HOST", "localhost"),
  // The port on which the server should run.
  apiPort: process.env.CENTRAL_API_PORT, //EnvVars.number("CENTRAL_API_PORT", ""),

  // the name of the Auth0 domain set up
  authDomain: process.env.AUTH0_DOMAIN,//EnvVars.string("AUTH0_DOMAIN", "auth0.com"),
  // the clientId of the Auth0 account
  authClient: process.env.AUTH0_CLIENT, //EnvVars.string("AUTH0_CLIENT", "idhdh"),
  // the audience of the Auth0 account
  authAudience: process.env.AUTH0_AUDIENCE || "storycrafter.co",
  authRealm: process.env.AUTH0_REALM || "Local-Test",

  cloudName: process.env.CLOUDINARY_CLOUD_NAME || '-',

  // The port on which the client bundle development server should run.
  clientDevServerPort: process.env.CLIENT_DEV_PORT || 3331 ,

  mapboxAccessToken: process.env.MAPBOX_ACCESS_TOKEN,

  welcomeMessage: process.env.WELCOME_MSG,
  dataPath: 'data',//EnvVars.string("DATA_PATH", path.resolve(__dirname, "data")),

  // Basic configuration for the HTML page that hosts our application.
  // We make use of react-helmet to consume the values below. 
  // @see https://github.com/nfl/react-helmet
  htmlPage: { // TODO: could put there in env rather than hard coded here
      titleTemplate: "Storycrafter",
      defaultTitle: "Storycrafter Content Management",
      description: "Official Content Management System of Storycrafter"
  },

  loginScreen: {
      serviceTitle: process.env.SERVICE_TITLE || "" , 
      welcome: process.env.LOGIN_WELCOME || "" , 
      backgroundImage: process.env.LOGIN_BACKGROUND || "",
  },
  
}

// Export Helper Method
export default function getConfigurationProperty(property) {
  const parts = property.split('.')// TODO: handle more elegantly 
  if(parts.length > 1) {
    return clientSideConfig[parts[0]][parts[1]]
  }
  return clientSideConfig[property] 
}

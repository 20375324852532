import { fromJS } from "immutable";
import * as CONST from "actions/action_constants"; // fromJS,

const userPreferences = (state = fromJS({}), action) => {
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(action.userPreferences));

    case CONST.USER_PREFERENCE_CHANGE:
      const selector = [...action.selector];
      selector.push(action.name);
      return state.setIn(selector, action.value);

    default:
      return state;
  }
};

export default userPreferences;

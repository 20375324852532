// import * as api from 'api';
// import {loadAMCState} from 'store/localStorage';

import * as CONST from 'actions/action_constants';
import Logger from "utils/logger"
// import * as themeEditorActions from './themeEditor';
// import * as libraryActions from './library';

export const authCheck = base => ({
  type: CONST.INIT_AUTH_CHECK
});

export const initializeApp = (idToken) => {
  Logger.debug({ idToken },'[ACTIONS] initializeApp');
  return {
    type: CONST.APP_INIT_REQUEST,
    isFetching: true,
    idToken,
  };
};

// Calls the API to get a token and
// dispatches actions along the way
export const authenticateUser = creds =>
  // @TODO besure to check if we are already fetching auth.isFetching
  ({
    type: CONST.LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  });

export const passwordResetRequested = email => ({
  type: CONST.PASSWORD_RESET_REQUESTED,
  email,
});

export const validateCredentials = (email, password) => { //TODO DON't see this used, constant is not defined as well
  Logger.debug('actions - validateCredentials()');
  return {
    type: CONST.VALIDATE_CREDENTIALS,
    email,
    password,
  };
};

// Logs the user out
export function logoutUser() {
  return {
    type: CONST.LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

export const queueRequest = (queId, queType) => ({
  type: CONST.QUEUE_REQUEST,
  queId,
  queType,
});

export const queueResponse = (queId, success, message) => ({
  type: CONST.QUEUE_RESPONSE,
  queId,
  success,
  message,
});

export const queueItemShown = queId => ({
    type: CONST.QUEUE_ITEM_SHOWN,
    queId,
  });

export const clearSaveQueue = queId => ({
  type: CONST.CLEAR_QUEUE_ITEM,
  queId,
});

export const setState = state => ({
  type: CONST.SET_STATE,
  state,
});

export const toggleSidebar = () => ({
  type: CONST.TOGGLE_SIDEBAR,
});

export const changeLanguage = langID => ({
  type: CONST.CHANGE_LANGUAGE,
  langID,
});

export const resetUIError = () => ({
  type: CONST.RESET_UI_ERROR
})

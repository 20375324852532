import { combineReducers } from "redux-immutable";
import analytics from "./analytics";
import contentEditor from "./contentEditor";
import dashboard from "./dashboard";
import library from "./library";
import userPreferences from "./userPreferences";
import profile from "./profile";
import projectEditor from "./projectEditor";
import quizEditor from "./quizEditor";
import settings from "./settings";
import storyCategoriesBrowser from "./storyCategoriesBrowser";
import storyEditor from "./storyEditor";
import themeEditor from "./themeEditor";
import userManager from "./userManager";
import userEditor from "./userEditor";

/**
 * UI REDUCER
 * is a combined reducer of all the cms sections...
 * STATE.data.styles
 * so all targeting needs to take that into account
 */

// combine parts into one data reducer...
const ui = combineReducers({
  analytics,
  contentEditor,
  dashboard,
  library,
  userPreferences,
  profile,
  projectEditor,
  quizEditor,
  settings,
  storyCategoriesBrowser,
  storyEditor,
  themeEditor,
  userEditor,
  userManager
});

export default ui;

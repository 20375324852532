import { Map } from 'immutable';
import _isEmpty from 'lodash/isEmpty';

export const userListBrowserMapStateToProps = (state, ownProps) => {
  const { params, path } = ownProps.match;
  const users = state.getIn(['data', 'accountusers'], Map()).toJS();
  const sidebarMode = `${state.getIn(['base', 'mainSidebar', 'mode'])}Extra`;
  const addErrorMessage = state.getIn(['ui', 'userManager', 'add_error']);
  const addError = _isEmpty(addErrorMessage) ? false : addErrorMessage;
  const deleteErrorMessage = state.getIn(['ui', 'userManager', 'delete_error']);
  const deleteError = _isEmpty(deleteErrorMessage) ? false : deleteErrorMessage;
  const currentUserID = state.getIn(['data', 'user', "_id"]);

  return {
    sidebarMode,
    users,
    currentUserID,
    addError,
    deleteError
  };
};


export const userEditorMapStateToProps = (state, ownProps) => {
  const { params, path } = ownProps.match;
  const { userID } = params;
  const form = state.getIn(['base', 'accountManager', 'forms', 'user'], Map()).toJS();
  const userData = state.getIn(['data', 'accountusers', userID], Map()).toJS();
  const errorMessage = state.getIn(['ui', 'userEditor', 'error']);
  const currentUserID = state.getIn(['data', 'user', "_id"]);
  const error = _isEmpty(errorMessage) ? false : errorMessage;

  // Initialize isSaved for this user's data. This
  // property does not exist in the database.
  if (userData._id !== undefined){
    userData.isSaved = userData.isSaved === undefined ? true : userData.isSaved;
  }

  if (userID === currentUserID){
    delete form.active;
    delete form.groupID;
  }

  return {
    form,
    userData,
    error
  }
};

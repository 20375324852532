/**
* AUTH Reducer
*/
import { fromJS } from "immutable";
//import {combineReducers} from 'redux-immutable';
import * as CONST from "actions/action_constants";
//import _values from 'lodash/values';

//import isEmpty from 'lodash/isEmpty';
export const defAuth = { isAuthenticated: false };

/**
  Auth Reducer
  
*/
const auth = (state = fromJS(defAuth), action) => {
  const { accessToken, refreshToken, expiresIn } = action;
  switch (action.type) {
    case CONST.INIT_AUTH_CHECK:
      
      return state.set("isAuthenticated", false).set("isAuthChecking", true);
    case CONST.AUTH_CHECK_SUCCESS:
    case CONST.LOGIN_SUCCESS:
      
      return (
        state
          .set("isAuthenticated", true)
          .set("isAuthChecking", false) // this is set to true while do the preAuth check - AUTH_CHECK
          .set("isFetching", false) // this is set to true when a login starts - LOGIN_REQUEST
          //.set("expiresIn", expiresIn)
          //.set("refreshToken", refreshToken) // we are storing this now, because it will be needed for renewal, and may NOT be in the cookie
          //.set("accessToken", accessToken)
          .delete("error")
      );

    case CONST.INIT_AUTH_CHECK_FAILURE:
    case CONST.AUTH_CHECK_FAILURE:
    case CONST.AUTH_RENEW_FAILURE: // this may need to be separate out later
      
      return state.set("isAuthenticated", false).set("isAuthChecking", false);

    case CONST.LOGIN_REQUEST:
     
      return state
        .set("isAuthenticated", false)
        .set("isFetching", true)
        .delete("error");

    case CONST.LOGIN_FAILURE:
      const { message } = action;
   
      return state.set("error", message).set("isFetching", false);

    case CONST.LOGOUT_SUCCESS:
      return state
        .set("isAuthenticated", false)
        .delete("error")
        .delete("expiresIn");
    //.delete("refreshToken")
    //.delete("accessToken");

    default:
      return state; // if action was not passed send state back
  }
};

/****************************************
 * MAP Props selectors
****************************************/

export const getAuthProps = (state, ownProps) => {
  const auth = state.get("auth", {}).toJS();
  const { isAuthenticated, isFetching, error } = auth;
  return {
    isAuthenticated,
    isFetching,
    error
  };
};

export default auth;

import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants';
/**
 *  THEMES SUB REDUCER
 * the STATE that gets passed to this reducer is
 * STATE.data.themes
 * so all targeting needs to take that into account
 */
const themes = (state = Map({}), action) => {
  const { themeIndex, isSaved, themeData } = action;

  switch (action.type) {
    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props
    case CONST.THEME_EDITOR_SUCCESS:
      const { response } = action;
      return state.merge(fromJS(response.themes));

    case CONST.UPDATE_THEME_REQUEST:
      return state.setIn([themeIndex, 'isSaving'], true).setIn([themeIndex, 'isSaved'], true);

    case CONST.UPDATE_THEME_SUCCESS:
      // @NOTE may have to do a merge, not sure we can chain setIn...
      // @NOTE if we want to allow changes while saving, then we CANT toggle the is saved here because it could override
      // dont set the isSaved, because a user COULD potentialy make changes while the saving process occured
      return state.setIn([themeIndex, 'isSaving'], false);

    case CONST.UPDATE_THEME_FAILURE:
      // @NOTE if there is a faliure then the theme woudl need to be marked as isSaved false
      return state.setIn([themeIndex, 'isSaving'], false).setIn([themeIndex, 'isSaved'], false);

    case CONST.ADD_THEME_SUCCESS:
      return state.setIn([themeIndex, 'isSaving'], false);

    case CONST.REVERT_THEME:
      // totally replace what was there
      return state.set(themeIndex, fromJS(themeData));

    case CONST.REMOVE_COPY_THEME:
      return state.delete('revertCopy');

    case CONST.COPY_THEME:
      const { force } = action;
      const currCopy = state.get('revertCopy');
      // NO need to change the state if nothing was passed... or same as current unless FORCE
      if (!force && currCopy && themeData && themeData.param === currCopy.get('param')) {
        return state;
      }
      return state.set('revertCopy', fromJS(themeData));

    case CONST.SET_THEME_STYLE_SUCCESS:
      const { selectedStyle } = action;
      return state.setIn([themeIndex, 'style'], fromJS(selectedStyle)); // this needs to replace current values

    case CONST.SET_THEME_STYLE_VALUE_SUCCESS:
      const { property, value } = action;
      const newState = state.setIn([themeIndex, 'isSaved'], isSaved);
      //
      return newState.setIn([themeIndex, 'style', property], value);
    // @NOTE just like the style we will have a set of actions for layout or any children of the theme node
    default:
      return state;
  }
};

export default themes;

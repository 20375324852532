import React, {Component} from "react";
import Logger from "utils/logger";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import Popover from "material-ui/Popover";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import ArrowDropDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import {Dialog} from "@terraincognita/ui-core";
import {getStyleList} from "reducers/data/index.js";
import "./StylesActionBar.scss";

//import SelectField from 'material-ui/SelectField';//this component has an issue with position and layers in a poopup
//import DropDownMenu from 'material-ui/DropDownMenu'; //this component has an issue with position and layers in a poopup
//import RaisedButton from 'material-ui/RaisedButton';
//import CMSInputText from 'components/CMSInputText/CMSInputText';


// POPUP CATEGORYS DROP MENU
const CategoryDropMenu = (props) => {
  // write out drop menu filter of categoriers
  // when selected it will set
  // @TODO could separate parts out to reusable popup card list with filter

  const stylesCategories = props.menuItems || [];
  //className="col-sm-12 style-selector-header-text"<ArrowDropDown />
  return (
    <div >
      <FlatButton labelPosition="before" onClick={props.onOpenClick} label={props.selectedLabel}
                  icon={<ArrowDropDown />}/>
      <IconMenu
        value={props.selectedCategoryId}
        iconButtonElement={<IconButton></IconButton>}
        onChange={(event, value) => props.onChange(event, value)}
        open={props.openDropMenu}
        key={props.selectedCategoryId}
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        targetOrigin={{horizontal: 'left', vertical: 'top'}}
      >
        { stylesCategories.map((option, index) => {
            return <MenuItem key={option.id} value={index} primaryText={option.label}/>
          }
        ) }
      </IconMenu>
    </div>
  )

}

// POPUP LIST OF CARDS
const StyleList = (props) => {
  // write out scrolling list of styless
  //const allStyles = props.allStyles || {}; //@TODO return from selector as an array filtered be category
  //const userDefined =  === 'editable' ? true : false;
  const styleList = getStyleList(props.stylesStore, props.filter);

  return (
    <div className="tiles-content-area">
      {
        // the list of styles for the category that is selected
        styleList.map((style) => {


          let headerRowStyle = {};
          headerRowStyle = {
            backgroundColor: style.background
          };

          return (
            <div key={style.id} className="container-fluid tile-container">
              <div className="row tile-header-row" style={headerRowStyle}>
                <span className="tile-header-text">{style.name}</span>
              </div>
              <div className="row">
                { props.previewColorList && props.previewColorList.map((varName) => {
                  let colorRowStyle = {
                    backgroundColor: style[varName]
                  };
                  return (
                    <div key={varName} className="col-sm-3 tile-color-row" style={colorRowStyle}></div>
                  )
                })
                }
              </div>

              <div className="tile-action-container">
                <div className="row tile-action-row" onClick={() => props.onSelect(style.param)}>
                  <i className="material-icons">&#xE86C;</i>
                  <span className="tile-action-text">Apply</span>
                </div>
                <div className="row tile-action-row" onClick={() => props.onPreview(style.param)}>
                  <i className="material-icons">&#xE417;</i>
                  <span className="tile-action-text">Preview</span>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

/**
 * SidebarStylesSubheader
 * @type Component
 * @description This this compenent will appear above the sidebar listing it has some special buttons
 * that open a filterable popup list of styles and a form to add new styles
 * it will also handle the appearance of a confirmation modal
 * and i add style form
 *
 * @Props
 *   styleCategories {Array}
 *   allStyles={Object}
 *   previewColorList={Array}
 *  selectedCategoryIndex={Number}
 */

class ActionBar extends Component {
  // this parent compoent handles state for some of its UI dumb components
  constructor(props) {
    super(props);
    // this.state = {
    //   layout: this.props.layout,
    // };
    this.state = {
      // add new stlye form related
      displayAddForm: false, //whether or not to show the save display dialog
      //isStyleAddFormSaving: false, // replaced by props.inProcess
      newStyleName: '',//hold when the new name is added
      //styleAdded: false, //replaced by props.processComplete toggle to know if selected style has been saved
      addStyleCompleteMsg: "Style has been saved!",
      // on success set to this.setState({isStyleAddFormSaving:false, newStyleName:'',styleAdded:true}) false
      // Apply a style related
      displayPopover: false,
      popupAnchorEl: null,
      //filterAnchorEl: null,
      openDropMenu: false, //controls if the drop menu in the popover is open
      selectedCategoryIndex: ( this.props.selectedCategoryIndex || 0 ), //ref to the object
      selectedStyleId: "", //index of the partuclar style choosen
      selectedStyle: undefined,// when style is selected inside a category we pass this to methods for preview or set new
      displaySelectConfirmation: false, // whether or not we need the confirmation modal
      confirmationMsg: "Replace your current values with values from template?",
    }
  }

  // ----------------------------------------
  // ACTION HANDLERS FOR POP-UP MENU
  handleOpenCategoryMenu(event) {
    this.setState({
      openDropMenu: true,
      //filterAnchorEl : event.currentTarget
    });
  }

  // click on the find button to open/close style menu
  handleOpenPopover(event) {
    event.preventDefault();

    this.setState({
      popupAnchorEl: event.currentTarget,
      openDropMenu: false,
      displayPopover: !this.state.displayPopover

    })
  };

  // could be called on any click when popover is open...
  handleClosePopoverRequest(reason) {
    Logger.debug({ reason }, '[SidebarStylesActionbar] handleClose');
    //@NOTE should we just reset to a empty preview object? i thik if we did
    // we coudl potentially lose prever of the data currently editing
    //const previewStyle = this.props.activeDataStore.get('style').toJS();
    this.props.previewThemeStyle(''); // reset the preview object
    this.setState({displayPopover: false, openDropMenu: false});
  };

  // ----------------------------------------
  //ACTION HANDLERS related to STYLE SELECTION
  /**
   * STEP 1 after user selects a style from popup
   */
  handleStyleSelection(value) {


    //@NOTE should the call to preview come from hear rather than in the action creator?

    //show confirmation....
    this.setState({
      'displaySelectConfirmation': true,// shows confirmation box
      'displayPopover': false, // hides popup list
      'selectedStyleId': value // stores for use after confirm
    });
  }

  /**
   * STEP 2 after user hits confirm
   */
  handleStyleSelectConfirm(event, value) {
    Logger.debug({ event, value }, '[SIDEBARSTYLESUBHEADER] handleStyleSelectConfirm');
    const {selectedStyleId} = this.state;
    const selectedStyle = this.props.stylesStore.get(selectedStyleId).toJS();
    //@NOTE we MAY NOT want to pass the full object- just the index...
    this.props.selectThemeStyle(selectedStyle);
    this.setState({
      'displaySelectConfirmation': false,
      'selectedStyleId': ''
    });// clear out selected index
  }

  /**
   * STEP 2a after user hits cancel
   */
  handleConfirmationCancel(event, value) {
    Logger.debug({ event, value }, '[SIDEBARSTYLESUBHEADER] handleConfirmationCancel' );
    this.setState({
      'displaySelectConfirmation': false,
      'selectedStyleId': ''
    });
  }

  // ----------------------------------------
  //ACTION HANDLERS related to STYLE PREVIEW
  /**
   * @param the actual object of the one selected
   * this will be passed on to action for dispatch
   */
  handlePreviewSelection(value) {
    Logger.debug({value}, '[SidebarStylesSubheader] Preview value');
    this.props.previewThemeStyle(value);
  };

  //ACTION HANDLERS related to categories of styles
  //@NOTE NEED TO distinquish between style group change and actual style selection
  handleCategoryChange(event, value) {
    this.setState({'selectedCategoryIndex': value});
  };


  // ----------------------------------------------------
  // ACTION HANDLERS related to STYLE ADD
  /**
   * @param the actual object of the current theme style
   * we will want to passed on to action for dispatch
   */
  handleOpenAddStyleForm() {

    this.props.resetProcessComplete(); //want to make sure form is reset on call
    this.setState({displayAddForm: true});
  }

  handleAddStyleFormClose() {
    this.setState({displayAddForm: false});
    this.props.resetProcessComplete(); //want to make sure form is reset on call
  }

  handleAddStyleFormInput(event, newValue) {

    this.setState({newStyleName: newValue});//will be used on save
  }

  // we will use the form value to get the style name and then target the style
  handleAddStyleFormSave() {
    Logger.debug({ newStyleName: this.state.newStyleName }, '[SidebarStylesSubheader] handleAddStyleFormSave()');
    this.setState({isStyleAddFormSaving: true});
    this.props.addThemeStyle(this.state.newStyleName);// add to the store
  }

  render() {
    //@NOTE not fond of having all these constants in the render....
    //@TODO move styles into the css
    const styles = {
      customWidth: {
        width: 300,
      },
      underlineStyle: {
        color: '',
      },
      dialogStyle: {
        overflowY: 'visible',
        marginLeft: 40,
        marginTop: 40
      }
    };
    const categoryLabel = this.props.styleCategories ? this.props.styleCategories[this.state.selectedCategoryIndex].label : 'None Selected';
    const categoryFilter = this.props.styleCategories ? this.props.styleCategories[this.state.selectedCategoryIndex].id : '';

    // @todo coudl use same compoent we use in the cmslist
    const addStyleFormContent = (
      <div className="save-style-container">
        <div className="row">
          <span className="style-name-label">Style Name</span>
        </div>
        <div className="row">
          <TextField id="text-field-save-style" onChange={this.handleAddStyleFormInput}/>
        </div>
      </div>
    );

    const confirmationContent = <span>{this.state.confirmationMsg}{this.state.selectedStyleId}</span>;

    return (
      <div className="sidebar-styles-actionbar">
        <FlatButton
          className="col-sm-5"
          id="find-style-button"
          onClick={ this.handleOpenPopover }
          label="Apply Style"
          labelPosition="after"
          icon={<i className="material-icons md-24">&#xE8B6;</i>}
        />
        <FlatButton
          className="col-sm-7"
          onClick={ this.handleOpenAddStyleForm }
          label="Add To My Styles"
          labelPosition="after"
          icon={<i className="material-icons md-24">&#xE3BA;</i>}
        />
        <Popover
          className="style-selector-list"
          open={this.state.displayPopover}
          onRequestClose={this.handleClosePopoverRequest}
          anchorEl={this.state.popupAnchorEl}

          style={styles.dialogStyle}
          zDepth={5}
          canAutoPosition={true}
          useLayerForClickAway={false}
        >
          <div className="style-selector-header">
            <CategoryDropMenu
              selectedLabel={categoryLabel}
              openDropMenu={this.state.openDropMenu}
              onOpenClick={this.handleOpenCategoryMenu}
              onChange={this.handleCategoryChange}
              menuItems={this.props.styleCategories}
              selectedCategoryId={this.state.selectedCategoryIndex}/>
          </div>
          <StyleList {...this.props} filter={categoryFilter} onSelect={this.handleStyleSelection}
                     onPreview={this.handlePreviewSelection}/>
        </Popover>

        <Dialog className="add-style-dialog"
                content={this.props.processComplete ? this.state.addStyleCompleteMsg : addStyleFormContent}
                handleConfirm={this.handleAddStyleFormSave}
                confirmLabel="Add"
                handleCancel={this.handleAddStyleFormClose}
                cancelLabel="Cancel"
                modal={true}
          //width={650}
                processingLabel={this.state.isStyleAddFormSaving ? 'saving...' : ''}
                isProcessing={this.props.inProcess}
                processComplete={this.props.processComplete}
                open={this.state.displayAddForm}
                bodyStyle={{paddingTop: 20}}
                onRequestClose={this.handleAddStyleFormClose}
                title="Add New Style"
        />

        <Dialog className="replace-style-dialog"
                content={confirmationContent}
                handleConfirm={this.handleStyleSelectConfirm}
                confirmLabel="Apply"
                handleCancel={this.handleConfirmationCancel}
                cancelLabel="Cancel"
                bodyStyle={{paddingTop: 20}}
                modal={false}
                width={650}
                open={this.state.displaySelectConfirmation}
                onRequestClose={this.handleConfirmationCancel}
                title="Replace the Current Style"
          //actions={confirmationActions}
        />
      </div>
    )
  }
}

export default ActionBar;
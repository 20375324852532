import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logger from "utils/logger";
import RaisedButton from 'material-ui/RaisedButton';
import { Dialog } from '@terraincognita/ui-core';
import styles from './Sidebar.scss';

/**
 * SidebarButtonArea
 *
 * @type                    Component
 * @description             Section with buttons on top of sidebar
 *
 * @props
 *  title {mixed}           Title to be displayed in the header
 */
class SidebarButtonArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCancelConfirm: false,
    };
    this.handleDisplayRevertConfirm = this.handleDisplayRevertConfirm.bind(this);
    this.handleDisplayRevertCancel = this.handleDisplayRevertCancel.bind(this);
    this.handleRevertAction = this.handleRevertAction.bind(this);
  }

  handleDisplayRevertConfirm() {
    this.setState({ displayCancelConfirm: true });
  }

  handleDisplayRevertCancel() {
    Logger.debug('[SIDE BUTTON AREA] handleDisplayRevertCancel');
    this.setState({ displayCancelConfirm: false });
  }

  handleRevertAction() {
    this.setState({ displayCancelConfirm: false });
    this.props.handleRevert();
  }

  render() {
    const buttonStyle = {
      boxShadow: 'none',
      marginRight: 8,
      minWidth: 65,
      backgroundColor: 'transparent',
    };

    const labelStyle = {
      fontSize: 13,
    };
    return (
      <div className="sidebar-button-area">
        <RaisedButton
          onClick={this.props.handleSave}
          label={this.props.saveLabel}
          default
          style={buttonStyle}
          labelStyle={labelStyle}
          labelColor="white"
          backgroundColor={styles.backSaveButton}
          disabled={this.props.disabled}
        />
        {!this.props.hideRevertButton ? (
          <RaisedButton
            onClick={
              this.props.confirmRevert ? this.handleDisplayRevertConfirm : this.props.handleRevert
            }
            label={this.props.revertLabel}
            default
            labelStyle={labelStyle}
            backgroundColor={styles.backCancelButton}
            style={buttonStyle}
            disabled={this.props.disabled}
          />
        ) : null}
        {!this.props.hideRevertButton ? (
          <Dialog
            content="Please confirm that you would like to revert your changes"
            handleConfirm={this.handleRevertAction}
            confirmLabel="Confirm"
            bodyStyle={{ paddingTop: 20 }}
            handleCancel={this.handleDisplayRevertCancel}
            cancelLabel="Cancel"
            modal={false}
            open={this.state.displayCancelConfirm}
            title="Confirm Revert Action"
          />
        ) : null}
      </div>
    );
  }
}

SidebarButtonArea.defaultProps = {
  saveLabel: 'Save',
  hideRevertButton: false,
  revertLabel: 'Revert',
  disabled: false,
  confirmRevert: false,
};

SidebarButtonArea.propTypes = {
  saveLabel: PropTypes.string,
  revertLabel: PropTypes.string,
  hideRevertButton: PropTypes.bool,
  disabled: PropTypes.bool,
  handleRevert: PropTypes.func,
  handleSave: PropTypes.func,
  confirmRevert: PropTypes.bool,
};

export default SidebarButtonArea;

import React from "react";
import {Link} from "react-router-dom";
import _kebabCase from "lodash/kebabCase";
//import {history} from 'storeroutes';

//@NOTE i think we need to catch the on click in order to fire to do the copy of the theme

const ThemeCard = (props) => {
    const slug = props.slugBase + _kebabCase(props.param);
    //const themeId = props.param
    //const type = 'theme';
    //const thumb = '';//this.props.assetMedia.full.url
    const statusClass = props.isActive?'theme-status-indicator active':'theme-status-indicator in-active';

    // <MenuItem
    //     className="navigate-item"
    //     onClick={navClick}
    //     primaryText={item.label}
    //     rightIcon={<ActionInfo />}
    // ></MenuItem>
    
    // const handleClick = () => {
    //     //props.onClick(themeId);
    //     history.push(slug);
    //     
    // };
    // onClick={handleClick}
    return (
        <Link to={slug}>
            <div className="cms-card">
                <div className="card-overlay">

                </div>
                <div className="image-container">
                    
                    <h3 className={statusClass}>
                        {props.isActive? <i className="material-icons">&#xE86C;</i> : null}
                        <span className="status-text">{props.isActive?'Active':'In-Active'}</span>
                    </h3>
                    {/* <img src={thumb} key={props._id} role="presentation"/> */}
                </div>
                
                <div className="title-container">
                    <h2>{props.label}</h2>

                </div>
                
            </div>
        </Link>
    )

}

export default ThemeCard;
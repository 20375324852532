import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ContentContainer from "modules/ContentContainer";
import { analyticsMapStateToProps } from "mapToProps/analytics";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DatePicker from "material-ui/DatePicker";
import _isEqual from "lodash/isEqual";
import * as analyticsActions from "actions/analytics";
import C3Chart from "react-c3js";
import _reactDom from "react-dom";
import "c3/c3.css";
import "./Analytics.scss";

/**
 * November 21, 2018
 *
 * Had to override the C3Chart.update() method to force it
 * to regenerate the chart every time the data changes. The
 * native updateChart() method doesn't account for the axis
 * data changing, and the only way we figured out how to
 * accommodate that is to force the chart to regenerate every
 * time.
 *
 * NOTE: The only dependency we have to account for is _reactDom
 * which we've imported here.
 *
 * Using react-c3js@0.1.20 - Make certain to check for changes
 * to this method and the package if updates are released.
 */
C3Chart.prototype.updateChart = function (config) {
  this.chart = this.generateChart((0, _reactDom.findDOMNode)(this), config);
  if (config.unloadBeforeLoad) {
    this.unloadData();
  }
  this.loadNewData(config.data);
};

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.state = {
      dateRange: this.props.filters.dateRange,
      destinations: this.props.filters.destinations,
      sessions: this.props.filters.sessions,
      popularContent: this.props.filters.popularContent,
      popularContentGroup: this.props.filters.popularContentGroup,
      dateStart: this.props.filters.dateStart
        ? this.props.filters.dateStart
        : yesterday,
      dateEnd: this.props.filters.dateEnd
        ? this.props.filters.dateEnd
        : yesterday,
      loading: false
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(filter, value) {
    this.setState({ [filter]: value, loading: true }, () =>
      this.props.requestAnalytics(this.state)
    );
  }

  // componentDidUpdate(prevProps, prevState){
  //   if(this.props.filter && prevProps.filter && this.props.filter.dateRange !== prevProps.filter.dateRange){
  //     this.setState({dateRange:this.props.filter.dateRange})
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevProps, this.props)) {
      this.setState({ loading: false });
    }
  }

  render() {
    const topBar = <div />;

    const {
      popularContentLabels,
      analyticsData,
      langData,
      weekData,
      popularContentData
    } = this.props;

    const count =
      typeof analyticsData[0] !== "undefined" ? analyticsData[0].count : 0;
    const avgDuration =
      typeof analyticsData[0] !== "undefined"
        ? analyticsData[0].duration_avg / 1000
        : 0;
    const date = new Date(null);
    date.setSeconds(avgDuration);
    const avgDurationFormatted = date.toISOString().substr(11, 8);

    return (
      <div className="analytics-module">
        <Helmet>
          <title>Analytics</title>
        </Helmet>
        <ContentContainer
          className="content-container"
          sidebarMode={this.props.sidebarMode}
          title={"Analytics"}
          topBar={topBar}
        >
          <div className="filter-bar-container">
            <SelectField
              value={this.state.dateRange}
              onChange={(event, key, value) =>
                this.handleFilterChange("dateRange", value)
              }
            >
              {this.props.filterOptions &&
                this.props.filterOptions.dateRange.map((dateRangeItem, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={dateRangeItem.value}
                      primaryText={dateRangeItem.label}
                    />
                  );
                })}
            </SelectField>
            {this.state.dateRange && this.state.dateRange === "custom" ? (
              <div className="custom-date-range-container">
                <DatePicker
                  autoOk
                  hintText="Date From"
                  value={this.state.dateStart}
                  onChange={(event, value) =>
                    this.handleFilterChange("dateStart", value)
                  }
                  shouldDisableDate={date => date > this.state.dateEnd}
                />
                <DatePicker
                  autoOk
                  hintText="Date To"
                  value={this.state.dateEnd}
                  onChange={(event, value) =>
                    this.handleFilterChange("dateEnd", value)
                  }
                  shouldDisableDate={date => date < this.state.dateStart}
                />
              </div>
            ) : null}
            <SelectField
              style={{ marginLeft: 20 }}
              value={this.state.destinations}
              onChange={(event, key, value) =>
                this.handleFilterChange("destinations", value)
              }
            >
              {this.props.filterOptions &&
                this.props.filterOptions.destinations.map(
                  (dateRangeItem, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={dateRangeItem.value}
                        primaryText={dateRangeItem.label}
                      />
                    );
                  }
                )}
            </SelectField>
          </div>

          <div className="overview-language-container">
            <div className="overview-container">
              <h2>Overview</h2>
              <div className="sessions-counter">
                <span className="main-text">{count}</span>
                <br />
                Project
                <br />
                Sessions
              </div>
              <div className="average-duration">
                <span className="main-text">{avgDurationFormatted}</span>
                <br />
                Average Project
                <br />
                Session Duration
              </div>
            </div>
            <div className="language-container">
              <h2>Language</h2>
              {langData ? (
                <C3Chart
                  data={langData.data}
                  size={{ height: 200, width: 200 }}
                />
              ) : null}
            </div>
          </div>
          <hr />
          <div className="sessions-by-container">
            <h2>Project Sessions By</h2>
            <SelectField
              value={this.state.sessions}
              onChange={(event, key, value) =>
                this.handleFilterChange("sessions", value)
              }
            >
              {this.props.filterOptions &&
                this.props.filterOptions.sessions.map((dateRangeItem, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={dateRangeItem.value}
                      primaryText={dateRangeItem.label}
                    />
                  );
                })}
            </SelectField>
            {weekData ? (
              <C3Chart
                data={weekData.data}
                axis={weekData.axis}
                sessionsBy="Week"
                unloadBeforeLoad={true}
              />
            ) : null}
          </div>

          <hr />

          <div className="popular-content-container">
            <h2>Popular Content</h2>
            <hr style={{ margin: 0, marginTop: 30 }} />
            <SelectField
              value={this.state.popularContent}
              onChange={(event, key, value) =>
                this.handleFilterChange("popularContent", value)
              }
            >
              {this.props.filterOptions &&
                this.props.filterOptions.popularContent.map(
                  (dateRangeItem, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={dateRangeItem.value}
                        primaryText={dateRangeItem.label}
                      />
                    );
                  }
                )}
            </SelectField>

            <SelectField
              style={{ marginLeft: 20 }}
              value={this.state.popularContentGroup}
              onChange={(event, key, value) =>
                this.handleFilterChange("popularContentGroup", value)
              }
            >
              {this.props.filterOptions &&
                this.props.filterOptions.popularContentGroup.map(
                  (dateRangeItem, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={dateRangeItem.value}
                        primaryText={dateRangeItem.label}
                      />
                    );
                  }
                )}
            </SelectField>

            {typeof popularContentData !== "undefined" &&
            popularContentLabels.length > 0 &&
            !this.state.loading ? (
              <C3Chart
                data={popularContentData.data}
                legend={{ show: false }}
                color={{ pattern: ["#fd7f23"] }}
                axis={{
                  rotated: true,
                  x: {
                    show: true,
                    type: "category",
                    categories: popularContentLabels.map(label =>
                      label.replace("<p>", "").replace("</p>", "")
                    )
                  },
                  y: {
                    show: false
                  }
                }}
              />
            ) : null}
          </div>
        </ContentContainer>
      </div>
    );
  }

  componentDidMount() {
    this.props.requestAnalytics(this.state);
  }
}

Analytics.defaultProps = {};

Analytics.propTypes = {
  sidebarMode: PropTypes.string,
  sampleData: PropTypes.object
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { slugBase, account } = stateProps;
  // const {account} = ownProps;
  return Object.assign({}, stateProps, {
    requestAnalytics: filters => {
      return dispatchProps.requestAnalytics(filters, account._id);
    }
  });
}

function mapStateToProps(state, ownProps) {
  return analyticsMapStateToProps(state, ownProps);
}

export default connect(
  mapStateToProps,
  analyticsActions,
  mergeProps
)(Analytics);

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RaisedButton from "material-ui/RaisedButton";
import { CardBrowser, CardBrowserSelectedDisplay } from "@terraincognita/ui-core";
import ContentContainer from "modules/ContentContainer";
import { layoutSelectorMapStateToProps } from "mapToProps/storyEditor";
import * as sceneEditorActions from "actions/sceneEditor";
import "./LayoutSelector.scss";

export class LayoutSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.isEditMode
        ? this.props.activeLayoutID
          ? this.props.activeLayoutID
          : null
        : null
    };
    this.selectLayout = this.selectLayout.bind(this);
    this.backClickHandler = this.backClickHandler.bind(this);
    this.handleCloseAction = this.handleCloseAction.bind(this);
    this.functionHandler = this.functionHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isEditMode || nextProps.isEditMode) {
      if (
        nextProps.activeLayoutID &&
        nextProps.activeLayoutID !== this.state.activeLayoutID
      ) {
        this.setState({ selected: this.props.activeLayoutID });
      }
    }
  }

  selectLayout(layoutId) {
    if (this.state.selected === layoutId) {
      this.setState({ selected: null });
    } else {
      this.setState({ selected: layoutId });
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.props.open && nextProps.open) {
      this.setState({ selected: null });
    }
  }

  backClickHandler() {
    this.setState({ selected: null });
  }

  functionHandler() {
    this.props.closeAction();
    if (this.props.isEditMode) {
      this.props.changeLayout(this.state.selected);
    } else {
      this.props.addSlide(this.state.selected);
    }
  }

  handleCloseAction() {
    this.props.closeAction();
  }

  render() {
    const topBar = this.state.selected ? (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedLabel={`Select Layout`}
          deselectAllAction={this.props.closeAction}
        />
        <div className="select-button-container">
          <RaisedButton
            primary
            className="new-asset-button"
            label="SELECT"
            labelStyle={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.functionHandler();
            }}
          />
        </div>
      </div>
    ) : (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedLabel={`Select Layout`}
          deselectAllAction={this.props.closeAction}
        />
      </div>
    );

    return (
      <div className={`layout-selector ${this.props.open ? "open" : ""}`}>
        <ContentContainer
          isLoading={this.props.isLoading}
          className="content-container"
          selectMode={true}
          sidebarMode="none"
          title="Select Layout"
          topBar={topBar}
        >
          {this.props.layouts.length > 0 && this.props.open ? (
            <CardBrowser
              selected={[this.state.selected]}
              minCardSize={150}
              minCardMargin={60}
              items={this.props.layouts}
              enableKeys={false}
              showButtons
              openModal={this.props.open}
              isModal
              selectHandler={this.selectLayout}
              selectMode={false}
              exclusiveSelectHandler={this.selectLayout}
              backClickHandler={this.backClickHandler}
            />
          ) : null}
        </ContentContainer>
      </div>
    );
  }
}
LayoutSelector.defaultProps = {
  open: false,
  isEditMode: false
};

LayoutSelector.propTypes = {
  open: PropTypes.bool,
  sceneType: PropTypes.string,
  closeAction: PropTypes.func,
  storyID: PropTypes.string,
  slideId: PropTypes.string,
  layouts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  activeLayout: PropTypes.string, // If we are in Edit mode, we get the active layout currently selected
  isEditMode: PropTypes.bool // If true, a new slide will not be added but a layout changed on an existing one
};

function mapStateToProps(state, ownProps) {
  return layoutSelectorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { storyID, slideId, sceneType } = ownProps;
  return Object.assign({}, stateProps, {
    closeAction: ownProps.closeAction,
    addSlide: layoutId =>
      dispatchProps.createScene(storyID, { type: sceneType }, layoutId),
    changeLayout: layoutId =>
      dispatchProps.changeLayout(storyID, slideId, layoutId)
  });
}

export default connect(
  mapStateToProps,
  sceneEditorActions,
  mergeProps
)(LayoutSelector);

import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants'; // fromJS,

/** 
* PROFILE REDUCER
* 
*/
const profileEditor = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { profile } = action; // ,
      return state.merge(fromJS(profile));
    default:
      return state;
  }
};

export default profileEditor;

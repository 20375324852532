import Logger from "utils/logger";

const uploadItem = (item, settings, callback) => {
  const data = new FormData();
  data.append("file", item.image_url);
  data.append("upload_preset", settings.upload_preset);
  data.append("folder", settings.folder);
  fetch(`https://api.cloudinary.com/v1_1/${settings.cloud_name}/image/upload`, {
    method: "post",
    body: data
  })
    .then(resp => resp.json())
    .then(data => {
      if ("error" in data) {
        callback([], data.error);
      } else {
        Logger.debug("Uploaded successfully to " + data.url);
        callback([{ ...data, image_metadata: item }]);
      }
    })
    .catch(err => {
      Logger.error(err);
      callback([], err);
    });
};
export const openSearchWidget = (settings, callback) => {
  GLOBAL_SETTINGS = settings;
  GLOBAL_CALLBACK = callback;
  var iframe;
  iframe = document.createElement("iframe");
  iframe.id = "jstory-search-widget";
  iframe.src =
    "https://j-story.org/app/search-widget?access_token=k5jdg9haihe6klnfg";
  iframe.style.width = "1110px";
  iframe.style.height = "550px";
  iframe.style.background = "#FFFFFF";
  iframe.allowtransparency = true;
  document.body.appendChild(iframe);
};
let GLOBAL_SETTINGS;
let GLOBAL_CALLBACK;
window.addEventListener("message", message => {
  if (message.data && message.data.value) {
    message.data.value.map((chosenItem, index) => {
      Logger.debug("Chosen item: " + JSON.stringify(chosenItem));
      uploadItem(chosenItem, GLOBAL_SETTINGS, GLOBAL_CALLBACK);
    });
    var elem = document.querySelector("#jstory-search-widget");
    if (elem) {
      Logger.debug("Removing iframe");
      elem.parentNode.removeChild(elem);
    }
  }
});

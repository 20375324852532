/**
 * topBar
 */
import React from "react";
import PropTypes from "prop-types";
import Logger from "utils/logger";
import RaisedButton from "material-ui/RaisedButton";
import {
  CardBrowserSlider,
  CardBrowserFilter,
  CardBrowserSelectedDisplay
} from "@terraincognita/ui-core";
import _isEmpty from "lodash/isEmpty";

class TopBar extends React.Component {
  render() {
    const {
      cardBrowserConfig,
      proceedToAdd,
      openDeleteModal,
      exclusiveSelectHandler,
      isSliderDisabled,
      setSliderMultiplier,
      onSortByClick,
      onSortByOrderClick
    } = this.props;
    // don't display anything if config isnt passed
    if (_isEmpty(cardBrowserConfig)) {
      return null;
    } else if (cardBrowserConfig.selectMode) {
      // are we in select mode?
      return (
        <div className="selected-bar">
          <CardBrowserSelectedDisplay
            selectedCount={
              cardBrowserConfig.selected
                ? cardBrowserConfig.selected.length
                : undefined
            }
            deselectAllAction={exclusiveSelectHandler}
          />
          <div className="delete-asset-button-container">
            <RaisedButton
              primary
              className="new-scene-button"
              label="DELETE"
              labelStyle={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                openDeleteModal();
              }}
            />
          </div>
        </div>
      );
    }
    // default state
    return (
      <div className="filters-bar-container">
        <div className="filters-bar">
          <RaisedButton
            primary
            className="new-asset-button"
            label="ADD"
            labelStyle={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              Logger.debug("ADD");
              proceedToAdd();
            }}
          />

          {!isSliderDisabled && <div className="divider" />}

          {!isSliderDisabled && (
            <CardBrowserSlider
              changeValue={setSliderMultiplier}
              initialValue={cardBrowserConfig.cardSizeMultiplier}
            />
          )}

          {onSortByClick ? (
            <CardBrowserFilter
              onSortByClick={onSortByClick}
              onSortByOrderClick={onSortByOrderClick}
              sortByTypeOptions={cardBrowserConfig.sortByTypeOptions}
              sortByType={cardBrowserConfig.sortByType}
              sortByOrder={cardBrowserConfig.sortByOrder}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  goBackAction: PropTypes.string,
  cardBrowserConfig: PropTypes.object,
  setSliderMultiplier: PropTypes.func,
  exclusiveSelectHandler: PropTypes.func,
  proceedToAdd: PropTypes.func,
  openDeleteModal: PropTypes.func,
  onSortByClick: PropTypes.func,
  onSortByOrderClick: PropTypes.func
};

export default TopBar;

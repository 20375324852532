import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Switch from "react-router-dom/Switch";
import Route from "react-router-dom/Route";
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar";
import { storyEditorMapStateToProps } from "mapToProps/storyEditor";
import * as storyActions from "actions/storyEditor";
import SceneEditor from "./SceneEditor";
import SceneBrowser from "./SceneBrowser";
import SceneListBrowser from "./SceneListBrowser";
import CollectionItemsBrowser from "../CollectionItemsBrowser";
import CategoryListBrowser from "./CategoryMananger/CategoryListBrowser";
import CategoryEditor from "./CategoryMananger/CategoryEditor";
import StoryPreviewer from "./StoryPreviewer";
import "./StoryEditor.scss";
// import SlideshowEditor from './SlideshowEditor';

// temp stubs
// start by adding stubs here will move them out later,
const SettingsEditor = props => (
  <div className="editor-content-container">Settings Editor</div>
);

const StyleEditor = props => {
  return <div className="editor-content-container">Style Editor</div>;
};
const LayoutEditor = props => (
  <div className="editor-content-container">Layout Editor</div>
);
// const StoryPreviewer = props => (
//   <div className={`editor-previewer-container  ${props.sidebarMode}`}>
//     <div className="label-container">Story Previewer Stub</div>
//   </div>
// );

/**
 * StoryEditor
 * a. edit scenes <- this leve we determine the scene type and how will be edited
 * b. edit styles
 * c. edit settings
 * NOTE: not sure what the style should be set on the content to make it sit nicely to the right of
 * Story editor navigation, we can create a standard apporach later
 */
export class StoryEditor extends Component {
  componentDidMount() {
    /*
    Defining the storyID is complicated by the fact that when a story is being edited,
    there's a storyID prop, but when a collection is being edited, there is no storyID,
    but rather a collectionID. So if there is a storyID prop, a story is being edited,
    so use the storyID prop. Otherwise, use the collectionID prop.
    */
    const storyID = this.props.storyID
      ? this.props.storyID
      : this.props.collectionID;

    // NOTE: we need to account for the fact that someone else could be editing the story
    // however we dont want to have the data called over and over
    // check to see if all storyData is present, and we have any scenes in the storyID
    // and see if they are in the scenes
    // NOTE: THIS could become a JOB -- ALWAYS
    this.props.requestStoryData(storyID);

    // TODO: Perhaps we don't need loading all the library here
    this.props.requestLibrary();
  }

  componentDidUpdate(prevProps, prevState) {
    const storyID = this.props.storyID
      ? this.props.storyID
      : this.props.collectionID;

    /*
    If the storyRevertCopy prop is undefined, that means this component has never 
    been mounted before, and a revert copy hasn't been created before. As long as
    the story data is defined, that means the store has been populated, and the 
    story data is accessible to be copied. It may be possible that the storyData
    prop will always be defined by this point, but I'm just not certain, so we're
    checking to make certain.

    Otherwise, if the storyRevertCopy is defined and the ID of that story doesn't
    match the storyID prop, that means a new story is being edited, and a new 
    revert copy needs to be created.
    */
    if (typeof this.props.storyRevertCopy === "undefined") {
      if (typeof this.props.storyData !== "undefined") {
        this.props.createStoryRevertCopy();
      }
    } else {
      if (storyID !== this.props.storyRevertCopy._id) {
        this.props.createStoryRevertCopy();
      }
    }
  }

  render() {
    const {
      slugBase,
      storyData,
      pageTitle,
      sidebarProps,
      section,
      styles,
      storyType
    } = this.props; // NOTE: this slugBase includes the ProjectID
    // TODO: we need to pass the back action for sidebar of children IF section is present
    const StorySidebarActionBar = (
      <div>
        <div className="story-type-container">{storyType || "Not Set"}</div>
      </div>
    );
    let sidebarActions;
    if (section === "settings") {
      sidebarActions = {
        handleInputChange: (prop, value, isLocalized) =>
          this.props.handleSettingsInputChange(prop, value, isLocalized),
        handleSave: () => {
          // Update the story revert copy to reflect the current state of
          // the story now that it's being saved.
          this.props.createStoryRevertCopy();
          this.props.saveSettings();
        },
        handleRevert: () => this.props.revertStoryChanges(),
        changeLanguage: value => this.props.changeLanguage(value),
        setSaved: value => this.props.setSaved(value),
        languages: this.props.languages,
        langID: this.props.langID,
        section: "settings",
        saveLabel: "Save"
      };
    } else if (section === "design") {
      sidebarActions = {
        handleInputChange: (prop, value, selector) =>
          this.props.handleDesignSettingsInputChange(prop, value, selector),
        handleSave: () => {
          // Update the story revert copy to reflect the current state of
          // the story now that it's being saved.
          this.props.createStoryRevertCopy();
          this.props.saveDesignSettings();
        },
        handleRevert: () => this.props.revertStoryChanges(),
        setSaved: value => this.props.setSaved(value),
        section: "design",
        saveLabel: "Save"
      };
    } else if (section === "questions") {
      sidebarActions = {
        handleSave: data => {
          this.props.saveQuestionsScene(data);
        },
        setSaved: value => this.props.setSaved(value),
        section: "questions",
        saveLabel: "Save"
      };
    }

    const { navigation } = sidebarProps;
    // NOTE: we could probably just loop and build the route switch below from the navigation data
    // if we decide to do that we will want to add a few props to the data

    // looks like the params.match prop is scoped to this switch, meaning that the components created in the switch will not receive
    // matches from higher in the slug, unless we added the params we want to catch in the path here, like we do in layout
    // so if we want to have the values passed down (accessible by maptoprops ) need to include in the matches
    // other wise we need to pass down just what is relevant...
    // could change our slug base to read just the "to" from the storyEditor

    return (
      <div className={`cms-screen story-editor ${storyType}`}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {/* for now all children bring their own sidebar as needed   */}
        <Switch>
          <Route
            exact
            path={`${slugBase}`}
            render={() => (
              <Sidebar {...sidebarProps} actionBar={StorySidebarActionBar} />
            )}
          />
          {/* <Route exact path={`${slugBase}/scenes`} render={() => <Sidebar {...sidebarProps} />} /> */}
          <Route
            exact
            path={`${slugBase}/design`}
            render={() => <Sidebar {...sidebarProps} {...sidebarActions} />}
          />
          <Route
            exact
            path={`${slugBase}/settings`}
            render={() => <Sidebar {...sidebarProps} {...sidebarActions} />}
          />
          <Route
            exact
            path={`${slugBase}/questions/:sceneID`}
            render={() => (
              <Sidebar
                {...sidebarProps}
                {...sidebarActions}
                className="questions-sidebar"
              />
            )}
          />
          <Route
            exact
            path={`${slugBase}/items`}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={`${slugBase}/events/:sceneID`}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={`${slugBase}/scenes/:sceneID/subscenes/:subsceneID/feedback`}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={`${slugBase}/places/:sceneID`}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={`${slugBase}/styles`}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={`${slugBase}/layout`}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={`${slugBase}/categories`}
            render={() => <Sidebar {...sidebarProps} />}
          />
        </Switch>

        <Switch>
          {/* NOTE: we added the switch at this level to insure ALL "sub" components below it have, to insure the active Story's data and Scenes have been loaded and can be accessed */}
          <Route
            exact
            path={`${slugBase}`}
            render={() => (
              <StoryPreviewer sidebarMode={this.props.sidebarMode} />
            )}
          />
          <Route
            exact
            path={`${slugBase}/items`}
            component={CollectionItemsBrowser}
          />
          <Route
            exact
            path={`${slugBase}/items/:itemID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/items/:itemID/subscenes/:subsceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/items/:itemID/subscenes/:subsceneID/feedback`}
            component={SceneEditor}
          />

          <Route exact path={`${slugBase}/scenes`} component={SceneBrowser} />
          <Route
            exact
            path={`${slugBase}/questions/:sceneID`}
            component={SceneListBrowser}
          />
          <Route
            exact
            path={`${slugBase}/events/:sceneID`}
            component={SceneListBrowser}
          />
          <Route
            exact
            path={`${slugBase}/basemap/:sceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/places/:sceneID/:subsceneID/gallery/:galleryAssetSceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/events/:sceneID/:subsceneID/gallery/:galleryAssetSceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/places/:sceneID/:subsceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/places/:sceneID`}
            component={SceneListBrowser}
          />
          <Route
            exact
            path={`${slugBase}/results/:sceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/cover/:sceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/cover/:sceneID/elements/:elementID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/questions/:sceneID/:subsceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/events/:sceneID/:subsceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/scenes/:sceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/scenes/:sceneID/:elementID`}
            component={SceneEditor}
          />

          <Route
            exact
            path={`${slugBase}/scenes/:sceneID/subscenes/:subsceneID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/scenes/:sceneID/subscenes/:subsceneID/feedback/:feedbackID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/scenes/:sceneID/subscenes/:subsceneID/feedback`}
            component={SceneListBrowser}
          />
          <Route
            exact
            path={`${slugBase}/scenes/:sceneID/elements/:elementID`}
            component={SceneEditor}
          />
          <Route
            exact
            path={`${slugBase}/categories`}
            component={CategoryListBrowser}
          />
          <Route
            exact
            path={`${slugBase}/categories/:categoryID`}
            component={CategoryEditor}
          />
          <Route
            exact
            path={`${slugBase}/design`}
            render={() => (
              <StoryPreviewer sidebarMode={this.props.sidebarMode} />
            )}
          />
          <Route
            exact
            path={`${slugBase}/settings`}
            render={() => (
              <StoryPreviewer sidebarMode={this.props.sidebarMode} />
            )}
          />
          <Route exact path={`${slugBase}/styles`} component={StyleEditor} />
          <Route exact path={`${slugBase}/layout`} component={LayoutEditor} />
        </Switch>

        {/* put switch back here for sceneEditor, stlyes.... 
          <StoryPreviewer isLoading={this.props.isLoading} sidebarMode={this.props.sidebarMode} /> */}
      </div>
    );
  }
}

/**
* helper method for using router history
* @param {string} url - where to navigate to - ? are w expecting a slug or relative to this parent?
* @param {object} history - context.router.history 
* @param {boolean} replace - are we just adding to history, or should we replace

*/
const navigateTo = (url, history, replace) => {
  if (replace) {
    history.replace(url);
  } else {
    history.push(url);
  }
};

StoryEditor.defaultProps = {
  isLoading: false
};

StoryEditor.propTypes = {
  isLoading: PropTypes.bool,
  sidebarMode: PropTypes.string,
  sidebarProps: PropTypes.object,
  section: PropTypes.string,
  storyData: PropTypes.object,
  storyID: PropTypes.string,
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  langID: PropTypes.string,
  storyType: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return storyEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { account, storyID, collectionID, sceneID, langID } = stateProps;

  return Object.assign({}, stateProps, {
    requestStoryData: storyID => dispatchProps.requestStoryData(storyID),
    requestLibrary: () => dispatchProps.requestLibrary(account),
    handleSettingsInputChange: (prop, value, isLocalized) => {
      // See notes in componentDidMount() regarding why this logic for
      // defining a storyID is necessary.
      const currentStoryID = storyID ? storyID : collectionID;
      dispatchProps.setStoryProp(
        currentStoryID,
        prop,
        value,
        isLocalized,
        langID
      );
    },
    saveSettings: () => {
      // See notes in componentDidMount() regarding why this logic for
      // defining a storyID is necessary.
      const currentStoryID = storyID ? storyID : collectionID;
      dispatchProps.saveSettings(currentStoryID);
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    handleDesignSettingsInputChange: (prop, value, selector) => {
      // See notes in componentDidMount() regarding why this logic for
      // defining a storyID is necessary.
      const currentStoryID = storyID ? storyID : collectionID;
      dispatchProps.setStoryDesignProp(currentStoryID, prop, value, selector);
    },
    saveDesignSettings: () => {
      // See notes in componentDidMount() regarding why this logic for
      // defining a storyID is necessary.
      const currentStoryID = storyID ? storyID : collectionID;
      dispatchProps.saveDesignSettings(currentStoryID);
    },
    saveQuestionsScene: data => {
      dispatchProps.saveQuestionsScene(sceneID, data);
    },
    setSaved: value => {
      dispatchProps.setStoryBrowserIsSaved(value);
    },
    createStoryRevertCopy: () => {
      const currentStoryID = storyID ? storyID : collectionID;
      dispatchProps.createStoryRevertCopy(currentStoryID);
    },
    revertStoryChanges: () => {
      dispatchProps.revertStoryChanges();
    }
  });
}

// NOTE: may want to replace the component did mount with use a JOB here to do the call to get scenes?
// Not sure if we need the 'withRouter' at this level
export default withRouter(
  connect(mapStateToProps, storyActions, mergeProps)(StoryEditor)
);

import React from "react";
import Sidebar from "modules/Sidebar/Sidebar";
import ActionBar from "./StylesActionBar";
import {List} from "@terraincognita/ui-core";

//import './Sidebar.scss';


class SectionSidebar extends React.Component {

    render() {
        const {actionBarData, sectionData, navigation, handleInputChange, resetProcessComplete, slug, updateTheme, revertTheme, isSaving} = this.props;

        return (
            <Sidebar {...this.props}
                     goBackAction={this.props.backAction}
                     title={this.props.header}
                     subheader={this.props.subheader}
                     showButtons={true}
                     handleSave={updateTheme}
                     handleRevert={revertTheme}
                     disabled={isSaving || this.props.isSaved}
                     isSavingLabel="Saving..."
                     saveLabel="Save"
                     revertLabel="Revert"
                     actionBar={actionBarData ?
                         <ActionBar {...this.props}
                                    stylesStore={actionBarData.stylesStore}
                                    styleCategories={actionBarData.categories}
                                    previewColorList={actionBarData.previewColorList}
                                    selectedCategoryIndex={actionBarData.selected}
                         />
                         : null}
            >
                <List data={sectionData} items={navigation}
                         resetProcessComplete={resetProcessComplete}
                         handleInputChange={handleInputChange} slugBase={slug}/>

            </Sidebar>
        );
    }
}

export default SectionSidebar;
import { put, call } from 'redux-saga/effects';
import { fetchCollectionBrowser, fetchCollectionData } from 'api';
import { loadState } from 'store/localStorage';
import * as CONST from 'actions/action_constants';
import { deLocalizeTextProps } from 'helpers/localizationHelper';
//----
// SELECTORS
// used in the saga

// STORY BROWSER SAGA WORKERS
/**
 * FetchStoryBrowserSaga
 */
export function* fetchCollectionBrowserSaga() {
  try {
    const { response, error } = yield call(fetchCollectionBrowser);

    if (response) {
      yield put({ type: CONST.COLLECTION_BROWSER_SUCCESS, collectionBrowserData: response });
    } else {
      throw new Error(error);
    }

  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.COLLECTION_BROWSER_ERROR, message });
  }
}

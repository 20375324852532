import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "material-ui/Tabs";
import { List, CardBrowser, Dialog } from "@terraincognita/ui-core";
import RaisedButton from "material-ui/RaisedButton";
import _debounce from "lodash/debounce";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ContentContainer from "modules/ContentContainer";
import { collectionItemEditorMapStateToProps } from "mapToProps/sceneEditor";
import IconButton from "material-ui/IconButton";
import LeftChevron from "material-ui/svg-icons/navigation/chevron-left";
import RightChevron from "material-ui/svg-icons/navigation/chevron-right";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";

export class CollectionItemEditor extends React.Component {
  constructor(props) {
    super(props);

    const data = this.props.activeEditingScene.elements;

    // Define the selected tab.
    let selectedTab = "details";
    if (this.props.isSceneElementEditorOpen) {
      if (
        this.props.activeEditingScene.subScenes.includes(
          this.props.activeEditingSubScene._id
        )
      ) {
        selectedTab = "gallery";
      } else {
        selectedTab = "rotationGallery";
      }
    }

    this.state = {
      tab: selectedTab,
      isDirty: this.props.activeEditingScene.isSaved
        ? !this.props.activeEditingScene.isSaved
        : false,
      data,
      deleteModalOpen: false,
      selected: [],
      activeLanguage: this.props.langID
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (formGroup, param, value) {
      this.handleInputChanges.apply(this, [formGroup, param, value]);
    }, 500);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleRevertActionFromModal =
      this.handleRevertActionFromModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeEditingScene.elements) {
      this.setState({
        data: { ...nextProps.activeEditingScene.elements }
      });
    }
    if (nextProps.isSceneElementEditorOpen) {
      // Define the selected tab.
      let selectedTab = "gallery";
      if (nextProps.isSceneElementEditorOpen) {
        if (
          nextProps.activeEditingScene.rotationSubScenes &&
          nextProps.activeEditingScene.rotationSubScenes.includes(
            nextProps.activeEditingSubScene._id
          )
        ) {
          selectedTab = "rotationGallery";
        }
      }
      this.handleTabChange(selectedTab);
    }
    if (typeof nextProps.activeEditingScene.isSaved !== "undefined") {
      this.setState({ isDirty: !nextProps.activeEditingScene.isSaved });
    } else {
      this.setState({ isDirty: false });
    }
  }

  proceedToLink(link) {
    this.props.history.push(link);
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  render() {
    const {
      activeEditingScene,
      activeSlideAssetCloudId,
      galleryData,
      rotationGalleryData,
      cardBrowserConfig,
      isGallerySupported,
      isRotationGallerySupported
    } = this.props;

    const tabState = this.state.tab;
    const topBar = (
      <div className="collection-item-editor-topbar">
        <div className="scene-navigation-component">
          {this.props.goPrevURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("prev")}
            >
              <LeftChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : null}
          <div className="slide-numbers-container">
            {this.props.curSlideNo ? this.props.curSlideNo : null}
            &nbsp; of &nbsp;
            {this.props.totalSlideNo ? this.props.totalSlideNo : null}
          </div>
          {this.props.goNextURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("next")}
            >
              <RightChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : (
            <div className="empty-arrow" />
          )}
        </div>

        <RaisedButton
          primary
          disabled={!this.state.isDirty}
          className="new-asset-button"
          label="SAVE"
          labelStyle={{ textTransform: "none" }}
          onClick={e => {
            e.stopPropagation();
            this.handleSave();
          }}
          style={{ float: "right", marginRight: 50, marginTop: 15 }}
        />
        <SelectField
          className="language-select"
          value={this.state.activeLanguage}
          onChange={this.handleLanguageChange}
        >
          {this.props.languages &&
            _map(this.props.languages, item => (
              <MenuItem
                key={item.id}
                value={item.id}
                primaryText={item.label}
              />
            ))}
        </SelectField>
        {this.state.tab === "gallery" ||
        this.state.tab === "rotationGallery" ? (
          this.props.cardBrowserConfig.selected &&
          this.props.cardBrowserConfig.selected.length > 0 ? (
            <RaisedButton
              primary
              className="new-asset-button"
              label="DELETE"
              labelStyle={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                this.openDeleteModal();
              }}
              style={{ float: "right", marginRight: 50, marginTop: 15 }}
            />
          ) : (
            <RaisedButton
              primary
              className="new-asset-button"
              label="ADD NEW"
              labelStyle={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                this.props.openAssetSelectorFunc(null, true, {
                  tabState
                });
              }}
              style={{ float: "right", marginRight: 50, marginTop: 15 }}
            />
          )
        ) : null}
      </div>
    );
    const tabStyle = {
      buttonStyle: {
        backgroundColor: "#d7d7d7",
        color: "rgb(72, 72, 72)",
        zIndex: 0
      }
    };

    const tabs = (
      <Tabs value={this.state.tab} onChange={this.handleTabChange}>
        <Tab label="DETAILS" value="details" {...tabStyle} />

        {isGallerySupported ? (
          <Tab label="GALLERY" value="gallery" {...tabStyle} />
        ) : null}
        {isRotationGallerySupported ? (
          <Tab label="ROTATION" value="rotationGallery" {...tabStyle} />
        ) : null}
      </Tabs>
    );

    let headerTitle;
    if (this.props.activeEditingScene) {
      if (this.props.activeEditingScene.elements.title) {
        headerTitle = this.props.activeEditingScene.elements.title;
      }
    } else {
      headerTitle = "Loading...";
    }

    return (
      // Test
      <div className="collection-item-editor">
        <Helmet>
          <title>Collection Item Editor</title>
        </Helmet>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="collection-item-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.slugBase}
          handleCloseClicked={this.handleCloseClicked}
          confirmNavigation={this.state.isDirty}
          headerTheme="light"
          title={headerTitle}
          headerMode="extended-extra"
          topBar={topBar}
          headerTabs={tabs}
        >
          <div className="collection-item-editor-container">
            <div
              className="tab-form-container details-form-container"
              style={{
                visibility: this.state.tab === "details" ? "visible" : "hidden"
              }}
            >
              <div className="tab-column column-general column-right-border">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.details["column-1"]}
                    handleDeleteClick={assetData =>
                      this.handleDeleteAsset(assetData, "column-1")
                    }
                    handleAddClick={props =>
                      this.props.openAssetSelectorFunc(
                        { param: props.param, section: "column-1" },
                        false
                      )
                    }
                    handleInputChange={(param, value) =>
                      this.debounceInputChange("column-1", param, value)
                    }
                    handleEditClick={props => {
                      this.props.handleImageEditClick(props);
                    }}
                  />
                ) : null}
              </div>
              <div className="tab-column column-description column-right-border">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.details["column-2"]}
                    handleDeleteClick={assetData =>
                      this.handleDeleteAsset(assetData, "column-2")
                    }
                    handleAddClick={props =>
                      this.props.openAssetSelectorFunc(
                        { param: props.param, section: "column-2" },
                        false
                      )
                    }
                    handleInputChange={(param, value) =>
                      this.debounceInputChange("column-2", param, value)
                    }
                    handleEditClick={props => {
                      this.props.handleImageEditClick(props);
                    }}
                  />
                ) : null}
              </div>
              <div className="tab-column column-asset">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.details["column-3"]}
                    handleDeleteClick={assetData =>
                      this.handleDeleteAsset(assetData, "column-3")
                    }
                    handleAddClick={props =>
                      this.props.openAssetSelectorFunc(
                        { param: props.param, section: "column-3" },
                        false
                      )
                    }
                    handleInputChange={(param, value) => {
                      this.debounceInputChange("column-3", param, value);
                    }}
                    handleEditClick={props => {
                      this.props.handleImageEditClick(props);
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div
              className="tab-form-container gallery-form-container"
              style={{
                visibility: this.state.tab === "gallery" ? "visible" : "hidden"
              }}
            >
              {Object.keys(galleryData).length > 0 ? (
                <CardBrowser
                  items={galleryData}
                  minCardSize={cardBrowserConfig.cardSizeMultiplier}
                  enableKeys={false}
                  cardBrowserMaxCardSize={
                    cardBrowserConfig.cardBrowserMaxCardSize
                  }
                  showButtons
                  setCardMargin={20}
                  displayCaption={false}
                  isSequentiable
                  executeOnReSort={this.props.setSubSceneOrder}
                  cardStyle={{
                    backgroundColor: "whitesmoke",
                    titleBackgroundColor: "whitesmoke",
                    titleColor: "black",
                    imageFit: "contain"
                  }}
                  editClickHandler={this.props.editClickHandler}
                  selectHandler={this.props.selectHandler}
                  exclusiveSelectHandler={this.props.exclusiveSelectHandler}
                  backClickHandler={this.props.backClickHandler}
                />
              ) : null}
            </div>
            <div
              className="tab-form-container rotation-gallery-form-container"
              style={{
                visibility:
                  this.state.tab === "rotationGallery" ? "visible" : "hidden"
              }}
            >
              {Object.keys(rotationGalleryData).length > 0 ? (
                <CardBrowser
                  items={rotationGalleryData}
                  minCardSize={cardBrowserConfig.cardSizeMultiplier}
                  enableKeys={false}
                  cardBrowserMaxCardSize={
                    cardBrowserConfig.cardBrowserMaxCardSize
                  }
                  showButtons
                  setCardMargin={20}
                  displayCaption={false}
                  isSequentiable
                  executeOnReSort={(oldIndex, newIndex) =>
                    this.props.setSubSceneOrder(
                      oldIndex,
                      newIndex,
                      this.state.tab === "rotationGallery"
                    )
                  }
                  cardStyle={{
                    backgroundColor: "whitesmoke",
                    titleBackgroundColor: "whitesmoke",
                    titleColor: "black",
                    imageFit: "contain"
                  }}
                  editClickHandler={this.props.editClickHandler}
                  selectHandler={this.props.selectHandler}
                  exclusiveSelectHandler={this.props.exclusiveSelectHandler}
                  backClickHandler={this.props.backClickHandler}
                />
              ) : null}
            </div>
          </div>
        </ContentContainer>
        <Dialog
          content={
            'If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          }
          handleConfirm={this.handleRevertActionFromModal}
          confirmLabel="Confirm"
          bodyStyle={{ paddingTop: 20 }}
          handleCancel={this.closeDisplayRevertCancel}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
        <Dialog
          content={
            'Press "Delete" to remove the selected asset(s) from this gallery.'
          }
          handleConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          handleCancel={this.closeDeleteModal}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title="Delete Asset(s)?"
        />
      </div>
    );
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });

    this.props.deleteSubScene(this.state.tab);
    this.props.exclusiveSelectHandler(null); // deselect
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.updateScene(this.state.data);
  }

  handleCloseClicked() {
    this.handleSceneExit(this.props.slugBase);
  }

  handlePrevNextClicked(direction) {
    const targetLink =
      direction === "prev" ? this.props.goPrevURL : this.props.goNextURL;
    this.handleSceneExit(targetLink);
  }

  handleSceneExit(targetLink) {
    if (this.state.isDirty) {
      this.setState({ tempLink: targetLink }, () =>
        this.openDisplayRevertCancel()
      );
    } else {
      this.proceedToLink(targetLink);
    }
  }

  handleRevertActionFromModal() {
    this.closeDisplayRevertCancel();
    this.props.revertScene();
    this.proceedToLink(this.state.tempLink);
    this.setState({ tempLink: null });
  }

  handleInputChanges(formGroup, param, value) {
    const formData = _cloneDeep(this.state.data);

    /*
            The Quill RTE sometimes converts the empty string to the following 
            HTML characters: <p><br></p>. If that's the input change being 
            processed, ignore it. We do not, however, want to ignore a change 
            from a non-empty string to the Quill empty string (<p><br></p>).
            but we do want to convert the Quill empty string to an actual empty 
            string.
            */
    if (value === "<p><br></p>") {
      if (formData[param] === "") {
        return;
      }
      value = "";
    }

    if (formData[param] !== value) {
      formData[param] = value;

      this.props.sceneSetIsSaved(false);
      this.props.handleInputChange(param, value, formGroup);
      this.setState({ formData, isDirty: true });
    }
  }

  handleTabChange(value) {
    this.setState({
      tab: value
    });
  }

  handleDeleteAsset(assetData, section) {
    this.props.sceneSetAssetElement(assetData.use, "", section);
    this.setState({ isDirty: true });
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.changeLanguage(value);
  }
}

CollectionItemEditor.contextTypes = {
  router: PropTypes.object
};

CollectionItemEditor.defaultProps = {};

CollectionItemEditor.propTypes = {
  activeEditingScene: PropTypes.object,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  langID: PropTypes.string,
  elementID: PropTypes.string,
  galleryData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Gallery scene data
  rotationGalleryData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Gallery scene data
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  scenes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugBase: PropTypes.string,
  type: PropTypes.string,
  isSceneElementEditorOpen: PropTypes.bool,
  openLayoutSelectorFunc: PropTypes.func,
  openAssetSelectorFunc: PropTypes.func,
  openSceneEditorModalFunc: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return collectionItemEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    storyID,
    itemID,
    slugBase,
    activeEditingScene,
    forms,
    langID,
    localizedElements,
    dataReadyForSave,
    collectionSceneType,
    cardBrowserConfig
  } = stateProps;
  const cardBrowserSelector = [
    "sceneTypes",
    collectionSceneType,
    "cardBrowserConfig"
  ]; // immutable path to locate config
  return Object.assign({}, stateProps, {
    handleInputChange: (property, value, formGroup) => {
      dispatchProps.setElementInput(
        property,
        value,
        langID,
        activeEditingScene._id,
        false,
        false,
        localizedElements.includes(property),
        forms.details[formGroup]
      );
    },
    openAssetSelectorFunc: (param, multiple, selectedTab) => {
      const tab = selectedTab ? selectedTab.tabState : null;
      ownProps.openAssetSelectorFunc(param, multiple, tab);
    },
    deleteSubScene: selectedTab => {
      const isRotationGallery = selectedTab === "rotationGallery";
      const { selected } = cardBrowserConfig;
      dispatchProps.deleteSubScene(itemID, selected, isRotationGallery);
    },
    sceneSetIsSaved: isSaved => {
      dispatchProps.sceneSetIsSaved(activeEditingScene._id, isSaved);
    },
    setSubSceneOrder: (oldIndex, newIndex, isRotationGallery) =>
      dispatchProps.setSubSceneOrder(
        itemID,
        oldIndex,
        newIndex,
        isRotationGallery
      ),
    sceneSetAssetElement: (elementID, assetID, section) => {
      dispatchProps.sceneSetAssetElement(
        activeEditingScene._id,
        elementID,
        assetID,
        section
      );
    },
    backClickHandler: id => {
      dispatchProps.exclusiveSelectToggle(null, cardBrowserSelector);
    },
    selectHandler: id => {
      dispatchProps.selectToggle(id, cardBrowserSelector);
    },
    exclusiveSelectHandler: id => {
      dispatchProps.exclusiveSelectToggle(id, cardBrowserSelector);
    },
    editClickHandler: (id, coords) => {
      ownProps.openSceneEditorModalFunc(id, coords.left, coords.top);
    },
    handleImageEditClick: props => {
      // I have no idea why this function can be accessed here, but
      // not in the render() function above.
      ownProps.openImageCropperFunc(props.param);
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    updateScene: () => {
      const sceneType = "collection";
      dispatchProps.updateScene(
        activeEditingScene._id,
        dataReadyForSave,
        forms.details,
        sceneType
      );
    },
    revertScene: () => {
      dispatchProps.revertScene();
    }
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps)(CollectionItemEditor)
);

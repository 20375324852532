import { Map } from "immutable";
import _map from "lodash/map";

export const themeSelectorMapStateToProps = (state, ownProps) => {
  const { selectedThemeId } = ownProps;

  const account = state.getIn(["data", "account"], Map).toJS();
  const allThemes = state.getIn(["base", "themes"], Map([])).toJS();
  const themeTypes = state.getIn(["base", "themeTypes"], Map([])).toJS();

  const standardThemes = []; // Will contain all standard themes.
  const customThemes = []; // Will contain all custom themes associated with this account.

  // Filter out all custom themes except the ones used by
  // this account
  _map(allThemes, theme => {
    const isAccountCustomTheme = account.customThemes
      ? account.customThemes.includes(theme._id)
      : false;

    if (theme.typeId === themeTypes.standard._id || isAccountCustomTheme) {
      const { assets } = theme;
      const preppedTheme = {
        isCloudinary: true,
        ...assets.cmsThumb,
        _id: theme._id,
        type: theme.typeId === themeTypes.standard._id ? "standard" : "custom",
        title: theme.title
      };

      if (preppedTheme.type === "standard") {
        standardThemes.push(preppedTheme);
      } else {
        customThemes.push(preppedTheme);
      }
    }
  });

  const alphaSort = (a, b) => {
    var titleA = a.title.toUpperCase(); // ignore upper and lowercase
    var titleB = b.title.toUpperCase(); // ignore upper and lowercase
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    // names must be equal
    return 0;
  };

  standardThemes.sort(alphaSort);
  customThemes.sort(alphaSort);

  // The themes array will contain all themes combined, with the active theme first,
  // followed by custom themes alphabetized by title, and then standard themes
  // alphabetized by title.
  const themes = [...customThemes, ...standardThemes];

  // If there's a selected theme, put it first.
  if (selectedThemeId) {
    // Find the index of the selected theme so we can
    // remove it and place it first in the list.
    const selectedThemeIndex = themes.findIndex(
      theme => theme._id === selectedThemeId
    );
    const firstTheme = { ...themes[selectedThemeIndex] };

    themes.splice(selectedThemeIndex, 1);
    themes.unshift(firstTheme);
  }

  return {
    themes,
    isOpen: ownProps.isOpen,
    selectedThemeId: ownProps.selectedThemeId,
    handleThemeSelected: ownProps.handleThemeSelected
  };
};

/**
 * Project Editor Reducer
 */
import { fromJS, Map } from "immutable";
import * as CONST from "actions/action_constants";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
//

/**
 * PROJECTEDITOR REDUCER
 * main switch for reponding to actions
 */
const projectEditor = (state = Map({}), action) => {
  const { projectEditor } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:     
      return state.merge(fromJS(projectEditor)).set("iFrameKey", 1);
    case CONST.SCENE_UPDATE_SUCCESS:
      const iFrameKey = state.get("iFrameKey");
      return state.merge(fromJS(projectEditor)).set("iFrameKey", iFrameKey + 1);
    default:
      return state; // if action was not passed send state back
  }
};

export default projectEditor;

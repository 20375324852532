import React from 'react';
import { connect } from 'react-redux';
import { getThemeEditorProps } from 'reducers/ui/themeEditor';
import ThemeEditorSidebar from './Sidebar';
import SectionSidebar from './Sidebar/SectionSidebar';
import Sidebar from 'modules/Sidebar/Sidebar';
import Previewer from 'components/Previewer';
import * as themeEditorActions from 'actions/themeEditor';
import './ThemeEditor.scss';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// import {fetchThemeEditorData} from 'actions/themeEditor';

// we could potentially pass components as props...
// we need to pass the params down to side bar so we can access slugs
// index 1 of routes is the toplevel 'after' base
// @TODO consider what shoudl happen to sidebar on small devices
export class ThemeEditor extends React.Component {
  componentWillUnmount() {
    // shoudl only fire when exiting the editor area for a specific theme

    // moved to saga....whihc gets called when the browser loads
    if (!this.props.isLoading) this.props.resetEditor();
    //    this.props.removeThemeCopy();//@TODO prob just always call and put logic in the action...
  }

  componentDidMount() {
    // if we have a new theme id selected we will make a copy
    // this should only fire on the INITIAL mount fo the comp, since we just reset its inner parts
    // even if it is a page reload we want to create a copy

    if (this.props.isLoading) this.props.fetchThemeEditorData();
  }

  componentDidUpdate() {
    // const themeIndex = _camelCase(this.props.params.themeId);
    // not sure if this can be part of a saga?? right now can't find a way to put in the saga
    //
    if (!this.props.isLoading) {
      // const themeIndex = _camelCase(this.props.params.themeId);
      this.props.copyTheme(this.props.themeData);
    }
  }

  render() {

    const { isLoading, sectionId } = this.props; // may no longer need all these
    const SidebarComp = isLoading ? Sidebar : sectionId ? SectionSidebar : ThemeEditorSidebar; // use the section if we are on a section or below
    return (
      <div className="cms-screen theme-editor">
        {/* <ReactCSSTransitionGroup
                 //transitionName="appear"
                 //transitionEnterTimeout={500}
                 //transitionLeaveTimeout={500}
                 > */}

        <SidebarComp {...this.props} />

        {/* </ReactCSSTransitionGroup> */}

        <Previewer {...this.props} isLoading={isLoading} />
      </div>
    );
  }
}

// i need to be able to get the selected them from the store
// that i can pass down, so doing a connect here...
// themeIndex : activeThemeIndex

function mapStateToProps(state, ownProps) {
  return getThemeEditorProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps) {
  const { themeIndex, themeData, revertCopy } = stateProps; // _camelCase(ownProps.params.themeId);
  return Object.assign({}, stateProps, {
    fetchThemeEditorData: () => dispatchProps.fetchThemeEditorData(),
    copyTheme: () => dispatchProps.copyTheme(themeData),
    updateTheme: () => dispatchProps.updateTheme(themeIndex, themeData),
    handleInputChange: (property, value) =>
      dispatchProps.setDataFromInput(property, value, themeIndex, themeData),
    removeThemeCopy: () => dispatchProps.removeThemeCopy(),
    revertTheme: () => dispatchProps.revertTheme(themeIndex, revertCopy),
    addThemeStyle: styleName => dispatchProps.addThemeStyle(styleName, themeData.style),
    selectThemeStyle: selectedStyle => dispatchProps.selectThemeStyle(selectedStyle, themeIndex),
    previewThemeStyle: previewStyleIndex => dispatchProps.previewThemeStyle(previewStyleIndex),
    resetProcessComplete: () => dispatchProps.resetProcessComplete(),
    resetEditor: () => dispatchProps.resetEditor(),
  });
}

export default connect(mapStateToProps, themeEditorActions, mergeProps)(ThemeEditor);

//import * as api from 'api';
//import {loadAMCState} from 'storelocalStorage';
//import uuid from 'uuid-v4';

import * as CONST from "actions/action_constants";

/**
 * @NOTE if I call the nested function passing dispatch and getState then
 * it appears I will need to call dispatch in the action creator and not just return an object
 * otherwise it appears the returned action is never dispatched
 */

export const fetchThemeEditorData = () => {
  return {
    type: CONST.THEME_EDITOR_REQUEST,
    isLoading: true // loader will display...
  };
};

export const resetEditor = () => {
  return {
    type: CONST.THEME_EDITOR_RESET
  };
};

/*
 * ADD THEME
 * @description method to Add a whole new theme
 * @param (String)
 * @param (Object)
 * returns (Object) action to be dispatched
 * this one we WAIT to add to store until successful response
 */
export const addTheme = (newName, themeData) => {};

/*
 * UPDATE THEME
 * @description this action will be ASYNC, it will do a call to the API passing the full theme data object, once it receivs
 * confirmaiton it will fire the other actions andshow notification
 * its purpose is to store theme changes in the database
 * @param (String)
 * @param (Object)
 * returns (Object) action to be dispatched
 * this one we WAIT to add to store until successful response
 */
export const updateTheme = (themeIndex, themeData) => {
  if (!themeIndex) return; // throw an error
  return {
    type: CONST.UPDATE_THEME_REQUEST,
    isSaving: true, // the dialog form or notification could  target this
    themeIndex,
    themeData
  };
};

/**
 * @description this method will dispatch the action that makes a copy of the theme being edited
 * we pass on the themeData and the force flag and reducer wil determine if a state change is needed...
 */
export const copyTheme = (themeData, force) => {
  return {
    type: CONST.COPY_THEME,
    themeData,
    force
  };
};

/**
@description this method will dispatch the action that REMOVES the copy of the theme being edited
*/
export const removeThemeCopy = () => {
  return {
    type: CONST.REMOVE_COPY_THEME
  };
};

/*
 * REVERT THEME
 * @description this method will fire the action to replace the currently active theme data with what it had previously been state of last save
 * @param (String)
 * @param (Object)
 * returns (Object) action to be dispatched
 * not sure if it would be best to just stair step back to previous state or how to do that for just the themeEditor nodes
 * since it seems possible... not yet sure what params we will need
 */
export const revertTheme = (themeIndex, revertCopy) => {
  if (!themeIndex) return;

  return {
    type: CONST.REVERT_THEME,
    themeIndex,
    themeData: revertCopy
  };
};

/*
 * RESET PROCESSING STATE
 *
 * returns (Object) action to be dispatched
 */
export const resetProcessComplete = () => {
  return {
    type: CONST.RESET_PROCESS_COMPLETE
  };
};

//-------------------------------------------------------------------------------
// STYLE RELATED
/*
 * ADD THEME STYLE
 * @description this action will do an API call to store a users new style selection then return to update store
 * @param (String)
 * @param (String)
 * returns (Object) action to be dispatched
 * this one we WAIT to add to store until successful response
 */
export const addThemeStyle = (styleName, styleSet) => {
  //const styleSet = getState().getIn(['data','themes', themeIndex, 'style']).toJS();
  return {
    type: CONST.ADD_STYLE_REQUEST,
    inProcess: true, // the dialog form will target this
    styleName,
    styleSet
  };
};

/*
 * PREVIEW THEME STYLE
 * @param (Object)  the style selected to preview
 * @param (Object)  the current layout object to preview
 * @param (Object)  the current settings object to preview
 */
export const previewThemeStyle = styleIndex => {
  //@NOTE could if no values are passed we can do the default here but would need the theme index...
  //const activeData = getState().getIn(['data','themes', themeIndex]);//
  return {
    type: CONST.PREVIEW_THEME_STYLE,
    styleIndex
  };
};

/*
 * SET APPLY NEW STYLE TO THEME
 * @description this action will update the active themes
 * style node with the new style and set the theme to dirty
 * @param (Object)
 * @param (String)
 * returns (Dispatch) with action object
 * DOES NOT DO AN API CALL, BUT IT DOES NEED to displath a call to preview?
 */
export const selectThemeStyle = (selectedStyle, themeIndex) => {
  return {
    type: CONST.SET_THEME_STYLE_SUCCESS,
    selectedStyle,
    themeIndex,
    isSaved: false // lets the UI know the theme is dirty
  };

  // //@TODO move to use a saga
  // const activeData = getState().getIn(['data','themes', themeIndex]).toJS();//
  // dispatch(
  //     previewThemeStyle(activeData.style)
  // )
  // return true;
};

/*
 * SET DATA FROM STYLE INPUTS (for a given theme)
 * @description this action will update the store with the new input values and set the theme to dirty
 * @param (String)
 * @param (String)
 * @param (String)
 * returns (Dispatch) with action object
 * @NOTE this will NOT do an API call simply set the isSaved Flag to false
 * @NOTE we will need similar for Layout and other children of active theme
 * @NOTE does not do an api call
 */
export const setDataFromInput = (property, value, themeIndex) => {
  // can we allow for simultaneous saving of var
  return {
    type: CONST.SET_THEME_STYLE_VALUE_SUCCESS,
    value,
    property,
    themeIndex,
    isSaved: false
  };
  // this assumes previous dispatch has changed the state....
  // after success we want to make sure the preview object is upto date
  // const activeData = getState().getIn(['data','themes', themeIndex]);//
  // dispatch(
  //     previewThemeStyle(activeData.get('style'))
  // )
  // return true;
};

import React from "react";
import PropTypes from "prop-types";
import Logger from "utils/logger"
import { List, Dialog } from "@terraincognita/ui-core";
import Sidebar from "modules/Sidebar/Sidebar";
import AssetSelector from "modules/Library/AssetSelector";
import ThemeSelector from "modules/ThemeSelector";
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _map from "lodash/map";
import _forIn from "lodash/forIn";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import "./Sidebar.scss";
import styles from "./Sidebar.scss";

class StoryEditorSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarData: {},
      isDirty: false,
      confirmModal: false,
      assetSelectorOpen: false,
      assetSelectorParam: null,
      isThemeSelectorOpen: false,
      activeLanguage: this.props.langID || "en"
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.debounceInputChange = _debounce(function (property, value) {
      this.handleInputChange.apply(this, [property, value]);
    }, 500);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.handleDisplayRevertCancel = this.handleDisplayRevertCancel.bind(this);
    this.handleRevertAction = this.handleRevertAction.bind(this);
    this.handleRevertActionFromModal =
      this.handleRevertActionFromModal.bind(this);
    this.handleAssetDelete = this.handleAssetDelete.bind(this);
    this.closeAssetSelector = this.closeAssetSelector.bind(this);
    this.handleAssetReplace = this.handleAssetReplace.bind(this);
    this.processAssetFromSelector = this.processAssetFromSelector.bind(this);
    this.openThemeSelector = this.openThemeSelector.bind(this);
    this.closeThemeSelector = this.closeThemeSelector.bind(this);
    this.handleThemeSelect = this.handleThemeSelect.bind(this);
    this.checkAndProcessCheckboxes = this.checkAndProcessCheckboxes.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    /*
    state.sidebarData - Forms in the sidebar component -- rendered using
    the List component -- are populated initially using the sidebar 
    component's state.sidebarData prop. The state.sidebarData prop is 
    initialized in the componentWillReceiveProps method using the sidebar 
    component's sidebarData prop.
    
    After initialization, the state.sidebarData prop is kept in sync with the
    component's sidebarData prop using the componentWillReceiveProps() lifecycle
    method. Any time a new editor is loaded, the sidebarData prop is updated, 
    and the componentWillReceiveProps() method updates the state.sidebarData prop. 
    That results in the form being repopulated using the appropriate data. 
    
    As form values are changed, the handleInputChange() method is 
    responsible for updating the state.sidebarData prop. 
    */
    if (!_isEqual(nextProps.sidebarData, this.state.sidebarData)) {
      this.setState({ sidebarData: nextProps.sidebarData });
    }

    // Since the language is set globally, we have to set the activeLanguage
    // here to make sure the language drop-down is populated correctly.
    this.setState({ activeLanguage: nextProps.langID });
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.sidebarData, this.props.sidebarData)) {
      this.checkAndProcessCheckboxes();
    }
  }

  checkAndProcessCheckboxes() {
    if (
      this.props.sidebarData &&
      this.props.sidebarData.limitNumberOfQuestions
    ) {
      let textField = document.getElementsByClassName("numberOfQuestions")[0];
      if (textField) {
        textField.classList.add("show");
      }
    }
    if (this.props.sidebarData && this.props.sidebarData.randomizeQuestions) {
      let textField = document.getElementsByClassName(
        "limitNumberOfQuestions"
      )[0];
      if (textField) {
        textField.classList.add("show");
      }
    }
  }

  handleInputChange(prop, value) {
    const formData = _cloneDeep(this.state.sidebarData);

    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    but we do want to convert the Quill empty string to an actual empty 
    string.
    */
    if (value === "<p><br></p>") {
      if (formData[prop] === "") {
        return;
      }
      value = "";
    }

    if (formData[prop] !== value) {
      /*
      Loop over form elements to see if any are dependent on the element
      just changed. If so, set those elements to their default values
      if the right trigger has been thrown.
      
      7/10/2021: This code needs to be fleshed out more. See the response
      to asset delete for more developed code.
      */
      // _forIn(this.props.navigation, (element, elementName) => {
      //   const { foreignDependency } = element;
      //   if (foreignDependency) {
      //     const { param, resetIf } = foreignDependency;
      //     if (param === prop) {
      //       if (value === resetIf) {
      //         const defaultValue = this.props.structure.elements[param].value;
      //         this.props.handleInputChange(
      //           param,
      //           defaultValue,
      //           this.props.navigation[param].isLocalized
      //         );
      //       }
      //     }
      //   }
      // });

      formData[prop] = value;
      this.props.handleInputChange(
        prop,
        value,
        this.props.navigation[prop].isLocalized
      );
      this.setState({ formData, isDirty: true });
    }

    /*
    Enabling dropdown

    3/27: Leaving this in for now, but not sure it's going to work
    with the changes made to support revert consistent with how revert
    is handled elsewhere in the app. Also, we're not displaying these
    form elements right now.
    */
    if (prop == "limitNumberOfQuestions") {
      let textField = document.getElementsByClassName("numberOfQuestions")[0];
      if (value === true && textField) {
        textField.classList.add("show");
      } else if (textField) {
        textField.classList.remove("show");
      }
    }
    if (prop == "randomizeQuestions") {
      let textField = document.getElementsByClassName(
        "limitNumberOfQuestions"
      )[0];
      let textField2 = document.getElementsByClassName("numberOfQuestions")[0];
      if (value === true && textField) {
        textField.classList.add("show");
        let checkbox = textField.querySelector("input");
        if (checkbox.checked) {
          textField2.classList.add("show");
        }
      } else if (textField) {
        textField.classList.remove("show");
        textField2.classList.remove("show");
      }
    }
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.changeLanguage(value);
  }

  render() {
    const {
      languages,
      sidebarMode,
      section,
      navigation,
      slugBase,
      title,
      className,
      goBackAction,
      showButtons,
      hideRevertButton,
      revertLabel,
      saveLabel,
      children
    } = this.props;

    // Injecting asset editor handlers
    if (navigation) {
      Object.keys(navigation).forEach(key => {
        // Support for cropping a story image has not yet been
        // implemented. Currently, user can only replace existing
        // image.
        if (navigation[key].type === "assetEditor") {
          navigation[key].handleEditClick = this.handleAssetReplace;
          navigation[key].handleDeleteClick = this.handleAssetDelete;
          navigation[key].handleAddClick = this.handleAssetReplace;
        } else if (navigation[key].type === "chooser") {
          navigation[key].handleActionClick =
            this[navigation[key].actionClickHandler];
        }
      });
    }

    setTimeout(this.checkAndProcessCheckboxes, 500);

    const { sidebarData } = this.state;

    return (
      <Sidebar
        title={title}
        className={className}
        goBackAction={!this.state.isDirty ? goBackAction : null}
        showButtons={
          this.state.isDirty &&
          (section === "settings" || section === "design" || showButtons)
        }
        handleSave={this.handleSave}
        hideRevertButton={hideRevertButton}
        revertLabel={revertLabel}
        handleRevert={
          this.handleRevertAction ? this.handleRevertAction : undefined
        }
        saveLabel={saveLabel}
      >
        {section === "settings" || section === "mainmenu" ? (
          <div className="language-selector-container">
            <SelectField
              className="language-select"
              value={this.state.activeLanguage}
              onChange={this.handleLanguageChange}
            >
              {languages &&
                _map(languages, item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.label}
                  />
                ))}
            </SelectField>
          </div>
        ) : null}

        {/* The navigation object contains a property for each form
        element. No idea why it's called navigation rather than something
        clear like formElements. These values are defined in the database. */}
        {navigation && Object.keys(navigation).length > 0 && (
          <List
            items={navigation}
            data={sidebarData}
            handleInputChange={(property, value) =>
              this.debounceInputChange(property, value)
            }
            slugBase={slugBase}
            styleName={
              section === "settings" ||
              section === "design" ||
              section === "mainmenu" ||
              section === "publish" ||
              section === "questions"
                ? "plain"
                : "boxed"
            }
          />
        )}

        <Dialog
          content="Please confirm that you would like to revert your changes"
          handleConfirm={this.handleRevertAction}
          confirmLabel="Confirm"
          bodyStyle={{ paddingTop: 20 }}
          handleCancel={this.handleDisplayRevertCancel}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.confirmModal}
          title="Confirm Revert Action"
        />

        {(section === "settings" || section === "mainmenu") &&
          this.state.assetSelectorOpen && (
            <AssetSelector
              open
              minWidthCaption={parseInt(styles.minWidthCaption, 10)}
              minCardMargin={parseInt(styles.minCardMargin, 10)}
              captionHeight={parseInt(styles.captionHeight, 10)}
              isModal
              standardBarButtonAction={this.processAssetFromSelector}
              title="Select Asset"
              hideStandardButtonIfNonSelected
              allowsMultiple={false}
              standardBarButtonLabel="SELECT"
              sidebarMode={sidebarMode}
              isAssetSelectorMode
              closeModalAction={this.closeAssetSelector}
            />
          )}

        {section === "design" && this.state.isThemeSelectorOpen && (
          <ThemeSelector
            isOpen
            handleThemeSelect={this.handleThemeSelect}
            handleCloseClick={this.closeThemeSelector}
            selectedThemeId={sidebarData.selectedTheme._id}
            isModal
          />
        )}

        {children}
      </Sidebar>
    );
  }

  handleRevertAction() {
    this.setState({ isDirty: false });
    this.props.handleRevert();
  }

  handleRevertActionFromModal() {
    this.handleRevertAction();
    this.props.goBackAction();
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  handleDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.handleSave();

    // If we comment out the following code, the initial form
    // settings aren't temporarily displayed in the form.
    if (typeof this.props.setSaved !== "undefined") {
      this.props.setSaved(true);
    }
  }

  closeAssetSelector() {
    this.setState({ assetSelectorOpen: false });
  }

  /**
   * The user has selected an asset to assign to a story, project or
   * main menu scene. Add the asset data to the local state form data
   * to trigger display of the asset in the editor. Then trigger
   * addition of the asset to the relevant document in the store.
   *
   * @param {Array} assetID - Array of selected assetIDs.
   * @param {Array} asset - Array of asset documents that contain all
   *                        data from the Library.
   */
  processAssetFromSelector(assetID, asset) {
    this.closeAssetSelector();

    const formData = _cloneDeep(this.state.sidebarData);

    formData.asset = {
      assetID: assetID[0],
      isCloudinary: true,
      cloudName: asset[0].cloudName,
      cloudPublicId: asset[0].cloudPublicId,
      config: asset[0].config,
      height: asset[0].height,
      width: asset[0].width,
      type: asset[0].type
    };

    // Long term, we need to get the assetParam from the form.
    const assetParam =
      this.props.section === "mainmenu"
        ? this.state.assetSelectorParam
        : "asset";
    this.props.handleInputChange(assetParam, formData.asset, false);
    this.setState({ formData, isDirty: true });
  }

  handleAssetReplace(props) {
    Logger.debug("[SIDEBAR] handleAssetReplace");
    this.setState({ assetSelectorOpen: true, assetSelectorParam: props.param });
  }

  /**
   * The user has clicked the trashcan icon on the asset. Remove the
   * asset data from the local state form data to trigger removal of the
   * asset from the editor. Then trigger removal of the asset from the
   * story in the store.
   * @param {Object} data - No idea what this object represents. Looks
   *                        like an amalgamation of all form data.
   */
  handleAssetDelete(data) {
    const formData = _cloneDeep(this.state.sidebarData);

    formData.asset = {
      assetID: null
    };

    // Long term, we need to get the assetParam from the form.
    const assetParam = this.props.section === "mainmenu" ? data.use : "asset";

    /*
    Loop over form elements to see if any are dependent on the asset just
    deleted. The resetIf prop is set to "" if the intent is to reset the
    form element to it's default value if the asset is deleted.

    NOTE: Do we need to make the foreignDependency prop an array? Is it
    possible that a field might have multiple dependencies?

    7/10/2021 - Commenting out this code because we decided not to reset
    values if the asset is deleted, but the logic is sound if we ever 
    decide to proceed, so leave it in place.
    */
    // _forIn(this.props.navigation, (element, elementName) => {
    //   const { foreignDependency } = element;
    //   if (foreignDependency) {
    //     const { param, resetIf } = foreignDependency;
    //     if (param === assetParam) {
    //       // resetIf will be set to "" if the objective is to reset the
    //       // element value to default if it's bound to an asset element.
    //       if (resetIf === "") {
    //         const structureElement =
    //           this.props.structure.elements[element.param];

    //         // TO DO: This code might not properly account for all element types.
    //         // Look in structure because all text elements are defined as "text".
    //         const defaultValue =
    //           structureElement.type === "text"
    //             ? structureElement.text
    //             : structureElement.value;

    //         // TO DO: Figure out how to reset values for all languages if the
    //         // text element is localized
    //         if (!this.props.navigation[element.param].isLocalized) {
    //           this.props.handleInputChange(
    //             element.param,
    //             defaultValue,
    //             this.props.navigation[element.param].isLocalized
    //           );
    //         }
    //       }
    //     }
    //   }
    // });

    this.props.handleInputChange(assetParam, formData.asset, false);
    this.setState({ formData, isDirty: true });
  }

  /* THEME MANAGEMENT  */
  openThemeSelector(e) {
    e.preventDefault();
    this.setState({ isThemeSelectorOpen: true });
  }

  closeThemeSelector() {
    this.setState({ isThemeSelectorOpen: false });
  }

  handleThemeSelect(themeId) {
    this.closeThemeSelector();

    if (this.state.sidebarData.selectedTheme._id !== themeId) {
      const formData = _cloneDeep(this.state.sidebarData);
      formData.selectedTheme._id = themeId;
      this.props.handleInputChange("themeId", themeId, false);
      this.setState({ formData, isDirty: true });
    }
  }
}

StoryEditorSidebar.defaultProps = { section: null };

StoryEditorSidebar.propTypes = {
  title: PropTypes.string,
  section: PropTypes.string,
  navigation: PropTypes.object,
  sidebarData: PropTypes.object,
  dataReadyForSave: PropTypes.object,
  changeLanguage: PropTypes.func,
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  langID: PropTypes.string,
  setSaved: PropTypes.func,
  className: PropTypes.string,
  actionBar: PropTypes.element
};

export default StoryEditorSidebar;

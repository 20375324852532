import { Map } from 'immutable';
import * as CONST from 'actions/action_constants';

const userEditor = (state = Map({}), action) => {

  if (action.message === "Error: Request failed with status code 401") {
    return state;
  }

  switch (action.type) {
    case CONST.USER_EDITOR_SAVE_ERROR:
      return state.set('error', action.message);

    case CONST.USER_EDITOR_SAVE_ERROR_CONFIRM:
      return state.set('error', '');

    default:
      return state;
  }
};

export default userEditor;

import { Map } from "immutable";

export default (state, ownProps) => {
  const { userPreferencesSelector, value } = ownProps;

  const previewOptions = [
    { value: "full", label: "Full Scale" },
    { value: "pp", label: "Phone (portrait)" },
    { value: "pl", label: "Phone (landscape)" },
    { value: "tsl", label: "Tablet (small landscape)" },
    { value: "tsp", label: "Tablet (small portrait)" },
    { value: "tll", label: "Tablet (large landscape)" },
    { value: "tlp", label: "Tablet (large portrait)" },
    { value: "ds", label: "Desktop (small)" },
    { value: "dm", label: "Desktop (medium)" },
    { value: "dl", label: "Desktop (large)" },
    { value: "kl", label: "Kiosk (landscape)" },
    { value: "kp", label: "Kiosk (portrait)" }
  ];

  const defaultPreview = "dm";

  return { previewOptions, defaultPreview, userPreferencesSelector, value };
};

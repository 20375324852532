import { fromJS, Map } from "immutable";
import { combineReducers } from "redux-immutable";
import * as CONST from "actions/action_constants";
import _values from "lodash/values";
import account from "./account";
import accountusers from "./accountusers";
import user from "./user";
import assets from "./assets";
import projects from "./projects";
import feedback from "./feedback";
import stories from "./stories";
import scenes from "./scenes";
import quiz from "./quiz";
import themes from "./themes";
import analytics from "./analytics";
import storyCategories from "./storyCategories";

// import _filter from 'lodash/filter';
// use this to convert a toJS object to an array

/** **************************************
 * DATA REDUCER
 *************************************** */

/**
 * STYLES SUB REDUCER
 * the STATE that gets passed to this reducer is
 * STATE.data.styles
 * so all targeting needs to take that into account
 */
const styles = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.THEME_EDITOR_SUCCESS:
      const { response } = action;
      return state.merge(fromJS(response.styles));
    case CONST.ADD_STYLE_SUCCESS:
      const { styleName, styleSet } = action;
      return state.set(styleName, fromJS(styleSet));
    default:
      return state;
  }
};

/**
 * LAYOUTS SUB REDUCER
 * the STATE that gets passed to this reducer is
 * STATE.data.layouts
 * so all targeting needs to take that into account
 * NOTE: should this be under config?
 */
const layouts = (state = Map({}), action) => {
  const { data } = action;
  switch (action.type) {
    case CONST.THEME_EDITOR_SUCCESS:
      const { layouts } = data;
      return state.merge(fromJS(layouts));
    default:
      return state;
  }
};

/* SETTINGS SUB REDUCER */
const settings = (state = Map({}), action) => {
  const { data } = action;
  switch (action.type) {
    case CONST.THEME_EDITOR_SUCCESS:
      const { settings } = data;
      return state.merge(fromJS(settings));
    default:
      return state;
  }
};

// combine parts into one data reducer...
const data = combineReducers({
  account,
  accountusers,
  user,
  assets,
  projects,
  feedback,
  stories,
  scenes,
  quiz,
  themes,
  styles,
  layouts,
  settings,
  analytics,
  storyCategories
});

export default data;

//----------------------------------------
// SELECTORS

/*
 * Selector to return list of styles filtered by passed param
 * @param {Map} the styles state object
 * @return {Array}

 * i can't seem to get the immutable filter method to working
 * so for now I am going to convert...
 state.filter(style => style.userDefined === true).toJS();
 */
export const getStyleList = (state, filter = "all") => {
  // const styles = state.toJS();
  switch (filter) {
    case "editable":
      return _values(
        state.filter(style => style.get("userDefined") === true).toJS()
      );
    // return _filter(styles, {'userDefined':true});
    case "noneditable":
      return _values(
        state.filter(style => style.get("userDefined") === false).toJS()
      );
    // return _filter(styles, {'userDefined':false});
    default:
      return _values(state.toJS()); // this is shallow so not totally what we want
  }
};

import { createStore, applyMiddleware } from "redux";

import cmsApp from "reducers";
import createSagaMiddleware, { END } from "redux-saga";
import rootSaga from "sagas";
import { fromJS } from "immutable";
import { loadState } from "./localStorage";

import _merge from "lodash/merge";

// @NOTE we can't import conditionally, so using an alias for now
// the extexsion CAN look at NODE_ENV but we aLWAYS set to Production for React
// import { composeWithDevTools as composeWithDevToolsLogOnly} from 'redux-devtools-extension/logOnly';
import { composeWithDevTools } from "redux-devtools-extension";
// setting up options to limit use on production of the extension
const composeOptions = {
  shouldRecordChanges: process.env.DEPLOYMENT !== "production",
  autoPause: process.env.DEPLOYMENT === "production",
  features: {
    export: process.env.DEPLOYMENT !== "production",
    import: process.env.DEPLOYMENT !== "production",
    jump: process.env.DEPLOYMENT !== "production",
    skip: process.env.DEPLOYMENT !== "production",
    reorder: process.env.DEPLOYMENT !== "production",
    dispatch: process.env.DEPLOYMENT !== "production",
    test: process.env.DEPLOYMENT !== "production"
  }
};
// const composeEnhancers = process.env.DEPLOYMENT !== 'production'
//   ?
//   : composeWithDevToolsLogOnly(composeOptions);
const composeEnhancers = composeWithDevTools(composeOptions);

/**
 * configureStore
 * method is called from either the clientside entry point and the server-side if used
 * @param {Object} initialState an object can be passed to this medthod that will be used to kick things off
 * if there is also a local store that has auth creds, then this will be merged into the inital state
*/
const configureStore = initialState => {
  // read from the localstore and add to inital state
  // NOTE: -- may not want to do this...may just read the cookie later when we attempt to load config??
  //
  const localStore = loadState();
  const state = localStore
    ? { auth: localStore.auth, data: { client: localStore.client } }
    : localStore;

  const persistentState = fromJS(_merge(initialState, state)); //convert the local store into immutable to make store structure

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware]; // thunk, add any other middlewares we might want here
  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares) //addding enhancemtns
    // other store enhancers if any
  );

  const store = createStore(cmsApp, initialState, enhancer);
  // IF we need to set off a server side process...
  //store.runSaga = sagaMiddleware.run;
  //store.close = () => store.dispatch(END);

  // start the root saga that will listen for dispatching
  sagaMiddleware.run(rootSaga);
  return store;
};

// const DestStore = configureStore();
export default configureStore;

/*
import { createStore, applyMiddleware } from "redux";
import cmsApp from "reducers";
import createSagaMiddleware from "redux-saga";
import rootSagaWatcher from "sagas";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

  const store = createStore(cmsApp, enhancer);

  sagaMiddleware.run(rootSagaWatcher);
  return store;
};

const CMSStore = configureStore();
export default CMSStore;

*/

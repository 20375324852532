/**
 * Helper class to handle logging treatment
 * 
 * TODO: allow for compile time variables to change output
 * TODO: allow for when running on PROD to send logs to another spot
 * For first pass we only will spit out logs when running locally,
 *  we can then add a optional param to allow specific log levels on DEV and PROD env
 */
const LOG_LEVELS = { 
    DEBUG: 'debug', // log everything 
    WARN: 'warn', // log  warn, info, error
    INFO: 'info', // log info, error
    ERROR: 'error', // log error
}
const logLevel = process.env.IS_LOCAL ? LOG_LEVELS.DEBUG : LOG_LEVELS.ERROR

/**
 * @param {object} param1 object or error that contains properties to inspect
 * @param {string} param2 message to give information
 */
const Logger = {
    log: (...args) => {
        if(logLevel !== LOG_LEVELS.DEBUG) {
            return   
        }
        console.log(args[0], args[1])          
    },
    debug: (...args) => {
        if(logLevel !== LOG_LEVELS.DEBUG) {
          return   
        }
        console.log(args[0], args[1])       
    },
    warn: (...args) => {
        if(![LOG_LEVELS.DEBUG, LOG_LEVELS.WARN].includes(logLevel)) {
           return 
        }
        console.warn(args[0], args[1])   
    },
    info: (...args) => {
        if(![LOG_LEVELS.DEBUG, LOG_LEVELS.INFO, LOG_LEVELS.ERROR].includes(logLevel)) {
          return  
        }
        console.info(args[0], args[1])    
    },   
    error: (...args) => {
        if(![LOG_LEVELS.DEBUG, LOG_LEVELS.INFO, LOG_LEVELS.ERROR].includes(logLevel)) {
           return 
        }  
        console.error(args[0], args[1]) 
    }
}

export default Logger
import { put, call } from 'redux-saga/effects';
import { fetchAnalytics, fetchAnalyticsUI } from 'api';
import { loadState } from 'store/localStorage';
import * as CONST from 'actions/action_constants';
import { deLocalizeTextProps } from 'helpers/localizationHelper';
import _cloneDeep from 'lodash/cloneDeep';
//----
// SELECTORS
// used in the saga

// ANALYTICS SAGA WORKERS
/**
 * fetchAnalyticsSaga
 */
export function* fetchAnalyticsSaga(action) {
  try {
    const { filters } = action;
    const query = {};

    if (filters.dateRange && filters.dateRange !== 'custom' && filters.dateRange !== 'yesterday') {
      query.day_offset = parseInt(filters.dateRange);
    } else if (filters.dateRange === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      query.date_range = [yesterday, yesterday];
    } else {
      query.date_range = [new Date(filters.dateStart), new Date(filters.dateEnd)];
    }

    if (filters.destinations !== 'all') {
      query.source = filters.destinations;
    }

    // const query = {
    //   match: {                        // required
    //     account: String,              // required
    //     project: [String],            // required
    //     type: [String],
    //     story: [String],
    //     // Note: if day_offset or date range are excluded, all events matching the query are returned
    //     day_offset: Integer,          // Number of days from today to include (inclusive)
    //     date_range: [Date, Date],     // Includes events between these dates, take precedence over day_offset (inclusive)
    //     data: {
    //       key: value,                 // Matches docs with the same key and value
    //       key: [value, value, value],  // Matches docs with any value matching those in the array
    //       /**
    //        // Not Implemented
    //        key: {predicate, condition: '==' | '!=' | '>' | '<' | '>=' | '<='}, // Matches docs if value of key satisfies condition
    //        key: {range: [x, y]} // Matches docs where value is in the range of x, y (inclusive)
    //        */
    //     }
    //   },
    //
    //   group: {
    //     properties: [String],        // Group on properties common to all events
    //     time: [year|month|day],      // Group matching docs by one or more of the following (ordering is mirrored in query results)
    //     data: [key, ...]             // Group on data fields
    //   },
    //
    //   stats: {
    //     duration: [                 // Any duration metric will be included per group
    //       sum,
    //       min,
    //       max,
    //       avg
    //     ],
    //
    //   }
    // };

    const projectSessionsQuery = _cloneDeep(query);
    projectSessionsQuery.type = 'session-project';
    const storySessionsQuery = _cloneDeep(query);
    storySessionsQuery.type = 'session-story';

    const projectMatch = encodeURI(JSON.stringify(projectSessionsQuery));
    const storyMatch = encodeURI(JSON.stringify(storySessionsQuery));

    const distinct = encodeURI(
      JSON.stringify({
        properties: ['source', 'lang'],
        data: [],
      }),
    );

    const statsAvg = encodeURI(
      JSON.stringify({
        duration: [
          // Any duration metric will be included per group
          'avg',
        ],
      }),
    );

    const statsSum = encodeURI(
      JSON.stringify({
        duration: [
          // Any duration metric will be included per group
          'sum',
        ],
      }),
    );

    const weekGroup = encodeURI(
      JSON.stringify({
        time: [
          // Any duration metric will be included per group
          filters.sessions,
        ],
      }),
    );

    const langGroup = encodeURI(
      JSON.stringify({
        properties: [
          // Any duration metric will be included per group
          'languageID',
        ],
      }),
    );

    const popularContentGroup = encodeURI(
      JSON.stringify({
        properties: [
          // Any duration metric will be included per group
          filters.popularContent,
        ],
      }),
    );

    const { analyticsData, error } = yield call(fetchAnalytics, 'analyticsData', projectMatch, statsAvg);
    if (!error) {
      const { analyticsDataPerLang, error } = yield call(fetchAnalytics, 'analyticsDataPerLang', projectMatch, statsSum, langGroup);
      if (!error){
        const { analyticsDataPerWeek, error } = yield call(fetchAnalytics, 'analyticsDataPerWeek', projectMatch, statsSum, weekGroup);
        if (!error) { 
          const { analyticsUIdata, error } = yield call(fetchAnalyticsUI, storyMatch, distinct);
          const popularMatch = filters.popularContent === 'projectID' ? projectMatch : storyMatch;
          if (!error){
            const { analyticsPopularContent, error } = yield call(
              fetchAnalytics,
              'analyticsPopularContent',
              popularMatch,
              statsAvg,
              popularContentGroup,
            );
            if (!error){
              yield put({
                type: CONST.ANALYTICS_SUCCESS,
                analyticsData,
                analyticsUIdata,
                analyticsDataPerWeek,
                analyticsDataPerLang,
                analyticsPopularContent,
                filters,
              });
            } else {
              throw new Error(error);
            }
          } else {
            throw new Error(error);
          }
        } else {
          throw new Error(error);
        }
      } else {
        throw new Error(error);
      }
    } else {
      throw new Error(error);
    }

  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.ANALYTICS_ERROR, message });
  }
}

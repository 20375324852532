import { put, call, select } from 'redux-saga/effects';
import { createUser, updateUser, deleteUser } from 'api';
import * as selectors from './selectors';
import * as CONST from 'actions/action_constants';

export function* handleUserManagerAddRequestSaga(action){
  try {
    const { response, error } = yield call(createUser, action.userData);
    if (response) {
      yield put({ type: CONST.USER_MANAGER_ADD_SUCCESS, userData: response });
    } else {
      throw new Error(error);
    }
    
  } catch(error) {
    const { message } = error;
    yield put({ type: CONST.USER_MANAGER_ADD_ERROR, message });
  }
}

export function* handleUserManagerDeleteRequestSaga(action){
  try {
    for (let i = 0; i < action.userIDs.length; i++) {
      const userID = action.userIDs[i];
      const { response, error } = yield call(deleteUser, userID);
      if (response) {
        yield put({ type: CONST.USER_MANAGER_DELETE_SUCCESS, userID });
      } else {
        throw new Error(error);
      }
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.USER_MANAGER_DELETE_ERROR, message });
  }
}

export function* handleUserEditorSaveRequestSaga(action){
  try {
    const userData = yield select(selectors.getUser, action.userID);
    delete userData._id;
    delete userData.isSaved;

    const { response, error } = yield call(updateUser, action.userID, userData);
    if (response) {
      yield put({ type: CONST.USER_EDITOR_SAVE_SUCCESS, userID: action.userID });
    } else {
      throw new Error(error);
    }
  
  } catch(error) {
    const { message } = error;
    yield put({ type: CONST.USER_EDITOR_SAVE_ERROR, message });
  }
}
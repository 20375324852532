import { put, call } from "redux-saga/effects";
import { fetchLibrary, updateAsset, createAsset, deleteAsset } from "api";
import Logger from "utils/logger";
import { loadState } from "store/localStorage";
import * as CONST from "actions/action_constants";
import uuid from "uuid-v4";

export function* fetchLibrarySaga(action) {
  try {
    const { response, error } = yield call(fetchLibrary);
    if (response) {
      yield put({ type: CONST.LIBRARY_FETCH_SUCCESS, library: response });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.LIBRARY_FETCH_ERROR, message });
  }
}

export function* assetUpdateSaga(action) {
  const { assetIndex, assetData } = action;

  const queId = uuid();
  // TODO: need to do a call to the check auth saga
  try {
    // @TODO besure we dont pass the _id in with other data
    Logger.debug({ assetIndex, assetData }, "[LIBRARY SAGA] - assetUpdateSaga ");

    // make sure we add to the que
    yield put({ type: CONST.QUEUE_REQUEST, queType: "library", queId });
    if (typeof assetData.isSaved !== "undefined") delete assetData.isSaved;
    const { response, error } = yield call(updateAsset, assetIndex, assetData);
    Logger.debug({ response }, "[LIBRARY SAGA] assetUpdateSaga ");
    if(error){
      Logger.error(error, "[LIBRARY SAGA] assetUpdateSaga" );
    } 
    if (response) {
      yield put({
        type: CONST.LIBRARY_ASSET_UPDATE_SUCCESS,
        assetIndex,
        assetData
      });

      // create a new copy --
      yield put({ type: CONST.COPY_ASSET, force: true, assetData });
      // tell the queItem we are complete
      yield put({
        type: CONST.QUEUE_RESPONSE,
        success: true,
        message: response.message || "Operation Complete",
        queId
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.LIBRARY_ASSET_UPDATE_ERROR, assetIndex, message });
    yield put({
      type: CONST.QUEUE_RESPONSE,
      success: false,
      message,
      queId
    });
  }
}

export function* createAssetSaga(action) {
  const { assetData, account } = action;
  try {
    const dataForSaveArray = [];
    for (const item of assetData) {
      const { uploadInfo } = item;
      const source = uploadInfo ? uploadInfo : item;
      const { image_metadata } = source;

      const dataForSave = {
        account,
        acknowledgement: image_metadata
          ? typeof image_metadata.Rights !== "undefined"
            ? `<p>${image_metadata.Rights}</p>`
            : ""
          : "",
        filename: `${source.original_filename}.${source.format}`,
        fileSize: source.bytes,
        format: source.format,
        caption: image_metadata
          ? typeof image_metadata.Description !== "undefined"
            ? `<p>${image_metadata.Description}</p>`
            : typeof image_metadata.description !== "undefined"
            ? `<p>${image_metadata.description}</p>`
            : ""
          : "",
        city: image_metadata
          ? typeof image_metadata.City !== "undefined"
            ? image_metadata.City
            : ""
          : "",
        country: image_metadata
          ? typeof image_metadata.Country !== "undefined"
            ? image_metadata.Country
            : ""
          : "",
        creator: image_metadata
          ? typeof image_metadata.Creator !== "undefined"
            ? image_metadata.Creator
            : typeof image_metadata.creator !== "undefined"
            ? image_metadata.creator
            : ""
          : "",
        modifiedDate: new Date().toISOString().slice(0, 19).replace("T", " "),
        date: image_metadata
          ? typeof image_metadata.DateCreated !== "undefined"
            ? image_metadata.DateCreated
            : ""
          : "",
        id: image_metadata
          ? typeof image_metadata.ArtworkSourceInventoryNo !== "undefined"
            ? image_metadata.ArtworkSourceInventoryNo
            : typeof image_metadata.ObjectSourceInventoryNo !== "undefined"
            ? image_metadata.ObjectSourceInventoryNo
            : ""
          : "",
        title: image_metadata
          ? typeof image_metadata.Title !== "undefined"
            ? `<p>${image_metadata.Title}</p>`
            : typeof image_metadata.title !== "undefined"
            ? `<p>${image_metadata.title}</p>`
            : `<p>${source.original_filename}</p>`
          : `<p>${source.original_filename}</p>`,
        isCloudinary: true,
        cloudName: "dgpzfuyzy",
        cloudPublicId: source.public_id,
        height: source.height,
        width: source.width,
        type:
          source.resource_type === "video"
            ? "video"
            : source.format == "pdf"
            ? "pdf"
            : "image"
      };
      const { response, error } = yield call(createAsset, dataForSave);
      if (response) {
        dataForSave._id = response._id;
        dataForSaveArray.push(dataForSave);
      } else {
        throw new Error(error);
      }
    }

    yield put({
      type: CONST.LIBRARY_ASSET_CREATE_SUCCESS,
      assetData: dataForSaveArray
    });
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.LIBRARY_ASSET_CREATE_ERROR, message, assetData });
  }
}

export function* resetAssetSaga(action) {
  yield put({ type: CONST.REMOVE_COPY_ASSET });
}

export function* assetDeleteSaga(action) {
  const { assets } = action;
  try {
    for (const assetId of assets) {
      const { response, error } = yield call(deleteAsset, assetId);
      if (error) {
        throw new Error(error);
      }
    }
    yield put({
      assets,
      type: CONST.LIBRARY_DELETE_MULTIPLE_SUCCESS
    });
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.LIBRARY_DELETE_MULTIPLE_ERROR, message });
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Logger from "utils/logger";
import ContentContainer from "modules/ContentContainer";
import AssetViewer from "modules/Library/AssetViewer";
import LibrarySidebar from "./LibrarySidebar";
import { Dialog } from "@terraincognita/ui-core";
import * as libraryActions from "actions/library";
import { getLibraryMainContainerProps } from "reducers/ui/library";
import ImageCropper from "modules/Library/ImageCropper";
import RaisedButton from "material-ui/RaisedButton";
import AssetSelector from "./AssetSelector";
import "./Library.scss";
import Auth from "common/Auth";
import styles from "./Library.scss";
import config from "config";
import { openSearchWidget } from "common/j-story";

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalOpen: false,
      imageCropperOpen: false
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.openImageCropper = this.openImageCropper.bind(this);
    this.closeImageCropper = this.closeImageCropper.bind(this);
    this.processCropAction = this.processCropAction.bind(this);
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });
    this.props.setLibraryIsLoading(true);
    this.props.deleteMultiple();
  }

  processCropAction(cropData) {
    const x = Math.round((cropData.x * this.props.asset.width) / 100);
    const y = Math.round((cropData.y * this.props.asset.height) / 100);
    const newWidth = Math.round(
      (cropData.width * this.props.asset.width) / 100
    );
    const newHeight = Math.round(
      (cropData.height * this.props.asset.height) / 100
    );
    this.props.cropAsset({
      config: {
        cropCoordinates: {
          x: x,
          y: y,
          width: newWidth,
          height: newHeight
        }
      }
    });
  }

  getPercentageCropData(cropData) {
    const x = (cropData.x * 100) / this.props.asset.width;
    const y = (cropData.y * 100) / this.props.asset.height;
    const newWidth = (cropData.width * 100) / this.props.asset.width;
    const newHeight = (cropData.height * 100) / this.props.asset.height;
    return { x: x, y: y, width: newWidth, height: newHeight };
  }

  openImageCropper() {
    if (Auth.isAuthenticated()) {
      this.setState({ imageCropperOpen: true });
    } else {
      this.props.setUIError("Session Expired");
    }
  }

  closeImageCropper() {
    this.setState({ imageCropperOpen: false });
  }

  openUploadWidget() {
    if (Auth.isAuthenticated()) {
      if (typeof cloudinary !== "undefined") {
        // @ TODO: Decide whether or not we need to set a folder with the account ID
        // account ID can be accessed with this.props.account
        const cloudName = config("cloudName");
        cloudinary.openUploadWidget(
          {
            // eslint-disable-line
            folder: this.props.account,
            uploadPreset: this.props.uploadPreset,
            showPoweredBy: false,
            sources: [
              "local",
              "url",
              "camera",
              "dropbox",
              "image_search",
              "google_photos",
              "facebook"
            ],
            cloudName: cloudName
            // stylesheet: `https://storycrafter.co/cloudinary-widget-external.css?noCache=${Date.now()}`
          },
          (error, result) => {
            if (!error && result) {
              if (result.event === "queues-end") {
                this.props.createAssetRequest(
                  result.info.files,
                  this.props.account
                );

                this.props.onSortByClick("modifiedDate");
                this.props.onSortByOrderClick("desc");
              }
            }
          }
        );
      }
    } else {
      this.props.setUIError("Session Expired");
    }
  }

  openJHNUploadWidget() {
    if (Auth.isAuthenticated()) {
      openSearchWidget(
        {
          cloud_name: config("cloudName"), // "dnva5y44r",
          folder: this.props.account,
          upload_preset: "vibeotib" // "lhiqbrlx"
        },
        (result, error) => {
          if (typeof result !== "undefined" && result.length > 0) {
            Logger.debug({result},'[LIBRARY] openJHNUploadWidget');
            // Save through API - Add to Data Library Items
            // @TODO pass an array of items, even if there is only on
            this.props.createAssetRequest(result, this.props.account);
            this.props.onSortByClick("modifiedDate");
            this.props.onSortByOrderClick("desc");
          }
        }
      );
    } else {
      this.props.setUIError("Session Expired");
    }
  }

  scrollToTop() {
    document.getElementById("cardBrowser").scrollTop = 0;
    window.scrollTop = 0;
  }

  render() {
    const assetId =
      typeof this.context.router.route.match.params.assetId !== "undefined"
        ? this.context.router.route.match.params.assetId
        : false;
    const backRoute = assetId
      ? this.context.router.route.location.pathname.replace(
          "/view/" + assetId,
          ""
        )
      : this.props.isAddNewAsset
      ? "/library"
      : "/";
    const pathname = typeof this.context.router.route.location.pathname
      ? this.context.router.route.location.pathname
      : false;

    let assetEditorModalContentContainer = null;

    let activeSlideAssetCloudId =
      this.props.asset && this.props.asset.cloudPublicId
        ? "//res.cloudinary.com/dgpzfuyzy/image/upload/c_crop,f_auto,q_auto/v1/" +
          this.props.asset.cloudPublicId
        : null;

    let assetDownloadURL = "https://res.cloudinary.com/dgpzfuyzy";
    if (this.props.asset) {
      const fileExtRegEx = /\.[^/.]+$/;
      let matches = this.props.asset.filename.match(fileExtRegEx);
      let fileExt = matches ? matches[0] : ".jpg";

      switch (this.props.asset.type) {
        case "image":
          assetDownloadURL += "/image/upload/";
          assetDownloadURL += fileExt === ".svg" ? "fl_sanitize/" : "";
          assetDownloadURL += this.props.asset.cloudPublicId + fileExt;
          break;

        // Under development. Started by copying logic for handling video.
        case "pdf":
          assetDownloadURL +=
            "/image/upload/" + this.props.asset.cloudPublicId + fileExt;
          break;

        case "video":
        case "cloudinaryVideo":
          fileExt = ".mp4";
          assetDownloadURL +=
            "/video/upload/v1/" + this.props.asset.cloudPublicId + fileExt;
          break;
      }
    }

    const topBarAssetEditor = (
      <div className="asset-editor-topbar">
        {this.props.asset && this.props.asset.type === "image" ? (
          <RaisedButton onClick={this.openImageCropper} primary label="CROP" />
        ) : null}
      </div>
    );

    assetEditorModalContentContainer = (
      <ContentContainer
        addClassName="asset-viewer"
        isLoading={this.props.isLoading}
        error={this.props.error}
        sidebarMode={this.props.sidebarMode}
        topBar={topBarAssetEditor}
      >
        <AssetViewer
          asset={this.props.asset}
          isSaved={this.props.isSaved}
          cardViewerMaxCardSize={this.props.cardViewerMaxCardSize}
          prev={this.props.assetPrev}
          revertAssetAction={this.props.revertAsset}
          setLibraryIsLoading={this.props.setLibraryAssetIsLoading}
          copyAssetFunction={this.props.copyAsset}
          resetScrollAction={this.scrollToTop}
          saveFrameAsPosterFunction={time => {
            this.props.updateAsset({
              ...this.props.asset,
              config: {
                posterFrame: time
              }
            });
          }}
          isLoading={this.props.isAssetLoading}
          next={this.props.assetNext}
          history={this.props.history}
        />
      </ContentContainer>
    );

    let assetSelectorContentContainer = (
      <AssetSelector
        title="Assets"
        isLoading={this.props.isLoading}
        selectBarButtonLabel="DELETE"
        standardBarButtonLabel="UPLOAD"
        minWidthCaption={parseInt(styles.minWidthCaption, 10)}
        minCardMargin={parseInt(styles.minCardMargin, 10)}
        captionHeight={parseInt(styles.captionHeight, 10)}
        setCardMargin={20}
        error={this.props.error}
        isAssetSelectorMode={false}
        isModal={false}
        sidebarMode={this.props.librarySideBarMode}
        showButtons={true}
        enableKeys={true}
        selectBarDeselectButtonAction={this.props.exclusiveSelectHandler}
        selectBarButtonAction={e => {
          e.stopPropagation();
          this.openDeleteModal();
        }}
        standardBarButtonAction={e => this.openUploadWidget()}
        onJHNButtonClick={e => this.openJHNUploadWidget()}
        selectHandlerAction={this.props.selectHandler}
        exclusiveSelectHandlerAction={this.props.exclusiveSelectHandler}
        backClickHandlerAction={this.props.backClickHandler}
        shiftSelectHandlerAction={this.props.shiftSelectHandler}
        editClickHandlerAction={this.props.editClickHandler}
      />
    );

    const percCropData = this.props.cropData
      ? this.getPercentageCropData(this.props.cropData)
      : null;
    
    return (
      <div className="cms-screen library">
        <Helmet>
          <title>Assets</title>
        </Helmet>
        {assetId ? (
          <LibrarySidebar
            dataNode="library"
            assetDownloadURL={assetDownloadURL}
            assetId={assetId}
            history={this.props.history}
            backRoute={backRoute}
            pathname={pathname}
            isAddNewAsset={this.props.isAddNewAsset}
          />
        ) : this.props.isAddNewAsset ? (
          <LibrarySidebar
            dataNode="library"
            history={this.props.history}
            backRoute={backRoute}
            pathname={pathname}
            isAddNewAsset={this.props.isAddNewAsset}
          />
        ) : null}

        {this.props.asset ? assetEditorModalContentContainer : null}
        {assetSelectorContentContainer}
        <Dialog
          content="Once deleted, it cannot be restored"
          handleConfirm={this.closeDeleteModal}
          confirmLabel="Cancel"
          handleCancel={this.proceedToDelete}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Delete"
          modal={false}
          open={this.state.deleteModalOpen}
          title="Delete this asset?"
        />
        {this.props.asset && this.props.asset.type === "image" ? (
          <ImageCropper
            open={this.state.imageCropperOpen}
            closeAction={this.closeImageCropper}
            processCropAction={this.processCropAction}
            imageScr={activeSlideAssetCloudId + ".jpg"}
            data={percCropData}
          />
        ) : null}
      </div>
    );
  }
}

Library.contextTypes = {
  router: PropTypes.object
};

Library.defaultProps = {
  cloudName: config("cloudName"),
  uploadPreset: "vibeotib"
};

Library.propTypes = {
  uploadPreset: PropTypes.string, // Cloudinary Upload Preset
  cropData: PropTypes.object, // Crop data if image is cropped
  librarySideBarMode: PropTypes.string, // sidebarMode to keep in the back with the library
  cloudName: PropTypes.string // Cloudinary Cloud Name for the account
};

function mapStateToProps(state, ownProps) {
  return getLibraryMainContainerProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    account,
    revertCopy,
    assetId,
    asset,
    cardBrowserConfigUserPrefsSelector
  } = stateProps;

  return Object.assign({}, stateProps, {
    removeAssetCopy: () => dispatchProps.removeAssetCopy(),
    resetAssetEditor: () => dispatchProps.resetAssetEditor(),
    cropAsset: configData => dispatchProps.updateAsset(assetId, configData),
    revertAsset: () => dispatchProps.revertAsset(assetId, revertCopy),
    copyAsset: () => dispatchProps.copyAsset(asset),
    requestLibrary: () => {
      return dispatchProps.requestLibrary(account);
    },
    updateAsset: assetData => dispatchProps.updateAsset(assetId, assetData),
    selectHandler: id => dispatchProps.selectToggle(id),
    exclusiveSelectHandler: id => {
      Logger.debug("[CARD BROWSER - Library] - exclusiveSelectHandler");
      dispatchProps.exclusiveSelectToggle(id);
    },
    shiftSelectHandler: (id, items) =>
      dispatchProps.shiftSelectToggle(id, items),
    backClickHandler: id => dispatchProps.exclusiveSelectToggle(id),
    editClickHandler: url => {
      ownProps.history.push(url);
    },
    deleteMultiple: () => dispatchProps.deleteMultiple(stateProps.selected),
    setLibraryIsLoading: value => dispatchProps.setLibraryIsLoading(value),
    setLibraryAssetIsLoading: value => {
      dispatchProps.setLibraryAssetIsLoading(value);
    },
    createAssetRequest: data => dispatchProps.createAssetRequest(data, account),
    onSortByClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByType",
        value
      ),
    onSortByOrderClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByOrder",
        value
      ),
    setUIError: message => dispatchProps.setUIError(message)
  });
}

export default connect(mapStateToProps, libraryActions, mergeProps)(Library);

import _map from "lodash/map";

/**
 * localizeTextProps
 * helper function that will prepare data to be used by a components
 * @param {string} langID - global value passed
 * @param {array} localizedProps - an array of props to be converted
 * @param {object} data
 *
 */
export const localizeTextProps = (langID, localizedProps, data) => {
  // loop through the localizedProps array and store the correct getLocalizedValue
  if (!localizedProps) return data;
  const newData = { ...data };
  if (typeof newData === "undefined" || !newData) return data;
  _map(localizedProps, (prop, index) => {
    newData[prop] = getLocalizedValue(langID, newData[prop]);
  });
  return newData;
};

/**
 * Localize i18n form data. If the form element indicates that
 * the form value has multiple translations (isLocalized===true),
 * set the data to a string based on the passed language ID.
 * Only values associated with the currently selected language
 * can be displayed in the target form.
 *
 *
 * @param  {String} langID       Two-character string representing the currently
 *                                selected language.
 * @param  {Object} formElements Object with a property for each form element.
 * @param  {Object} data         Form data with i18n objects that need to be converted.
 * @return {Object}              Localized form data.
 */
export const localizeTextProps2 = (langID, formElements, data) => {
  // loop through the localizedProps array and store the correct getLocalizedValue
  const newData = { ...data };
  if (typeof newData === "undefined" || !newData) return data;
  _map(formElements, (element, key) => {
    if (element.isLocalized) {
      if (newData[key]) {
        if (newData[key].text) {
          newData[key] = getLocalizedValue(langID, newData[key].text);
        } else {
          newData[key] = getLocalizedValue(langID, newData[key]);
        }
      }
    }
  });
  return newData;
};

export const deLocalizeTextProps = (langID, localizedProps, data) => {
  // loop through the localizedProps array and store the correct getLocalizedValue
  if (!localizedProps) return data;
  const newData = { ...data };
  _map(localizedProps, (value, index) => {
    newData[value] = setLocalizedValue(langID, newData[value]);
  });
  return newData;
};

// @returns {string}
const getLocalizedValue = (langID, textProp) => {
  // Initialize the value to the empty string. We'll return the
  // empty string if there's no data defined for the given language.
  let value = "";

  if (typeof textProp === "string") {
    // Some localized text elements may not be propertly formatted.
    // They may be strings. Just return the string for now.
    return textProp;
  } else if (typeof textProp === "undefined") {
    // No idea why this would be the case, but if we get to this
    // point and the text prop is undefined, return an empty string.
    return value;
  }

  // If a value is set for the given language, return it.
  if (textProp[langID]) {
    value = textProp[langID];
  }

  return value;
};

// @returns {object}
const setLocalizedValue = (langID, value) => {
  const prop = {};
  if (value && langID) {
    prop[langID] = value;
  }
  return prop;
};

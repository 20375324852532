import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getQuizEditorProps} from "reducers/ui/quizEditor";
import * as quizEditorActions from "actions/quizEditor";
import Sidebar from "./Sidebar";
import Previewer from "components/Previewer";
import SectionSidebar from "./Sidebar/Section";
import ContentContainer from "modules/ContentContainer";
import QuestionEditor from "./QuestionEditor";
import "./QuizEditor.scss";
import styles from "./QuizEditor.scss";

class QuizEditor extends Component {

  render() {

    const SidebarActionBar =
      <div>
        <div className="quiz-type-container">
          Graded Quiz
        </div>
        <div className="change-quiz-container">
          CHANGE TYPE
        </div>
      </div>;

    return (
      <div className="quiz-editor">

        {
          (this.props.section) ?
            <SectionSidebar {...this.props.mainSidebarProps} section={this.props.section}
                            data={this.props.sectionData} actionBar={SidebarActionBar}/> :
            <Sidebar {...this.props.mainSidebarProps} actionBar={SidebarActionBar}/>
        }
        {
          (this.props.section) ?
            <ContentContainer
              isLoading={this.props.isLoading}
              backgroundColor={styles.backgroundEditor}
              overflow="auto"
              sidebarMode={this.props.sidebarMode}>
              {
                (this.props.section === 'questions') ?
                  <QuestionEditor formElements={this.props.quizQuestionForm}
                                  questionData={this.props.questionData}
                                  defaultFirstQuestion={typeof this.props.sectionData[0] !== 'undefined' ? this.props.sectionData[0] : null}
                                  responseFormElements={this.props.responseFormElements}/>
                  : null
              }
            </ContentContainer>
            :
            <Previewer isLoading={this.props.isLoading} sidebarMode={this.props.sidebarMode}
                       previewPath="https://dev.dest.neonsky.com/f95e98bd22564807b37126c848a03fc0/quick-quiz"/>
        }

      </div>
    );
  }

  componentDidMount() {
    this.props.requestQuiz();
  }

}

QuizEditor.defaultProps = {
  section: null
};

QuizEditor.propTypes = {
  section: PropTypes.string,
  mainSidebarProps: PropTypes.object,
  sectionData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  answersData: PropTypes.object
};


function mapStateToProps(state, ownProps) {
  return getQuizEditorProps(state, ownProps);
}

export default connect(mapStateToProps, quizEditorActions)(QuizEditor);
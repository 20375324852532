import { put, call, select } from "redux-saga/effects";
import {
  fetchStoryBrowser,
  fetchStoryData,
  fetchStoryScenes,
  fetchStoryCategories,
  createStory,
  updateStory,
  deleteStory,
  createScene,
  createSubScene,
  updateScene,
  deleteScene,
  duplicateScene,
  deleteSubScene,
  deleteElementAsset,
  dummyDelay
} from "api";
import Logger from "utils/logger";
import { loadState } from "store/localStorage";
import * as CONST from "actions/action_constants";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
import _cloneDeep from "lodash/cloneDeep";
import * as selectors from "./selectors";
import { arrayMove } from "react-sortable-hoc";
import { deLocalizeTextProps } from "helpers/localizationHelper";
//----
// SELECTORS
// used in the saga

// STORY BROWSER SAGA WORKERS
/**
 * FetchStoryBrowserSaga
 */
export function* fetchStoryBrowserSaga() {
  try {
    const { response, error } = yield call(fetchStoryBrowser);

    if (response) {
      yield put({
        type: CONST.STORY_BROWSER_SUCCESS,
        storyBrowserData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_BROWSER_ERROR, message });
  }
}

/**
 * fetchStoryDataSaga
 * Saga Method that is fired by the action : STORY_DATA_REQUEST
 * the goal here is to aquire any storydata we don't currently have, along with the scenes
 */
export function* fetchStoryDataSaga(action) {
  try {
    const { storyID } = action;
    const { response, error } = yield call(fetchStoryData, storyID);

    if (response) {
      const storyScenesResponse = yield call(fetchStoryScenes, storyID);

      if (storyScenesResponse) {
        const storyCategoriesResponse = yield call(
          fetchStoryCategories,
          storyID
        );

        yield put({
          type: CONST.STORY_DATA_SUCCESS,
          storyData: response,
          storyScenes: storyScenesResponse.response,
          categories: storyCategoriesResponse.response
        });
      } else {
        throw new Error("Error Fetching Story Scenes");
      }
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_DATA_ERROR, message });
  }
}

/**
 *  createStorySaga
 * called by action : STORY_CREATE_REQUEST
 */
export function* createStorySaga(action) {
  try {
    const { inputData } = action;
    const title = inputData
      ? { en: inputData.title }
      : { en: "Default Test Title" };
    const label = inputData ? inputData.label : title.en; // NOTE: will need to be localized
    const type = inputData ? inputData.type : "5970953b7c01142ca6d2fd3c"; // set default type to be gallery for Now
    const storyData = {
      type,
      title,
      label
    };

    const { response, error } = yield call(createStory, storyData);

    if (response) {
      storyData._id = response._id;
      yield put({ type: CONST.STORY_CREATE_SUCCESS, storyData: response });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_CREATE_ERROR, message });
  }
}

// Doesn't appear to be used as of 11/26/2019. If it's determined
// that this function is used, the call to the updateStory() API
// method needs to be updated. The first parameter needs to be the
// storyID.

// export function* updateStorySaga(action) {
//   try {
//     const { inputData } = action;
//     const storyData = inputData;

//     const { response, error } = yield call(updateStory, storyData);

//     if (response) {
//       yield put({ type: CONST.STORY_UPDATE_SUCCESS, storyData });
//     } else {
//       throw new Error(error);
//     }
//   } catch (error) {
//     const { message } = error;
//     yield put({ type: CONST.STORY_UPDATE_ERROR, message });
//   }
// }

export function* saveSettingsSaga(action) {
  try {
    const { storyID } = action;
    const story = yield select(selectors.getStory, storyID);

    const { response, error } = yield call(updateStory, storyID, story);

    if (error) {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_SETTINGS_SAVE_ERROR, message });
  }
}

export function* saveDesignSettingsSaga(action) {
  try {
    const { storyID } = action;
    const story = yield select(selectors.getStory, storyID);

    const { response, error } = yield call(updateStory, storyID, story);

    if (error) {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_DESIGN_SETTINGS_SAVE_ERROR, message });
  }
}

export function* saveQuestionsSceneSaga(action) {
  try {
    // later will come from action
    const { sceneID } = action;
    const { inputData } = action;
    if (inputData.limitNumberOfQuestions === false) {
      inputData.numberOfQuestions = null;
    }
    const updatedScene = yield call(updateScene, sceneID, inputData);

    // add new ID to the data and put the success
    yield put({
      type: CONST.STORY_QUESTION_SCENE_SAVE_SUCCESS,
      sceneID,
      sceneData: inputData
    });
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_QUESTION_SCENE_SAVE_ERROR, message });
  }
}

/**
 *
 */
export function* deleteStorySaga(action) {
  try {
    const { selected } = action;

    const { response, error } = yield call(deleteStory, selected);

    if (response) {
      yield selected.map(storyID =>
        put({ type: CONST.STORY_DELETE_SUCCESS, storyID })
      );
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_DELETE_ERROR, message });
  }
}

/**
 * this saga only handles the change of the scene order,
 * but uses the same api method as update
 * it recieves the old and new positions and then r
 */
export function* setStorySceneOrderSaga(action) {
  try {
    const { storyID, oldIndex, newIndex } = action;
    // select the story by ID
    const story = yield select(selectors.getStory, storyID);
    if (!story) {
      throw new Error(`Unable to select the story${storyID}`);
    }
    const { sceneIDs } = story;
    // use positions to reorder the array of scene ids
    const newScenIDs = arrayMove(sceneIDs, oldIndex, newIndex);
    const storyData = { type: story.type, sceneIDs: newScenIDs };

    yield put({ type: CONST.STORY_SET_SCENE_ORDER, storyID, storyData });

    const result = yield call(updateStory, storyID, storyData);

    yield put({
      type: CONST.STORY_SET_SCENE_ORDER_SUCCESS,
      storyID,
      storyData
    });
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_SET_SCENE_ORDER_ERROR, message });
  }
}

// DEPRECATED...
export function* resetSceneOrderSaga(action) {
  try {
    yield put({ type: CONST.STORY_SLIDE_RESET_ORDER, data: { ...action } });
    yield put({ type: CONST.STORY_SLIDE_UPDATE_ACTIVE, data: { ...action } });
  } catch (error) {
    //  revert oldOrder - newOrder
    yield put({ type: CONST.STORY_SLIDE_RESET_ORDER, data: { ...action } });
  }
}
// -----------------------------------
// SCENES

/**
 * Create Scene
 */
export function* createSceneSaga(action) {
  try {
    const { storyID, layoutID, isLocalized } = action;
    const localize = typeof isLocalized !== "undefined" ? isLocalized : true;

    const inputData = action.sceneData;
    // NOTE: sceneID is the parent scene's _id
    // TODO: make this always go to title.en
    const title =
      inputData && inputData.title
        ? localize
          ? { en: inputData.title }
          : inputData.title
        : localize
        ? { en: "Untitled" }
        : "Untitled";
    // const slug = _slugify(title.en);
    // const label = inputData ? inputData.label : title.en; // NOTE: will need to be localized
    const type = inputData ? inputData.type : "5970953b7c01142ca6d2fd3d"; // set default type to be slideshow-slide for Now
    // convert title to a slug
    // NOTE can always obtian create date from the object ID
    const sceneData = {
      title,
      typeID: type,
      layoutID
    };

    const { response, error } = yield call(createScene, sceneData, storyID);

    if (response) {
      yield put({ type: CONST.SCENE_CREATE_SUCCESS, sceneData: response });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SCENE_CREATE_ERROR, message });
  }
}

export function* deleteSceneElementAssetSaga(action) {
  try {
    const { sceneID, elementID } = action;
    const { response, error } = yield call(
      deleteElementAsset,
      sceneID,
      elementID
    );

    if (response) {
      yield put({
        type: CONST.SCENE_UPDATE_SUCCESS,
        sceneID,
        sceneData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SCENE_UPDATE_ERROR, message });
  }
}

/**
 * updateSceneSaga
 * @param {object} action
 * this saga will handle to the updating a scene,
 * certain sceneTypes will need different structures passed to api
 * the action should contain:
 * sceneData, editingForm, sceneType, subsceneType, elementID
 */
export function* updateSceneSaga(action) {
  const { sceneID, sceneData } = action;
  let { elementID } = action; // sceneData is pulled from scenes store BUT is NOT always exact rep
  let updateData; // sceneData is pulled from scenes store

  // If elementID is defined, that means a change is being
  // made in an Element Editor (Scene, Gallery, etc.). Determine
  // the element type before proceeding.
  const elementType = elementID
    ? elementID === "background" && action.sceneData.background
      ? action.sceneData.background.type
      : action.sceneData.type
    : null;
  // updateData is the object we will built and send to API

  try {
    const currScene = yield select(selectors.getScene, sceneID);
    const sceneType = yield select(selectors.getSceneTypeParam, currScene.type);

    switch (sceneType) {
      case "viewer-asset":
      case "game-asset":
        /*
        Pass the entire element to the API. This accounts for 
        the possibility of custom element data. If the meta config
        data is being edited, sceneData is a pointer to all scene
        elements, which means we have to reference the asset prop.
        If a crop is being procressed, only the asset element object
        is passed.
        */
        updateData = sceneData.asset
          ? { ...sceneData.asset }
          : { ...sceneData };
        break;

      case "quiz-graded-question":
        elementID = null;
        _map(sceneData, (element, key) => {
          if (typeof element === "object" && element) {
            if (typeof element.assetID !== "undefined") {
              sceneData[key] = { assetID: element.assetID };
            } else if (typeof element.list !== "undefined") {
              sceneData[key] = [];
              _map(element.list, (element2, key2) => {
                element2.title =
                  typeof element2.title.text !== "undefined"
                    ? element2.title.text
                    : "";
                sceneData[key].push({ ...element2 });
              });
            } else if (element.text !== "undefined") {
              sceneData[key] = element.text;
            }
          } else if (typeof element === "string") {
            // sceneData[key] = { en: sceneData[key] };
          } else {
          }
        });
        updateData = sceneData;
        break;

      case "slideshow-slide":
      case "cover":
        updateData =
          elementID === "background"
            ? sceneData
            : elementType === "asset"
            ? {
                assetID: sceneData.assetID
              }
            : { ...sceneData[elementID] };

        if (elementType === "asset" && Object.keys(sceneData).length > 0) {
          elementID = sceneData.use || elementID; // If we are only cropping we get the elementID in the 'use' key
          updateData = {
            assetID: sceneData.assetID,
            assetConfig: sceneData.assetConfig,
            type: elementType,
            metaConfig: sceneData.metaConfig,
            color: sceneData.color,
            styles: sceneData.styles,
            classes: sceneData.classes
          };
        }
        break;

      /* 
      Ideally at some point in the future, we can dispense
      with the other cases and handle everything using the
      logic below which looks at the element type.
      */
      case "timeline-event":
      case "map-place":
      case "quiz-graded-results":
      case "project-story-menu":
        elementID = null;
        /*
        6/5/2021 - The main menu editor (project-story-menu) doesn't
        include a sceneData dispatch prop. Discovered that the 
        sceneData prop is merely a pointer to the elements object of
        the scene in Redux. So if it's not defined, just look to the
        elements object of currScene. Eventually all scene editors can
        be updated to stop passing sceneData.
        */
        updateData = _cloneDeep(sceneData || currScene.elements);
        _map(updateData, (element, key) => {
          switch (element.type) {
            case "text":
              updateData[key] = element.text;
              break;

            case "checkbox":
            case "select":
            case "select-multiple":
              updateData[key] = element.value;
              break;

            case "asset":
              updateData[key] = { assetID: element.assetID };
              break;

            case "ordered-list":
              const feedbackData = [];
              _map(element.list, (element2, key2) => {
                feedbackData.push({
                  grade: parseInt(element2.grade) || 0,
                  title: { ...element2.title.text },
                  asset:
                    element2.asset === null
                      ? { assetID: null }
                      : { ...element2.asset }
                });
              });
              updateData[key] = feedbackData;
              break;
          }

          if (sceneType === "project-story-menu") {
            updateData.layoutID = currScene.layoutID;
          }

          /*
          The timeline-event scene has a list object
          on the featured image element that shouldn't
          be there, so delete it. Otherwise, it'll cause
          the axios command to fail becuase of problems
          it will encounter trying to convert the data to
          JSON. The functions on the list object create a
          circular structure which can't be converted to JSON.
          */
          if (updateData.featuredImage) {
            delete updateData.featuredImage.list;
          }
        });
        break;

      case "timeline-events":
      case "map-places":
        elementID = null;
        _map(sceneData, (element, key) => {
          if (
            element.type === "text" &&
            typeof element.text !== "undefined" &&
            element
          ) {
            sceneData[key] = sceneData[key].text;
          }
        });
        updateData = sceneData;
        break;

      // This is a collection scene type. All other scene
      // types are accounted for above.
      default:
        elementID = null;
        _forEach(sceneData, (element, key) => {
          switch (element.type) {
            case "text":
              sceneData[key] =
                typeof element.text !== "undefined" ? sceneData[key].text : "";
              break;

            case "select":
            case "select-multiple":
              sceneData[key] = element.value;
              break;
          }
        });
        updateData = sceneData;
    }

    const { response, error } = yield call(
      updateScene,
      sceneID,
      updateData,
      elementID
    );

    // the result from the API will be a full scene returned
    if (response) {
      yield put({
        type: CONST.SCENE_UPDATE_SUCCESS,
        sceneID,
        sceneData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SCENE_UPDATE_ERROR, message });
  }
}

export function* deleteSceneSaga(action) {
  try {
    const { storyID, selected: selectedSceneIds } = action;

    const { response, error } = yield call(
      deleteScene,
      selectedSceneIds,
      storyID
    );

    if (response) {
      yield put({
        type: CONST.STORY_SCENE_DELETE_SUCCESS,
        selected: selectedSceneIds,
        storyID
      });
      yield selectedSceneIds.map(sceneID =>
        put({ type: CONST.SCENE_DELETE_SUCCESS, sceneID, storyID })
      );
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SCENE_DELETE_ERROR, message });
  }
}

/**
 * Create SubScene
 * will call createSubScene
 * NOTE: right now we are making all subscens be the sceneType of 'viewer-asset'
 */
export function* createSubSceneSaga(action) {
  try {
    // later will come from action
    const { storyID, isRotationGallery, sceneType, subsceneType, sceneData } =
      action;
    const parentSceneID = action.sceneID;
    const subsceneTypeId = yield select(selectors.getSceneTypeId, subsceneType);
    let sceneDataPost,
      cleanArray = [];
    if (
      sceneType === "gallery" ||
      sceneType === "collection" ||
      (sceneType === "map-places" && subsceneType === "viewer-asset") ||
      (sceneType === "timeline-events" && subsceneType === "viewer-asset")
    ) {
      // Cleaning assetIDs array
      if (typeof sceneData === "string") {
        cleanArray = [sceneData];
      } else {
        // Why not just cleanArray = [...sceneData]. Why checking for
        // whether or not item is not true?
        sceneData.forEach(item => {
          if (item) {
            cleanArray.push(item);
          }
        });
      }
      sceneDataPost = {
        typeID: subsceneTypeId,
        assetIDs: cleanArray
      };
      if (isRotationGallery) {
        sceneDataPost.isRotationGallery = true;
      }
    } else {
      const title = sceneData
        ? { en: sceneData.title }
        : { en: "Default Sub Scene Test Title" };
      sceneDataPost = {
        typeID: subsceneTypeId,
        title
      };
    }

    const { response, error } = yield call(
      createSubScene,
      sceneDataPost,
      storyID,
      parentSceneID
    );

    if (response) {
      const newSubsceneIDs = [];
      Object.keys(response).forEach(id => {
        newSubsceneIDs.push(id);
      });

      yield put({
        type: CONST.SUBSCENE_CREATE_SUCCESS,
        sceneData: response,
        newSubsceneIDs,
        parentSceneID,
        isRotationGallery
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SUBSCENE_CREATE_ERROR, message });
  }
}

// I think we may not need this....
// export function* updateSubSceneSaga(action) {
//   const { sceneID, subsceneData } = action;
//   const sceneID = subsceneData._id; // we get the sceneID from the data object, and then remove it so we don't end up with duplicate
//   // the store data has an _id in it.
//   delete subsceneData._id;
//   // data = {
//   //   title: 'New Title',
//   //   caption: '',
//   //   acknowledgement: 'The same acknowledgement',
//   // };
//
//   // TODO: This is not a good design, formatting this way in the saga:
//   const subsceneFormattedData = {
//     elements: {
//       asset: {
//         metaConfig: {},
//       },
//     },
//   };
//   if (typeof subsceneData.title !== 'undefined') {
//     subsceneFormattedData.elements.asset.metaConfig.title = subsceneData.title;
//   }
//   if (typeof subsceneData.caption !== 'undefined') {
//     subsceneFormattedData.elements.asset.metaConfig.caption = subsceneData.caption;
//   }
//   if (typeof subsceneData.secondaryCaption !== 'undefined') {
//     subsceneFormattedData.elements.asset.metaConfig.secondaryCaption =
//       subsceneData.secondaryCaption;
//   }
//   if (typeof subsceneData.acknowledgement !== 'undefined') {
//     subsceneFormattedData.elements.asset.metaConfig.acknowledgement = subsceneData.acknowledgement;
//   }
//
//   const result = yield call(updateScene, sceneID, subsceneFormattedData);
//
//   try {
//     yield put({ type: CONST.SUBSCENE_UPDATE_SUCCESS, sceneID, subsceneFormattedData });
//   } catch (e) {
//     yield put({ type: CONST.SUBSCENE_UPDATE_ERROR, e });
//   }
// }

export function* deleteSubSceneSaga(action) {
  try {
    const { parentSceneID, selected, isRotationGallery } = action;
    const { response, error } = yield call(
      deleteSubScene,
      selected,
      parentSceneID,
      isRotationGallery
    );

    if (response) {
      yield put({
        type: CONST.SUBSCENE_DELETE_SUCCESS,
        selected,
        parentSceneID,
        isRotationGallery
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SUBSCENE_DELETE_ERROR, message });
  }
}

/**
 * this saga only handles the change of the scene order,
 * but uses the same api method as update
 * it recieves the old and new positions and then r
 */
export function* setSceneSubSceneOrderSaga(action) {
  try {
    const { sceneID, oldIndex, newIndex, isRotationGallery } = action;
    // select the parent scene by ID
    const scene = yield select(selectors.getScene, sceneID);
    if (!scene) {
      throw new Error("Unable to select the scene{sceneID}");
    }
    const scenekey = isRotationGallery ? "rotationSubScenes" : "subScenes";
    const dataSubScenes = scene[scenekey];
    // use positions to reorder the array of scene ids
    const newScenIDs = arrayMove(dataSubScenes, oldIndex, newIndex);

    const sceneData = { [scenekey]: newScenIDs };
    const result = yield call(updateScene, sceneID, sceneData);

    // notify success
    yield put({
      type: CONST.SCENE_SET_SUBSCENE_ORDER_SUCCESS,
      sceneID,
      sceneData: result.response,
      isRotationGallery
    });
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SCENE_SET_SUBSCENE_ORDER_ERROR, message });
  }
}

export function* sceneChangeLayoutSaga(action) {
  const { storyID, sceneID, layoutID } = action;
  const sceneData = { layoutID };
  const result = yield call(updateScene, sceneID, sceneData);
  // the result from the API will be an full scene returned
  try {
    yield put({
      type: CONST.SCENE_UPDATE_SUCCESS,
      sceneID,
      sceneData: result.response
    });
  } catch (e) {
    Logger.error(e,'[SAGAS] - sceneChangeLayoutSaga');
  }
}

// export function* sceneChangeClassNamesSaga(action) {
//   const { storyID, sceneID, classNames } = action;
//   const sceneData = { classNames };
//   const result = yield call(updateScene, sceneID, sceneData);
//   // The updateScene API returns the full scene.
//   try {
//     yield put({
//       type: CONST.SCENE_UPDATE_SUCCESS,
//       sceneID,
//       sceneData: result.response
//     });
//   } catch (e) {
//     Logger.error(e);
//   }
// }

export function* sceneChangeStylesSaga(action) {
  const { sceneID } = action;
  const scene = yield select(selectors.getScene, sceneID);
  const sceneData = { styles: scene.styles };
  const result = yield call(updateScene, sceneID, sceneData);
  // The updateScene API returns the full scene.
  try {
    yield put({
      type: CONST.SCENE_UPDATE_SUCCESS,
      sceneID,
      sceneData: result.response
    });
  } catch (e) {
    Logger.error(e,'[SAGAS][STORY EDITOR] sceneChangeStylesSaga');
  }
}

export function* setSceneElementAssetSaga(action) {
  try {
    let assetData, assetConfig;
    // In some cases, when the asset is being deleted, the assetID
    // is set to '' and in other cases, it's set to null. Ideally,
    // it should be set to null in all cases.

    const assetID =
      action.assetID === null || action.assetID === ""
        ? null
        : typeof action.assetID === "string"
        ? action.assetID
        : action.assetID[0];
    if (assetID) {
      assetData = yield select(selectors.getAsset, assetID);
      assetConfig = assetData.config ? assetData.config : {};
    }

    yield put({
      ...action,
      assetID,
      assetConfig,
      cloudName: assetData ? assetData.cloudName : undefined,
      cloudPublicId: assetData ? assetData.cloudPublicId : undefined,
      assetType: assetData ? assetData.type : undefined,
      type: CONST.SCENE_SET_ASSET_ELEMENT_SUCCESS
    });
  } catch (error) {
    const { message } = error;

    yield put({
      type: CONST.SCENE_SET_ASSET_ELEMENT_ERROR,
      message
    });
  }
}

/**
 * setElementValueSaga
 * called by STORY_EDITOR_SET_ELEMENT_VALUE_REQUEST action
 */
export function* setElementValueSaga(action) {
  try {
    const revertCopy = yield select(selectors.getRevertCopy);

    // the action should contain the "revertCopy to compare against"
    // 1. use passed information to update the scene
    const sceneAction = { ...action };
    sceneAction.type = CONST.STORY_EDITOR_SET_ELEMENT_VALUE;
    const newScene = yield put(sceneAction);
    // 2. need to determine if the scene matches orginal or has changed
    // could this be returned in the first put?

    const isSaved = _isEqual(revertCopy, newScene);
    // yield put({ type: CONST.STORY_EDITOR_CHECK_SCENE_CHANGE, newScene });
    // 3. tell the story if it needs saving or not
    yield put({
      type: CONST.STORY_EDITOR_SET_ELEMENT_VALUE_SUCCESS,
      storyID: action.storyID,
      isSaved
    });
  } catch (error) {
    const { message } = error;
    yield put({
      type: CONST.STORY_EDITOR_SET_ELEMENT_VALUE_FAILURE,
      message
    });
  }
}

export function* duplicateSceneSaga(action) {
  try {
    const { storyID, sceneID } = action;
    const { response, error } = yield call(duplicateScene, storyID, sceneID);

    if (response) {
      yield call(fetchStoryDataSaga, action);
      yield put({ type: CONST.SCENE_DUPLICATE_SUCCESS });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.SCENE_DUPLICATE_ERROR, message });
  }
}

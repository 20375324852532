import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants'; // fromJS,

/** 
* PROFILE REDUCER
* 
*/
const settingsEditor = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { settings } = action; // ,
      return state.merge(fromJS(settings));
    default:
      return state;
  }
};

export default settingsEditor;

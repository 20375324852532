import React from "react";
import PropTypes from "prop-types";
import SidebarNav from "./SidebarNav";
import SidebarHeader from "./SidebarHeader";
import SidebarActionBar from "./SidebarActionBar";
import SidebarButtonArea from "./SidebarButtonArea";
import "./Sidebar.scss";

/**
 * Sidebar
 *
 * @type                    Component
 * @description             Sidebar component used to contain the sidebar along the application
 *
 * @props
 *  goBackAction {string}   Path to link from the back Arrow
 *  title {mixed}           Title for the sidebar - it can be any combination of components
 *  subHeader {mixed}       Children to be injected inside the sidebarSubHeader section
 *  children {mixed}        The content of the main section of the sidebar
 */

//@TODO consider what should happen to sidebar on small devices

class Sidebar extends React.Component {
  componentDidMount() {
    this.scrollContainerTop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.scrollContainerTop();
    }
  }

  scrollContainerTop() {
    const sidebarContainer =
      document.getElementsByClassName("sidebar-container")[0];

    sidebarContainer.scrollTo(0, 0);
  }

  render() {
    //@TODO add a class IF sub header is present so height can be calculated
    return (
      //@TODO allow for passing width and loading...
      <aside className={`sidebar ${this.props.className}`}>
        <SidebarHeader
          size={this.props.headerSize}
          title={this.props.title}
          subtitle={this.props.subheader}
        >
          {this.props.goBackAction ? (
            <SidebarNav goBackAction={this.props.goBackAction} />
          ) : (
            <SidebarNav />
          )}
          {this.props.showButtons ? (
            <SidebarButtonArea
              {...this.props}
              saveLabel={
                this.props.isSaving
                  ? this.props.isSavingLabel
                  : this.props.saveLabel
              }
            />
          ) : null}
          {this.props.auxButton ? (
            <div className="auxButton">{this.props.auxButton}</div>
          ) : null}
        </SidebarHeader>
        {this.props.actionBar ? (
          <SidebarActionBar>{this.props.actionBar}</SidebarActionBar>
        ) : null}
        <div
          className={`sidebar-container ${
            this.props.scrollY ? "scroll-y" : ""
          }`}
        >
          {this.props.children}
        </div>
      </aside>
    );
  }
}

Sidebar.defaultProps = {
  goBackAction: "/",
  isSaving: false,
  showButtons: false,
  actionBar: null,
  scrollY: false,
  headerSize: "small-height"
};

Sidebar.propTypes = {
  goBackAction: PropTypes.string,
  title: PropTypes.string,
  subheader: PropTypes.string,
  className: PropTypes.string,
  handleRevert: PropTypes.func,
  handleSave: PropTypes.func,
  isSavingLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  isSaving: PropTypes.bool,
  actionBar: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  showButtons: PropTypes.bool,
  scrollY: PropTypes.bool,
  headerSize: PropTypes.string // Options: small-height, medium-height, large-height
};

export default Sidebar;

import React from "react";
import PropTypes from "prop-types";
import FlatButton from "material-ui/FlatButton";
import Logger from "utils/logger";
import _map from "lodash/map";
import {List} from "@terraincognita/ui-core";
import Draggable from "react-draggable";
import "./ActionFormModal.scss";

export class ActionFormModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isValid: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.setUpRequired = this.setUpRequired.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleInputChange(property, value) {
    Logger.debug({ property, value, data: this.state.data },'[ACTION FORM MODAL] - handleInputChange');
    let paramProperty;
    if (typeof property === 'object' && property.target) {
      paramProperty = property.target.name;
    } else {
      paramProperty = property;
    }
    let currentData = this.state.data;
    if (paramProperty) {
      currentData[paramProperty] = value;
    }
    this.setState({data: {...currentData}});
    this.checkRequiredFields();
  }

  checkRequiredFields() {
    let isValid = true;
    for(let required of this.state.required){
      if(!this.state.data[required]){
        isValid = false;
      }
    }
    this.setState({isValid: isValid});
  }

  setUpRequired() {
    let requiredArray = [];
    _map(this.props.form, (formElement) => {
      if(formElement.isRequired) {
        requiredArray.push(formElement.param);
      }
    });
    this.setState({required: requiredArray});
  }

  componentWillReceiveProps() {
    this.setUpRequired();
  }

  componentDidMount() {
    this.setUpRequired();
  }

  render() {
    const labelStyle = {
      fontSize: 13,
    };

    return (
      <div className={`scene-editor-modal-container ${this.props.open ? 'open' : ''}`}>
        <Draggable handle=".handler">
          <div className={`scene-editor-modal`}
               style={{left: this.props.leftPosition, top: this.props.topPosition}}>
            <div className="modal-relative-container">
              <div className="header-container handler">
                <h2>{this.props.title}</h2>
              </div>
              <div className="form-container">
                <List
                  styleName="plain"
                  items={this.props.form}
                  data={this.state.data}
                  handleInputChange={(property, value) => this.handleInputChange(property, value)}
                />
              </div>
              <div className="modal-actions-container">
                <FlatButton
                  onClick={() => this.handleClose()}
                  label={this.props.revertLabel}
                  default
                />
                <FlatButton
                  onClick={() => this.handleSave()}
                  label={this.props.saveLabel}
                  primary
                  disabled={!this.state.isValid}
                />
              </div>
            </div>
          </div>
        </Draggable>
        <div className="modal-overlay" onClick={this.handleCloseAction}/>
      </div>
    );
  }

  handleClose() {
    this.props.closeAction();
    this.setState({data: {}});
  }

  handleSave() {
    const data = {...this.state.data}
    this.props.handleSave(data);
    this.setState({data: {}})
  }

}
ActionFormModal.defaultProps = {
  open: false,
  leftPosition: "40%",
  topPosition: "15%",
  revertLabel: "Cancel",
  saveLabel: "Create"
};

ActionFormModal.propTypes = {
  open: PropTypes.bool,
  form: PropTypes.object,
  closeAction: PropTypes.func,
  leftPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSave: PropTypes.func,
  saveLabel: PropTypes.string,
  revertLabel: PropTypes.string
};

export default ActionFormModal;

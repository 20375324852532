import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import { withRouter } from 'react-router-dom';
import Logger from "utils/logger";
import Sidebar from '../StoryEditor/Sidebar';
import UserListBrowser from './UserListBrowser';
import UserEditor from './UserEditor';
import { accountManagerMapStateToProps } from 'mapToProps/accountManager';
import * as accountActions from 'actions/account';
import './AccountManager.scss';
//@TODO replace this will form to edit profile data

class AccountManager extends Component {

  componentDidMount() {
    this.props.requestAccountManager();
  }

  render() {
    Logger.debug({ props: this.props }, '[ACCOUNT MANAGER JSX] - render');

    const {
      sidebarProps
    } = this.props;

    return (
      <div className="account-manager">
        <Helmet>
          <Switch>
            <Route
              exact
              path={'/account'}
              render={() => <title>Account Management</title>}
            />
            <Route
              exact
              path={'/account/users'}
              render={() => <title>Users</title>}
            />
          </Switch>
          
        </Helmet>
        <Switch>
          <Route
            exact
            path={'/account'}
            render={() => <Sidebar {...sidebarProps} />}
          />
          <Route
            exact
            path={'/account/users'}
            render={() => <Sidebar {...sidebarProps} />}
          />
        </Switch>
        <Switch>
          <Route exact path={'/account/users'} component={UserListBrowser} />
          <Route exact path={'/account/users/:userID'} component={UserEditor} />
        </Switch>
        {/* <Route exact path={`${slugBase}/settings`} component={Settings} /> */}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return accountManagerMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps){

  return Object.assign({}, stateProps, {
    requestAccountManager: () => dispatchProps.requestAccountManager()
  });
}

export default withRouter(connect(mapStateToProps, accountActions, mergeProps)(AccountManager));
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import IconButton from "material-ui/IconButton";
import GoBackArrow from "material-ui/svg-icons/navigation/arrow-back";
//import FlatButton from 'material-ui/FlatButton';

/**
 * SidebarNav
 *
 * @type                    Component
 * @description             Navigation item for the sidebar
 *
 * @TODO                    an iconElementRight can be added to the right, currently commented
 * @props
 *  goBackAction {string}   Path for the Back Arrow icon to link
 */
class SidebarNav extends Component {
  //   iconElementRight={<EditAction /> } @todo Decide if we need an element on the right to inject here
  render() {
    return (
      <div className="sidebar-nav" style={{display: this.props.goBackAction ? "block" : "none"}}>
        {
          this.props.goBackAction ?
            <IconButton
              style={{paddingTop: 20}}
              disableTouchRipple={true}
              className="back-arrow"
              containerElement={<Link to={this.props.goBackAction}/>}>
              <GoBackArrow viewBox="4 0 24 24"/>
            </IconButton>
            : null
        }
      </div>
    );
  }
}

SidebarNav.defaultProps = {};

SidebarNav.propTypes = {
  goBackAction: PropTypes.string
};


export default SidebarNav;

import { Map } from 'immutable';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';

/**
 * accountMAnagerMapStateToProps
 * @param {object} state
 * @param {object} ownProps
 */
export const accountManagerMapStateToProps = (state, ownProps) => {  
  const { params, path } = ownProps.match;

  let goBackAction = '';
  let sidebarData = {};
  let sideBarSlugBase = '/account';
  let sideBarTitle;
  let navigation;
  switch(path){
    case '/account':
      sideBarTitle = 'Account Management';
      navigation = state.getIn(['base', 'accountManager', 'navigation'], Map).toJS();
      break;

    case '/account/users':
      goBackAction = '/account';
      sideBarTitle = 'Users';
      break;
  }

  return {
    sidebarProps: {
      title: sideBarTitle,
      navigation,
      sidebarData,
      slugBase: sideBarSlugBase,
      goBackAction,
    }
  };
};
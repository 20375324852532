import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants';
import _map from 'lodash/map';
// import {loadSiteData} from "storelocalStorage";

/*
 * THEME EDITOR
 */

// reducer
const userManager = (state = Map({}), action) => {
  
  if (action.message === "Error: Request failed with status code 401") {
    return state;
  }

  switch (action.type) {
    case CONST.USER_MANAGER_ADD_ERROR:
      return state.set('add_error', action.message);

    case CONST.USER_MANAGER_ADD_ERROR_CONFIRM:
      return state.set('add_error', '');

    case CONST.USER_MANAGER_DELETE_ERROR:
      return state.set('delete_error', action.message);

    case CONST.USER_MANAGER_DELETE_ERROR_CONFIRM:
      return state.set('delete_error', '');
    
    default:
      return state;
  }
};

export default userManager;

import * as CONST from 'actions/action_constants';

export const requestFeedback = () => ({
  type: CONST.FEEDBACK_REQUEST,
});

export const deleteFeedback = selected => ({
  type: CONST.FEEDBACK_DELETE_REQUEST,
  selected,
});

/**
* Account Reducer
* each site will be keyed off their ids

*/
import { Map, fromJS } from 'immutable';
import * as CONST from 'actions/action_constants';

/**
 Account Reducer
*/
// data related to the account, applies to all users
// each state has a default state that is passed
const account = (state = Map({}), action) => {
  const { data } = action;
  switch (action.type) {
    // case CONST.AUTH_CHECK_SUCCESS:
    case CONST.APP_INIT_SUCCESS:
      const { account } = data; // @NOTE there is an account that is a screen
      // .set("mainSidebar", fromJS(response.mainSidebar || {}));
      return state.merge(fromJS(account));

    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props

    default:
      return state; // if action was not passed send state back
  }
};

export default account;

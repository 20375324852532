import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as quizEditorActions from "actions/quizEditor";
import _map from "lodash/map";
import Plus from "material-ui/svg-icons/content/add";
import Answer from "./Answer";
import Divider from "material-ui/Divider";
import {Form} from "@terraincognita/ui-core";
import "./QuestionEditor.scss";


class QuestionEditor extends React.Component {

  handleSelectImage() {
    alert("Here we select Image from Image Browser");
  }

  render() {
    return (
      <div className="question-editor">
        <div className="form-container">
          <Form elements={this.props.formElements} className="main-form"/>

          <div className="main-image-container">
            Main Image
            <div className="main-image"
                 style={this.props.questionData.mainImage ?
                 {backgroundImage: 'url(' + this.props.questionData.mainImage.src + ')'} : {background: "#f5f5f5"}}
                 onClick={this.handleSelectImage}>
              <Plus className="plus-symbol" color={this.props.questionData.mainImage ? "white" : "#bbb"}/>
            </div>
          </div>
          <div style={{clear: "both"}}/>

          <Divider style={ {marginTop: 32, marginBottom: 24} }/>

          <h1>Answers</h1>

          {this.props.questionData.answers && _map(this.props.questionData.answers, item => (
            <Answer {...item} key={item._id}/>
            )
          )}
          {
            !this.props.questionData.answers ?
              _map([0, 1, 2, 3], item => (
                <Answer key={item}/>
              ))
              : null
          }
          <div style={{clear: "both"}}/>

          <Divider style={ {marginTop: 32, marginBottom: 24} }/>

          <Form elements={this.props.responseFormElements} className="main-form"/>
          <div className="main-image-container">
            Response Images
            <div className="main-image"
                 style={this.props.questionData.correctImage ? {backgroundImage: 'url(' + this.props.questionData.correctImage.src + ')'} : {background: "#f5f5f5"}}
                 onClick={this.handleSelectImage}>
              <Plus className="plus-symbol" color={this.props.questionData.correctImage ? "white" : "#bbb"}/>
            </div>
            <br/>
            <div className="main-image"
                 style={this.props.questionData.incorrectImage ? {backgroundImage: 'url(' + this.props.questionData.incorrectImage.src + ')'} : {background: "#f5f5f5"}}
                 onClick={this.handleSelectImage}>
              <Plus className="plus-symbol" color={this.props.questionData.incorrectImage ? "white" : "#bbb"}/>
            </div>
          </div>
          <div style={{clear: "both"}}/>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.defaultFirstQuestion) {
      this.props.copyQuestion(this.props.defaultFirstQuestion);
    }
  }

}

QuestionEditor.defaultProps = {};

QuestionEditor.propTypes = {
  formElements: PropTypes.object,
  responseFormElements: PropTypes.object,
  questionData: PropTypes.object,
  defaultFirstQuestion: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return ownProps;
}

// export default QuestionEditor;
export default connect(mapStateToProps, quizEditorActions)(QuestionEditor);
import { call, put, apply } from 'redux-saga/effects'; // take, fork,
import * as CONST from 'actions/action_constants';
import { authenticate, fetchAccessToken } from 'api';
import Logger from "utils/logger";
import { saveState, loadState, clearState, saveStateProp } from 'store/localStorage';

import { initializeApp } from 'actions';
import config from 'config';
import Auth from 'common/Auth';
import { triggerPasswordReset } from 'api';

/*
* helper function called from 2 different Sagas to calculate the time when 
* the access_token will expire, recieves the nuymber form the Auth0 endpoint
* @param {number} expiresIn
* NOTE: having odd experience with () so separeted the variables before adding
*/
const setExpiresAt = (expiresIn) => {
  const expTime = Number(expiresIn) * 1000; //
  const currTime = new Date().getTime();
  const expiresAt = expTime + currTime;
  return expiresAt;
};
/**
 * InitAuthSaga 
 * INIT_AUTH_CHECK
 * the saga that will detemine 
 * - if the user peviously logged in 
 * - if their refresh token is valid
 * IF user is valid then the store accees_token will be stored and the user information retrieved
otherwise the login form will be displayed
 
 * this saga is ONLY called on application loaded
 * may also be called from a timed event before access token expires
 * @param {Object} action 
*/
export function* initAuthCheck(action) {
  try {
    Logger.debug({ action },'[AUTH] initAuthCheck')
    // if the subSaga throws an error
    // if I do a put it just returns the action object NOT the result of the saga
    // it will WAIT for the saga to run but continues this one
    // yield put({ type: CONST.SAGA_AUTH_CHECK });
    const authInfo = yield call(authCheckSaga, {});
    // || !authInfo.id_token || || !authInfo.access_token
    if (!authInfo) throw new Error('Previous Session Data Not Present');

    // if for some reason the idToken wasnt in local they will need to relogin

    // now that that are authenticated
    // load the config, pass the idToken
    yield put({
      type: CONST.APP_INIT_REQUEST,
    });
  } catch (error) {
    const { message } = error;
    // may not need this if we failed the other saga...
    yield put({ type: CONST.INIT_AUTH_CHECK_FAILURE, message });
  }
}

/**
 * AuthCheckSaga
 * SAGA_AUTH_CHECK
 * this Saga will be called from other sagas before making an API request, 

 * it will need to detemine 
 * - if current access_token exists and not expired
 * - if refresh_toke xpired and 
 * can the access_token set in storage be used for API call?
 * otherwise check failure will be passed
 * 
 * @param {Object} action 
 * @return {Object}
*/
export function* authCheckSaga(action) {
  const { useSession } = action;
  // autoRenew, this determines if we are to get a new access token if none is found
  // useSession sets how we store

  try {
    const local = yield loadState();
    let newLocal; // if we need to renew we will put here
    if (!local) throw new Error('No Tokens Present');

    // did access_token already expire?
    // or not present
    if (!Auth.isAuthenticated()) {
      Logger.debug('[authCheckSaga] access expired...call renew');
      // call the renewal saga
      newLocal = yield call(renewAccessSaga, { useSession });
      if (!newLocal) throw new Error('Unable to renew access');
    }

    // I am not sure when calling this from another saga, we need to notify the store of success
    yield put({
      type: CONST.AUTH_CHECK_SUCCESS,
    });
    // how do we make sure the saga ends?
    return newLocal || local;
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.AUTH_CHECK_FAILURE, message });
    // throw new Error(message);
    return null; // should this return an error? doent seem to bubble as expected
  }
}

/**
* Login Saga
*/
export function* loginSaga(action) {
  const { creds } = action;
  const { store_login } = creds;
  // set Auth0 realm

  try {
    // throw new Error('You are Invalid');
    const response = yield call(Auth.login, creds);
    // response.type = CONST.LOGIN_SUCCESS;
    // const response = yield call(authenticate, creds) || {}; //@note will need to pass clientID
    const { idToken, refreshToken, expiresIn, accessToken } = response;
    const accessExpiresAt = setExpiresAt(expiresIn); // set when the accessToken expires
    const expireDate = new Date(accessExpiresAt)
    Logger.debug({ accessExpiresAt, expireDate, expiresIn }, '[AUTHSAGA] access will expire');
    // // add to local storage
    const useSession = !store_login;
    // TODO: check form prop to see if keep store_login in is preset
    // NOTE: we may want to always put the expries at in local store to make things easy
    // NOTE: we put the idToken from Auth0 in the local so on return we could call config if access has not expired
    // NOTE: since adding a Auth0 rule to add the email and the account_id to the access_token we dont need to keep the id_token
    const forLocal = {
      refreshToken,
      accessToken,
      accessExpiresAt,
    };

    yield call(saveState, forLocal, useSession); // is this a yeild?

    // TODO:
    // Auth.startRenewalTimer(expiresIn - 100);

    yield put({ type: CONST.LOGIN_SUCCESS });
    yield put({
      type: CONST.APP_INIT_REQUEST,
      idToken,
    });
  } catch (exp) {
    // it is possible the exception could have a response.body with a clearer description
    let errorMsg;
    const { message, response } = exp;
    errorMsg = message;
    if (response && response.body) {
      const { error_description, error } = response.body;
      errorMsg = `${error_description.charAt(0).toUpperCase() + error_description.slice(1)}`;
    }
    yield put({ type: CONST.LOGIN_FAILURE, message: errorMsg });
  }
}

/**
* Refresh Access Saga
* will be similar to the authCheck
* be called from a timed event before access token expires
* may be able reuse?? 
*/
export function* renewAccessSaga(action) {
  const { useSession } = action;
  try {
    const local = yield loadState();
    if (!local) throw new Error('No Tokens Present');
    const { refreshToken } = local;

    //
    if (!refreshToken) throw new Error('Access Expired');

    // only need a new access if expires or non existant
    const validate = yield call(fetchAccessToken, refreshToken);
    // EXIT if an error was returned... or no valid tokens
    if (!validate) throw new Error(validate);

    // NOTE: for some reason the format of the variables is different from login...
    const { id_token:idToken, expires_in, access_token:accessToken } = validate;

    //
    // NOTE: we put the idToken from Auth0 in the local so on return we could call config if access has not expired
    // just needs the new date...
    const accessExpiresAt = setExpiresAt(expires_in);
    const expireDate = new Date(accessExpiresAt)
    Logger.debug( { accessExpiresAt, expireDate, expires_in }, '[AUTH SAGA] access will expire');
    const newLocal = {
      refreshToken,
      accessToken,
      accessExpiresAt,
      idToken
    };
    // update local store -
    yield call(saveState, newLocal, useSession);
    return newLocal;
  } catch (error) {
    const { message } = error;
    yield clearState();
    yield put({ type: CONST.AUTH_RENEW_FAILURE, message });
    return null;
  }
}

export function* validateCredentialsSaga(action) {
  Logger.debug('[AUTH SAGA] - validateCredentialsSaga');
  const { email, password } = action;
  yield put({ type: CONST.VALIDATE_CREDENTIALS, email, password });
}

export function* handlePasswordResetRequestedSaga(action) {
  const { email } = action;
  yield call(triggerPasswordReset, email);
}

/**
* Logut Saga
* will be similar to the authCheck
* be called from a timed event before access token expires
* may be able reuse?? 
*/
export function* logoutSaga() {
  try {
    const response = { isAuthenticated: false, isFetching: false };

    // clear out the local storage
    yield call(clearState);
    // stop the auto renew timer...
    Auth.stopRenewalTimer();

    // remove the token in the store, and other store values
    yield put({ type: CONST.LOGOUT_SUCCESS, response });

    // do the call to Auth0 to indicate that are signed out -- causes a redirect so we do it last
    // doesnt return anything....
    // seems to get odd results so goign to leave it for now and not call
    // Auth.logout()
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.LOGOUT_FAILURE, message });
  }
}

import * as CONST from "actions/action_constants";
import Logger from "utils/logger"

export const requestQuiz = () => {
  Logger.debug('[ACTIONS LIBRARY] - requestLibrary');
  return {
    type: CONST.QUIZ_EDITOR_REQUEST
  };
};

/*
 * UPDATE ASSET
 *
 * @description this action will be ASYNC, it will do a call to the API passing the full asset data object, once it receives
 * confirmation it will fire the other actions and show notification
 * its purpose is to store theme changes in the database
 * @param questionIndex (String)
 * @param questionData (Object)
 * returns (Object) action to be dispatched
 * this one we WAIT to add to store until successful response
 */
export const updateQuestion = (questionIndex, questionData) => {
  if (!questionIndex) return; // throw an error
  return {
    type: CONST.QUIZ_QUESTION_UPDATE_REQUEST,
    isSaving: true,
    questionIndex,
    questionData
  };
};

/**
 * @description this method will dispatch the action that makes a copy of the question being edited
 * we pass on the questionData and the force flag and reducer will determine if a state change is needed...
 */
export const copyQuestion = (questionData, force = false) => {
  return {
    type: CONST.COPY_QUESTION,
    questionData,
    force
  };
};

/**
 @description this method will dispatch the action that REMOVES the copy of the question being edited
 */
export const removeQuestionCopy = () => {
  return {
    type: CONST.REMOVE_COPY_QUESTION
  };
};

export const prepareAddNewQuestion = () => {
  return {
    type: CONST.PREPARE_ADD_QUESTION
  };
};

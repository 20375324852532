import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import { CardBrowser, CardBrowserSelectedDisplay } from '@terraincognita/ui-core';
import ContentContainer from 'modules/ContentContainer';
import { itemSelectorMapStateToProps } from 'mapToProps/projectEditor';
import * as projectActions from 'actions/projects';
import './ProjectEditor.scss';

export class ItemSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
    this.backClickHandler = this.backClickHandler.bind(this);
    this.handleCloseAction = this.handleCloseAction.bind(this);
    this.functionHandler = this.functionHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.exclusiveSelectHandler = this.exclusiveSelectHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isEditMode || nextProps.isEditMode) {
      if (nextProps.activeLayout && nextProps.activeLayout !== this.state.activeLayout) {
        this.setState({ selected: this.props.activeLayout });
      }
    }
  }

  selectHandler(assetId) {
    const existingSelected = this.state.selected;
    const index = existingSelected.indexOf(assetId);

    if (index === -1) {
      existingSelected.push(assetId);
    } else {
      existingSelected.splice(index, 1);
    }
    const selectMode = existingSelected.length > 0;
    this.setState({ selected: existingSelected, selectMode });
  }

  exclusiveSelectHandler(assetId) {
    const removingOneAsset = this.state.selected === 1 && this.state.selected[0] === assetId;
    const selected = assetId && !removingOneAsset ? [assetId] : [];
    this.setState({ selected, selectMode: selected.length > 0 });
  }

  componentDidUpdate(nextProps) {
    if (!this.props.open && nextProps.open) {
      this.setState({ selected: null });
    }
  }

  backClickHandler() {
    this.setState({ selected: null });
  }

  functionHandler() {
    this.props.closeAction();
    if (typeof this.props.addItemsToProject !== 'undefined') {
      this.props.addItemsToProject(this.state.selected);
    }
  }

  handleCloseAction() {
    this.props.closeAction();
  }

  render() {
    const topBar = this.state.selected ? (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedCount={this.state.selected.length}
          deselectAllAction={this.props.closeAction}
        />
        <div className="select-button-container">
          <RaisedButton
            primary
            className="new-asset-button"
            label="SELECT"
            labelStyle={{ textTransform: 'none' }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.functionHandler();
            }}
          />
        </div>
      </div>
    ) : (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedCount={this.state.selected}
          deselectAllAction={this.props.closeAction}
        />
      </div>
    );

    return (
      <div className={`item-selector ${this.props.open ? 'open' : ''}`}>
        <ContentContainer
          isLoading={this.props.isLoading}
          className="content-container"
          selectMode
          sidebarMode="none"
          title="Select Layout"
          topBar={topBar}
        >
          {this.props.items.length > 0 && this.props.open ? (
            <CardBrowser
              selected={this.state.selected}
              minCardSize={220}
              minCardMargin={50}
              items={this.props.items}
              enableKeys={false}
              showButtons
              openModal={this.props.open}
              isModal
              selectHandler={this.selectHandler}
              selectMode={false}
              exclusiveSelectHandler={this.exclusiveSelectHandler}
              backClickHandler={() => this.exclusiveSelectHandler(null)}
            />
          ) : null}
        </ContentContainer>
      </div>
    );
  }
}
ItemSelector.defaultProps = {
  open: false,
};

ItemSelector.propTypes = {
  open: PropTypes.bool,
  projectID: PropTypes.string,
  itemType: PropTypes.string,
  closeAction: PropTypes.func,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

function mapStateToProps(state, ownProps) {
  return itemSelectorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { projectID, itemType } = ownProps;
  return Object.assign({}, stateProps, {
    closeAction: ownProps.closeAction,
    addItemsToProject: data => dispatchProps.addItemsToProject(projectID, data),
  });
}

export default connect(mapStateToProps, projectActions, mergeProps)(ItemSelector);

// import * as api from 'storeapi';
// import {loadAMCState} from 'storelocalStorage';

import * as CONST from 'actions/action_constants';

export const requestAccountManager = () =>
  ({
    type: CONST.ACCOUNT_MANAGER_REQUEST
  });

// export const requestCollectionBrowser = account =>
//   ({
//     type: CONST.COLLECTION_BROWSER_REQUEST,
//     account,
//   });

// export const requestProjectBrowser = username => ({
//   type: CONST.PROJECT_BROWSER_REQUEST,
//   username,
// });

// export const setProjectOrder = (projectID, oldIndex, newIndex) => ({
//   type: CONST.PROJECT_SET_STORY_ORDER_REQUEST,
//   projectID,
//   oldIndex,
//   newIndex,
// });

// export const publishProject = (projectID, data) => ({
//   type: CONST.PROJECT_PUBLISH_REQUEST,
//   projectID,
//   data,
// });

// export const createProjectRevertCopy = projectID => ({
//   type: CONST.PROJECT_CREATE_REVERT_COPY,
//   projectID
// });

// export const revertProjectChanges = () => ({
//   type: CONST.PROJECT_REVERT_CHANGES
// });

// export const setProjectProp = (projectID, prop, value, isLocalized, langID) => ({
//   type: CONST.PROJECT_SET_PROP,
//   projectID,
//   prop,
//   value,
//   isLocalized,
//   langID,
// });

// export const updateProject = (projectID) => ({
//   type: CONST.PROJECT_UPDATE_REQUEST,
//   projectID,
// });

// export const addItemsToProject = (projectID, storyIDs) => {
//   return {
//     type: CONST.PROJECT_ADD_STORIES_REQUEST,
//     projectID,
//     storyIDs,
//   };
// };

// export const deleteStoriesFromProject = (projectID, data) => ({
//   type: CONST.PROJECT_DELETE_STORIES_REQUEST,
//   projectID,
//   data,
// });

// export const changeLanguage = langID => ({
//   type: CONST.CHANGE_LANGUAGE,
//   langID,
// });

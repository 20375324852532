import { put, call } from 'redux-saga/effects';
import { fetchAccountUsers } from 'api';
import * as CONST from 'actions/action_constants';

export function* handleAccountManagerRequestSaga(){
  try {
    const { response, error } = yield call(fetchAccountUsers);
    if (response) {
      yield put({ type: CONST.ACCOUNT_USERS_FETCH_SUCCESS, data: response });
    } else {
      throw new Error(error);
    }

  } catch(error) {
    const { message } = error;
    yield put({ type: CONST.ACCOUNT_USERS_FETCH_ERROR, message });
  }
}
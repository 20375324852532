import { fromJS, Map } from "immutable";
import _without from "lodash/without";
import Logger from "utils/logger";
import * as CONST from "actions/action_constants";
/**
 * Stories Sub Reducer
 * holds an object of each stories data
 */

const processAddSlide = (state, slideData) => {
  // Checking if an order has been set
  //  TODO: leverage that we now have an ordered array sceneIDs
  // will a NEW scene be added to top or bottom?
  Logger.debug({ sildeId: slideData._id }, "[REDUCER][STORIES] processAdd Slide");
  // if (typeof slideData.order === 'undefined') {
  //   // checking number of items already in the collection
  //   slideData.order = Object.keys(state.getIn([slideData.storyID, 'scenes']).toJS()).length;
  // }
  // TODO: set the new order of the sceneIDs array....
  // add new id to previous array and then set in state
  return state; // .setIn([slideData.storyID, 'scenes', slideData._id], slideData);
};

/**
 * TODO: leverage that we now have an ordered array sceneIDs
 */
const slideResetOrder = (state, storyID, oldOrder, newOrder) => {
  const slidesObject = state.getIn([storyID, "scenes"]).toJS();

  // Converting to Array
  const scenesArray = [];
  Object.keys(slidesObject).forEach(key => {
    scenesArray[slidesObject[key].order] = slidesObject[key];
  });

  const array = scenesArray.slice(0);
  if (newOrder >= array.length) {
    let k = newOrder - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newOrder, 0, array.splice(oldOrder, 1)[0]);

  const newSlidesObject = {};
  array.forEach((value, index) => {
    newSlidesObject[value._id] = value;
    newSlidesObject[value._id].order = index;
  });
  return state.setIn([storyID, "scenes"], fromJS(newSlidesObject));
};

const stories = (state = Map({}), action) => {
  const {
    collectionBrowserData,
    storyBrowserData,
    storyData,
    slideData,
    selected,
    sceneData
  } = action;
  let storyID;
  switch (action.type) {
    // RECEIVING STORY DATA
    case CONST.STORY_BROWSER_SUCCESS:
      return state.merge(fromJS(storyBrowserData));
    case CONST.COLLECTION_BROWSER_SUCCESS:
      return state.merge(fromJS(collectionBrowserData));
    case CONST.STORY_DATA_SUCCESS:
      storyID = storyData._id;
      return state.merge(fromJS({ [storyID]: { ...storyData } }));

    case CONST.STORY_CREATE_SUCCESS:
      storyID = storyData._id;
      return state.merge(fromJS({ [storyID]: { ...storyData } }));

    // case CONST.STORY_UPDATE_SUCCESS:
    case CONST.STORY_DELETE_SUCCESS:
      // will want to loop through the selected array and delete each id from state
      // decided to move the loop into the saga to keep this cleaner
      storyID = action.storyID;
      return state.remove(storyID);

    case CONST.STORY_CREATE_REVERT_COPY:
      return state.set("revertCopy", state.get(action.storyID));

    case CONST.STORY_REVERT_CHANGES:
      const revertData = state.get("revertCopy").toJS();
      revertData.isSaved = true;
      const revertStoryID = revertData._id;
      return state.set(revertStoryID, fromJS(revertData));

    case CONST.STORY_EDITOR_ADD_SLIDE_SUCCESS:
      // return state.setIn([action.storyID, 'isSaved'], false);
      return processAddSlide(state, slideData);

    case CONST.STORY_SET_SCENE_ORDER:
      storyID = action.storyID;
  
      // is a merge better here?
      return state.setIn([storyID, "sceneIDs"], fromJS(storyData.sceneIDs));

    // case CONST.STORY_SLIDE_RESET_ORDER:

    //   // TODO: just change the scenes array in the specific story
    //   const data = action.data;
    //   return slideResetOrder(state, data.storyID, data.oldOrder, data.newOrder);
    case CONST.SCENE_CREATE_SUCCESS:
      let storyIDfromScene, sceneDataToSave;
      Object.keys(sceneData).forEach(element => {
        // Assumming that there's only one
        sceneDataToSave = sceneData[element];
        storyIDfromScene = sceneDataToSave.storyID;
      });
      const scenesList = state
        .getIn([storyIDfromScene, "sceneIDs"], Map([]))
        .toJS();
      scenesList.push(sceneDataToSave._id);
      return state.setIn([storyIDfromScene, "sceneIDs"], fromJS(scenesList));

    case CONST.STORY_EDITOR_SET_ELEMENT_VALUE_SUCCESS:
      // did the scene change?
      return state.setIn([action.storyID, "isSaved"], action.isSaved);
    //  return storySetElementValue(state, action);
    case CONST.STORY_SET_PROP:
      const selector = [];
      selector.push(action.storyID, action.prop);

      if (action.isLocalized) {
        selector.push(action.langID);
      }

      return state
        .setIn(selector, action.value)
        .setIn([action.storyID, "isSaved"], false);

    case CONST.STORY_SET_DESIGN_PROP:
      return state
        .setIn([action.storyID, "layoutConfig", action.prop], action.value)
        .setIn([action.storyID, "isSaved"], false);

    case CONST.STORY_SLIDE_REVERT:
      // const revertData = state.get('revertCopy');
      return (
        state
          // .setIn([action.storyID, 'scenes', action.sceneID], revertData)
          .setIn([action.storyID, "isSaved"], true)
      );
    case CONST.STORY_SLIDE_UPDATE_SUCCESS:
    case CONST.SUBSCENE_UPDATE_SUCCESS:
      return state.setIn([action.storyID, "isSaved"], true);

    case CONST.STORY_SCENE_DELETE_SUCCESS:
      const storyScenes = state.getIn([action.storyID, "sceneIDs"]).toJS(); // NOTE: could do a immutable filter?? and not convert to js
      const newScenes = _without(storyScenes, ...selected);
      return state.setIn([action.storyID, "sceneIDs"], fromJS(newScenes));

    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props

    default:
      return state;
  }
};

export default stories;

import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Logger from "utils/logger";
import {List, PopupMenu} from "@terraincognita/ui-core";
import Sidebar from "modules/Sidebar/Sidebar";
import {getLibrarySidebarProps} from "reducers/ui/library";
import * as libraryActions from "actions/library";
import Image from "material-ui/svg-icons/image/image";
import Audiotrack from "material-ui/svg-icons/image/audiotrack";
import Videocam from "material-ui/svg-icons/av/videocam";
import Download from "material-ui/svg-icons/file/file-download";
import MenuItem from "material-ui/MenuItem";
import Done from "material-ui/svg-icons/action/done";
import _debounce from "lodash/debounce";
import "./LibrarySidebar.scss";

export class LibrarySidebar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      langValue: "English"
    };
    this.handleLanguage = this.handleLanguage.bind(this);
  }

  componentDidMount() {
    // The sidebar container div scrolls up for some inexplicable
    // reason sometimes. Reset the scroll position to account for this.
    const sidebarContainer = document.getElementsByClassName("sidebar-container")[0];
    sidebarContainer.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // The sidebar container div scrolls up for some inexplicable
    // reason sometimes. Reset the scroll position to account for this.
    if (prevProps.assetId !== this.props.assetId){
      const sidebarContainer = document.getElementsByClassName("sidebar-container")[0];
      sidebarContainer.scrollTo(0, 0);
    }
  }

  getAssetEditorActionBar(type) {
    if (!type) return null;
    let icon;
    switch (type) {
      case 'image':
        icon = <Image color="white"/>;
        break;
      case 'video':
        icon = <Videocam color="white"/>;
        break;
      case 'audio':
      default:
        icon = <Audiotrack color="white"/>;
    }
    return (
      <div className="sidebar-subheader-content">
        <div className="subheader-icon">
          {icon}
        </div>
        <div className="subheader-meta">
          <div className="filename">
            {this.props.assetData.filename}
          </div>
          <div className="extra-data">
            {/*<span className="resolution-mp">8MP</span>*/}
            {/*<span className="resolution-size">3264 x 2448</span>*/}
            {/*<span className="filesize">3 MB</span>*/}
            <a href={this.props.assetDownloadURL} download style={{"float": "right", "marginTop": -20}}>
              <Download color="#424242"/>
            </a>
          </div>
        </div>
      </div>
    );
  };

  handleLanguage(value) {
    Logger.debug({ value },'[LIBRARY SIDEBAR] handing language');
    this.setState({
      langValue: value
    })
  }

  render() {
    const showButtons = !this.props.isLoadingAssetEditor && !this.props.isSaved;
    Logger.debug({ isSaved: this.props.isSaved, isLoadingAssetEditor: this.props.isLoadingAssetEditor }, '[LIBRARY SIDEBAR] - render : SHOW BUTTONS');
    const auxButton = <PopupMenu value="English" action={this.handleLanguage} mode="dark">
      <MenuItem
        style={{fontSize: 14}}
        value="English"
        key="english"
        leftIcon={this.state.langValue === 'English' ? <Done/> : <Done color="rgba(255,255,255,0)"/>}
        primaryText="English"/>
      <MenuItem
        style={{fontSize: 14}}
        value="Español"
        key="espanol"
        leftIcon={this.state.langValue === 'Español' ? <Done/> : <Done color="rgba(255,255,255,0)"/>}
        primaryText="Español"/>
      <MenuItem
        style={{fontSize: 14}}
        value="Français"
        key="francais"
        leftIcon={this.state.langValue === 'Français' ? <Done/> : <Done color="rgba(255,255,255,0)"/>}
        primaryText="Français"/>
    </PopupMenu>;

    return (
      <Sidebar
        scrollY={false}
        goBackAction={showButtons ? null : this.props.backAction} title={this.props.header}
        handleSave={this.props.updateAsset}
        confirmRevert={true}
        handleRevert={this.props.revertAsset}
        showButtons={showButtons}
        auxButton={this.props.isAssetEditor ? auxButton : null}
        isSavingLabel="Saving..."
        headerSize="small-height"
        saveLabel="Save"
        revertLabel="Cancel"
        actionBar={this.getAssetEditorActionBar(this.props.assetData ? this.props.assetData.type : null)}>
        {this.props.isAssetEditor ?
          <div className="forms-container">
            {this.props.hasData ?
              <List
                id="asset-editor-list"
                handleInputChange={this.props.handleInputChange}
                items={this.props.sidebar}
                styleName="plain"
                data={this.props.assetData}/> : null
            }
          </div>
          :
          <div className="filters-container">
            <List items={this.props.sidebar}
                  styleName="plain"
                  handleInputChange={this.props.sidebarHandleInputChange}
                  slugBase={`/${this.props.slug}`}/>
          </div>
        }
      </Sidebar>
    );
  }
}

LibrarySidebar.defaultProps = {
  isAddNewAsset: false
};

LibrarySidebar.propTypes = {
  assetDownloadURL: PropTypes.string,
  isAddNewAsset: PropTypes.bool
};


function mapStateToProps(state, ownProps) {
  return getLibrarySidebarProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {assetId, assetData, revertCopy} = stateProps;//_camelCase(ownProps.params.themeId);
  Logger.debug({ ownProps },'[LIBRARY SIDEBAR] - mergeProps', );

  const handleSearchDebounced = _debounce(function (property, value) {
    dispatchProps.setDataFromInput.apply(this, [property, value, assetId, assetData]);
  }, 1000);


  return Object.assign({}, stateProps, {
    assetDownloadURL: ownProps.assetDownloadURL,
    updateAsset: () => dispatchProps.updateAsset(assetId, assetData),
    handleInputChange: (property, value) => handleSearchDebounced(property, value),
    revertAsset: () => dispatchProps.revertAsset(assetId, revertCopy),
    sidebarHandleInputChange: (value, checked) => {
      if (checked) {
        dispatchProps.setLibraryCategoryFilter(value);
      } else {
        dispatchProps.unsetLibraryCategoryFilter(value);
      }
    },
    copyAsset: () => dispatchProps.copyAsset(assetData, true),
    saveTempAsset: () => dispatchProps.saveTempAssetCreate({data: assetData})
  })
}

export default connect(mapStateToProps, libraryActions, mergeProps)(LibrarySidebar);
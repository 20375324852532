import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Map } from "immutable";
import { withRouter } from "react-router-dom";
import { SketchPicker } from "react-color";

import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _findIndex from "lodash/findIndex";

import { List, Dialog } from "@terraincognita/ui-core";

import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton";
import LeftChevron from "material-ui/svg-icons/navigation/chevron-left";
import RightChevron from "material-ui/svg-icons/navigation/chevron-right";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import AssetSelector from "modules/Library/AssetSelector";
import * as storyCategoryActions from "actions/story-category";
import ContentContainer from "modules/ContentContainer";
import { localizeTextProps2 } from "helpers/localizationHelper";
import * as selectors from "sagas/selectors";

import styles from "./styles.scss";

const topBar = (
  isSavedEnabled,
  handleSaveRequest,
  isPrevEnabled,
  isNextEnabled,
  currentCategoryPageNumber,
  totalCategories,
  languages,
  languageID,
  handlePrevNextClicked,
  handleLanguageChange
) => (
  <div className="editor-topbar">
    <div className="category-navigation-component">
      {isPrevEnabled && (
        <IconButton
          style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
          disableTouchRipple
          className="back-arrow"
          onClick={() => handlePrevNextClicked("prev")}
        >
          <LeftChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
        </IconButton>
      )}
      <div className="category-numbers-container">
        {currentCategoryPageNumber ? currentCategoryPageNumber : null}
        &nbsp; of &nbsp;
        {totalCategories ? totalCategories : null}
      </div>
      {isNextEnabled ? (
        <IconButton
          style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
          disableTouchRipple
          className="back-arrow"
          onClick={() => handlePrevNextClicked("next")}
        >
          <RightChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
        </IconButton>
      ) : (
        <div className="empty-arrow" />
      )}
    </div>

    <RaisedButton
      primary
      disabled={!isSavedEnabled}
      className="new-asset-button"
      label="SAVE"
      labelStyle={{ textTransform: "none" }}
      onClick={e => {
        e.stopPropagation();
        handleSaveRequest();
      }}
      style={{ float: "right", marginRight: 50, marginTop: 15 }}
    />

    <SelectField
      className="language-select"
      value={languageID}
      onChange={handleLanguageChange}
    >
      {languages &&
        _map(languages, item => (
          <MenuItem key={item.id} value={item.id} primaryText={item.label} />
        ))}
    </SelectField>
  </div>
);

class CategoryEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModal: false,
      assetSelectorOpen: false
    };

    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (column, property, value) {
      this.handleInputChanges.apply(this, [column, property, value]);
    }, 500);
    this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
    this.handleAddAsset = this.handleAddAsset.bind(this);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.closeAssetSelector = this.closeAssetSelector.bind(this);
    this.handleAssetSelected = this.handleAssetSelected.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleRevertActionFromModal = this.handleRevertActionFromModal.bind(
      this
    );
    this.handlePrevNextClicked = this.handlePrevNextClicked.bind(this);
  }

  componentWillMount() {
    /*
    When the user accesses the CategoryEditor from the CategoryBrowser, 
    category data will be injected as a prop. In that scenario, notify 
    the application that the editor has received initial data. 
    
    It is necessary to check for whether or not categoryData._id is defined
    because when this life cycle method is called on a deep link the category
    data has not yet been retrieved from the database, and is therefore 
    unavailable to this component. So only notify the application in this 
    life cycle method that the editor has received initial data if it's 
    actually present at this point!
    */
    if (this.props.categoryData._id) {
      this.props.handleEditorDataInitialized();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /*
    On a deep link, this life cycle method notifies the application that the 
    editor has received initial data. Once the category data has been written
    to Redux state on a deep link, this component's props are updated. In that
    scenario, the first time this method is executed, the previous 
    categoryData._id property will be undefined and the current categoryData._id 
    property will have a value. Take that opportunity to notify the application
    that the editor has received initial data.
    */
    if (
      prevProps.categoryData._id === undefined &&
      this.props.categoryData._id !== undefined
    ) {
      this.props.handleEditorDataInitialized();
    }
  }

  render() {
    // Map State Props
    const {
      // TODO: Figure out how isLoading works.
      // isLoading,
      pageTitle,
      languages,
      languageID,
      storyID,
      categoryID,
      forms,
      categoryData,
      prevCategoryID,
      nextCategoryID,
      currentCategoryPageNumber,
      totalCategories,
      error,
      sidebarMode
    } = this.props;

    // Map Dispatch Props
    const { handleSaveRequest, handleSaveErrorConfirm } = this.props;

    let headerTitle;
    if (categoryData) {
      headerTitle = categoryData.name;
    } else {
      headerTitle = "Loading...";
    }

    // If the user has set the category name to an empty string, disable the
    // "Save" button to prevent him from saving the changes.
    const isSavedEnabled =
      categoryData.name === "" ? false : !categoryData.isSaved;
    const isPrevEnabled = prevCategoryID ? true : false;
    const isNextEnabled = nextCategoryID ? true : false;

    return (
      <div className="category-editor">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <AssetSelector
          open={this.state.assetSelectorOpen}
          minWidthCaption={parseInt(styles.minWidthCaption, 10)}
          minCardMargin={parseInt(styles.minCardMargin, 10)}
          captionHeight={parseInt(styles.captionHeight, 10)}
          isModal
          standardBarButtonAction={this.handleAssetSelected}
          title="Select Asset"
          hideStandardButtonIfNonSelected
          allowsMultiple={false}
          standardBarButtonLabel="SELECT"
          sidebarMode={this.props.sidebarMode}
          isAssetSelectorMode
          closeModalAction={this.closeAssetSelector}
        />
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          // isLoading={isLoading}
          className="category-editor-content-container"
          sidebarMode={sidebarMode}
          closeAction={`/stories/${storyID}/categories/`}
          handleCloseClicked={this.handleCloseClicked}
          // Research the purpose of this prop. There's no isSaved prop
          // defined in the UserEditor, on which this editor is based.
          // Should this be categoryData.isSaved?
          // confirmNavigation={isSaved}
          headerTheme="light"
          title={headerTitle}
          headerMode="extended-extra"
          topBar={topBar(
            isSavedEnabled,
            handleSaveRequest,
            isPrevEnabled,
            isNextEnabled,
            currentCategoryPageNumber,
            totalCategories,
            languages,
            languageID,
            this.handlePrevNextClicked,
            this.handleLanguageChange
          )}
        >
          <div className="editor-container">
            <div className="tab-form-container">
              <div className="tab-column column-1 column-right-border">
                {forms.col1 ? (
                  <List
                    styleName="plain"
                    data={categoryData ? categoryData : null}
                    items={forms.col1}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange(1, param, value)
                    }
                  />
                ) : null}
              </div>
              <div className="tab-column column-2 column-right-border">
                <div className="cms-list">
                  {/* <div className="label-container">
                    <span>Color *</span>
                  </div> */}
                  <div className="list-group-header">
                    <h2>Color</h2>
                  </div>
                  <SketchPicker
                    color={categoryData.color}
                    width={300}
                    onChangeComplete={this.handleColorPickerChange}
                  />
                </div>
              </div>
              <div className="tab-column column-3">
                {forms.col3 ? (
                  <List
                    styleName="plain"
                    handleAddClick={this.handleAddAsset}
                    handleDeleteClick={this.handleDeleteAsset}
                    data={categoryData ? categoryData : null}
                    items={forms.col3}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </ContentContainer>
        <Dialog
          content={
            'If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          }
          handleConfirm={this.handleRevertActionFromModal}
          confirmLabel="Confirm"
          bodyStyle={{ paddingTop: 20 }}
          handleCancel={this.closeDisplayRevertCancel}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
        <Dialog
          content={`An error occurred updating the user. ${error}`}
          confirmLabel="OK"
          handleConfirm={handleSaveErrorConfirm}
          handleCancel={handleSaveErrorConfirm}
          //cancelLabel="Cancel"
          bodyStyle={{ paddingTop: 20 }}
          modal={false}
          open={error === false ? false : true}
          title={"Error Saving Changes"}
        />
      </div>
    );
  }

  handleInputChanges(column, property, value) {
    if (this.props.categoryData[property] !== value) {
      this.props.handleInputChange(column, property, value);
    }
  }

  handleColorPickerChange(value) {
    // this.props.handleInputChange(2, "color", value.hex.toUpperCase());
    this.props.handleInputChange(2, "color", value.rgb);
  }

  handleDeleteAsset() {
    const assetData = {
      assetID: null
    };
    this.props.handleInputChange(3, "asset", assetData);
  }

  handleAddAsset() {
    this.setState({ assetSelectorOpen: true });
  }

  closeAssetSelector() {
    this.setState({ assetSelectorOpen: false });
  }

  /**
   * The user has selected an asset to assign to the story. Add the
   * asset data to the local state form data to trigger display of the
   * asset in the editor. Then trigger addition of the asset to the
   * story in the store.
   *
   * @param {Array} assetID - Array of selected assetIDs.
   * @param {Array} asset - Array of asset documents that contain all
   *                        data from the Library.
   */
  handleAssetSelected(assetID, asset) {
    this.closeAssetSelector();

    const assetData = {
      assetID: assetID[0],
      use: "asset",
      type: "asset",
      isCloudinary: true,
      cloudName: asset[0].cloudName,
      cloudPublicId: asset[0].cloudPublicId,
      assetConfig: asset[0].config,
      height: asset[0].height,
      width: asset[0].width,
      assetType: asset[0].type
    };

    this.props.handleInputChange(3, "asset", assetData);
  }

  handleCloseClicked() {
    if (!this.props.categoryData.isSaved) {
      this.openDisplayRevertCancel();
    } else {
      this.props.handleClose();
    }
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  handleRevertActionFromModal() {
    this.closeDisplayRevertCancel();
    this.props.handleRevertChanges();
    this.props.handleClose();
  }

  handleLanguageChange(event, index, value) {
    this.props.changeLanguage(value);
  }

  handlePrevNextClicked(direction) {
    const newCategoryID =
      direction === "prev"
        ? this.props.prevCategoryID
        : this.props.nextCategoryID;
    this.props.advanceCategory(newCategoryID);
  }
}

CategoryEditor.defaultProps = {};

CategoryEditor.propTypes = {};

function mapStateToProps(state, ownProps) {
  const { storyID, categoryID } = ownProps.match.params;

  // Need story and story type param (human-readable) to define page title.
  // The story object has the type ID, and storyTypes are keyed off human-readable
  // story type name.
  const story = state.getIn(["data", "stories", storyID]);
  const storyTypeParam = story
    ? selectors.getStoryTypeParam(state, story.get("type"))
    : null;
  const storyType = storyTypeParam
    ? state.getIn(["base", "storyTypes", storyTypeParam])
    : null;
  const pageTitle = storyType
    ? `${storyType.get("title")} Category Editor`
    : null;

  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const languageID = state.getIn(["base", "config", "langID"]);
  const forms = state
    .getIn(["ui", "storyEditor", "forms", "category"], Map())
    .toJS();

  // Calculate prev/next category for top nav.
  const storyCategories = state
    .getIn(["data", "storyCategories"], Map())
    .toJS();
  delete storyCategories.revertCopy;
  const categories = _map(storyCategories, storyCategory => storyCategory);

  let totalCategories = 0;
  let prevCategoryID = null;
  let nextCategoryID = null;
  let currentCategoryPageNumber = 1;
  if (categories) {
    totalCategories = categories.length;
    _forEach(categories, (category, index) => {
      if (category._id === categoryID) {
        currentCategoryPageNumber = index + 1;
        if (index > 0) {
          prevCategoryID = categories[index - 1]._id;
        }
        if (index < categories.length - 1) {
          nextCategoryID = categories[index + 1]._id;
        }
      }
    });
  }

  // Only input fields in column 1 are potentially localized.
  const categoryData = localizeTextProps2(
    languageID,
    forms.col1,
    state.getIn(["data", "storyCategories", categoryID], Map()).toJS()
  );
  const errorMessage = state.getIn(["ui", "categoryEditor", "error"]);
  const error = _isEmpty(errorMessage) ? false : errorMessage;

  // Initialize isSaved for this category's data. This
  // property does not exist in the database.
  if (categoryData._id !== undefined) {
    categoryData.isSaved =
      categoryData.isSaved === undefined ? true : categoryData.isSaved;
  }

  return {
    pageTitle,
    languages,
    languageID,
    storyID,
    categoryID,
    forms,
    categoryData,
    prevCategoryID,
    nextCategoryID,
    currentCategoryPageNumber,
    totalCategories,
    error
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { storyID, categoryID } = ownProps.match.params;
  const { forms, languageID } = stateProps;
  return Object.assign({}, stateProps, {
    handleEditorDataInitialized: () => {
      dispatchProps.handleEditorDataInitialized(categoryID);
    },

    handleInputChange: (column, property, value) => {
      const formPropertyConfig = forms[`col${column}`][property];
      const inputLanguageID = formPropertyConfig.isLocalized
        ? languageID
        : null;
      dispatchProps.handleInputChange(
        categoryID,
        property,
        formPropertyConfig.type,
        value,
        inputLanguageID
      );
    },

    handleSaveRequest: () => {
      dispatchProps.handleSaveRequest(categoryID);
    },

    handleSaveErrorConfirm: () => {
      dispatchProps.handleSaveErrorConfirm();
    },

    handleRevertChanges: () => {
      dispatchProps.handleRevertChanges();
    },

    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },

    advanceCategory: newCategoryID => {
      ownProps.history.push(`/stories/${storyID}/categories/${newCategoryID}`);
    },

    handleClose: () => {
      ownProps.history.push(`/stories/${storyID}/categories/`);
    }
  });
}

export default withRouter(
  connect(mapStateToProps, storyCategoryActions, mergeProps)(CategoryEditor)
);

import { put, call } from 'redux-saga/effects';
import { fetchFeedback, deleteFeedback } from 'api';
import { loadState } from 'store/localStorage';
import * as CONST from 'actions/action_constants';
import { deLocalizeTextProps } from 'helpers/localizationHelper';
//----
// SELECTORS
// used in the saga

// PROJECT BROWSER SAGA WORKERS
/**
 * fetchProjectBrowserSaga
 */
export function* fetchFeedbackSaga(action) {
  try {
    const { response, error } = yield call(fetchFeedback);

    if (response) {
      yield put({ type: CONST.FEEDBACK_SUCCESS, feedbackData: response });
    } else {
      throw new Error(error);
    }

  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.FEEDBACK_ERROR, message });
  }
}

export function* deleteFeedbackSaga(action) {
  try {
    const { selected } = action;
    const { response, error } = yield call(deleteFeedback, selected);

    if (response) {
      yield selected.map(feedbackID => put({ type: CONST.FEEDBACK_DELETE_SUCCESS, feedbackID }));
    } else {
      throw new Error(error);
    }

  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.FEEDBACK_DELETE_ERROR, message });
  }
}

import { put, call, select } from "redux-saga/effects";
import {
  fetchStoryCategories,
  createStoryCategory,
  updateStoryCategory,
  deleteStoryCategory
} from "api";
import * as selectors from "./selectors";
import * as CONST from "actions/action_constants";

export function* fetchStoryCategoriesSaga(action) {
  try {
    const { response, error } = yield call(
      fetchStoryCategories,
      action.storyID
    );
    if (response) {
      yield put({
        type: CONST.STORY_CATEGORIES_REQUEST_SUCCESS,
        categories: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_CATEGORIES_REQUEST_ERROR, message });
  }
}

export function* createStoryCategorySaga(action) {
  try {
    const { response, error } = yield call(createStoryCategory, action.storyID);
    if (response) {
      yield put({
        type: CONST.STORY_CATEGORY_CREATE_SUCCESS,
        categoryData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_CATEGORY_CREATE_ERROR, message });
  }
}

export function* deleteStoryCategoriesSaga(action) {
  const { categoryIDs } = action;
  try {
    for (let i = 0; i < categoryIDs.length; i++) {
      const categoryID = categoryIDs[i];
      const { response, error } = yield call(deleteStoryCategory, categoryID);
      if (response) {
        yield put({ type: CONST.STORY_CATEGORY_DELETE_SUCCESS, categoryID });
      } else {
        throw new Error(error);
      }
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_CATEGORIES_DELETE_ERROR, message });
  }
}

export function* updateStoryCategorySaga(action) {
  const { categoryID } = action;
  try {
    const categoryData = yield select(selectors.getStoryCategory, categoryID);
    delete categoryData._id;
    delete categoryData.isSaved;

    const { response, error } = yield call(
      updateStoryCategory,
      categoryID,
      categoryData
    );
    if (response) {
      yield put({
        type: CONST.STORY_CATEGORY_EDITOR_SAVE_SUCCESS,
        categoryID
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.STORY_CATEGORY_EDITOR_SAVE_ERROR, message });
  }
}

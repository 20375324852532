import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import config from "config";
import { authenticateUser, changeLanguage, passwordResetRequested } from "actions";
import {getAuthProps} from "shared/reducers/auth";
import {withRouter, Redirect} from "react-router-dom";
import {Image} from "cloudinary-react";
import {Form} from "@terraincognita/ui-core";
import "./Login.scss";

class Login extends Component {
  constructor(props) {
    super(props)
    const defaultEmail = props.defaultEmail || ''; //just set it blank because was annoying for testing :)
    this.elements = {
      email: {
        label: "E-mail",
        required: true,
        param: "email",
        type: "email",
        defaultValue: defaultEmail,
        placeholder: 'Your e-mail address here'
      },
      password: {
        label: "Password",
        param: "password",
        required: true,
        type: "password",
      },
      // store_login: {
      //  label: "Keep me signed in",
      //  type: "toggle",
      //  param: "store_login"
      // },
      login_button: {
        label: 'Sign In',
        param: 'login',
        type: 'submit'
      }
    };

    this.resetPasswordFormElements = {
      passwordResetEmail: {
        label: "E-mail",
        required: true,
        param: "passwordResetEmail",
        type: "email",
      },
      buttonBank: {
        type: "buttonBank",
        buttons: [
          {
            label: "Cancel",
            param: "cancelButton",
            type: "button",
            onClick: this.handleCancelClick
          },
          {
            label: "Send Link",
            param: "sendButton",
            type: "button",
            onClick: this.handleSendEmailClick
          }
        ]
      }
    }

    this.formStates = {
      DEFAULT: "DEFAULT",
      PASSWORD_RESET: "PASSWORD_RESET",
      PASSWORD_RESET_EMAIL_SENT: "PASSWORD_RESET_EMAIL_SENT"
    }

    this.state = {
      email: defaultEmail,
      password: null,
      store_login: false,
      passwordResetEmail: '',
      formState: this.formStates.DEFAULT
    };

    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handlePasswordResetClick = this.handlePasswordResetClick.bind(this);
    //this.handleSendEmailClick = this.handleSendEmailClick.bind(this);
    //this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleButtonBankButton1Click = this.handleButtonBankButton1Click.bind(this);
    this.handleButtonBankButton2Click = this.handleButtonBankButton2Click.bind(this);
  }

  validateEmail(email) {
    var pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return pattern.test(email);
  }

  handleLoginClick(event) {
    event.preventDefault();
    const {dispatch} = this.props;

    if (!this.state.email) {
      this.setState({error: 'E-mail cannot be empty'});
      return;
    }

    if (!this.state.password) {
      this.setState({error: 'Password can not be empty'});
      return;
    }

    this.setState({error: null});

    if (this.validateEmail(this.state.email)) {
      const creds = {
        password: this.state.password,
        store_login: this.state.store_login,
        username: this.state.email
      };
      dispatch(changeLanguage("en"));
      dispatch(authenticateUser(creds));
    } else {
      this.setState({error: 'Please enter a valid e-mail address'});
    }
  }

  handlePasswordResetClick(e) {
    e.preventDefault();
    this.setState({ error: null, formState: this.formStates.PASSWORD_RESET });
  }

  //handleSendEmailClick(e){
  handleButtonBankButton2Click(e){
    //e.preventDefault();
    const {dispatch} = this.props;

    if (!this.state.passwordResetEmail){
      this.setState({error: 'E-mail cannot be empty'})
      return;
    }

    this.setState({ error: null });

    if (this.validateEmail(this.state.passwordResetEmail)) {
      const creds = {
        password: this.state.password,
        store_login: this.state.store_login,
        username: this.state.email
      };
      this.setState({ formState: this.formStates.PASSWORD_RESET_EMAIL_SENT });
      dispatch(passwordResetRequested(this.state.passwordResetEmail));
    } else {
      this.setState({ error: 'Please enter a valid e-mail address' });
    }
  }

  handleButtonBankButton1Click(e) {
    this.setState({ error: null, formState: this.formStates.DEFAULT });
  }

  handleFormChange(input, value) {
    this.setState({ [input]: value});
  }

  render() {
    const error = this.state.error || this.props.error;
    let {from} = this.props.location.state || {from: {pathname: '/library'}};
    // dont want to get into a logout loop
    if (from.pathname === '/logout') {
      from = {from: {pathname: '/'}}
    }
    if (!this.props.isAuthenticated) {
      return (
        <div className='login'>
          <div className="header">
            <div className="header-content">
              <div className="logo-container">
                <a href="https://storycrafter.co">
                  <Image cloudName={config('cloudName')} publicId={'system/storycrafter-logo-white.png'} />
                </a>
              </div>
            </div>
          </div>
          <div className='form-container'>
            <div className='outer'>
              {this.state.formState === this.formStates.PASSWORD_RESET_EMAIL_SENT
                ? <div className='password-reset-email-sent'><p>We've sent a password reset link to {this.state.passwordResetEmail}</p><p className="forgot-password-need-more-help-link"><a href="https://storycrafter.zendesk.com/hc/en-us/articles/360024939533" target="_blank">Need more help?</a></p></div>
                : null}
              <div className='inner'>
                <div className="header-container">
                  {/* <div>{config('loginScreen.serviceTitle')}</div>
                  <div>{config('loginScreen.welcome')}</div> */}
                  {this.state.formState === this.formStates.DEFAULT || this.state.formState === this.formStates.PASSWORD_RESET_EMAIL_SENT ? <h1>Account Sign In</h1> : <h1>Forgot Your Password?</h1>}
                </div>
                {this.state.formState === this.formStates.PASSWORD_RESET ? 
                <div className="password-reset-instructions"><p>Enter the email address you use to sign in, and we'll 
                  send you a link to reset your password.</p></div> : null}
                <Form
                  elements={this.state.formState === this.formStates.DEFAULT || this.state.formState === this.formStates.PASSWORD_RESET_EMAIL_SENT ? this.elements : this.resetPasswordFormElements}
                  handleChange={(input, value) => {
                    this.handleFormChange(input, value)
                  }}
                  handleSubmit={this.handleLoginClick}
                  handleButtonBankButton1Click={this.handleButtonBankButton1Click}
                  handleButtonBankButton2Click={this.handleButtonBankButton2Click}
                />
                  
                {error ? <div className='error'>{error}</div> : null}
              </div>
              {this.state.formState === this.formStates.DEFAULT || this.state.formState === this.formStates.PASSWORD_RESET_EMAIL_SENT
                ? <div className='forgot-password-link'><a href='#' onClick={this.handlePasswordResetClick}>Forgot your password?</a></div>
                : null}
            </div>
          </div>
        </div>
      );
    } else if (!this.props.isFetching && this.props.isAuthenticated) {
      return (
        <Redirect to={from}/>
      )
    }
  }
}

Login.contextTypes = {
  router: PropTypes.object
};

//NOTE: this is connected because it is outside the props 
const mapStateToProps = (state, ownProps) => {
  return getAuthProps(state, ownProps)
}

export default withRouter(connect(mapStateToProps)(Login));

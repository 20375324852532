import React, { Component } from "react";
import PropTypes from "prop-types";
import injectTapEventPlugin from "react-tap-event-plugin";
import Switch from "react-router-dom/Switch";
import Route from "react-router-dom/Route";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import CircularProgress from "material-ui/CircularProgress";
import config from "config";
import * as baseActions from "shared/actions";
import { getAppProps } from "shared/reducers";
import { connect } from "react-redux";
import styles from "./App.scss";
import Layout from "modules/Layout";
import Login from "modules/Login";
import "./App.scss";

const muiTheme = getMuiTheme({
  userAgent: "all", //@note: Matching the server data 'all' - from the client it gets Browser information but it doesnt match the server data.
  palette: {
    primary1Color: styles.accent2,
    primary2Color: styles.accent2,
    primary3Color: "rgb(225, 225, 225)",
    accent1Color: styles.accent2,
    accent2Color: "rgb(245, 245, 245)",
    accent3Color: styles.accent3
  }
});

class App extends Component {
  // used to target items in the head
  buildStaticPath(path) {
    const { staticFileUrl } = this.props;
    if (staticFileUrl) {
      return `${staticFileUrl}${path}`;
    }
    return `${path}`;
  }
  render() {
    const fontFamily = "Material+Icons";
    const fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`; // TODO: see about using import statemnt for these

    const bartsExternalCSSFile = process.env.NODE_ENV === "development" ? 'cms-external.css' : 'cms-external.css'; // TODO: FOR now this is the same, need to port css from prod to dev
    const bartsExternalCSSUrl = `https://storycrafter.co/${bartsExternalCSSFile}?noCache=${Date.now()}`
    
    // the loader should appear:
    // -  while we do a auth check (isAuthChecking)
    // - while we log them in (isAuthFetching)
    // - while we are fetching the init config --> isInit may need to move that into layout
    return (
      <div className="inner-container">
        <Helmet>
          <html lang="en" />
          <title>{config("htmlPage.defaultTitle")}</title>

          <meta
            name="application-name"
            content={config("htmlPage.defaultTitle")}
          />
          <meta name="description" content={config("htmlPage.description")} />
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="msapplication-TileColor" content="#2b2b2b" />
          <meta
            name="msapplication-TileImage"
            content={this.buildStaticPath("/favicons/mstile-144x144.png")}
          />
          <meta name="theme-color" content="#2b2b2b" />
          {/*
         A great reference for favicons:
         https://github.com/audreyr/favicon-cheat-sheet
         It's a pain to manage/generate them. I run both these in order,
         and combine their results:
         http://realfavicongenerator.net/
         http://www.favicomatic.com/
         */}
          <link
            rel="apple-touch-icon-precomposed"
            sizes="152x152"
            href={this.buildStaticPath(
              "/favicons/apple-touch-icon-152x152.png"
            )}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href={this.buildStaticPath(
              "/favicons/apple-touch-icon-144x144.png"
            )}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="120x120"
            href={this.buildStaticPath(
              "/favicons/apple-touch-icon-120x120.png"
            )}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href={this.buildStaticPath(
              "/favicons/apple-touch-icon-114x114.png"
            )}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="76x76"
            href={this.buildStaticPath("/favicons/apple-touch-icon-76x76.png")}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href={this.buildStaticPath("/favicons/apple-touch-icon-72x72.png")}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="57x57"
            href={this.buildStaticPath("/favicons/apple-touch-icon-57x57.png")}
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="60x60"
            href={this.buildStaticPath("/favicons/apple-touch-icon-60x60.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={this.buildStaticPath(
              "/favicons/apple-touch-icon-180x180.png"
            )}
          />
          <link
            rel="mask-icon"
            href={this.buildStaticPath("/favicons/safari-pinned-tab.svg")}
            color="#00a9d9"
          />
          <link
            rel="icon"
            type="image/png"
            href={this.buildStaticPath("/favicons/favicon-196x196.png")}
            sizes="196x196"
          />
          <link
            rel="icon"
            type="image/png"
            href={this.buildStaticPath("/favicons/favicon-128.png")}
            sizes="128x128"
          />
          <link
            rel="icon"
            type="image/png"
            href={this.buildStaticPath("/favicons/favicon-96x96.png")}
            sizes="96x96"
          />
          <link
            rel="icon"
            type="image/png"
            href={this.buildStaticPath("/favicons/favicon-32x32.png")}
            sizes="32x32"
          />
          <link
            rel="icon"
            sizes="16x16 32x32"
            href={this.buildStaticPath("/favicon.ico")}
          />
          <meta name="msapplication-TileColor" content="#2b2b2b" />
          <meta
            name="msapplication-TileImage"
            content={this.buildStaticPath("/favicons/mstile-144x144.png")}
          />
          <meta
            name="msapplication-square70x70logo"
            content={this.buildStaticPath("/favicons/mstile-70x70.png")}
          />
          <meta
            name="msapplication-square150x150logo"
            content={this.buildStaticPath("/favicons/mstile-150x150.png")}
          />
          <meta
            name="msapplication-wide310x150logo"
            content={this.buildStaticPath("/favicons/mstile-310x150.png")}
          />
          <meta
            name="msapplication-square310x310logo"
            content={this.buildStaticPath("/favicons/mstile-310x310.png")}
          />
          <link rel="manifest" href={this.buildStaticPath("/manifest.json")} />

          <link rel="stylesheet" href={fontUrl} />
          <link rel="stylesheet" href={bartsExternalCSSUrl} />
          
          <script
            src="//widget.cloudinary.com/v2.0/global/all.js"
            type="text/javascript"
          />
        </Helmet>

        <MuiThemeProvider muiTheme={muiTheme}>
          {!this.props.isAuthChecking &&
          !this.props.isAuthFetching &&
          !this.props.isFetching ? (
            <Switch>
              <Route path="/login" component={Login} />
              <Route
                {...this.props}
                render={props => {
                  return this.props.isAuthenticated ? (
                    <Layout {...this.props} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location }
                      }}
                    />
                  );
                }}
              />
            </Switch>
          ) : (
            <div className="row progress-container">
              <CircularProgress
                className="progress-indicator"
                size={80}
                thickness={10}
              />
            </div>
          )}
        </MuiThemeProvider>
      </div>
    );
  }

}

App.contextTypes = {
  router: PropTypes.object
};

injectTapEventPlugin();

const mapStateToProps = (state, ownProps) => {
  return getAppProps(state, ownProps);
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { queId } = stateProps;
  return Object.assign({}, ownProps, stateProps, {
    queueItemShown: () => dispatchProps.queueItemShown(queId),
    toggleSidebar: () => dispatchProps.toggleSidebar()
  });
}

export default withRouter(
  connect(mapStateToProps, baseActions, mergeProps)(App)
);

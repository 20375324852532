import { fromJS, Map } from "immutable";
import * as CONST from "actions/action_constants";
import Logger from "utils/logger";
import _merge from "lodash/merge";
import _map from "lodash/map";

/**
 * Library Reducer
 * @param {Object} state - instance of immutable Map
 * @param {Object} action
 *
 */
const library = (state = Map({}), action) => {

  const { message, filter } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      let { assets } = action;
      // Checking if we have a search parameter already in the URL
      // @TODO: Find a better way to merge the default state without getting rid of URL params
      const urlSearch = state.getIn(["cardBrowserConfig", "keywordSearch"]);
      // let { assets } = response || {};
      if (!assets || !assets.keywordSearch) {
        assets = _merge(assets, { keywordSearch: urlSearch });
      }
      // library.keywordSearch = library && library.keywordSearch
      //   ? library.keywordSearch
      //   : urlSearch;
      return state.merge(fromJS(assets));
    case CONST.SET_LIBRARY_SORT_FILTER:
      return setLibrarySortByFilter(state, action);
    case CONST.SET_LIBRARY_CAT_FILTER:
      return setLibraryCategoryFilter(state, action.filter);
    case CONST.UNSET_LIBRARY_CAT_FILTER:
      return unsetLibraryCategoryFilter(state, action.filter);
    case CONST.SET_LIBRARY_LOADING:
      return setLibraryIsLoading(state, action.value);
    case CONST.SET_LIBRARY_ASSET_LOADING:
      return state.set("isAssetLoading", action.value);
    case CONST.SET_LIBRARY_SEARCH:
      return setLibrarySearch(state, action.search);
    case CONST.SET_LIBRARY_SLIDER_SIZE_MULTIPLIER:
      return state.setIn(
        ["cardBrowserConfig", "cardSizeMultiplier"],
        action.value
      );
    case CONST.LIBRARY_FETCH_REQUEST:
      return state.set("isLoading", true);
    case CONST.LIBRARY_FETCH_SUCCESS:
      return state.set("isLoading", false).delete("error");
    case CONST.LIBRARY_ADD_PAGE:
      const columns = action.columns;
      return addLibraryPage(state, columns);
    case CONST.LIBRARY_SET_SCROLL:
      return state.setIn(["cardBrowserConfig", "scrollTop"], action.scrollTop);
    case CONST.LIBRARY_SELECT_TOGGLE:
      return selectToggle(state, action.assetId);
    case CONST.LIBRARY_EXCLUSIVE_SELECT_TOGGLE:
      return exclusiveSelectToggle(state, action.assetId);
    case CONST.LIBRARY_SHIFT_SELECT_TOGGLE:
      return shiftSelectToggle(state, action.assetId, action.items);
    case CONST.LIBRARY_ASSET_UPDATE_REQUEST:
      return state.set("isLoadingAssetEditor", true);
    case CONST.LIBRARY_ASSET_UPDATE_SUCCESS:
      return state.set("isLoadingAssetEditor", false);
    case CONST.LIBRARY_SET_TOTAL_COUNT:
      return state.setIn(
        ["cardBrowserConfig", "totalCount"],
        action.totalCount
      );
    case CONST.REVERT_ASSET:
      return state.set("isSaved", true);
    case CONST.LIBRARY_DELETE_MULTIPLE_REQUEST:
      return state.set("isLoading", true);
    case CONST.LIBRARY_DELETE_MULTIPLE_SUCCESS:
      return state
        .setIn(["cardBrowserConfig", "selected"], fromJS([]))
        .setIn(["cardBrowserConfig", "lastSelected"], null)
        .setIn(["cardBrowserConfig", "selectMode"], false)
        .set("isLoading", false);
    case CONST.LIBRARY_DELETE_MULTIPLE_ERROR:
      return state
        .setIn(["cardBrowserConfig", "selected"], fromJS([]))
        .setIn(["cardBrowserConfig", "lastSelected"], null)
        .setIn(["cardBrowserConfig", "selectMode"], false)
        .set("error", action.message)
        .set("isLoading", false);
    case CONST.LIBRARY_CARD_BROWSER_RESET:
      return (
        state
          .setIn(["cardBrowserConfig", "selected"], fromJS([]))
          .setIn(["cardBrowserConfig", "lastSelected"], null)
          .setIn(["cardBrowserConfig", "selectMode"], false)
          // .setIn(["cardBrowserConfig", "cardSizeMultiplier"], 210)
          .setIn(["cardBrowserConfig", "keywordSearch"], "")
          .setIn(["cardBrowserConfig", "scrollTop"], false)
          // .setIn(["cardBrowserConfig", "sortByType"], "title")
          .setIn(["cardBrowserConfig", "pageSize"], 15)
          .setIn(["cardBrowserConfig", "activePage"], 1)
          // .setIn(["cardBrowserConfig", "sortByOrder"], "asc")
          .set("isLoading", false)
      );

    // case LOCATION_CHANGE:
    //     return checkLocationParams(state, action.payload);
    case CONST.LIBRARY_SET_BROWSING_MODE:
      return state.setIn(["cardBrowserConfig", "browsingMode"], action.mode);
    case CONST.LIBRARY_FETCH_ERROR:
      return state.set("isLoading", false).set("error", message);
    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

/**
 * locationChange -- called from the base reducer
 * @description Detects changes in the URL to trigger different actions
 *
 * @param state {Map} The Application Tree State
 * @param payload {Object} URL Path data
 * @returns {*}
 */
// function locationChange(state, payload) {
//
//     let activePath = payload.pathname;
//     // Removing leading slash if existent
//     if (activePath.substring(0, 1) === '/') {
//         activePath = activePath.substring(1, activePath.length);
//     }
//     const paths = activePath.split('/');
//
//     // Defining necessary changes in state dependent on URL
//     if (paths[0] === 'library') {
//         let categoryFilter = typeof paths[1] === 'undefined' ? null : paths[1];
//         return setLibraryCategoryFilter(state, categoryFilter);
//     }
//
//     return state;
// }

/**
 * setLibraryCategoryFilter
 *
 * @description Changes the state setting library category filter
 *
 * @param state {Immutable} Application Tree State
 * @param filter {String} Category filter for the library
 */
export function setLibraryCategoryFilter(state, filter) {

  if (typeof state !== "undefined" && filter) {
    const existingFilters =
      typeof state.get("filterCategory") !== "undefined"
        ? state.get("filterCategory").toJS() || []
        : [];
    const index = existingFilters.indexOf(filter);
    if (index === -1) {
      existingFilters.push(filter);
    }
    return state.set("filterCategory", fromJS(existingFilters));
  }
  return state;
}

/**
 * setLibraryCategoryFilter
 *
 * @description Changes the state setting library category filter
 *
 * @param state {Immutable} Application Tree State
 * @param filter {String} Category filter for the library
 */
export function unsetLibraryCategoryFilter(state, filter) {
  if (typeof state !== "undefined") {
    const existingFilters = state.get("filterCategory").toJS() || [];
    const index = existingFilters.indexOf(filter);
    if (index !== -1) {
      existingFilters.splice(index, 1);
    }
    return state.set("filterCategory", fromJS(existingFilters));
  }
  return state;
}

/**
 * setLibrarySortByFilter
 *
 * @description Changes state to set the sortBy filter in the library
 *
 * @param state {Object}
 * @param filter {String}
 * @returns {*}
 */
function setLibrarySortByFilter(state, filter) {

  if (typeof state !== "undefined") {
    const currentSortByType = state.getIn(["cardBrowserConfig", "sortByType"]);
    const sortByType = filter.sortByType || currentSortByType;
    const isSwitchingSortByType = filter.sortByType
      ? filter.sortByType !== currentSortByType
      : false;
    const sortByOrder = isSwitchingSortByType
      ? filter.sortByType === "modifiedDate"
        ? "desc"
        : "asc"
      : filter.sortByOrder || state.getIn(["cardBrowserConfig", "sortByOrder"]);
    const newState = state.setIn(
      ["cardBrowserConfig", "sortByType"],
      sortByType
    );
    return newState.setIn(["cardBrowserConfig", "sortByOrder"], sortByOrder);
  }
  return state;
}

/**
 * setLibrarySearch
 *
 * @description Modifies state to set the keywordSearch attribute in the library state
 *              forcing library refresh afterwards.
 *
 * @param state {Map}
 * @param search {String}
 * @returns {*}
 */
function setLibrarySearch(state, search) {
  if (typeof state !== "undefined" && typeof search !== "undefined") {
    return state.setIn(["cardBrowserConfig", "keywordSearch"], search);
  }
  return state;
}

/**
 * setLibraryIsLoading
 *
 * @description Sets library loading state
 * @param state {Map}
 * @param value {boolean}
 * @returns {Map}
 */
function setLibraryIsLoading(state, value) {
  return state.set("isLoading", value);
}

/**
 * selectToggle
 *
 * @description Selects/Deselects an item updating the selected array
 * @param state {Map}
 * @param assetId {string}
 * @returns {Map}
 */
function selectToggle(state, assetId) {
  if (typeof state !== "undefined" && assetId) {
    let newState = state;
    const existingSelected =
      typeof state.getIn(["cardBrowserConfig", "selected"]) !== "undefined"
        ? state.getIn(["cardBrowserConfig", "selected"]).toJS() || []
        : [];
    const index = existingSelected.indexOf(assetId);
    if (index === -1) {
      // It's not selected
      existingSelected.push(assetId);
      newState = newState.setIn(["cardBrowserConfig", "lastSelected"], assetId);
    } else {
      existingSelected.splice(index, 1);
      newState = newState.setIn(["cardBrowserConfig", "lastSelected"], null);
    }
    const selectMode = existingSelected.length > 0;
    return newState
      .setIn(["cardBrowserConfig", "selected"], fromJS(existingSelected))
      .setIn(["cardBrowserConfig", "selectMode"], selectMode);
  }
  return state;
}

/**
 * selectToggle
 *
 * @description Selects/Deselects an item resetting the selected array
 * @param state {Map}
 * @param assetId {string}
 * @returns {Map}
 */
function exclusiveSelectToggle(state, assetId) {
  if (typeof state !== "undefined") {
    const removingOneAsset =
      state.getIn(["cardBrowserConfig", "selected"]).toJS().length === 1 &&
      state.getIn(["cardBrowserConfig", "selected"]).toJS()[0] === assetId;
    const selected = assetId && !removingOneAsset ? [assetId] : [];
    return state
      .setIn(["cardBrowserConfig", "selected"], fromJS(selected))
      .setIn(["cardBrowserConfig", "selectMode"], selected.length > 0)
      .setIn(["cardBrowserConfig", "lastSelected"], assetId);
  }
  return state;
}

/**
 *
 * @param state {Map}
 * @param assetId {string}
 * @param items {Object}
 * @returns {Map}
 */
function shiftSelectToggle(state, assetId, items) {
  if (typeof state !== "undefined" && assetId) {
    if (!state.getIn(["cardBrowserConfig", "lastSelected"])) {
      return exclusiveSelectToggle(state, assetId);
    }
    // Converting array of objects to array of strings
    const itemsArray = [];
    items.forEach(val => {
      itemsArray.push(val._id);
    });
    const lastSelected = state.getIn(["cardBrowserConfig", "lastSelected"]);
    const existingSelected =
      typeof state.getIn(["cardBrowserConfig", "selected"]) !== "undefined"
        ? state.getIn(["cardBrowserConfig", "selected"]).toJS() || []
        : [];
    const lastIndex = itemsArray.indexOf(lastSelected);
    const selectedIndex = itemsArray.indexOf(assetId);
    // If last and current clicked item are the same we execute an exclusive select
    if (lastIndex === selectedIndex) {
      return exclusiveSelectToggle(state, assetId);
    }
    let selectMode = true;
    // Finding out what items need to be selected in the range
    const first = lastIndex > selectedIndex ? selectedIndex : lastIndex;
    const last = lastIndex > selectedIndex ? lastIndex : selectedIndex;
    // let itemsToSelect = [];
    for (let i = first; i <= last; i++) {
      const activeItem = itemsArray[i];
      // Adding only assets that are not already selected
      if (existingSelected.indexOf(activeItem) === -1) {
        existingSelected.push(activeItem);
      }
    }
    if (existingSelected.length < 1) selectMode = false;

    return state
      .setIn(["cardBrowserConfig", "selected"], fromJS(existingSelected))
      .setIn(["cardBrowserConfig", "selectMode"], selectMode)
      .setIn(["cardBrowserConfig", "lastSelected"], assetId);
  }
  return state;
}

/*
 * LIBRARY DATA
 */

/**
 * getLibraryCategoryFilteredItems
 *
 * @description Returns an array for an object with all items in its properties
 *
 * @param allItems {Object}
 * @param filters {Array}
 * @return {Array}
 * NOTE:we get the assets from db as an array, and they are converted to an object before return, if we end up converting to array here is that extra
 */
const getLibraryCategoryFilteredItems = (allItems, filters) => {
  let filteredItems = [];
  // Applying filterCategory if existent - else all items are added
  const dontFilter =
    typeof filters === "undefined" || filters === null || filters.length === 0;

  filteredItems = _map(allItems, (item, key) => {
    // Object.keys(allItems).forEach(key => {
    // @TODO: Find a better way to skip the revertCopy item
    if (
      item &&
      ((dontFilter && key !== "revertCopy") ||
        (!filteredItems.includes(key) && filters.indexOf(item.type) !== -1))
    ) {
      return item;
      // filteredItems.push(item);
    }
    // return item;
  });

  return filteredItems;
};

/**
 * getLibrarySortedItems
 *
 * @description Returns the array of All Items sorted according to the 'By' and the 'Type'
 *
 * @param allItems {Array}
 * @param sortBy {string}
 * @param sortType {string}
 * @returns {Array}
 */
const getLibrarySortedItems = (allItems, sortBy, sortType) => {

  const selector =
    sortBy === "modifiedDate"
      ? a => new Date(a[sortBy])
      : a => (typeof a[sortBy] !== "undefined" ? a[sortBy].toLowerCase() : 0); //
  if (sortType === "desc") {
    allItems.sort((a, b) =>
      selector(a) < selector(b) ? 1 : selector(b) < selector(a) ? -1 : 0
    );
  } else if (sortType === "asc") {
    allItems.sort((a, b) =>
      selector(a) > selector(b) ? 1 : selector(b) > selector(a) ? -1 : 0
    );
  }
  return allItems;
};

/**
 * getSearchFilteredItems
 *
 * @description Applies search term on title for each item in the library
 * @param allItems {Array}
 * @param searchTerm {String}
 * @returns {*}
 */
const getSearchFilteredItems = (allItems, searchTerm) =>

  allItems.filter(val => {
    if (typeof val !== "undefined") {
      return (
        (typeof val.title !== "undefined"
          ? val.title.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.filename !== "undefined"
          ? val.filename.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.caption !== "undefined"
          ? val.caption.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.notes !== "undefined"
          ? val.notes.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.acknowledgement !== "undefined"
          ? val.acknowledgement.toLowerCase().search(searchTerm.toLowerCase()) >
            -1
          : false) ||
        (typeof val.descriptiveCaption !== "undefined"
          ? val.descriptiveCaption
              .toLowerCase()
              .search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.transcript !== "undefined"
          ? val.transcript.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.rightsHolder !== "undefined"
          ? val.rightsHolder.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.publisher !== "undefined"
          ? val.publisher.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false) ||
        (typeof val.rights !== "undefined"
          ? val.rights.toLowerCase().search(searchTerm.toLowerCase()) > -1
          : false)
      );
    }
    return false;
  });

/**
 *
 * @param state
 * @returns {*}
 */
const addLibraryPage = (state, columns) => {
  if (
    typeof state !== "undefined" &&
    state.getIn(["cardBrowserConfig", "totalCount"]) !==
      state.getIn(["cardBrowserConfig", "pageSize"])
  ) {
    const pageStepSize =
      columns || state.getIn(["cardBrowserConfig", "pageStepSize"]);
    Logger.debug({ pageStepSize, columns }, "[LIBRARY] - addLibraryPage");
    const newPageSize =
      state.getIn(["cardBrowserConfig", "pageSize"]) + pageStepSize >=
      state.getIn(["cardBrowserConfig", "totalCount"])
        ? state.getIn(["cardBrowserConfig", "totalCount"])
        : state.getIn(["cardBrowserConfig", "pageSize"]) + pageStepSize;
    return state.setIn(["cardBrowserConfig", "pageSize"], newPageSize);
  }
  return state;
};

/**
 * Get Library Visible Items
 *
 * @description Takes all the library data and the state and generates an array with the visible items
 * @param allItems {Object} All Data from Library
 * @param cardBrowserConfig {Object} All Library UI Conf for Card Browser
 * @returns {Array} Populated array with visible items
 */
const getLibraryVisibleItems = (allItems, cardBrowserConfig, paginateIt) => {
  // Apply category filtering
  const categoryFilteredItems = getLibraryCategoryFilteredItems(
    allItems,
    cardBrowserConfig.filterCategory
  );
  // Apply search filtering
  const searchFilter = cardBrowserConfig.keywordSearch;
  const searchFilteredItems = searchFilter
    ? getSearchFilteredItems(categoryFilteredItems, searchFilter)
    : categoryFilteredItems;

  // Apply Sorting
  const sortedItems = getLibrarySortedItems(
    searchFilteredItems,
    cardBrowserConfig.sortByType,
    cardBrowserConfig.sortByOrder
  );
  // Apply pagination
  const doPagination = false; // typeof paginateIt !== 'undefined' ? paginateIt : true;

  // Get Visible Items Array
  const pageSize = cardBrowserConfig.pageSize;
  const visibleItems = [];
  const BreakException = {};
  try {
    sortedItems.forEach(value => {
      if (!value) throw BreakException;
      if (visibleItems.length >= pageSize && doPagination) {
        throw BreakException;
      }
      value.url = `/library/view/${value._id}`;
      // value.imgSrc = value.assetMedia ? value.assetMedia.full.url;
      visibleItems.push(value);
    });
  } catch (e) {
    //Logger.error({ error: e, visibleItems},"[LIBRARY REDUCER] - getLibraryVisibleItems, error in sort");
  }

  return visibleItems;
};

export const getLibrarySidebarProps = (state, ownProps) => {
  const editorUI = state.getIn(["ui", "library"]);

  Logger.debug({ editorUI }, "[LIBRARY REDUCER] - editorUI");

  const data = state.getIn(["data", "assets"]) || {}; // @TODO make this read from prop
  // ownProps.dataNode
  // @TODO: Wire-up the saga so it can load directly a specific asset without passing from the library
  // For ASSET view
  const assetId = ownProps.assetId;

  const hasData = Object.keys(data.toJS()).length > 0;
  const assetData = assetId
    ? hasData
      ? data.get(assetId, Map()).toJS()
      : null
    : {};
  const isSaved = assetData
    ? typeof assetData.isSaved !== "undefined"
      ? assetData.isSaved
      : true
    : true;
  // Setting Sidebar content
  const sidebar =
    assetId || ownProps.isAddNewAsset
      ? editorUI.get("assetForm").toJS()
      : editorUI.get("navigation").toJS();
  const revertCopy = state.getIn(["data", "assets", "revertCopy"], Map());

  return {
    isAddNewAsset: ownProps.isAddNewAsset,
    hasData,
    slug: ownProps.history.location.pathname,
    assetId,
    isLoading: editorUI.get("isLoading"),
    isLoadingAssetEditor: editorUI.get("isLoadingAssetEditor"),
    isAssetEditor: !!assetId || ownProps.isAddNewAsset,
    header: assetId
      ? hasData
        ? typeof assetData.title !== "undefined"
          ? assetData.title
          : ""
        : "Loading..."
      : editorUI.get("label") || "",
    sidebar: sidebar || null,
    isSaved,
    backAction: ownProps.backRoute,
    assetData,
    revertCopy: revertCopy.toJS()
  };
};

export const getAssetSelectorMapStateToProps = (state, ownProps) => {
  const assets = state.getIn(["data", "assets"]).toJS();
  const libraryUI = state.getIn(["ui", "library"]).toJS();
  const cardBrowserConfigUserPrefsSelector = [
    "library",
    "browser",
    "cardBrowserConfig"
  ];
  const cardBrowserConfigUserPrefs = state
    .getIn(
      ["ui", "userPreferences", ...cardBrowserConfigUserPrefsSelector],
      Map({})
    )
    .toJS();

  const cardBrowserConfig = libraryUI.cardBrowserConfig;
  if (cardBrowserConfigUserPrefs.sortByType) {
    cardBrowserConfig.sortByType = cardBrowserConfigUserPrefs.sortByType;
  }
  if (cardBrowserConfigUserPrefs.sortByOrder) {
    cardBrowserConfig.sortByOrder = cardBrowserConfigUserPrefs.sortByOrder;
  }

  const accountData = state.getIn(["data", "account"]).toJS();
  // Detecting loading state in library
  const isLoading = libraryUI.isLoading;
  const libraryVisibleItems = getLibraryVisibleItems(assets, cardBrowserConfig);
  // Calculating actual total count
  const actualTotalCount =
    typeof assets.revertCopy !== "undefined"
      ? Object.keys(assets).length - 1
      : Object.keys(assets).length;

  return {
    items: libraryVisibleItems,
    actualTotalCount,
    cardBrowserConfigUserPrefsSelector,
    browsingMode: cardBrowserConfig.browsingMode,
    minCardSize:
      typeof ownProps.minCardSize !== "undefined"
        ? ownProps.minCardSize
        : cardBrowserConfig.cardSizeMultiplier,
    minCardMargin:
      typeof ownProps.minCardMargin !== "undefined"
        ? ownProps.minCardMargin
        : undefined,
    minWidthCaption:
      typeof ownProps.minWidthCaption !== "undefined"
        ? ownProps.minWidthCaption
        : undefined,
    cardBrowserMaxCardSize: cardBrowserConfig.cardBrowserMaxCardSize,
    cardViewerMaxCardSize: cardBrowserConfig.cardViewerMaxCardSize,
    initialSliderValue:
      typeof ownProps.initialSliderValue !== "undefined"
        ? ownProps.initialSliderValue
        : cardBrowserConfig.cardSizeMultiplier,
    isLoading,
    selectedItems: cardBrowserConfig.selected,
    filterSortByTypeOptions:
      typeof ownProps.filterSortByTypeOptions !== "undefined"
        ? ownProps.filterSortByTypeOptions
        : cardBrowserConfig.sortByTypeOptions,
    filterSortByOrder: cardBrowserConfig.sortByOrder,
    filterSortByType: cardBrowserConfig.sortByType,
    selectMode: cardBrowserConfig.selectMode,
    selectedCount: cardBrowserConfig.selected
      ? cardBrowserConfig.selected.length
      : 0,
    lastSelected: cardBrowserConfig.lastSelected,
    searchContent: cardBrowserConfig.keywordSearch,
    scrollTop: cardBrowserConfig.scrollTop,
    totalCount: cardBrowserConfig.totalCount,
    account: accountData._id || "",
    ...ownProps
  };
};

export const getLibraryMainContainerProps = (state, ownProps) => {
  const accountData = state.getIn(["data", "account"]).toJS();
  const libraryData = state.getIn(["data", "assets"]).toJS();
  const libraryUI = state.getIn(["ui", "library"]).toJS();
  const cardBrowserConfigUserPrefsSelector = [
    "library",
    "browser",
    "cardBrowserConfig"
  ];
  const cardBrowserConfigUserPrefs = state
    .getIn(
      ["ui", "userPreferences", ...cardBrowserConfigUserPrefsSelector],
      Map({})
    )
    .toJS();

  const cardBrowserConfig = libraryUI.cardBrowserConfig;
  if (cardBrowserConfigUserPrefs.sortByType) {
    cardBrowserConfig.sortByType = cardBrowserConfigUserPrefs.sortByType;
  }
  if (cardBrowserConfigUserPrefs.sortByOrder) {
    cardBrowserConfig.sortByOrder = cardBrowserConfigUserPrefs.sortByOrder;
  }

  const isAddNewAsset = ownProps.match.path.indexOf("new") !== -1;
  const assetId =
    typeof ownProps.match.params !== "undefined"
      ? ownProps.match.params.assetId
      : null;
  let sidebarMode = state.getIn(["base", "mainSidebar", "mode"]);
  const librarySideBarMode = state.getIn(["base", "mainSidebar", "mode"]);

  // Finding out if we are viewing a specific ASSET
  const asset = assetId ? libraryData[assetId] : null;
  // If existent, removing the revertAsset node
  if (asset && typeof asset.revertCopy !== "undefined") delete asset.revertCopy;
  const isSaved = asset
    ? typeof asset.isSaved !== "undefined"
      ? asset.isSaved
      : true
    : true;
  // Detecting loading state in library
  const isLoading = libraryUI.isLoading;
  const allFilteredItems = !isLoading
    ? getLibraryVisibleItems(libraryData, cardBrowserConfig, false)
    : {};
  let prev = {},
    next = {};

  const itemsCopy =
    allFilteredItems.length > 0 ? allFilteredItems.slice(0) : {};
  if (assetId) {
    const l = itemsCopy.length;
    for (let i = 0; i < l; i++) {
      prev =
        typeof itemsCopy.slice(i - 1, i)[0] !== "undefined"
          ? itemsCopy.slice(i - 1, i)[0]
          : null; // @note: In case we want to jump to last itemsCopy.slice(-1)[0]
      next =
        typeof itemsCopy.slice(i + 1, i + 2)[0] !== "undefined"
          ? itemsCopy.slice(i + 1, i + 2)[0]
          : null; // @note: In case we want to jump to first itemsCopy.slice(0,1)[0]
      if (itemsCopy[i]._id === assetId) break;
    }
    sidebarMode += "Extra";
  } else if (isAddNewAsset) {
    sidebarMode += "Extra";
  }

  let cropData = null;

  // Extracting Cropping Data
  if (asset && typeof asset.config !== "undefined") {
    cropData = asset.config.cropCoordinates
      ? asset.config.cropCoordinates
      : null;
  }
  const revertCopy = state.getIn(["data", "assets", "revertCopy"], Map({}))
    ? state.getIn(["data", "assets", "revertCopy"], Map({})).toJS()
    : null;
  return {
    isAddNewAsset,
    cropData,
    selected: cardBrowserConfig.selected,
    isAssetLoading: libraryUI.isAssetLoading,
    history: ownProps.history,
    uploadPreset: libraryUI.uploadPreset,
    slugBase: ownProps.history.location.pathname,
    browsingMode: cardBrowserConfig.browsingMode,
    assetId: assetId || null,
    asset,
    assetPrev: prev,
    assetNext: next,
    revertCopy,
    sidebarMode,
    librarySideBarMode,
    isLoading,
    isSaved,
    account: accountData._id || "",
    error: libraryUI.error || ""
  };
};

/*
 * LIBRARY FILTERS
 * // ----------------------
 // activePage: 1,
 // pageSize: 10,
 // sortByType: "title",
 // sortByOrder: "desc",
 // filterCategory: "image",
 // keywordSearch: null,
 */

export default library;

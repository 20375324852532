import React, {Component} from "react";
import PropTypes from "prop-types";

/**
 * SidebarHeader
 *
 * @type                    Component
 * @description             Header of the sidebar
 *
 * @props
 *  title {mixed}           Title to be displayed in the header
 */
class SidebarHeader extends Component {

  render() {
    return (
      <div className={`sidebar-header ${this.props.size}`}>
        {this.props.children}
        <div style={{clear: "both"}}/>
        <h2 className={this.props.subtitle ? '' : "onlyTitle"} dangerouslySetInnerHTML={{'__html':this.props.title}} />
        {(this.props.subtitle) ? <h3>{this.props.subtitle}</h3> : null}
      </div>
    )
  }
}

SidebarHeader.defaultProps = {
  size: 'small-height'
};

SidebarHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string
};

export default SidebarHeader;
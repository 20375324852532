import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logger from "utils/logger";
import ContentContainer from 'modules/ContentContainer';
import FooterContainer from 'modules/ContentContainer/FooterContainer';
import './Previewer.scss';

/**
 * @title preview container compoenent
 * @description this compoenent will house an iframe that loads up the site being edited
 * it creates a connection using messageing to send data to the react app inside the iframe
 * it has an internal state for desciding whcih size ifrome it will display
 * for now it is a connected compoent so that it can generate a preview object
 * based on state of the store
 * the state.themeEditor.previewData is an object that will contain the information needed
 * @props {String} previewPath
 * @props {Object} previewTheme
 */

export class Previewer extends Component {
  constructor(props) {
    super(props);
    Logger.debug({ props },'[PREVIEWER] PROPS');
    this.state = {
      display: 'tablet',
      isPreviewReady: false,
      iframeStyle: {
        transform: ' scale(0.45) translate(-50%, -50%)',
        WebkitTransform: 'scale(0.45) translate(-50%, -50%)',
        OTransform: 'scale(0.45) translate(-50%, -50%)',
        msTransform: 'scale(0.45) translate(-50%, -50%)',
        MozTransform: 'scale(0.45) translate(-50%, -50%)',
      },
      scaleMultipliers: {
        desktop: 0.45,
        tablet: 0.4,
        mobile: 0.93,
      },
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  handleClick() {
    this.setState({ display: !this.state.display, isPreviewReady: false });
  }

  handleClose() {
    this.setState({ display: false });
  }

  handleChange(value) {
    Logger.debug({ value }, '[PREVIEW CONT] Changing to ');
    this.setState({ display: value, isPreviewReady: false });
    this.updateDimensions(value);
  }

  receiveMessage(event) {
    /*
     if the event didnt come from the preview iframe we will just ignore....

     */

    // const origin = event.origin || event.originalEvent.origin; // For Chrome, the origin property is in the event.originalEvent object.
    // const previewOrigin = 'http://dev.app.neonsky:8088'; //@TODO make this dependant upon env variable
    // if (origin !== previewOrigin)
    //    return;

    const messageData = event.data || {};
    // if we think we will reciew more than one message typw, then we may want to check
    // if( !messageData.type || messageData.type !== 'PREV_DEST_INIT')
    //    return;

    // ...
    if (!this.state.isPreviewReady && messageData.isReady) {
      this.setState({ isPreviewReady: messageData.isReady });
    }
  }

  updateDimensions(displaySize) {
    const displayRealSize = typeof displaySize === 'object' ? this.state.display : displaySize;
    const widthMultiplier = document.getElementById('displayContainer').offsetWidth / 900;
    const multiplier = this.state.scaleMultipliers[displayRealSize] * widthMultiplier;
    this.setState({
      iframeStyle: { // TODO: since we use auto prefixer do we need to store all the vendors? might still since this is live rendering
        transform: `scale(${multiplier}) translate(-50%, -50%)`,
        WebkitTransform: `scale(${multiplier}) translate(-50%, -50%)`,
        OTransform: `scale(${multiplier}) translate(-50%, -50%)`,
        msTransform: `scale(${multiplier}) translate(-50%, -50%)`,
        MozTransform: `scale(${multiplier}) translate(-50%, -50%)`,
      },
    });
    Logger.debug({ transform: this.state.transform},'[PREVIEW CONT] updateDimensions');
  }

  // not sure if we want it on will or did...
  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
    window.addEventListener('resize', this.updateDimensions);
    // this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    const { previewTheme } = this.props;

    // @NOTE either create thw shallow object here or in the reducer
    const targetOrigin = '*'; // 'http://app.neonsky.com'
    // @TODO send message to the ifrmae that wlll provide object to update styles....
    // @TODO set the value of the targetOrigin based on env variable
    // document.getElementById(this.state.display).contentWindow.api.methodName()
    // the previewer app will need to make the api object and his methods accessible as a property of the content windew
    // we will need to poll to know when api.isReady is true
    // the component will update the first time once the preview is ready, and will pass the initial preview object
    if (this.state.isPreviewReady) {
      Logger.debug({
        previewTheme,
        display: this.state.display,
        },
        '[PREVIEWCONTAINER] componentDidUpdate() :: Posting Message:'
      );
      document
        .getElementById(this.state.display)
        .contentWindow.postMessage(previewTheme, targetOrigin);
    }
  }

  render() {
    return (
      <ContentContainer
        {...this.props}
        sidebarMode={this.props.sidebarMode}
        hasBottomBar
        addClassName="preview-container"
      >
        <div className="display-container" id="displayContainer">
          {this.state.display === 'desktop'
            ? <div className="desktop">
              <iframe id="desktop" src={this.props.previewPath} style={this.state.iframeStyle} />
            </div>
            : null}

          {this.state.display === 'tablet'
            ? <div className="tablet">
              <iframe id="tablet" src={this.props.previewPath} style={this.state.iframeStyle} />
            </div>
            : null}

          {this.state.display === 'mobile'
            ? <div className="mobile">
              <iframe id="mobile" src={this.props.previewPath} style={this.state.iframeStyle} />
            </div>
            : null}
        </div>
        <FooterContainer>
          <div className="display-control">
            <a href="#" onClick={() => this.handleChange('desktop')}>
              <i
                className={`material-icons md-36 md-light ${this.state.display === 'desktop'
                  ? ''
                  : 'md-inactive'}`}
              >
                &#xE30B;
              </i>
            </a>
          </div>

          <div className="display-control">
            <a href="#" onClick={() => this.handleChange('tablet')}>
              <i
                className={`material-icons md-36 md-light ${this.state.display === 'tablet'
                  ? ''
                  : 'md-inactive'}`}
              >
                &#xE331;
              </i>
            </a>
          </div>
          <div className="display-control">
            <a href="#" onClick={() => this.handleChange('mobile')}>
              <i
                className={`material-icons md-36 md-light ${this.state.display === 'mobile'
                  ? ''
                  : 'md-inactive'}`}
              >
                &#xE325;
              </i>
            </a>
          </div>
        </FooterContainer>
      </ContentContainer>
    );
  }
}
Previewer.defaultProps = {
  sidebarMode: 'small',
};

Previewer.propTypes = {
  previewPath: PropTypes.string,
  sidebarMode: PropTypes.string,
  previewTheme: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default Previewer;

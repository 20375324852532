/**
 * LocalStoage is a utility file holding helper methods relating to storing and retreiving data on user's machine
 * we have the option of putting the values in session or storage depending on user login selection
 * TODO: clean this up, and allow for it to put to cookie or local store
 *  no longer need all the separate for sites versus amc mainly just need the access cookie
 * may want to retain a set of methods for cookie, and one for localStorage incase we decide to leverage both for diffeent tasks
 * NOTE: probably wont need to save the full state...
*/
import Logger from "utils/logger";

//---------------------------------------------------
// COOKIE STORAGE MEtTHODS

//---------------------------------------------------
// LOCAL SESSION/STORAGE METHODS

export const saveState = (state, isSession) => {
  try {
    const serializedState = JSON.stringify(state);
    if (isSession) {
      sessionStorage.setItem("co.storycrafter.manage", serializedState);
    } else {
      localStorage.setItem("co.storycrafter.manage", serializedState);
    }
  } catch (err) {
    // @TODO log error somewhere
    Logger.error(err, "[LOCAlSTORE] error storing session?[" + isSession + "]");
  }
};

/**
 * LoadState gets a specific item from local storeage
 * stored as storyCrafterCMS item
*/
export const loadState = () => {
  try {
    const storeState = localStorage.getItem("co.storycrafter.manage");
    const sessState = sessionStorage.getItem("co.storycrafter.manage");
    const serializedState = storeState !== null ? storeState : sessState;
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveStateProp = (prop, value, isSession) => {
  try {
    const serializedState = isSession
      ? localStorage.getItem("co.storycrafter.manage")
      : sessionStorage.getItem("co.storycrafter.manage");

    const state = JSON.parse(serializedState || "{}");
    state[prop] = value;
    const reSerializedState = JSON.stringify(state);
    if (isSession) {
      sessionStorage.setItem("co.storycrafter.manage", reSerializedState);
    } else {
      localStorage.setItem("co.storycrafter.manage", reSerializedState);
    }
  } catch (err) {
    // @TODO log error somewhere
  }
};

export const clearState = state => {
  try {
    localStorage.removeItem("co.storycrafter.manage");
    sessionStorage.removeItem("co.storycrafter.manage");
    Logger.debug("Cleared storyCrafterCMS in Local Storage/Session");
  } catch (err) {
    Logger.error(err, 'Error clearing localStorage state')
  }
};

/**
 * Projects Reducer
 *
 */
import { Map, fromJS } from "immutable";
import * as CONST from "actions/action_constants";

const projects = (state = Map({}), action) => {
  const { list, projectID, projectData, themeId } = action;
  switch (action.type) {
    case CONST.PROJECT_BROWSER_SUCCESS:
      // return state.merge(projectBrowserData);
      return state.merge(list);
    case CONST.PROJECT_CREATE_SUCCESS:
      const tempProjectID = projectData._id;
      return state.merge(fromJS({ [tempProjectID]: { ...projectData } }));
    case CONST.PROJECT_CREATE_REVERT_COPY:
      return state.set("revertCopy", state.get(action.projectID));
    case CONST.PROJECT_REVERT_CHANGES:
      const revertData = state.get("revertCopy").toJS();
      revertData.isSaved = true;
      const revertProjectID = revertData._id;
      return state.set(revertProjectID, fromJS(revertData));
    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    case CONST.PROJECT_SET_PROP:
      const selector = [];
      selector.push(action.projectID, action.prop);

      /*
      Now account for form element type...


      I don't think I can get access to state.ui here.
      I suspect it has to be passed in.
      */
      // const project = state.get(action.projectID);
      // const formConfig = state.get(ui.projectEditor.projectTypes[projectType].forms.settings).toJS());

      // For now, we can assume all form elements are of type text.
      // switch (formElementType){
      //   case 'text':
      //   case 'textarea':
      //   case 'rte':
      //   case 'rte-ext':
      //   case 'number':
      //     // If the form element is a text type element, account
      //     // for the possibility that it could be localized
      //     if (action.isLocalized) {
      //       selector.push(action.langID);
      //     }
      //     break;
      // }

      if (action.isLocalized) {
        selector.push(action.langID);
      }
      return state
        .setIn(selector, action.value)
        .setIn([projectID, "isSaved"], false);
    case CONST.PROJECT_SET_STORY_ORDER:
      return state.setIn([projectID, "storyIDs"], fromJS(projectData.storyIDs));
    case CONST.PROJECT_ADD_STORIES_SUCCESS:
      return state.set(projectData._id, fromJS(projectData));
    // No longer used as of 3/28/19. Project data already updated in store. No
    // need to replace with data returned by API.
    // case CONST.PROJECT_UPDATE_SUCCESS:
    //   return state.set(projectData._id, projectData);
    case CONST.PROJECT_DELETE_SUCCESS:
      return state.remove(projectID);
    case CONST.PROJECT_DELETE_STORIES_SUCCESS:
      return state.set(projectID, projectData);
    case CONST.PROJECT_THEME_CHANGE_SUCCESS:
      return state.setIn([projectID, "themeId"], themeId);
    default:
      return state;
  }
};

export default projects;

import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants'; // fromJS,

/** 
* DASHBOARD REDUCER
* 
*/
const dashboard = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { dashboard } = action; // ,
      return state.merge(fromJS(dashboard));
    default:
      return state;
  }
};

export default dashboard;

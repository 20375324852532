import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import QuestionItem from "./QuestionItem";
import Plus from "material-ui/svg-icons/content/add";
import * as quizEditorActions from "actions/quizEditor";
import _map from "lodash/map";
import "../QuizSidebar.scss";
import styles from "../QuizSidebar.scss";


class QuestionsList extends React.Component {

  render() {
    return (
      <div className="questions-list">
        {this.props.questions && _map(this.props.questions, item => (
            <QuestionItem {...item} key={item.id}/>
          )
        )}
        <div className={`question-item add-new-question ${this.props.addingQuestion === null ? 'active' : ''}`}
             onClick={this.props.prepareAddNewQuestion}>
          <Plus className="plus-symbol" color={styles.colorSecondary}/>
          <div className="new-question-text">Add new Question...</div>
        </div>
      </div>
    );
  }

}

QuestionsList.defaultProps = {};

QuestionsList.propTypes = {
  questions: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const addingQuestion = state.getIn(['quizEditor', 'editingQuestion']);
  return {...ownProps, addingQuestion};
}

// export default QuestionsList;
export default connect(mapStateToProps, quizEditorActions)(QuestionsList);
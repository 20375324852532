import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import ContentContainer from "modules/ContentContainer";
import { instanceBrowserMapStateToProps } from "mapToProps/storyEditor";
import RaisedButton from "material-ui/RaisedButton";
import ActionFormModal from "../ActionFormModal";
import {
  CardBrowser,
  CardBrowserSlider,
  CardBrowserFilter,
  CardBrowserSearch,
  CardBrowserSelectedDisplay,
  Dialog
} from "@terraincognita/ui-core";
import * as storyActions from "actions/storyEditor";
import "./InstanceBrowser.scss";

export class InstanceBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModalOpen: false,
      addModalOpen: false,
      deselectAll: false,
      selected: []
    };

    this.resizingWindow = this.resizingWindow.bind(this);
    this.checkAndUpdateTotalCount = this.checkAndUpdateTotalCount.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.proceedToAdd = this.proceedToAdd.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.exclusiveSelectHandler = this.exclusiveSelectHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  openAddModal() {
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  proceedToAdd(data) {
    this.setState({ addModalOpen: false });
    // STUB in a way to get the title from user for now

    if (this.props.instanceType === "project") {
      this.props.createNewProject(data);
    } else {
      this.props.createNewStory(data);
    }
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false, selected: [] });
    if (this.props.instanceType === "project") {
      this.props.deleteProject();
    } else {
      this.props.deleteStory();
    }
  }

  exclusiveSelectHandler(selecting) {
    const deselectAll = typeof selecting !== "undefined" && selecting;

    this.setState({
      deselectAll: !deselectAll,
      selected: deselectAll ? { ...this.state.selected, selecting } : []
    });
    if (typeof this.props.exclusiveSelectHandler !== "undefined") {
      this.props.exclusiveSelectHandler(selecting);
    }
  }

  selectHandler(id) {
    this.setState({ deselectAll: false });
    if (typeof this.props.selectHandler !== "undefined") {
      this.props.selectHandler(id);
    }
  }
  render() {
    const topBar = this.props.storyBrowserUIState.selectMode ? (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedCount={
            this.props.selected ? this.props.selected.length : undefined
          }
          deselectAllAction={this.exclusiveSelectHandler}
        />
        <div className="delete-asset-button-container">
          <RaisedButton
            primary
            className="new-asset-button"
            label="DELETE"
            labelStyle={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              this.openDeleteModal();
            }}
          />
        </div>
      </div>
    ) : (
      <div className="filters-bar-container">
        <CardBrowserSearch
          cardSearchMinWidth={300}
          action={this.props.onChangeSearch}
          search={
            this.props.storyBrowserUIState.keywordSearch
              ? this.props.storyBrowserUIState.keywordSearch
              : ""
          }
          cardSearchHeight={24}
        />
        <div className="filters-bar">
          {this.props.instanceType !== "collection" ? (
            <RaisedButton
              primary
              className="new-asset-button"
              label="NEW"
              labelStyle={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                // NOTE will need a modal eventually
                this.openAddModal();
              }}
            />
          ) : null}

          {this.props.instanceType !== "collection" ? (
            <div className="divider" />
          ) : null}

          <CardBrowserSlider
            changeValue={this.props.setSliderMultiplier}
            initialValue={this.props.storyBrowserUIState.cardSizeMultiplier}
          />

          <div className="divider" />

          <CardBrowserFilter
            onSortByClick={this.props.onSortByClick}
            onSortByOrderClick={this.props.onSortByOrderClick}
            sortByTypeOptions={this.props.storyBrowserUIState.sortByTypeOptions}
            sortByType={this.props.storyBrowserUIState.sortByType}
            sortByOrder={this.props.storyBrowserUIState.sortByOrder}
          />
        </div>
      </div>
    );

    const addStoryForm =
      this.props.instanceType === "project"
        ? {
            title: {
              type: "text",
              isRequired: true,
              label: "Title",
              param: "title",
              value: "",
              inlineLabel: true,
              order: 2
            },
            type: {
              type: "radio",
              isRequired: true,
              label: "Story Type",
              param: "type",
              value: "",
              options: [
                {
                  value: "story",
                  label: "Story"
                },
                {
                  value: "collection",
                  label: "Collection"
                }
              ]
            }
          }
        : {
            title: {
              type: "text",
              isRequired: true,
              label: "Title",
              param: "title",
              value: "",
              inlineLabel: true,
              order: 2
            },
            type: {
              type: "radio",
              isRequired: true,
              label: "Story Type",
              param: "type",
              value: "",
              options: [
                {
                  value: "gallery",
                  label: "Gallery"
                },
                {
                  value: "slideshow",
                  label: "Slideshow"
                },
                {
                  value: "quiz-graded",
                  label: "Graded Quiz"
                },
                {
                  value: "timeline",
                  label: "Timeline"
                },
                {
                  value: "map",
                  label: "Map"
                }
              ]
            }
          };

    const instanceLabelPlural =
      this.props.instanceType === "story"
        ? "Stories"
        : this.props.instanceType === "collection"
        ? "Collections"
        : "Projects";
    const instanceLabel =
      this.props.instanceType === "story"
        ? "Story"
        : this.props.instanceType === "collection"
        ? "Collection"
        : "Project";

    return (
      <div className="cms-screen story-browser">
        <Helmet>
          <title>{instanceLabel}</title>
        </Helmet>
        <ContentContainer
          isLoading={this.props.storyBrowserUIState.isLoading}
          className="content-container"
          selectMode={this.props.storyBrowserUIState.selectMode}
          sidebarMode={this.props.sidebarMode}
          title={`${
            this.props.projectID && this.props.projectData
              ? this.props.projectData.title
              : instanceLabelPlural
          }`}
          topBar={topBar}
        >
          {this.props.items.length > 0 ? (
            <CardBrowser
              minCardSize={this.props.storyBrowserUIState.cardSizeMultiplier}
              items={this.props.items}
              itemName={instanceLabel}
              setCardMargin={20}
              enableKeys={false}
              deselectAll={this.state.deselectAll}
              editClickHandler={this.props.editClickHandler}
              showButtons
              selectHandler={this.selectHandler}
              selectMode={this.props.storyBrowserUIState.selectMode}
              selected={this.props.selected}
              exclusiveSelectHandler={this.exclusiveSelectHandler}
              backClickHandler={this.props.backClickHandler}
            />
          ) : null}
        </ContentContainer>
        <Dialog
          content="Once deleted, it cannot be restored"
          handleConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          handleCancel={this.closeDeleteModal}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title={`Delete this ${instanceLabel}?`}
        />
        <ActionFormModal
          open={this.state.addModalOpen}
          closeAction={this.closeAddModal}
          handleSave={this.proceedToAdd}
          title={`Add New ${instanceLabel}`}
          form={addStoryForm}
        />
      </div>
    );
  }

  componentDidMount() {
    // this should only be called once....
    switch (this.props.instanceType) {
      case "collection":
        this.props.requestCollectionBrowser();
        break;
      case "story":
        this.props.requestStoryBrowser();
        break;
      case "project":
        this.props.requestProjectBrowser();
        if (this.props.projectID) {
          this.props.requestStoryBrowser();
          this.props.requestCollectionBrowser();
        }
        break;
    }
    this.props.setSaved(true);
    window.addEventListener("resize", this.resizingWindow);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isCollections !== nextProps.isCollections ||
      this.props.isProjects !== nextProps.isProjects
    ) {
      // NOTE: We can include a mechanism here to check if they have already been loaded and not load again
      // but that, as it happens with the assets, would prevent noticing new additions.
      switch (nextProps.instanceType) {
        case "collection":
          this.props.requestCollectionBrowser();
          break;
        case "story":
          this.props.requestStoryBrowser();
          break;
        case "project":
          this.props.requestProjectBrowser();
          if (nextProps.projectID) {
            this.props.requestStoryBrowser();
            this.props.requestCollectionBrowser();
          }
          break;
      }
    }
  }

  componentDidUpdate() {
    this.checkAndUpdateTotalCount();
    if (this.state.firstLoad) {
      const interval = setInterval(() => {
        const itemsContainerHeight = document.getElementById("itemsContainer")
          ? document.getElementById("itemsContainer").offsetHeight
          : null;
        const cardBrowserHeight = document.getElementById("cardBrowser")
          ? document.getElementById("cardBrowser").offsetHeight
          : null;

        if (itemsContainerHeight && cardBrowserHeight) {
          if (cardBrowserHeight > itemsContainerHeight) {
            if (typeof this.props.executeOnScroll !== "undefined") {
              this.props.executeOnScroll();
            }
          } else {
            clearInterval(interval);
            this.setState({ firstLoad: false });
          }
        }
      }, 300);
    }
  }

  resizingWindow() {
    this.setState({ firstLoad: true });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizingWindow);
  }

  checkAndUpdateTotalCount() {
    if (typeof this.props.storyBrowserUIState === "undefined") return;
    const currentStoredCount = this.props.storyBrowserUIState.totalCount;
    const actualCount = this.props.actualTotalCount;
    if (actualCount > 0 && currentStoredCount !== actualCount) {
      this.props.storyBrowserSetTotalCount(actualCount);
    }
  }
}

InstanceBrowser.contextTypes = {
  router: PropTypes.object
};

InstanceBrowser.defaultProps = {
  isLoading: true,
  storyBrowserUIState: {
    selectMode: false,
    sortByTypeOptions: {
      date: {
        value: "modifiedDate",
        label: "Date Modified"
      },
      title: {
        value: "title",
        label: "Title"
      }
    },
    sortByType: "title",
    sortByOrder: "desc"
  },
  minCardSize: 210
};

InstanceBrowser.propTypes = {
  isLoading: PropTypes.bool,
  instanceType: PropTypes.string, // The type of instance (story, collection, project)
  storyBrowserUIState: PropTypes.object, // represents the node of the store for this section - NOT the data
  sidebarMode: PropTypes.string, // Sidebar Mode
  items: PropTypes.array,
  minCardSize: PropTypes.number,
  storyTypes: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return instanceBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    account,
    selected,
    storyTypes,
    projectTypes,
    username,
    cardBrowserConfigUserPrefsSelector
  } = stateProps;
  return Object.assign({}, stateProps, {
    backClickHandler: id => dispatchProps.exclusiveSelectToggle(id),
    editClickHandler: url => {
      ownProps.history.push(url);
    },
    createNewStory: data => {
      const storyTypeID = storyTypes[data.type]._id;
      data.type = storyTypeID;
      dispatchProps.createNewStory(data);
    },
    createNewProject: data => {
      const storyTypeID = projectTypes[data.type]._id;
      data.type = storyTypeID;
      dispatchProps.createNewProject(data);
    },
    setSaved: value => dispatchProps.setStoryBrowserIsSaved(value),
    // Doesn't appear to be used as of 11/26/2019
    // updateStory: input => dispatchProps.updateStory(input),
    deleteStory: () => dispatchProps.deleteStory(selected),
    deleteProject: () => dispatchProps.deleteProject(selected),
    exclusiveSelectHandler: id => dispatchProps.exclusiveSelectToggle(id),
    onChangeSearch: search => dispatchProps.setCardBrowserSearch(search),
    onSortByClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByType",
        value
      ),
    onSortByOrderClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByOrder",
        value
      ),
    requestStoryBrowser: () => dispatchProps.requestStoryBrowser(account),
    requestCollectionBrowser: () =>
      dispatchProps.requestCollectionBrowser(account),
    requestProjectBrowser: () => dispatchProps.requestProjectBrowser(username),
    selectHandler: (id, selector) => dispatchProps.selectToggle(id, selector),
    setSliderMultiplier: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "cardSizeMultiplier",
        value
      ),
    setStoryBrowserIsLoading: value =>
      dispatchProps.setStoryBrowserIsLoading(value),
    storyBrowserSetTotalCount: count =>
      dispatchProps.storyBrowserSetTotalCount(count)
  });
}

export default withRouter(
  connect(mapStateToProps, storyActions, mergeProps)(InstanceBrowser)
);

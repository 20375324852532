// import * as api from 'storeapi';
// import {loadAMCState} from 'storelocalStorage';

import * as CONST from "actions/action_constants";

export const requestLibrary = account => {
  return {
    type: CONST.LIBRARY_FETCH_REQUEST,
    account
  };
};

export const requestAsset = (assetId, account) => ({
  type: CONST.LIBRARY_ASSET_REQUEST,
  assetId,
  account
});

export const setUIError = message => ({
  type: CONST.SET_UI_ERROR_REQUEST,
  message
});

export function setCardBrowserOrderBy(sortByType) {
  return {
    type: CONST.SET_LIBRARY_SORT_FILTER,
    sortByType
  };
}

export function setCardBrowserOrderType(sortByOrder) {
  return {
    type: CONST.SET_LIBRARY_SORT_FILTER,
    sortByOrder
  };
}

export function setLibraryCategoryFilter(filter) {
  // We can force URL navigation with the action below - uncomment dependency above
  // browserHistory.push('/library/' + filter);
  return {
    type: CONST.SET_LIBRARY_CAT_FILTER,
    filter
  };
}

export function unsetLibraryCategoryFilter(filter) {
  return {
    type: CONST.UNSET_LIBRARY_CAT_FILTER,
    filter
  };
}

export function setCardBrowserSearch(search) {
  return {
    type: CONST.SET_LIBRARY_SEARCH,
    search
  };
}

export function setLibraryIsLoading(value) {
  return {
    type: CONST.SET_LIBRARY_LOADING,
    value
  };
}

export function setLibraryAssetIsLoading(value) {
  return {
    type: CONST.SET_LIBRARY_ASSET_LOADING,
    value
  };
}

export function addLibraryPage(columns) {
  return {
    type: CONST.LIBRARY_ADD_PAGE,
    columns
  };
}

export function setScrollPosition(scrollTop) {
  return {
    type: CONST.LIBRARY_SET_SCROLL,
    scrollTop
  };
}

export function selectToggle(assetId) {
  return {
    type: CONST.LIBRARY_SELECT_TOGGLE,
    assetId
  };
}

export function exclusiveSelectToggle(assetId) {
  return {
    type: CONST.LIBRARY_EXCLUSIVE_SELECT_TOGGLE,
    assetId
  };
}

export function shiftSelectToggle(assetId, items, first) {
  return {
    type: CONST.LIBRARY_SHIFT_SELECT_TOGGLE,
    assetId,
    items,
    first
  };
}

export const resetAssetEditor = () => ({
  type: CONST.LIBRARY_ASSET_EDITOR_RESET
});

export const resetLibraryCardBrowser = () => ({
  type: CONST.LIBRARY_CARD_BROWSER_RESET
});

/*
 * UPDATE ASSET
 *
 * @description this action will be ASYNC, it will do a call to the API passing the full asset data object, once it receives
 * confirmation it will fire the other actions and show notification
 * its purpose is to store theme changes in the database
 * @param themeIndex (String)
 * @param assetData (Object)
 * returns (Object) action to be dispatched
 * this one we WAIT to add to store until successful response
 */
export const updateAsset = (assetIndex, assetData) => {
  if (!assetIndex) return; // throw an error
  return {
    type: CONST.LIBRARY_ASSET_UPDATE_REQUEST,
    isSaving: true,
    assetIndex,
    assetData
  };
};

/**
 * @description this method will dispatch the action that makes a copy of the asset being edited
 * we pass on the assetData and the force flag and reducer wil determine if a state change is needed...
 */
export const copyAsset = (assetData, force) => {
  return {
    type: CONST.COPY_ASSET,
    assetData,
    force
  };
};

/**
 @description this method will dispatch the action that REMOVES the copy of the asset being edited
 */
export const removeAssetCopy = () => ({
  type: CONST.REMOVE_COPY_ASSET
});

export const setBrowsingMode = mode => ({
  type: CONST.LIBRARY_SET_BROWSING_MODE,
  mode
});

/*
 * REVERT ASSET
 * @description this method will fire the action to replace the currently active asset data with what it had previously been state of last save
 * @param (String)
 * @param (Object)
 * returns (Object) action to be dispatched
 */
export const revertAsset = (assetIndex, revertCopy) => {
  if (!assetIndex) return;

  return {
    type: CONST.REVERT_ASSET,
    assetIndex,
    assetData: revertCopy
  };
};

/*
 * SET DATA FROM ASSET INPUTS
 *
 * @description this action will update the store with the new input values and set the asset to dirty
 *
 * @param property (String)
 * @param value (String)
 * @param assetIndex (String)
 * returns (Dispatch) with action object
 * @NOTE this will NOT do an API call simply set the isSaved Flag to false
 */
export const setDataFromInput = (property, value, assetIndex) => ({
  type: CONST.LIBRARY_SET_ASSET_VALUE_SUCCESS,
  value,
  property,
  assetIndex,
  isSaved: false
});

/**
 * SET_LIBRARY_SLIDER_SIZE_MULTIPLIER
 *
 * @param value {Integer}
 * @returns (Dispatch) with action object
 */
export const setSliderMultiplier = value => ({
  type: CONST.SET_LIBRARY_SLIDER_SIZE_MULTIPLIER,
  value
});

/**
 * CREATE NEW ASSET
 *
 */
export const createAssetRequest = (assetData, account) => ({
  type: CONST.LIBRARY_ASSET_CREATE_REQUEST,
  assetData,
  account
});

export const librarySetTotalCount = totalCount => ({
  type: CONST.LIBRARY_SET_TOTAL_COUNT,
  totalCount
});

export const deleteMultiple = assets => ({
  type: CONST.LIBRARY_DELETE_MULTIPLE_REQUEST,
  assets
});

export const dispatchUserPreferenceChange = (selector, name, value) => ({
  type: CONST.USER_PREFERENCE_CHANGE,
  selector,
  name,
  value
});

/**
 * Feedback Reducer
 *
 */
import { Map } from 'immutable';
import * as CONST from 'actions/action_constants';

const feedback = (state = Map({}), action) => {
  const { feedbackData, feedbackID } = action;
  switch (action.type) {
    case CONST.FEEDBACK_SUCCESS:
      return state.merge(feedbackData);
    case CONST.FEEDBACK_DELETE_SUCCESS:
      return state.delete(feedbackID);
    default:
      return state;
  }
};

export default feedback;

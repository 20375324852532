/**
 * Story Categories Reducer
 */
import { fromJS, Map } from "immutable";
import * as CONST from "actions/action_constants";
import _merge from "lodash/merge";
import { ContentAdd } from "material-ui/svg-icons";

const storyCategories = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.STORY_DATA_REQUEST:
      return state.clear();

    case CONST.STORY_CATEGORIES_REQUEST_SUCCESS:
    case CONST.STORY_DATA_SUCCESS:
      return state.merge(fromJS(action.categories));

    case CONST.STORY_CATEGORY_CREATE_SUCCESS:
      return state.set(action.categoryData._id, fromJS(action.categoryData));

    case CONST.STORY_CATEGORY_EDITOR_DATA_INITIALIZED:
      return state.set("revertCopy", state.get(action.categoryID));

    case CONST.STORY_CATEGORY_DELETE_SUCCESS:
      return state.remove(action.categoryID);

    case CONST.STORY_CATEGORY_EDITOR_DATA_CHANGED:
      const { categoryID, property, propertyType, value, languageID } = action;
      const propertySelector = [categoryID, property];

      switch (propertyType) {
        case "text":
        case "textarea":
        case "rte":
        case "rte-ext":
        case "rte-ext-class":
        case "number":
          if (languageID) {
            propertySelector.push(languageID);
          }
          break;

        case "checkbox":
        case "select":
        case "select-multiple":
          propertySelector.push("value");
          break;
      }

      return state
        .setIn(propertySelector, fromJS(value))
        .setIn([categoryID, "isSaved"], false);

    case CONST.STORY_CATEGORY_EDITOR_SAVE_SUCCESS:
      return state.setIn([action.categoryID, "isSaved"], true);

    default:
      return state;
  }
};

export default storyCategories;

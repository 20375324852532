/**
 * BASE REDUCER
 *
 */
import { fromJS, Map } from "immutable";
import { combineReducers } from "redux-immutable";
import * as CONST from "actions/action_constants";
import { LOCATION_CHANGE } from "react-router-redux";

// default State objects
const defStatus = {
  isFetching: false,
  isInit: false
};

const defConfig = {
  langID: "en"
};

/**
 * SUB REDUCERS
 */

const status = (state = fromJS(defStatus), action) => {
  const { message } = action; // could used by multiple types
  switch (action.type) {
    case CONST.APP_INIT_REQUEST:
      return state.set("isFetching", true);

    case CONST.APP_INIT_SUCCESS:
      return state.set("isInit", true).set("isFetching", false);

    case CONST.APP_INIT_FAILURE:
      return state.set("error", message).set("isFetching", false);

    case CONST.LOGOUT_SUCCESS:
      return state.set("isInit", false);

    case CONST.APP_INIT_FAILURE:
    case CONST.SET_UI_ERROR_REQUEST:
    case CONST.LIBRARY_FETCH_ERROR:
    case CONST.LIBRARY_ASSET_CREATE_ERROR:
    case CONST.LIBRARY_ASSET_UPDATE_ERROR:
    case CONST.LIBRARY_DELETE_MULTIPLE_ERROR:
    case CONST.PROJECT_BROWSER_ERROR:
    case CONST.PROJECT_CREATE_ERROR:
    case CONST.PROJECT_UPDATE_ERROR:
    case CONST.PROJECT_DELETE_ERROR:
    case CONST.PROJECT_ADD_STORIES_ERROR:
    case CONST.PROJECT_DELETE_STORIES_ERROR:
    case CONST.PROJECT_SET_STORY_ORDER_ERROR:
    case CONST.PROJECT_PUBLISH_ERROR:
    case CONST.STORY_BROWSER_ERROR:
    case CONST.STORY_DATA_ERROR:
    case CONST.STORY_CREATE_ERROR:
    case CONST.STORY_UPDATE_ERROR:
    case CONST.STORY_SETTINGS_SAVE_ERROR:
    case CONST.STORY_DELETE_ERROR:
    case CONST.SCENE_CREATE_ERROR:
    case CONST.SCENE_UPDATE_ERROR:
    case CONST.SCENE_DELETE_ERROR:
    case CONST.SUBSCENE_CREATE_ERROR:
    case CONST.SUBSCENE_DELETE_ERROR:
    case CONST.COLLECTION_BROWSER_ERROR:
    case CONST.FEEDBACK_ERROR:
    case CONST.FEEDBACK_DELETE_ERROR:
    case CONST.ANALYTICS_ERROR:
    case CONST.ACCOUNT_USERS_FETCH_ERROR:
    case CONST.USER_MANAGER_ADD_ERROR:
    case CONST.USER_MANAGER_DELETE_ERROR:
    case CONST.USER_EDITOR_SAVE_ERROR:
      return state.set("isUIError", true).set("uiErrorMessage", message);

    case CONST.RESET_UI_ERROR:
      return state.set("isUIError", false).set("uiErrorMessage", "");

    default:
      return state;
  }
};

const config = (state = fromJS(defConfig), action) => {
  const { payload } = action;
  switch (action.type) {
    case CONST.CHANGE_LANGUAGE:
      return state.set("langID", action.langID);
    default:
      return state;
  }
};

const routing = (state = Map({}), action) => {
  const { payload } = action;
  switch (action.type) {
    case LOCATION_CHANGE:
      // stores the routes so other modules can access if needed
      return state.set("routing", { locationBeforeTransitions: payload });
    default:
      return state;
  }
};

const mainSidebar = (state = Map({}), action) => {
  const { mainSidebar } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(mainSidebar));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();

    case CONST.TOGGLE_SIDEBAR:
      const newSidebarState = state.get("mode") === "large" ? "small" : "large";
      return state.set("mode", newSidebarState);
    default:
      return state;
  }
};

const languages = (state = Map({}), action) => {
  const { languages } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(languages));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();

    default:
      return state;
  }
};

const queue = (state = Map({}), action) => {
  const { queId, message, success } = action; // @NOTE will move out of data

  switch (action.type) {
    case CONST.LOGOUT_SUCCESS:
      return state.clear();

    case CONST.QUEUE_REQUEST: // QUEUE_REQUEST
      // add to the asyncQue
      const newId = action.queId;
      // @TODO add a time stamp...
      // do we need all the data being saved store in the queue as well?
      const newQueueItem = {
        inProcess: true,
        type: action.queType,
        completed: false,
        success: false,
        id: newId
      };
      // the first request for the queue should create a immutable if not set
      //
      // const reqQue = state.get('saveQueue', Map());
      return state.set(newId, fromJS(newQueueItem));

    case CONST.QUEUE_RESPONSE: // QUEUE_RESPONSE
      return state
        .setIn([queId, "inProcess"], false) // may be repetitive to completed
        .setIn([queId, "message"], message)
        .setIn([queId, "success"], success)
        .setIn([queId, "completed"], true);

    case CONST.QUEUE_ITEM_SHOWN:
      return state.update(queId, q => q.set("shown", true));

    case CONST.CLEAR_QUEUE_ITEM:
      return state.delete(queId);

    default:
      return state;
  }
};

const accountManager = (state = Map({}), action) => {
  const { accountManager } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(accountManager));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

/* STORYTYPES  */
const storyTypes = (state = Map({}), action) => {
  const { storyTypes } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(storyTypes));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

/* PROJECT TYPES  */
const projectTypes = (state = Map({}), action) => {
  const { projectTypes } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(projectTypes));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

/* SCENETYPES */
const sceneTypes = (state = Map({}), action) => {
  const { sceneTypes } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(sceneTypes));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

/* STORYTYPES  */
const layouts = (state = Map({}), action) => {
  const { layouts } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(layouts));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

const themes = (state = Map({}), action) => {
  const { themes } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(themes));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

const themeTypes = (state = Map({}), action) => {
  const { themeTypes } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(themeTypes));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

/* FORMS  */
const forms = (state = Map({}), action) => {
  const { feedbackForms } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      return state.merge(fromJS(feedbackForms));

    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    default:
      return state;
  }
};

// combine parts into one data reducer...
const base = combineReducers({
  status,
  config,
  routing,
  queue,
  mainSidebar,
  languages,
  accountManager,
  projectTypes,
  storyTypes,
  sceneTypes,
  layouts,
  themes,
  themeTypes,
  forms
});

export default base;

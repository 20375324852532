import React from "react";
import "./ContentContainer.scss";

class FooterContainer extends React.Component {
  render() {
    return (
      <div
        className={`footer-container ${this.props.selectMode
          ? "select-mode"
          : ""}`}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FooterContainer;

import React, {Component} from "react";

/**
 * SidebarSubheader
 *
 * @type                    Component
 * @description             Section of the Sidebar under the header area where action items can be
 *
 * @props
 *  children {mixed}        The content of the sidebarSubHeader section
 */
class SidebarActionBar extends Component {
    
    render() {
        return (
            <div className="sidebar-action-bar">
                {this.props.children}
            </div>
        )
    }
}

export default SidebarActionBar;
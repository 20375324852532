import * as CONST from 'actions/action_constants';
 
export const handleEditorDataInitialized = (userID) => ({
  type: CONST.USER_EDITOR_DATA_INITIALIZED,
  userID
});

export const handleAddRequest = (userData) => ({
  type: CONST.USER_MANAGER_ADD_REQUEST,
  userData
})

export const handleAddErrorConfirm = () => ({
  type: CONST.USER_MANAGER_ADD_ERROR_CONFIRM
})

export const handleDeleteRequest = (userIDs) => ({
  type: CONST.USER_MANAGER_DELETE_REQUEST,
  userIDs
})

export const handleDeleteErrorConfirm = () => ({
  type: CONST.USER_MANAGER_DELETE_ERROR_CONFIRM
})

export const handleInputChange = (userID, property, value) => ({
  type: CONST.USER_EDITOR_DATA_CHANGED,
  userID,
  property,
  value
})

export const handleRevertChanges = () => ({
  type: CONST.USER_EDITOR_DATA_REVERT_REQUEST
})

export const handleSaveRequest = (userID) => ({
  type: CONST.USER_EDITOR_SAVE_REQUEST,
  userID
});

export const handleSaveErrorConfirm = () => ({
  type: CONST.USER_EDITOR_SAVE_ERROR_CONFIRM
})


// export const deleteScene = (userID) => ({
//   type: CONST.USER_DELETE_REQUEST,
//   userID
// });

// export const createUserRevertCopy = userData => ({
//   type: CONST.USER_CREATE_REVERT_COPY_REQUEST,
//   userData,
// });

// export const revertUser = (userID) => ({
//   type: CONST.USER_REVERT_REQUEST,
//   userID
// });

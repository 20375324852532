import React, {Component} from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import Logger from "utils/logger";
import config from "config";
import ChevronRight from "material-ui/svg-icons/navigation/chevron-right";
import ChevronLeft from "material-ui/svg-icons/navigation/chevron-left";
import Play from "material-ui/svg-icons/av/play-arrow";
import Pause from "material-ui/svg-icons/av/pause";
import RaisedButton from "material-ui/RaisedButton";
import "./AssetViewer.scss";
import {Image, Transformation, Video} from "cloudinary-react";
import {Dialog} from "@terraincognita/ui-core";
import CircularProgress from "material-ui/CircularProgress";
// import {history} from "storeroutes";

class AssetViewer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayModalChangesNext: false,
      displayModalChangesPrev: false,
      playingVideo: false
    };
    this.handleArrowKeys = this.handleArrowKeys.bind(this);
    this.doNextAsset = this.doNextAsset.bind(this);
    this.doPrevAsset = this.doPrevAsset.bind(this);
    this.proceedToNext = this.proceedToNext.bind(this);
    this.proceedToPrev = this.proceedToPrev.bind(this);
    this.closeModalNext = this.closeModalNext.bind(this);
    this.closeModalPrev = this.closeModalPrev.bind(this);
    this.setFrameAsPoster = this.setFrameAsPoster.bind(this);
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
  }

  handleArrowKeys(event) {
    if (this.inputHasFocus()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (event.code === 'ArrowRight' && this.props.next) {
      this.doNextAsset();
    } else if (event.code === 'ArrowLeft' && this.props.prev) {
      this.doPrevAsset();
    }
  }

  doNextAsset() {
    if (this.props.isSaved) {
      this.proceedToNext(false);
    } else {
      this.setState({displayModalChangesNext: true});
    }
  }

  doPrevAsset() {
    if (this.props.isSaved) {
      this.proceedToPrev(false);
    } else {
      this.setState({displayModalChangesPrev: true});
    }
  }

  closeModalNext() {
    this.setState({displayModalChangesNext: false});
  }

  closeModalPrev() {
    this.setState({displayModalChangesPrev: false});
  }

  proceedToNext(revert) {
    this.closeModalNext();
    if (typeof this.props.setLibraryIsLoading !== 'undefined') {
      this.props.setLibraryIsLoading(true);
    }
    if (revert && typeof this.props.revertAssetAction !== 'undefined') {
      this.props.revertAssetAction();
    }
    if (typeof this.props.resetScrollAction !== 'undefined') {
      this.props.resetScrollAction();
    }
    document.getElementById("asset-editor-list").scrollTop = 0;
    this.props.history.push(this.props.next.url);
  }

  proceedToPrev(revert) {
    this.closeModalPrev();
    if (typeof this.props.setLibraryIsLoading !== 'undefined') {
      this.props.setLibraryIsLoading(true);
    }
    if (revert && typeof this.props.revertAssetAction !== 'undefined') {
      this.props.revertAssetAction();
    }
    if (typeof this.props.resetScrollAction !== 'undefined') {
      this.props.resetScrollAction();
    }
    document.getElementById("asset-editor-list").scrollTop = 0;
    this.props.history.push(this.props.prev.url);
  }

  inputHasFocus() {
    for (let input of document.getElementsByTagName("input")) {
      if (input === document.activeElement) {
        return true;
      }
    }
    for (let input of document.getElementsByTagName("textarea")) {
      if (input === document.activeElement) {
        return true;
      }
    }
    for (let input of document.getElementsByClassName("ql-editor")) {
      if (input === document.activeElement) {
        return true;
      }
    }
    return false;
  }

  imageWasLoaded() {
    if (typeof this.props.setLibraryIsLoading !== 'undefined') {
      this.props.setLibraryIsLoading(false);
    }
  }

  componentDidMount() {
    Logger.debug('[ASSET VIEWER] - componentDidMount');
    document.addEventListener("keydown", this.handleArrowKeys, false);
    if (this.props.asset && typeof this.props.copyAssetFunction !== 'undefined') {
      this.props.copyAssetFunction();
    }
    document.getElementById("asset-editor-list").scrollTop = 0;
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleArrowKeys, false);
  }

  playVideo() {
    this.setState({playingVideo: true});
    document.getElementById("videoAsset").play();
  }

  pauseVideo() {
    this.setState({playingVideo: false});
    document.getElementById("videoAsset").pause();
  }

  render() {
    Logger.debug({ asset: this.props.asset }, '[ASSET VIEWER] - render: asset');
    const cropData = this.props.asset && this.props.asset.config && this.props.asset.config.cropCoordinates ?
      this.props.asset.config.cropCoordinates : {};
    return (
      <div className="cms-asset-viewer">
        <Helmet>
          <title>Library - Asset Viewer</title>
        </Helmet>
        {this.props.isLoading ?
          <div className="relative-content-container">
            <div className="progress-container">
              <CircularProgress
                className="progress-indicator"
                size={80}
                thickness={10}
              />
            </div>
          </div> : null }
        <div className="asset-container" style={{opacity: this.props.isLoading ? 0 : 1}}>
          {
            (this.props.prev && this.props.asset.type === 'video') ?
              <div className="prev-arrow-container arrow-container is-video">
                <div className="relative-container">
                  <button onClick={this.doPrevAsset}>
                    <ChevronLeft color="white"/>
                  </button>
                </div>
              </div>
              : null
          }
          {
            (this.props.prev && this.props.asset.type !== 'video') ?
              <div className="prev-arrow-container arrow-container">
                <div className="relative-container">
                  <button onClick={this.doPrevAsset}>
                    <ChevronLeft color="white"/>
                  </button>
                </div>
              </div>
              : null
          }
          {this.props.asset && typeof this.props.asset.isCloudinary !== 'undefined' && this.props.asset.isCloudinary ?

            this.props.asset.type === 'video' ?
              <div className="videoAssetContainer">
                <div className="video-control">
                  {
                    !this.state.playingVideo ?
                      <button onClick={this.playVideo}>
                        <Play color="black"/>
                      </button>
                      :
                      <button onClick={this.pauseVideo}>
                        <Pause color="black"/>
                      </button>
                  }
                </div>
                <Video
                  id="videoAsset"
                  cloudName={config('cloudName')}
                  publicId={this.props.asset.cloudPublicId}
                  onLoadedData={this.imageWasLoaded.bind(this)}
                />
              </div>
              :
              <Image
                cloudName={config('cloudName')}
                publicId={this.props.asset.cloudPublicId + '.jpg'}
                onLoad={this.imageWasLoaded.bind(this)}
              >
                <Transformation width={cropData ? cropData.width : this.props.cardViewerMaxCardSize}
                                crop={cropData ? 'crop' : 'scale'} {...cropData}/>
              </Image>


            :
            <img
              src={this.props.imgSrc}
              role="presentation"/>
          }
          {
            this.props.asset.type === 'video' ?
              <div className="video-poster-button-container">
                <RaisedButton onClick={this.setFrameAsPoster} primary label="Set Frame as Poster"/>
              </div>
              : null
          }
          {
            (this.props.next && this.props.asset.type === 'video') ?
              <div className="next-arrow-container arrow-container is-video">
                <div className="relative-container">
                  <button onClick={this.doNextAsset}>
                    <ChevronRight color="white"/>
                  </button>
                </div>
              </div>
              : null
          }
          {
            (this.props.next && this.props.asset.type !== 'video') ?
              <div className="next-arrow-container arrow-container">
                <div className="relative-container">
                  <button onClick={this.doNextAsset}>
                    <ChevronRight color="white"/>
                  </button>
                </div>
              </div>
              : null
          }
        </div>

        <Dialog
          content="If you exit without saving, your changes will be lost."
          handleConfirm={() => this.proceedToNext(true)}
          confirmLabel="Exit without saving"
          handleCancel={this.closeModalNext}
          cancelLabel="Cancel"
          bodyStyle={{paddingTop: 20}}
          modal={false}
          open={this.state.displayModalChangesNext}
          title="You have unsaved changes"
        />
        <Dialog
          content="If you exit without saving, your changes will be lost."
          handleConfirm={() => this.proceedToPrev(true)}
          confirmLabel="Exit without saving"
          bodyStyle={{paddingTop: 20}}
          handleCancel={this.closeModalPrev}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.displayModalChangesPrev}
          title="You have unsaved changes"
        />
      </div>
    );
  }

  setFrameAsPoster() {
    if (typeof document.getElementById("videoAsset") === 'undefined') return;
    const time = document.getElementById("videoAsset").currentTime;
    if (typeof this.props.saveFrameAsPosterFunction !== 'undefined') {
      this.props.saveFrameAsPosterFunction(time);
    }
  }

}

AssetViewer.defaultProps = {
  cardViewerMaxCardSize: 1800,
  isLoading: false,
  isSaved: true
};


AssetViewer.propTypes = {
  asset: PropTypes.object,
  next: PropTypes.object,
  prev: PropTypes.object,
  imgSrc: PropTypes.string,
  history: PropTypes.object,
  isSaved: PropTypes.bool,
  isLoading: PropTypes.bool,
  saveFrameAsPosterFunction: PropTypes.func,
  setLibraryIsLoading: PropTypes.func,
  revertAssetAction: PropTypes.func,
  copyAssetFunction: PropTypes.func,
  resetScrollAction: PropTypes.func,
  cardViewerMaxCardSize: PropTypes.number
};


export default AssetViewer;
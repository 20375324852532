import React from "react";
import PropTypes from "prop-types";
import Logger from "utils/logger";
import Sidebar from "modules/Sidebar/Sidebar";
import QuestionsList from "./Questions/QuestionsList";
import "./QuizSidebar.scss";


class SectionSidebar extends React.Component {

  updateQuestion() {
    Logger.debug('[SECTION] UpdatingQuestion ...');
  }

  render() {
    return (
      <Sidebar title={this.props.title}
               handleSave={this.updateQuestion}
               confirmRevert={true}
               handleRevert={this.updateQuestion}
               showButtons={true}
               headerSize="medium-height"
               saveLabel="Save"
               revertLabel="Cancel"
               goBackAction={this.props.goBackAction} subheader={this.props.subheader}
               actionBar={this.props.actionBar}>
        {this.props.section === 'questions' ?
          <QuestionsList questions={this.props.data}/>
          : null}
      </Sidebar>
    );
  }

}

SectionSidebar.defaultProps = {};

SectionSidebar.propTypes = {
  title: PropTypes.string,
  section: PropTypes.string,
  goBackAction: PropTypes.string,
  subheader: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  actionBar: PropTypes.element
};


export default SectionSidebar;
import auth0 from "auth0-js";
import Logger from "utils/logger";
import config from "config";
import { clearState, loadState } from "store/localStorage";
import axios from "axios";
import { getPort, getProtocol } from "api";

// I am sure this can be constructed better...

const authConfg = {
  domain: config("authDomain"),
  clientID: config("authClient"),
  audience: config("authAudience"),
  responseType: "token id_token", // the id_token is a JWT that contains profile information
  scope: "openid email offline_access"
};

const webAuth = new auth0.WebAuth(authConfg);

class Auth {
  constructor() {
    this.tokenRenewalTimeout = null;
    this.login = this.login.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);

    //this.expiresAt = undefined; // may want to store int``ernal to this class when it expires as well as in local store?
  }

  login(creds) {
    // TODO:change based on the environment
    creds.realm = config("authRealm", "Login-Test");
    Logger.debug({ creds: {...creds, password:'******'} }, "[AUTH] login() ");
    // since they use a callback, we are going to wrapp it in a promise to be used by the saga...
    return new Promise((resolve, reject) => {
      webAuth.client.login(creds, (err, data) => {     
        if (err) {
          Logger.error(err);
          return reject(err.original || err);
        }
        return resolve(data);
      });
    });
  }

  isAuthenticated(expiresAt) {
    // Check whether the current time is past the

    // access token's expiry time
    // check first if a value was passed, then if there was one stored in the class, finally from local store
    const accessExpiresAt = expiresAt ? expiresAt : loadState().accessExpiresAt;
    Logger.debug({ accessExpiresAt, expiresAt },'[AUTH] - isAuthenticated')
    const currentDate = new Date()
    const currentTime = currentDate.getTime()
    //Logger.debug({ currentDate, currentTime }, "[AUTH] - isAuthenticated");
    const isUserAuthenticated = currentTime < accessExpiresAt;
    const expireDate = new Date(accessExpiresAt)
    Logger.debug({ isUserAuthenticated, expireDate }, "[AUTH] - isAuthenticated");

    // NOTE: return false here to force a newal call
    return isUserAuthenticated;
  }

  getSessionExpirationDuration(){
    let accessExpiresAt = loadState().accessExpiresAt;
    return accessExpiresAt - new Date().getTime();
  }

  startRenewalTimer(expiresIn) {
    Logger.debug( { expiresIn }, "[AUTH] startRenewalTimer() " );
    //const local = loadState();
    //this.tokenRenewalTimeout = setTimeout(this.renewToken, expiresIn);
  }

  stopRenewalTimer() {
    Logger.debug("[AUTH] stopRenewalTimer() ");
    // clear the timeout that auto renews...
    clearTimeout(this.tokenRenewalTimeout);
  }

  renewToken() {
    clearTimeout(this.tokenRenewalTimeout);
    // kick off renewal saga
    Logger.debug("[AuthHelp] renewToken()*****");
  }

  logout() {
    const returnUrl = `${getProtocol()}${config("host")}${getPort()}/`;
    Logger.debug({ returnUrl },"[AUTH] logout()");
    //return new Promise((resolve, reject) => {
    // webAuth.logout(
    //   {
    //     clientID: config("authClient"),
    //     returnTo: returnUrl
    //   }
    // );
    //});
  }
}

export default new Auth();

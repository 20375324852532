/**
* Account Reducer
* each site will be keyed off their ids

*/
import { Map, fromJS } from 'immutable';
import * as CONST from 'actions/action_constants';

/**
 User Reducer
*/
// info about the user of a specifc account (client) - profile?
const user = (state = Map({}), action) => {
  const { data } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { user } = data;
      return state.merge(fromJS(user));

    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props

    default:
      return state; // if action was not passed send state back
  }
};

export default user;

import React, { Component } from 'react';
import ContentContainer from 'modules/ContentContainer';
import { Image } from 'cloudinary-react';
import './Dashboard.scss';
import config from 'config';
// import {loadSiteData} from 'storelocalStorage';

// const siteInfo = loadSiteData();// from local storeage
// @TDOD pul for config
// const listItems = {
//     themeEditor: {
//         label: "Themes",
//         param: "themesr",// db field name
//         type: "navigate",
//         to: "themes",
//     },
//     library: {
//         label: "Asset Library",
//         param: "library",// db field name
//         type: "navigate",
//         to: "library",
//     }
// };

class Dashboard extends Component {
  render() {
    return (
      <div className="cms-screen dashboard">
        {/* <Sidebar title="Dashboard" > */}
        {/* <List items={_values(listItems)} slugBase="" /> */}
        {/* </Sidebar> */}
        <ContentContainer>
          <div className="logo-container">
            <Image cloudName={config('cloudName')} publicId={'system/storycrafter-logo-1.png'} />
          </div>
          {/* <FooterContainer /> */}
        </ContentContainer>
      </div>
    );
  }
}

export default Dashboard;

import { Map } from "immutable";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
import Logger from "utils/logger";
import {
  localizeTextProps,
  localizeTextProps2
} from "helpers/localizationHelper";
import { getSortedItems } from "helpers/cardBrowserHelper";
import * as selectors from "sagas/selectors";
import config from "config";
import { flattenElements } from "helpers/formDataHelper";


/**
 * ProjectEditorMapStateToProps
 * called on the init of the connected Module - StoryBrowser
 * @param {object} state
 * @param {object} ownProps
 */
export const projectEditorMapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const { projectID, section } = params;

  const userPreferencesSelector = ["projectManager", "editor", projectID];
  const userPreferences = state
    .getIn(["ui", "userPreferences", ...userPreferencesSelector], Map({}))
    .toJS();

  const account = state.getIn(["data", "account"], Map);
  const user = state.getIn(["data", "user"], Map);
  const stories = state.getIn(["data", "stories"], Map).toJS();
  const projects = state.getIn(["data", "projects"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map).toJS();
  let projectRevertCopy = state.getIn(["data", "projects", "revertCopy"]);
  if (typeof projectRevertCopy !== "undefined") {
    projectRevertCopy = Map(projectRevertCopy).toJS();
  }
  const assets = state.getIn(["data", "assets"], Map).toJS();
  const projectEditor = state.getIn(["ui", "projectEditor"], Map({})).toJS();
  const mainMenuSceneStructure = state
    .getIn(
      ["ui", "storyEditor", "sceneTypes", "project-story-menu", "structure"],
      Map({})
    )
    .toJS();
  const { cardBrowserConfig, isLoading, to } = projectEditor;
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const storyTypes = state.getIn(["base", "storyTypes"], Map({})).toJS();
  const projectTypes = state.getIn(["base", "projectTypes"], Map({})).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const allThemes = state.getIn(["base", "themes"], Map([])).toJS();

  const { langID } = baseConfig; //
  // const localizedProps = projectEditor.localizedProps || ['title']; // read from storyEditor state, is it based on type???

  const projectData = projects
    ? localizeTextProps2(
        langID,
        projectEditor.forms.settings,
        projects[projectID]
      )
    : null;

  const projectType = selectors.getProjectTypeParam(state, projectData.typeID);
  const projectTypeData = projectType ? projectTypes[projectType] : null;

  /*
  navigation - Elements passed to List component to render a form in the 
    side bar. If the section doesn't have any side bar elements or it has
    custom elements, set navigation to an empty object.
  */
  let navigation = projectTypeData ? projectTypeData.navigation : null;
  let goBackAction = "/projects";
  let sideBarSlugBase = `${goBackAction}/${projectID}`;
  /*
  sidebarData - Data used in sidebar form if one is used. See notes in the
    StoryEditor Sidebar component's componentWillReceiveProps() method for
    more details (/shared/modules/StoryEditor/Sidebar/index.js)
  */
  let sidebarData = _cloneDeep(projectData); // storyData = localizeTextProps(langID, localizedProps, storyData)
  let sideBarTitle = projectData ? projectData.title : "";
  let visibleItems,
    publishForm,
    projectStories = [];
  let structure = null;
  let isFormDataDirty = false; // 7/10/2021 - Need this for main menu, so not managing it for any other data for now.

  if (section) {
    sideBarTitle = section !== "mainmenu" ? section : "Main Menu";
    sideBarSlugBase += `/${section}`;
    goBackAction += `/${projectID}`;

    if (section === "stories" || section === "collections") {
      const storyIDs =
        projects[projectID] && projects[projectID].storyIDs
          ? projects[projectID].storyIDs
          : {};

      _map(storyIDs, (story, index) => {
        const tempStory = stories[story] ? stories[story] : null;
        if (tempStory) {
          // Only need to localize the title because that's the only
          // thing used in the Project Editor.
          projectStories.push({
            ...localizeTextProps(langID, ["title"], tempStory),
            ...tempStory.asset
          });
        }
      });
      navigation = {};
    } else if (section === "design") {
      // navigation = {};
      navigation = projectEditor.forms.design;
      const selectedTheme = {};
      _map(allThemes, theme => {
        if (theme._id === projectData.themeId) {
          selectedTheme._id = theme._id;
          selectedTheme.title = theme.title;
          // selectedTheme.thumb = theme.assets.cmsThumb;
          selectedTheme.thumbCloudPublicId =
            theme.assets.cmsThumb.cloudPublicId;
          selectedTheme.cloudName = config("cloudName");
        }
      });
      sidebarData = { ...projectData, selectedTheme };
    } else if (section === "mainmenu") {
      navigation = projectEditor.forms.mainmenu;
      structure = mainMenuSceneStructure;
      const { storyMenuSceneID } = projectData;
      const projectMenuScene = scenes[storyMenuSceneID];

      isFormDataDirty = projectMenuScene
        ? projectMenuScene.isSaved !== undefined
          ? !projectMenuScene.isSaved
          : false
        : false;

      const projectMenuSceneData = projectMenuScene
        ? flattenElements(
            projectMenuScene.elements,
            projectEditor.forms.mainmenu,
            langID
          )
        : null;

      /*
      NB: All data passed to the form is from the elements -- which 
      is kinda nuts -- except the layoutID which is a root level
      scene setting. It's unfortunate that settings like logo placement
      are scene elements. Those settings should be broken out into
      a separate object, perhaps a setting object.
      */
      sidebarData = {
        layoutID: projectMenuScene ? projectMenuScene.layoutID : null,
        ...projectMenuSceneData
      };
    } else if (section === "publish") {
      navigation = projectEditor ? projectEditor.forms.publish : null;
      sidebarData = {};
    } else if (section === "settings") {
      navigation = projectEditor.forms.settings;
      const settingsAsset = projectData.asset ? projectData.asset : null;
      sidebarData = { ...projectData, ...settingsAsset };
    }
  }
  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}Extra`;

  return {
    accountID: account.get("_id", null),
    username: user.get("email", null),
    isLoading,
    sidebarMode,
    projectID,
    projectType,
    section,
    userPreferencesSelector,
    userPreferences,
    publishForm,
    sidebarProps: {
      title: sideBarTitle,
      navigation,
      structure,
      sidebarData,
      isFormDataDirty,
      section,
      slugBase: sideBarSlugBase,
      goBackAction
    },
    projectItemType: projectType,
    iFrameKey: projectEditor.iFrameKey,
    projectData,
    projectRevertCopy,
    languages,
    langID,

    minCardSize: cardBrowserConfig.cardSizeMultiplier
      ? cardBrowserConfig.cardSizeMultiplier
      : 210,
    settings: state.get("base", Map).toJS(), // need to see if this can just be passed down
    projectItems: projectStories || [],
    storyTypes,
    cardBrowserConfig
    // selectedTheme
  };
};

/**
 * itemSelectorMapStateToProps
 * called on the init of the connected Module
 * @param {object} state
 * @param {object} ownProps
 */
export const itemSelectorMapStateToProps = (state, ownProps) => {
  const { itemType, projectID } = ownProps;
  Logger.debug({ itemType, projectID }, "[PROJECT EDITOR] - itemSelectorMapStateToProps" );
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const projects = state.getIn(["data", "projects"], Map({})).toJS();
  const { langID } = baseConfig;
  let items = [];
  const projectEditor = state.getIn(["ui", "projectEditor"], Map({})).toJS();
  const localizedProps = projectEditor.localizedProps || ["title"];
  const existingIDs = projectID ? projects[projectID].storyIDs : [];
  const storyCollectionType = selectors.getStoryTypeId(state, "collection");
  const isCollection = itemType === "collection";
  items = state.getIn(["data", "stories"]).toJS();
  // switch (itemType) {
  //   case 'story':
  //     items = state.getIn(['data', 'stories']);
  //     break;
  //   case 'collection':
  //     items = state.getIn(['data', 'collections']);
  //     break;
  // }

  const storiesArray = [];
  _map(items, item => {
    const isAlreadyAdded = existingIDs.indexOf(item._id) !== -1;
    if (
      !isAlreadyAdded &&
      item &&
      ((isCollection && item.type === storyCollectionType) ||
        (!isCollection && item.type !== storyCollectionType))
    ) {
      // if (typeof filterByType === 'undefined' || layout.typeID === filterByType) {
      const { asset } = item;
      const isCloudinary = asset && typeof asset.cloudPublicId !== "undefined";
      const localizedItem = localizeTextProps(langID, localizedProps, item);
      storiesArray.push({
        isCloudinary,
        ...asset,
        _id: item._id,
        title: localizedItem.title
      });
    }
  });
  const sortedItems = getSortedItems(storiesArray, "title", "asc");

  return {
    items: sortedItems,
    open: ownProps.open,
    storyID: ownProps.storyID,
    slideId: ownProps.slideId,
    isEditMode: ownProps.isEditMode,
    activeLayout: ownProps.activeLayout
  };
};

/**
 * Story Categories Browser Reducer
 */
import { fromJS, Map } from "immutable";
import * as CONST from "actions/action_constants";
import _merge from "lodash/merge";

const storyCategoriesBrowser = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.STORY_CATEGORY_CREATE_SUCCESS:
      return state.set("navigateToNew", action.categoryData._id);

    case CONST.STORY_CATEGORY_EDITOR_DATA_INITIALIZED:
      return state.remove("navigateToNew");

    default:
      return state;
  }
};

export default storyCategoriesBrowser;

import { Map } from 'immutable';
import _map from 'lodash/map';
import Logger from "utils/logger";

export const analyticsMapStateToProps = (state, ownProps) => {
  const sidebarMode = `${state.getIn(['base', 'mainSidebar', 'mode'])}`;
  const account = state.getIn(['data', 'account'], Map).toJS();
  const analyticsData = state.getIn(['data', 'analytics', 'data'], Map({})).toJS();
  const analyticsDataPerLang = state.getIn(['data', 'analytics', 'dataPerLang'], Map({})).toJS();
  const analyticsDataPerWeek = state.getIn(['data', 'analytics', 'dataPerWeek'], Map({})).toJS();
  const analyticsPopularContent = state
    .getIn(['data', 'analytics', 'dataPopularContent'], Map({}))
    .toJS();
  const filters = state.getIn(['ui', 'analytics', 'filters'], Map({})).toJS();
  const filterOptions = state.getIn(['ui', 'analytics', 'filterOptions'], Map({})).toJS();

  // Calculating and building Data per Language
  const langColumns = [];
  _map(analyticsDataPerLang, (item) => {
    Logger.debug({ item }, "analyticsMapStateToProps: item=" )
    langColumns.push([item._id.languageID, item.count]);
  });

  const langData =
    langColumns.length > 0
      ? {
        data: {
          columns: langColumns,
          type: 'donut',
        },
      }
      : null;

  // Building object data per Week
  const weekValues = ['Sessions'];
  const weekLabels = ['x'];
  _map(analyticsDataPerWeek, (item) => {
    const itemLabel = item._id.week
      ? item._id.week
      : item._id.day ? item._id.day : item._id.month ? item._id.month : '';
    weekLabels.push(itemLabel);
    weekValues.push(item.count);
  });

  const weekColumns = [weekLabels, weekValues];

  // Building Popular Content
  const popularContentValues = [filters.popularContentGroup];
  const popularContentLabels = [];
  _map(analyticsPopularContent, (item) => {
    Logger.debug({item, popularContent: filters.popularContent}, 'Checking popular content');
    const itemLabel = item._id[filters.popularContent]
      ? item._id[filters.popularContent]
      : 'Untitled';
    popularContentLabels.push(itemLabel);
    const value =
      filters.popularContentGroup === 'Sessions'
        ? item.count
        : Math.round(item.duration_avg / 1000);
    popularContentValues.push(value);
  });

  const popularContentData = {
    popularContentBy: filters.popularContent,
    popularContentGroup: filters.popularContentGroup,
    data: {
      columns: [popularContentValues],
      types: {
        [filters.popularContentGroup]: 'bar',
      },
    },
    axis: {
      rotated: true,
    },
  };

  const weekData =
    weekColumns.length > 0
      ? {
        data: {
          x: 'x',
          columns: weekColumns,
        },
        axis: {
          x: {},
        },
        sessionsBy: 'Week',
      }
      : null;

  // Now define the axis data based on filter type.
  switch (filters.sessions) {
    case 'day':
      weekData.axis.x.type = 'timeseries';
      weekData.axis.x.tick = {
        value: weekColumns[0].slice(1),
      };
      break;

    case 'week':
    case 'month':
      weekData.axis.x.type = 'category';
      weekData.axis.x.categories = weekColumns[0].slice(1);
      weekData.data.columns.shift();
      delete weekData.data.x;
      break;
  }

  const sampleAnalyticsData = {
    language: {
      data: {
        columns: [['English', 80], ['Spanish', 20]],
        type: 'donut',
      },
    },
    sessions: {
      sessionsBy: 'Week',
      data: {
        columns: [['Sessions', 30, 200, 100, 400, 150, 250]],
      },
    },
    popularContent: {
      popularContentBy: 'Projects',
      populatContentGroup: 'Sessions',
      data: {
        columns: [['Sessions', 30, 200, 100, 400, 150, 250]],
        types: {
          Sessions: 'bar',
        },
      },
      axis: {
        rotated: true,
      },
    },
  };

  return {
    account,
    sidebarMode,
    analyticsData,
    langData,
    weekData,
    filters,
    filterOptions,
    popularContentData,
    popularContentLabels,
  };
};

import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants'; // fromJS,

/** 
* ANALYTICS REDUCER
* 
*/
const analyticsEditor = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { analytics } = action; // ,
      return state.merge(fromJS(analytics));
    case CONST.ANALYTICS_SUCCESS:
      const { filters } = action;
      return state.set('filters', fromJS({ ...filters }));
    default:
      return state;
  }
};

export default analyticsEditor;

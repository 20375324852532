/* eslint-disable global-require */

import React from "react";
import { render } from "react-dom";

import BrowserRouter from "react-router-dom/BrowserRouter";
import asyncBootstrapper from "react-async-bootstrapper";
import { AsyncComponentProvider } from "react-async-component";
import { Provider as ReduxProvider } from "react-redux";

import Logger from "utils/logger"

import ReactHotLoader from "./components/ReactHotLoader";
import { clientSideConfig } from "config"

import "./polyfills";

import { authCheck } from "actions";
//import { loadSiteData } from "store/localStorage";
import configureStore from "store/configureStore";
import AppConn from "modules/App";

Logger.debug({ clientSideConfig, IS_LOCAL:process.env.IS_LOCAL, BUILD_ENV:process.env.BUILD_ENV, NODE_ENV:process.env.NODE_ENV },'[APP] index.js buildtime values')


// Get the DOM Element that will host our React application.
const container = document.querySelector("#app");

// Does the user's browser support the HTML5 history API?
// If the user's browser doesn't support the HTML5 history API then we
// will force full page refreshes on each page change.
const supportsHistory = "pushState" in window.history;

const store = configureStore();

// Initializing app and setting initial State, since it isnt being done server side right now
// we want to wait and do this after authenitication
store.dispatch(authCheck());

// NOTE: dont need this, it will all be based on the account
//const siteInfo = loadSiteData(); // from local storeage
const routeBase = ""; //siteInfo.routeBase;

// Get any rehydrateState for the async components.
// eslint-disable-next-line no-underscore-dangle
const asyncComponentsRehydrateState =
  window.__ASYNC_COMPONENTS_REHYDRATE_STATE__; // TODO: thus may no longer be needed


/**
 * Renders the given React Application component.
 */
function renderApp(TheApp) {
  // Firstly, define our full application component, wrapping the given
  // component app with a browser based version of react router.
  const app = (
    <ReactHotLoader>
      <AsyncComponentProvider rehydrateState={asyncComponentsRehydrateState}>
        <BrowserRouter forceRefresh={!supportsHistory}>
          <ReduxProvider store={store}>
            <TheApp />
          </ReduxProvider>
        </BrowserRouter>
      </AsyncComponentProvider>
    </ReactHotLoader>
  );

  // We use the react-async-component in order to support code splitting of
  // our bundle output. It's important to use this helper.
  // @see https://github.com/ctrlplusb/react-async-component
  asyncBootstrapper(app).then(() => render(app, container));
}

// Execute the first render of our app.
renderApp(AppConn);

// This registers our service worker for asset caching and offline support.
// Keep this as the last item, just in case the code execution failed (thanks
// to react-boilerplate for that tip.)
//require("./registerServiceWorker");

// May not need this if using webpack watch...
// The following is needed so that we can support hot reloading our application.
// TODO: need to understand connection with the ReactHotLoader.js
if (process.env.IS_LOCAL && module.hot) {
  // Accept changes to this file for hot reloading.
  Logger.info('[CMS] setting up extra config for module.hot')
  module.hot.accept("./index.js");
  // Any changes to our App will cause a hotload re-render.
  module.hot.accept("modules/App", () => {
    renderApp(require("modules/App").default);
  });
}

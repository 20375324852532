import React from "react";
import PropTypes from "prop-types";
import Plus from "material-ui/svg-icons/content/add";
import {InputText, Toggle} from "@terraincognita/ui-core";
import "./QuestionEditor.scss";
import styles from "./QuestionEditor.scss";


class Answer extends React.Component {

  handleSelectImage() {
    alert("Here we select Image from Image Browser");
  }

  render() {
    const titleInputStyle = {
      color: styles.accentColor,
      fontSize: 24
    };
    const scoreInputStyle = {
      width: 50,
      leftMargin: 100
    };
    const subtitleInputStyle = {
      fontSize: 14
    };
    return (
      <div className="question-answer">
        <div className="image-container"
             style={this.props.image ? {backgroundImage: 'url(' + this.props.image.src + ')'} :
             {background: "#f5f5f5"}}
             onClick={this.handleSelectImage}>
          <Plus className="plus-symbol" color={this.props.image ? "white" : "#bbb"}/>
        </div>
        <div className="answers-container">
          <InputText valueFromInput={this.props.title} inputStyle={titleInputStyle} name="title"/>
          <InputText valueFromInput={this.props.subtitle} inputStyle={subtitleInputStyle} name="subtitle"
                     multiLine={true} label="Subtitle"/>
        </div>
        <div style={{clear: 'both'}}/>
        <div className="score-container">
          <InputText valueFromInput={this.props.score} name="score" label="Score" inputStyle={scoreInputStyle}/>
          <div className="toggle-container">
            <Toggle label="Is Correct" name="isCorrect" toggled={this.props.isCorrect}/>
          </div>
        </div>
      </div>
    );
  }

}

Answer.defaultProps = {};

Answer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isCorrect: PropTypes.bool,
  image: PropTypes.object

};


export default Answer;
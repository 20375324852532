import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Logger from "utils/logger";
import {feedbackBrowserMapStateToProps} from "mapToProps/feedback";
import * as feedbackActions from "actions/feedback";
import RaisedButton from "material-ui/RaisedButton";
import Sidebar from "modules/Sidebar/Sidebar";
import _map from "lodash/map";
import ContentContainer from "modules/ContentContainer";
import {TableGrid, Dialog} from "@terraincognita/ui-core";
import {Helmet} from "react-helmet";
import "./FeedbackBrowser.scss";

/**
 * FeedbackBrowser
 * browser for feedback items
 */
class FeedbackBrowser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      deleteModalOpen: false
    };
    this.navigateToFeedbackItem = this.navigateToFeedbackItem.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  openDeleteModal() {
    Logger.debug('[FEEDBACK BROWSER] - openDeleteModal');
    this.setState({deleteModalOpen: true});
  }

  closeDeleteModal() {
    this.setState({deleteModalOpen: false});
  }

  proceedToDelete() {
    this.setState({deleteModalOpen: false});
    const selected = [];
    _map(this.state.selected, (item) => {
      selected.push(this.props.feedback[item+1].id);
    });
    if(selected.length > 0){
      this.props.deleteFeedback(selected);
    }
  }

  render() {
    const {sidebarProps, sidebarMode} = this.props;

    const topBar = (
      <div className="filters-bar-container">
        {this.state.selected.length > 0 ? (
          <RaisedButton
            id="delete-button"
            primary
            className="new-asset-button"
            label="DELETE"
            labelStyle={{textTransform: 'none'}}
            onTouchTap={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.openDeleteModal();
            }}
          />
        ) : null}
      </div>
    );

    const headerStyle = {
      backgroundColor: '#f2f2f2',
    };

    const header = this.props.header
      ? this.props.header
      : {
      1: {
        id: 1,
        label: "Submitter’s Name",
      },
      2: {
        id: 2,
        label: "Person Recognized",
      },
      3: {
        id: 3,
        label: "Date Submitted",
      },
      4: {
        id: 4,
        label: "",
      },
    };

    return (<div className="feedback-browser-container">
      <Helmet>
        <title>Story Editor</title>
      </Helmet>
      <Sidebar {...sidebarProps}>
        <div className="feedback-browser-sideback"/>
      </Sidebar>
      <ContentContainer
        backgroundColor="white"
        className="content-container"
        sidebarMode={sidebarMode}
        topBar={topBar}
      >
        {this.props.feedback ? (
          <TableGrid
            items={this.props.feedback}
            header={header}
            idNode="id"
            handleRowSelectionFunc={this.handleRowSelection}
            headerStyle={headerStyle}
            multiSelectable
            viewAction={this.navigateToFeedbackItem}
            sortable
          />
        ) : null}
      </ContentContainer>
      <Dialog
        content="Once deleted, these feedback item cannot be restored"
        handleConfirm={this.proceedToDelete}
        confirmLabel="Delete"
        handleCancel={this.closeDeleteModal}
        bodyStyle={{paddingTop: 20}}
        cancelLabel="Cancel"
        modal={false}
        open={this.state.deleteModalOpen}
        title={`Delete feedback item(s)?`}
      />
    </div>);
  }

  handleRowSelection(rows) {
    this.setState({selected: rows});
  }

  navigateToFeedbackItem(itemID) {
    this.props.goToFeedbackItem(itemID);
  }

  componentDidMount() {
    this.props.requestFeedback();
  }

}

function mapStateToProps(state, ownProps) {
  return feedbackBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {slugBase} = stateProps;
  return Object.assign({}, stateProps, {
    requestFeedback: () => {
      return dispatchProps.requestFeedback()
    },
    goToFeedbackItem: (feedbackID) => {
      ownProps.history.push(`${slugBase}/${feedbackID}`);
    },
    deleteFeedback: (selected) => {
      return dispatchProps.deleteFeedback(selected)
    }
  });
}

FeedbackBrowser.defaultProps = {};

FeedbackBrowser.propTypes = {
  feedback: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};


export default connect(mapStateToProps, feedbackActions, mergeProps)(
  FeedbackBrowser,
);
import React from "react";
import {MenuItem} from "@terraincognita/ui-core";
import _map from "lodash/map";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import ChevronRight from "material-ui/svg-icons/navigation/chevron-right";
import ChevronLeft from "material-ui/svg-icons/navigation/chevron-left";
import "./MainSidebar.scss";
import styles from "./MainSidebar.scss";
const iconColor = styles.iconColor;

export class MainSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
  }

  handleSidebarToggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleAction();
  }

  render() {
    const homeTo = this.props.logo && this.props.logo.to ? this.props.logo.to : '/;'
    const iconStyleProps = {
      iconStyle: {
        width: 34,
        height: 34,
        color: iconColor
      },
      style: {
        width: 60,
        height: 60,
        padding: 1,
      }
    };

    let itemsToBeOrdered = [];
    let itemsNotOrdered = [];
    let orderedTotal = [];
    _map(this.props.navigation, item => {
      if(typeof item.order !== 'undefined'){
        itemsToBeOrdered.push(item);
      }else{
        itemsNotOrdered.push(item);
      }
    });
    itemsToBeOrdered.sort((a,b) => (a.order - b.order));
    _map(itemsToBeOrdered, item => {
      orderedTotal.push(item);
    });
    _map(itemsNotOrdered, item => {
      orderedTotal.push(item);
    });

    return (
      <div className={`main-sidebar ${ (this.props.mode === 'large' ? 'expanded' : 'collapsed') }`}>
        <div className="logo-container">
          <Link to={homeTo}>
            {
              (this.props.mode === 'large') ?
                <h1>{this.props.logo.textLarge}</h1>
                :
                <h1>{this.props.logo.textSmall}</h1>

            }
          </Link>
        </div>
        <div className="main-menu">
          {orderedTotal && _map(orderedTotal, item => (
              <MenuItem key={item.param || item.label}
                        preventLink={!this.props.isSaved}
                        preventLinkMessage="If you exit without saving, your changes will be lost."
                        activeNav={this.context.router.route.location.pathname}
                        {...item}
                        mode={this.props.mode}/>
            )
          )}
        </div>

        <div className="bottom-navigation">
          <div className="site-name-container">
            {
              (this.props.mode === 'large') ?
                this.props.siteName
                :
                null
            }
          </div>
          <div className="bottom-menu">
            {this.props.bottomNavigation && _map(this.props.bottomNavigation, item => (
                <MenuItem key={item.param || item.label}
                          {...item}
                          preventLink={!this.props.isSaved}
                          preventLinkMessage="If you exit without saving, your changes will be lost."
                          activeNav={this.context.router.route.location.pathname}
                          smallIcons={true}
                          mode={this.props.mode}/>
              )
            )}
          </div>
          <div className="sidebar-toggle" onClick={this.handleSidebarToggle}>
            <IconButton
              {...iconStyleProps}
              onClick={this.handleSidebarToggle}>
              {
                (this.props.mode === 'large') ?
                  <ChevronLeft /> :
                  <ChevronRight />
              }
            </IconButton>
            {
              (this.props.mode === 'large') ?
                <div className="toggle-label">Close Menu</div>
                : null
            }
          </div>
        </div>


      </div>
    )
  }
}

MainSidebar.contextTypes = {
  router: PropTypes.object,
};

MainSidebar.defaultProps = {
  mode: 'large',
  logo: {textLarge: '', textSmall: ''},
  isSaved: true
};

MainSidebar.propTypes = {
  mode: PropTypes.oneOf(['large', 'small']),
  logo: PropTypes.object,
  isSaved: PropTypes.bool,
  siteName: PropTypes.string,
  bottomNavigation: PropTypes.object,
  navigation: PropTypes.object
};


export default MainSidebar;
import { fromJS, Map } from "immutable";
import Logger from "utils/logger";
import * as CONST from "actions/action_constants";
import _without from "lodash/without";
import { localizeTextProps } from "helpers/localizationHelper";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
/**
 * Scenes Sub Reducer
 * holds an object of each stories data
 */

/**
 * setElementValue
 * Function called from the Scene Reducer
 */
const setElementValue = (scene, action) => {
  const { property, elementID, sceneID } = action;
  let value = action.value;

  // const stateJs = state.toJS();//scenes

  // Correcting Quill RTE empty value
  if (value === "<p><br></p>") value = "";

  // set defualts...
  // if there isnt an existing scene
  if (typeof scene === "undefined") {
    scene = {
      elements: {
        [elementID]: {
          [property]: value
        }
      }
    };
  }

  if (typeof scene.elements === "undefined") {
    scene.elements = {
      [elementID]: {
        [property]: value
      }
    };
  }

  if (typeof scene.elements[elementID] === "undefined") {
    scene.elements[elementID] = {
      [property]: value
    };
  }

  scene.elements[elementID][property] = value;

  return scene;
};

const scenes = (state = Map({}), action) => {
  const {
    storyScenes,
    projectMenuScenes,
    sceneID,
    elementID,
    newSubsceneIDs,
    layoutId,
    parentSceneID,
    selected,
    sceneType,
    subsceneType
  } = action;
  // NOTE: need to eventually change all references form slideData to sceneData
  let scene, tmpScene, subscenesKey;
  switch (action.type) {
    case CONST.STORY_DATA_SUCCESS:
      return state.merge(fromJS(storyScenes));

    case CONST.PROJECT_BROWSER_SUCCESS:
      return state.merge(fromJS(projectMenuScenes));

    case CONST.SCENE_CREATE_SUCCESS:
      return state.merge(fromJS(action.sceneData));

    case CONST.SUBSCENE_CREATE_SUCCESS:
      // Add new subsceneIDs to parent scene.
      const parentScene = state.get(parentSceneID).toJS();
      subscenesKey = action.isRotationGallery
        ? "rotationSubScenes"
        : "subScenes";
      if (!parentScene[subscenesKey]) parentScene[subscenesKey] = []; // incase this is the first
      _map(newSubsceneIDs, (sceneID, key) => {
        parentScene[subscenesKey].push(sceneID);
      });

      /*
      Update the parent scene in the store and add the new 
      subscene to the store.
      
      NOTE: Don't reference sceneData directly. It's undefined
      for some inexplicable reason.
      */
      return state
        .set(parentSceneID, fromJS(parentScene))
        .merge(fromJS(action.sceneData));

    case CONST.STORY_EDITOR_ADD_SCENE_SUCCESS:
      return state.merge(fromJS(action.slideData));

    case CONST.SCENE_CREATE_REVERT_COPY:
      const tempData = action.slideData || action.sceneData;
      return state.set("revertCopy", fromJS(tempData));

    case CONST.SCENE_CREATE_REVERT_COPY_FROM_ID:
      return state.set("revertCopy", state.get(action.sceneID));

    case CONST.SCENE_UPDATE_SUCCESS:
      const updatedScene = state
        .get(sceneID)
        .merge(fromJS(action.sceneData))
        .set("isSaved", true);
      return state.set(sceneID, updatedScene).set("revertCopy", updatedScene);

    case CONST.STORY_QUESTION_SCENE_SAVE_SUCCESS:
      const updatedScene2 = state
        .get(sceneID)
        .merge(fromJS(action.sceneData))
        .set("isSaved", true);
      return state.set(sceneID, updatedScene2).set("revertCopy", updatedScene2);

    case CONST.SCENE_DELETE_SUCCESS:
      // NOTE - this is called in a loop, do we need to leverage selected, since multiple can be removed?
      return state.delete(sceneID);

    case CONST.SUBSCENE_DELETE_SUCCESS:
      subscenesKey = action.isRotationGallery
        ? "rotationSubScenes"
        : "subScenes";
      const parentSubScenes = state.getIn([parentSceneID, subscenesKey]).toJS();
      const newSubScenes = _without(parentSubScenes, ...selected);
      return state
        .setIn([parentSceneID, subscenesKey], fromJS(newSubScenes))
        .delete(sceneID);

    case CONST.SCENE_REVERT:
      const revertData = state.get("revertCopy").toJS();
      const revertSceneID = revertData._id;
      return state.set(revertSceneID, fromJS(revertData));

    case CONST.SCENE_SET_SUBSCENE_ORDER_SUCCESS:
      const updatedSubScene = state
        .get(sceneID)
        .merge(fromJS(action.sceneData));
      return state.set(sceneID, updatedSubScene);

    case CONST.STORY_EDITOR_SET_ELEMENT_VALUE:
      tmpScene = sceneID ? state.get(sceneID).toJS() : null;
      scene = setElementValue(tmpScene, action);
      return state.set(sceneID, fromJS(scene));

    case CONST.SCENE_SET_ASSET_ELEMENT_SUCCESS:
      const assetConfig = action.assetConfig ? action.assetConfig : {};

      if (typeof elementID === "object") {
        const newAsset = { assetID: action.assetID };
        if (action.assetID !== null) {
          newAsset.assetType = action.assetType;
          newAsset.type = "asset";
          newAsset.use = "asset";
          newAsset.cloudName = action.cloudName;
          newAsset.cloudPublicId = action.cloudPublicId;
        }

        const scene = state.get(sceneID).toJS();
        scene.elements[elementID.param].list[parseInt(elementID.row)].asset =
          newAsset;

        return state
          .setIn([sceneID], fromJS(scene))
          .setIn([sceneID, "isSaved"], false);
      }

      return state
        .setIn([sceneID, "elements", elementID, "assetID"], action.assetID)
        .setIn([sceneID, "elements", elementID, "cloudName"], action.cloudName)
        .setIn(
          [sceneID, "elements", elementID, "cloudPublicId"],
          action.cloudPublicId
        )
        .setIn([sceneID, "elements", elementID, "assetConfig"], assetConfig)
        .setIn([sceneID, "elements", elementID, "assetType"], action.assetType)
        .setIn([sceneID, "isSaved"], false);

    case CONST.SCENE_STYLE_SETTING_CHANGE:
      const { level, area, name, value } = action;
      const stylesSelector = area
        ? [sceneID, "styles", level, area, name]
        : [sceneID, "styles", level, name];
      return state.setIn(stylesSelector, value);

    case CONST.SCENE_SET_INPUT_ELEMENT_REQUEST:
      /*
      Determine param type by looking at the form. Note that in 
      some cases, this isn't practical, but that's OK because in 
      the cases where we need the param type, it's available.
      
      In most cases, the action property will be a string. But 
      when a slideshow scene element is being updated, it will
      be an array.
      
      TO DO: Streamline so that the param type can be determined 
      in all cases.
      */
      const formElement =
        typeof action.property === "object"
          ? action.property[1]
          : action.property;
      const paramType =
        typeof action.form !== "undefined"
          ? typeof action.form[formElement] !== "undefined"
            ? action.form[formElement].type
            : null
          : null;

      let selector = null;

      if (action.isRoot) {
        selector = [sceneID, action.property];
      } else {
        selector = [sceneID, "elements"];

        switch (paramType) {
          case "assetEditor":
            selector.push(action.property);
            break;

          case "mapboxEditor":
            selector.push(action.property, "config");
            break;

          case "checkbox":
          case "select":
          case "thumbSelect":
            /*
            Most properties are going to be strings. If so, that indicates 
            this is a stand-alone element with a value property. Add the 
            property name to the selector array plus the value property.
            */
            if (typeof action.property === "string") {
              selector.push(action.property, "value");

              /*
            However, the Gallery Asset Editor and the Slideshow Scene Element 
            Editor define the property as an array. The first item in the array 
            is the elementID. The second item will be the element property being
            altered.
            */
            } else if (typeof action.property === "object") {
              selector.push(action.property[0], action.property[1]);
            }

            break;

          case "select-multiple":
            selector.push(action.property, "value");
            break;

          case "text":
          case "textarea":
          case "rte":
          case "rte-ext":
          case "rte-ext-class":
          case "number":
            /*
            Most properties are going to be strings. Add the property name
            to the selector array plus the text property since all text elements
            have a text prop.
            */
            if (typeof action.property === "string") {
              selector.push(action.property, "text");

              /*
            However, the Gallery Asset Editor and the Slideshow Scene Element 
            Editor define the property as an array. The first item in the array 
            is the elementID. If a text element is being altered, the second 
            item will be "text". If, however, a meta config property of an asset
            element is being altered, the second item will be the asset meta 
            config prop that's being altered.
            
            This is messy, and ideally should be simplified.
            */
            } else if (typeof action.property === "object") {
              selector.push(action.property[0]);
              if (action.isAssetMetaconfig) {
                selector.push("metaConfig");
              }
              selector.push(action.property[1]);
            }

            if (action.isLocalized) {
              selector.push(action.language);
            }

            break;

          case "partialDate":
            selector.push(action.property, Object.keys(action.value)[0]);
            break;

          case "multiForm":
            selector.push(action.property, "list");
            break;

          default:
            Logger.error(`WARNING: Form type (${paramType}) not defined in reducer for ${CONST.SCENE_SET_INPUT_ELEMENT_REQUEST}`);
        }
      }

      /*
      Update the selector in the store. Unless we're processing an update 
      to an ordered-list element, we can use the passed value as-is with
      the exception of partial dates.
      */
      if (!(action.property === "feedback" || action.property === "options")) {
        const finalValue =
          paramType && paramType === "partialDate"
            ? action.value[Object.keys(action.value)[0]]
            : action.value;

        return state
          .setIn(selector, finalValue)
          .setIn([sceneID, "isSaved"], false);

        /*
      NOTE: We only proceed to the logic below if the value being
      updated isn't feedback or options.

      For ordered-list elements, we have to grab a copy of the current
      element, loop over the action values and define the final data
      accordingly. Note that text element props other than "text" are
      obliterated by this update. It's not a problem because a save
      and a revert restore those props. But it seems like it would be
      best of those props weren't obliterated.
      */
      }
      const tempInputValue = _cloneDeep(action.value);
      const sceneData = _cloneDeep(state.get(sceneID).toJS());
      const existingFeedback =
        typeof sceneData.elements[action.property].list !== "undefined"
          ? sceneData.elements[action.property].list
          : [];
      const finalData = [];
      _map(tempInputValue, (item, key) => {
        const inputValueText = item.title;
        const existingText =
          typeof existingFeedback[key] !== "undefined"
            ? existingFeedback[key].title.text
            : {};
        const newText = { ...existingText, [action.language]: inputValueText };
        finalData[key] = { ...item, title: { text: newText } };
      });

      return state
        .setIn(selector, finalData)
        .setIn([sceneID, "isSaved"], false);

    case CONST.SCENE_SET_IS_SAVED:
      return state.setIn([sceneID, "isSaved"], action.isSaved);

    case CONST.STORY_DELETE_SUCCESS:
      const { storyID } = action;
      // remove any scenes associated with a story...
      return state.filter(scene => scene.get("storyID") !== storyID);

    case CONST.LOGOUT_SUCCESS:
      return state.clear(); // need to clear out all props
    default:
      return state;
  }
};

export default scenes;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List } from '@terraincognita/ui-core';
import _debounce from 'lodash/debounce';
import Logger from "utils/logger";
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import IconButton from 'material-ui/IconButton';
import LeftChevron from 'material-ui/svg-icons/navigation/chevron-left';
import RightChevron from 'material-ui/svg-icons/navigation/chevron-right';
import ContentContainer from 'modules/ContentContainer';
import { feedbackViewerMapStateToProps } from 'mapToProps/feedback';
import * as feedbackActions from 'actions/feedback';
import ReactCrop from 'react-image-crop';
import './FeedbackViewer.scss';
import 'react-image-crop/lib/ReactCrop.scss';
import styles from './FeedbackViewer.scss';

export class FeedbackViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crop:
        this.props.feedbackCropData && this.props.assetData
          ? this.getPercentageCropData(this.props.feedbackCropData, this.props.assetData)
          : null,
      pixelCrop: null,
      data: this.props.feedbackData ? this.props.feedbackData.elements : null,
    };
    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (baseParam, param, value) {
      this.handleInputChanges.apply(this, [baseParam, param, value]);
    }, 500);
    this.processCropAction = this.processCropAction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      crop: this.getPercentageCropData(nextProps.feedbackCropData, nextProps.assetData),
    });
    this.setState({
      data:
        nextProps.feedbackData && nextProps.feedbackData.elements
          ? nextProps.feedbackData.elements
          : null,
    });
  }

  proceedToLink(link) {
    this.props.history.push(link);
  }

  getPercentageCropData(feedbackCropData, assetData) {
    const realWidth = assetData.assetConfig.cropCoordinates
      ? assetData.assetConfig.cropCoordinates.width
      : assetData.width;
    const realHeight = assetData.assetConfig.cropCoordinates
      ? assetData.assetConfig.cropCoordinates.height
      : assetData.height;
    if (realWidth && realHeight) {
      const x = feedbackCropData.x * 100 / realWidth;
      const y = feedbackCropData.y * 100 / realHeight;
      const newWidth = feedbackCropData.width * 100 / realWidth;
      const newHeight = feedbackCropData.height * 100 / realHeight;
      return { x, y, width: newWidth, height: newHeight };
    }
    return null;
  }

  render() {
    const { feedbackData, assetCropData, assetData } = this.props;
    Logger.debug({ props: this.props }, '[FEEDBACK VIEWER]');
    const topBar = (
      <div className="feedback-gallery-editor-topbar">
        <div className="scene-navigation-component">
          {this.props.goPrevURL ? (
            <IconButton
              style={{ paddingTop: 23, float: 'left', marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.proceedToLink(this.props.goPrevURL)}
            >
              <LeftChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : null}
          <div className="slide-numbers-container">
            {this.props.curSlideNo ? this.props.curSlideNo : null}
            &nbsp; of &nbsp;
            {this.props.totalSlideNo ? this.props.totalSlideNo : null}
          </div>
          {this.props.goNextURL ? (
            <IconButton
              style={{ paddingTop: 23, float: 'left', marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.proceedToLink(this.props.goNextURL)}
            >
              <RightChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : (
            <div className="empty-arrow" />
          )}
        </div>
      </div>
    );

    const feedbackAssetCloudSrc = assetData
      ? `//res.cloudinary.com/dgpzfuyzy/image/upload/${assetCropData
        ? `c_crop,h_${assetCropData.height},w_${assetCropData.width},x_${assetCropData.x},y_${assetCropData.y}/`
        : ''}f_auto,q_auto,c_scale/v1/${assetData.cloudPublicId}`
      : undefined;

    return (
      <div className="feedback-gallery-event-editor">
        <Helmet>
          <title>Feedback Gallery Editor</title>
        </Helmet>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="quiz-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.slugBase}
          headerTheme="light"
          title={feedbackData ? feedbackData.title : 'Loading...'}
          headerMode="extended-extra"
          topBar={topBar}
        >
          <div className="feedback-gallery-editor-container">
            <List
              styleName="plain"
              data={this.state.data ? this.state.data : null}
              items={this.props.form}
              handleInputChange={(param, value) =>
                this.debounceInputChange('feedback', param, value)}
            />
          </div>
          <div className="feedback-gallery-asset-container">
            {feedbackAssetCloudSrc ? (
              <ReactCrop
                src={feedbackAssetCloudSrc}
                onChange={(crop, pixelCrop) => {
                  this.setState({ crop, pixelCrop });
                }}
                disabled
                crop={this.state.crop}
              />
            ) : null}
          </div>
        </ContentContainer>
      </div>
    );
  }

  processCropAction() {
    const assetData = this.props.feedbackData.elements && this.props.feedbackData.elements.asset;
    const cropData = this.state.crop;
    const x = Math.round(cropData.x * assetData.width / 100);
    const y = Math.round(cropData.y * assetData.height / 100);
    const newWidth = Math.round(cropData.width * assetData.width / 100);
    const newHeight = Math.round(cropData.height * assetData.height / 100);

    const result = {
      cropCoordinates: {
        x,
        y,
        width: newWidth,
        height: newHeight,
      },
    };
    Logger.debug({ cropData, result },'[FEEDBACK VIEWER] processCropAction');
  }

  handleInputChanges(baseParam, param, value) {
    const data = this.state.data;
    if (typeof value === 'object') {
      data[param] = { ...data[param], ...value };
      this.setState({ isDirty: true });
      this.props.storySetIsSaved(false);
    } else if (data[param] !== value) {
      data[param] = value;
      this.setState({ isDirty: true });
      this.props.storySetIsSaved(false);
    }
    this.setState({ data });
  }

  componentDidMount() {
    this.props.requestFeedback();
  }
}

FeedbackViewer.contextTypes = {
  router: PropTypes.object,
};

FeedbackViewer.defaultProps = {};

FeedbackViewer.propTypes = {
  assetData: PropTypes.object,
  feedbackData: PropTypes.object,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  percCropData: PropTypes.object,
  elementID: PropTypes.string,
  scenes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugBase: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return feedbackViewerMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { storyID, sceneID, slugBase, activeEditingSubScene, form } = stateProps;
  return Object.assign({}, stateProps, {
    requestFeedback: () => dispatchProps.requestFeedback(),
  });
}

export default withRouter(connect(mapStateToProps, feedbackActions, mergeProps)(FeedbackViewer));

import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants'; // fromJS,

/** **************************************
 * CONTET EDITOR REDUCER
*************************************** */
const contentEditor = (state = Map({}), action) => {
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { contentEditor } = action; // ,
      return state.merge(fromJS(contentEditor));
    default:
      return state;
  }
};

export default contentEditor;

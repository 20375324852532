import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants';

/* QUIZ */
const quiz = (state = Map({}), action) => {
  const { questionData, quizEditorData, questionIndex } = action;
  switch (action.type) {
    // RECEIVING QUIZ
    case CONST.QUIZ_EDITOR_SUCCESS:
      return state.merge(fromJS(quizEditorData));

    case CONST.COPY_QUESTION:
      const { force } = action;
      const currCopy = state.get('revertCopy', Map()).toJS();
      // Comparing QUESTION DATA - that something has changed
      let hasChanged = Object.keys(currCopy).length === 0;
      for (const key in currCopy) {
        if (questionData[key] !== currCopy[key]) {
          hasChanged = true;
        }
      }
      if (!force && !hasChanged) {
        return state;
      }
      return state.set('revertCopy', fromJS(questionData));

    case CONST.PREPARE_ADD_QUESTION:
      return state.delete('revertCopy');

    default:
      return state;
  }
};

export default quiz;

import { fromJS, Map } from 'immutable';
import Logger from "utils/logger";
import * as CONST from 'actions/action_constants';

const getClass = obj => Object.prototype.toString.call(obj);

// NOTE: might be able to leverage _isEqual ??
const objectTester = (a, b) => {
  // If a and b reference the same value, return true
  if (a === b) return true;

  // If a and b aren't the same type, return false
  if (typeof a !== typeof b) return false;

  // Already know types are the same, so if type is number
  // and both NaN, return true
  if (typeof a === 'number' && isNaN(a) && isNaN(b)) return true;

  // Get internal [[Class]]
  const aClass = getClass(a);
  const bClass = getClass(b);

  // Return false if not same class
  if (aClass != bClass) return false;

  // If they're Boolean, String or Number objects, check values
  if (aClass == '[object Boolean]' || aClass == '[object String]' || aClass == '[object Number]') {
    return a.valueOf() == b.valueOf();
  }

  // If they're RegExps, Dates or Error objects, check stringified values
  if (aClass == '[object RegExp]' || aClass == '[object Date]' || aClass == '[object Error]') {
    return a.toString() == b.toString();
  }

  // Otherwise they're Objects, Functions or Arrays or some kind of host object
  if (typeof a === 'object' || typeof a === 'function') {
    // For functions, check stringigied values are the same
    // Almost certainly false if a and b aren't trivial
    // and are different functions
    if (aClass == '[object Function]' && a.toString() != b.toString()) return false;

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    // If they don't have the same number of keys, return false
    if (aKeys.length != bKeys.length) return false;

    // Check they have the same keys
    if (!aKeys.every(key => b.hasOwnProperty(key))) {
      return false;
    }

    // Check key values - uses ES5 Object.keys
    return aKeys.every(key => objectTester(a[key], b[key]));
  }
  return false;
};

const isBackToOriginal = (state, firstLevel, secondLevel) => {
  const originalAsset = state.get('revertCopy', Map()).toJS();
  const changedAsset =
    typeof secondLevel !== 'undefined'
      ? state.getIn([firstLevel, 'scenes', secondLevel]).toJS()
      : state.get(firstLevel).toJS();

  const isBackToOriginal = objectTester(originalAsset, changedAsset) 
  Logger.debug({ originalAsset, changedAsset, isBackToOriginal }, '[ASSETS] - isBackToOriginal' );
  // NOTE: might be able to leverage _isEqual ??
  return isBackToOriginal;
};

const processLibraryUpdate = (state, assetIndex, assetData) => {
  Logger.debug({ assetIndex, assetData }, '[ASSETS] processLibraryUpdate' );
  if (
    typeof assetData.config !== 'undefined' &&
    (typeof assetData.config.cropCoordinates !== 'undefined' ||
      typeof assetData.config.posterFrame !== 'undefined')
  ) {
    const existingData = state.getIn([assetIndex]).toJS();
    return state
      .setIn([assetIndex, 'isSaved'], true)
      .setIn([assetIndex], fromJS({ ...existingData, ...assetData }));
  }
  return state.setIn([assetIndex, 'isSaved'], true);
};

/**
* ASSET LIBRARY REDUCER
*/
const assets = (state = Map({}), action) => {
  const { assetIndex, library, assetData, isSaved, data, assets } = action;
  //
  switch (action.type) {
    // NOTE: we have the potential here to populate the assets when we get the config if we want

    case CONST.LIBRARY_FETCH_SUCCESS:
      return state.merge(fromJS(library));

    case CONST.LIBRARY_ASSET_UPDATE_SUCCESS:
      return processLibraryUpdate(state, assetIndex, assetData);

    case CONST.LIBRARY_ASSET_UPDATE_ERROR:
      return state.setIn([assetIndex, 'isSaved'], false);

    case CONST.COPY_ASSET:
 
      const { force } = action;
      const currCopy = state.get('revertCopy', Map()).toJS();
      // Comparing ASSET DATA - that something has changed
      let hasChanged = Object.keys(currCopy).length === 0;
      for (const key in currCopy) {
        // check also if property is not inherited from prototype
        if (!currCopy.hasOwnProperty(key) || assetData[key] !== currCopy[key]) {
          hasChanged = true;
        }
      }
      // NO need to change the state if nothing was passed... or same as current unless FORCE
      if (!force && !hasChanged) {
        return state;
      }
      return state.set('revertCopy', fromJS(assetData));

    case CONST.REVERT_ASSET:
      // totally replace what was there
      return state.set(assetIndex, fromJS(assetData)).setIn([assetIndex, 'isSaved'], true);

    case CONST.REMOVE_COPY_ASSET:
      return state.delete('revertCopy');

    case CONST.LIBRARY_DELETE_MULTIPLE_SUCCESS:
      const newStateJs = state.toJS();
   
      for (const assetId of assets) {
        delete newStateJs[assetId];
      }
 
      return fromJS(newStateJs);

    case CONST.LIBRARY_ASSET_CREATE_SUCCESS:
  
      const newAssets = {};
      for (const item of assetData) {
        newAssets[item._id] = item;
      }
      return state.merge(fromJS(newAssets));

    case CONST.LIBRARY_SET_ASSET_VALUE_SUCCESS:
      const { property, value } = action;
      const oldValue = state.getIn([assetIndex, property]);
      Logger.debug({ oldValue, value }, 'LIBRARY_SET_ASSET_VALUE_SUCCESS');
      if (value === '<p><br></p>' && (oldValue === '' || typeof oldValue === 'undefined')) {
        return state;
      }
      const newState = state.setIn([assetIndex, property], value);

      if (isBackToOriginal(newState, assetIndex)) {
        return newState.setIn([assetIndex, 'isSaved'], true);
      }
      return newState.setIn([assetIndex, 'isSaved'], isSaved);

    case CONST.LOGOUT_SUCCESS:
      return state.clear();

    default:
      return state;
  }
};

export default assets;

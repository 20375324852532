import { put, call, select } from "redux-saga/effects";
import {
  fetchProjects,
  publishProject,
  updateProject,
  projectAddStories,
  deleteStoriesFromProject,
  createProject,
  deleteProject
} from "api";
import { loadState } from "store/localStorage";
import * as CONST from "actions/action_constants";
import * as selectors from "./selectors";
import { arrayMove } from "react-sortable-hoc";
import { deLocalizeTextProps } from "helpers/localizationHelper";
//----
// SELECTORS
// used in the saga

// PROJECT BROWSER SAGA WORKERS
/**
 * fetchProjectBrowserSaga
 */
export function* fetchProjectBrowserSaga(action) {
  try {
    const { response, error } = yield call(fetchProjects, action.username);

    if (response) {
      const { list, projectMenuScenes } = response;
      yield put({
        type: CONST.PROJECT_BROWSER_SUCCESS,
        list,
        projectMenuScenes
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_BROWSER_ERROR, message });
  }
}

export function* publishProjectSaga(action) {
  try {
    const { data, projectID } = action;

    const { response, error } = yield call(publishProject, projectID, data);

    if (response) {
      yield put({ type: CONST.PROJECT_PUBLISH_SUCCESS, projectData: response });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_PUBLISH_ERROR, message });
  }
}

export function* setProjectOrderSaga(action) {
  try {
    const { projectID, oldIndex, newIndex } = action;
    // select the parent scene by ID
    const project = yield select(selectors.getProject, projectID);
    if (!project) {
      throw new Error("Unable to select the project{projectID}");
    }
    const { storyIDs } = project;
    // use positions to reorder the array of scene ids
    const newStoryIDs = arrayMove(storyIDs, oldIndex, newIndex);
    const projectData = { storyIDs: newStoryIDs };
    yield put({ type: CONST.PROJECT_SET_STORY_ORDER, projectID, projectData });

    const { response, error } = yield call(
      updateProject,
      projectID,
      projectData
    );

    if (response) {
      yield put({
        type: CONST.PROJECT_SET_STORY_ORDER_SUCCESS,
        projectID,
        projectData
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_SET_STORY_ORDER_ERROR, message });
  }
}

export function* createProjectSaga(action) {
  try {
    const { inputData } = action;
    const title = inputData
      ? { en: inputData.title }
      : { en: "Default Test Title" };
    const label = inputData ? inputData.label : title.en; // NOTE: will need to be localized
    const typeID = inputData ? inputData.type : "5ade9bda364716bc5cdf6793"; // set default type to be story for Now

    const projectData = {
      typeID,
      title,
      label
    };

    const { response, error } = yield call(createProject, projectData);

    if (response) {
      yield put({ type: CONST.PROJECT_CREATE_SUCCESS, projectData: response });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_CREATE_ERROR, message });
  }
}

export function* deleteProjectSaga(action) {
  try {
    const { selected } = action;

    const { response, error } = yield call(deleteProject, selected);

    if (response) {
      yield selected.map(projectID =>
        put({ type: CONST.PROJECT_DELETE_SUCCESS, projectID })
      );
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_DELETE_ERROR, message });
  }
}

export function* updateProjectSaga(action) {
  try {
    const { projectID } = action;

    const project = yield select(selectors.getProject, projectID);
    const { response, error } = yield call(updateProject, projectID, project);

    if (error) {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_UPDATE_ERROR, message });
  }
}

export function* projectAddStoriesSaga(action) {
  try {
    const { storyIDs, projectID } = action;
    const data = { storyIDs };
    const { response, error } = yield call(projectAddStories, projectID, data);

    if (response) {
      yield put({
        type: CONST.PROJECT_ADD_STORIES_SUCCESS,
        projectData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_ADD_STORIES_ERROR, message });
  }
}

export function* projectDeleteStoriesSaga(action) {
  try {
    let { projectID, data } = action;
    data = { ids: data };
    const { response, error } = yield call(
      deleteStoriesFromProject,
      projectID,
      data
    );

    if (response) {
      yield put({
        type: CONST.PROJECT_DELETE_STORIES_SUCCESS,
        projectData: response,
        projectID
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.PROJECT_DELETE_STORIES_ERROR, message });
  }
}

/**
 * Stories Reducer
 * each site will be keyed off their ids

 */
import { fromJS, Map } from "immutable";
import * as CONST from "actions/action_constants";
import _merge from "lodash/merge";
import { localizeTextProps } from "helpers/localizationHelper";
import {
  selectToggle,
  exclusiveSelectToggle,
  getVisibleItems,
  setSortByFilter,
  setStoryBrowserSearch
} from "helpers/cardBrowserHelper";
//

/**
 * STORYEDITOR REDUCER
 * main switch for reponding to actions
 */
const storyEditor = (state = Map({}), action) => {
  const selector = action.selector || ["cardBrowserConfig"];
  const cardSizeMultiplierSelector = selector.concat(["cardSizeMultiplier"]);
  const totalCountSelector = selector.concat(["totalCount"]);
  const activeEditingElementSelector = selector.concat([
    "activeEditingElement"
  ]);
  const activeEditingSceneSelector = selector.concat(["activeEditingScene"]);

  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      let { storyEditor } = action;
      // Checking if we have a search parameter already in the URL
      const urlSearch = state.getIn(["cardBrowserConfig", "keywordSearch"]);
      if (!storyEditor || !storyEditor.keywordSearch) {
        storyEditor = _merge(storyEditor, { keywordSearch: urlSearch });
      }
      return state.merge(fromJS(storyEditor));
    case CONST.STORY_BROWSER_SELECT_TOGGLE:
      return selectToggle(state, action.id, action.selector);
    case CONST.STORY_BROWSER_EXCLUSIVE_SELECT_TOGGLE:
      return exclusiveSelectToggle(state, action.id, action.selector);
    case CONST.SCENE_DELETE_SUCCESS:
      // TODO- Introducing deselect for slideshow - we need to pass the selector - currently ONLY slideshow is using
      // the scene browser
      return exclusiveSelectToggle(state, null, [
        "storyTypes",
        "slideshow",
        "cardBrowserConfig"
      ]);
    case CONST.SET_STORY_BROWSER_SEARCH:
      return setStoryBrowserSearch(state, action.search, action.selector);
    case CONST.STORY_BROWSER_SET_TOTAL_COUNT:
      return state.setIn(totalCountSelector, action.totalCount);
    case CONST.STORY_EDITOR_SET_ACTIVE_ELEMENT:
      return state.setIn(activeEditingElementSelector, action.activeElement);
    case CONST.STORY_EDITOR_SET_ACTIVE_SLIDE:
      return state
        .setIn(activeEditingSceneSelector, fromJS(action.activeSlide._id)) // NOTE: here it is set as an ID other spots an object
        .setIn(activeEditingElementSelector, null);
    // return setactiveEditingScene(state, action.activeStory, action.activeSlide);
    case CONST.STORY_DATA_SUCCESS:
      return setFirstSlideAsActive(state, action.storyData);
    case CONST.STORY_BROWSER_REQUEST:
      return state.set("isLoading", true);
    case CONST.SET_STORY_BROWSER_ISSAVED:
      return state.set("isSaved", action.value).set("navigateToNew", null);
    case CONST.STORY_BROWSER_SUCCESS:
      // @TODO Update total count in Library
      return state.set("isLoading", false).delete("error");
    case CONST.STORY_DELETE_SUCCESS:
      return state.setIn(["cardBrowserConfig", "selectMode"], false);
    case CONST.SCENE_CREATE_SUCCESS:
      return state.set(
        "navigateToNew",
        action.sceneData[Object.keys(action.sceneData)[0]]._id
      );
    case CONST.SUBSCENE_CREATE_SUCCESS:
      return state.set("navigateToNew", action.newSubsceneIDs[0]);
    case CONST.SLIDESHOW_SCENE_EDITOR_MOUNT:
    case CONST.SCENE_CREATE_REVERT_COPY:
      return state.remove("navigateToNew");
    case CONST.STORY_EDITOR_ADD_SLIDE_SUCCESS:
      return state
        .setIn(activeEditingSceneSelector, action.slideData._id)
        .setIn(activeEditingElementSelector, null);
    case CONST.STORY_EDITOR_SET_ELEMENT_VALUE_SUCCESS:
      return state.set("isSaved", action.isSaved);
    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    case CONST.STORY_SLIDE_UPDATE_ACTIVE:
      return state.setIn(activeEditingSceneSelector, action.data.slideData._id);
    default:
      return state; // if action was not passed send state back
  }
};

const setFirstSlideAsActive = (state, storyData) => {
  // const scenesArray = [];
  // NOTE: scenes are now an array of IDs
  if (storyData && storyData.sceneIDs) {
    const { sceneIDs } = storyData;
    // Object.keys(storyData.scenes).forEach((key) => {
    //   scenesArray[storyData.scenes[key].order] = storyData.scenes[key];
    // });
    return state.set("activeEditingScene", sceneIDs[0]); // NOTE: here we are setting an ID, later its an object...
  }
  return state;
};

const storySlideUpdateActive = (state, action) => state;

export default storyEditor;

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logger from "utils/logger";
import { List, CardBrowser, Dialog } from "@terraincognita/ui-core";
import _debounce from "lodash/debounce";
import { Tabs, Tab } from "material-ui/Tabs";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton";
import LeftChevron from "material-ui/svg-icons/navigation/chevron-left";
import RightChevron from "material-ui/svg-icons/navigation/chevron-right";
import ContentContainer from "modules/ContentContainer";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import { timelineEventEditorMapStateToProps } from "mapToProps/sceneEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";

export class TimelineEventEditor extends React.Component {
  constructor(props) {
    super(props);

    const formData = this.props.activeEditingSubScene.elements
      ? _cloneDeep(this.props.activeEditingSubScene.elements)
      : null;

    this.state = {
      tab: "details",
      isDirty: false,
      isFormDataValid: true,
      activeLanguage: this.props.langID,
      formData
    };

    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (baseParam, param, value) {
      this.handleInputChanges.apply(this, [baseParam, param, value]);
    }, 500);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleAddAsset = this.handleAddAsset.bind(this);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.handleAssetSelected = this.handleAssetSelected.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleRevertActionFromModal =
      this.handleRevertActionFromModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeEditingSubScene.elements) {
      if (typeof nextProps.activeEditingSubScene.isSaved !== "undefined") {
        this.setState({
          isDirty: !nextProps.activeEditingSubScene.isSaved
        });
        this.props.storySetIsSaved(nextProps.activeEditingSubScene.isSaved);
      }
      this.setState({
        formData: nextProps.activeEditingSubScene
          ? nextProps.activeEditingSubScene.elements
          : this.state.formData
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isStateFormDataChanged = !_isEqual(
      prevState.formData,
      this.state.formData
    );
    if (isStateFormDataChanged) {
      let isFormDataValid = true;
      Object.keys(this.props.forms.details).map((group, i) => {
        Object.keys(this.props.forms.details[group]).map(controlName => {
          const control = this.props.forms.details[group][controlName];
          const { param, isRequired } = control;
          const value = this.state.formData[param];
          if (isRequired && value === "") {
            isFormDataValid = false;
          }
        });
      });
      this.setState({ isFormDataValid });
    }
  }

  proceedToLink(link) {
    this.setState({ isDirty: false }, () => {
      this.props.storySetIsSaved(true);
      this.props.history.push(link);
    });
  }

  render() {
    const { galleryData, cardBrowserConfig } = this.props;

    const tabState = this.state.tab;
    const topBar = (
      <div className="timeline-event-editor-topbar">
        <div className="scene-navigation-component">
          {this.props.goPrevURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("prev")}
            >
              <LeftChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : null}
          <div className="slide-numbers-container">
            {this.props.curSlideNo ? this.props.curSlideNo : null}
            &nbsp; of &nbsp;
            {this.props.totalSlideNo ? this.props.totalSlideNo : null}
          </div>
          {this.props.goNextURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => this.handlePrevNextClicked("next")}
            >
              <RightChevron viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : (
            <div className="empty-arrow" />
          )}
        </div>
        <RaisedButton
          primary
          disabled={!this.state.isDirty || !this.state.isFormDataValid}
          className="new-asset-button"
          label="SAVE"
          labelStyle={{ textTransform: "none" }}
          onClick={e => {
            e.stopPropagation();
            this.handleSave();
          }}
          style={{ float: "right", marginRight: 50, marginTop: 15 }}
        />

        <SelectField
          className="language-select"
          value={this.state.activeLanguage}
          onChange={this.handleLanguageChange}
        >
          {this.props.languages &&
            _map(this.props.languages, item => (
              <MenuItem
                key={item.id}
                value={item.id}
                primaryText={item.label}
              />
            ))}
        </SelectField>

        {this.state.tab === "gallery" ? (
          this.props.cardBrowserConfig.selected &&
          this.props.cardBrowserConfig.selected.length > 0 ? (
            <RaisedButton
              primary
              className="new-asset-button"
              label="DELETE"
              labelStyle={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                this.openDeleteModal();
              }}
              style={{ float: "right", marginRight: 50, marginTop: 15 }}
            />
          ) : (
            <RaisedButton
              primary
              className="new-asset-button"
              label="ADD NEW"
              labelStyle={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                this.props.openAssetSelectorFunc(null, true, {
                  tabState
                });
              }}
              style={{ float: "right", marginRight: 50, marginTop: 15 }}
            />
          )
        ) : null}
      </div>
    );

    const tabStyle = {
      buttonStyle: {
        backgroundColor: "#d7d7d7",
        color: "rgb(72, 72, 72)",
        zIndex: 0
      }
    };

    const tabs = (
      <Tabs value={this.state.tab} onChange={this.handleTabChange}>
        <Tab label="DETAILS" value="details" {...tabStyle} />
        <Tab label="GALLERY" value="gallery" {...tabStyle} />
      </Tabs>
    );

    return (
      <div className="timeline-event-editor">
        <Helmet>
          <title>Timeline Event Editor</title>
        </Helmet>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="quiz-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.slugBase}
          handleCloseClicked={this.handleCloseClicked}
          confirmNavigation={this.state.isDirty}
          headerTheme="light"
          title={this.state.formData.title}
          headerMode="extended-extra"
          topBar={topBar}
          headerTabs={tabs}
        >
          <div className="tab-form-container timeline-event-editor-container">
            {/* General Details Editor */}
            {this.state.tab === "details" && (
              <div className="details-form-container">
                {this.props.forms ? (
                  <div className="tab-column column-general column-right-border">
                    <List
                      styleName="plain"
                      data={this.state.formData ? this.state.formData : null}
                      items={this.props.forms.details.general}
                      handleInputChange={(param, value) =>
                        this.debounceInputChange("general", param, value)
                      }
                    />
                  </div>
                ) : null}
                <div className="tab-column column-description column-right-border">
                  {this.props.forms ? (
                    <List
                      styleName="plain"
                      data={this.state.formData ? this.state.formData : null}
                      items={this.props.forms.details.extendedDescription}
                      handleInputChange={(param, value) =>
                        this.debounceInputChange(
                          "extendedDescription",
                          param,
                          value
                        )
                      }
                    />
                  ) : null}
                </div>
                <div className="tab-column column-image">
                  {this.props.forms ? (
                    <List
                      styleName="plain"
                      handleAddClick={this.handleAddAsset}
                      handleDeleteClick={this.handleDeleteAsset}
                      data={this.state.formData ? this.state.formData : null}
                      items={this.props.forms.details.featuredImage}
                    />
                  ) : null}
                </div>
              </div>
            )}

            {/* Gallery Editor */}
            {this.state.tab === "gallery" && (
              <div className="tab-form-container gallery-form-container">
                {Object.keys(galleryData).length > 0 ? (
                  <CardBrowser
                    items={galleryData}
                    minCardSize={cardBrowserConfig.cardSizeMultiplier}
                    enableKeys={false}
                    cardBrowserMaxCardSize={
                      cardBrowserConfig.cardBrowserMaxCardSize
                    }
                    showButtons
                    setCardMargin={20}
                    displayCaption={false}
                    isSequentiable
                    executeOnReSort={this.props.setSubSceneOrder}
                    cardStyle={{
                      backgroundColor: "whitesmoke",
                      titleBackgroundColor: "whitesmoke",
                      titleColor: "black",
                      imageFit: "contain"
                    }}
                    editClickHandler={this.props.editClickHandler}
                    selectHandler={this.props.selectHandler}
                    exclusiveSelectHandler={this.props.exclusiveSelectHandler}
                    backClickHandler={this.props.backClickHandler}
                  />
                ) : null}
              </div>
            )}
          </div>
        </ContentContainer>
        <Dialog
          content={
            'If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          }
          handleConfirm={this.handleRevertActionFromModal}
          confirmLabel="Confirm"
          bodyStyle={{ paddingTop: 20 }}
          handleCancel={this.closeDisplayRevertCancel}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
        <Dialog
          content={
            'Press "Delete" to remove the selected asset(s) from this gallery.'
          }
          handleConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          handleCancel={this.closeDeleteModal}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title="Delete Asset(s)?"
        />
      </div>
    );
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });

    this.props.deleteSubScene(this.state.tab);
    this.props.exclusiveSelectHandler(null);
  }

  handleDeleteAsset() {
    this.props.sceneSetAssetElement("featuredImage", "");
    this.props.storySetIsSaved(false);
  }

  handleAddAsset() {
    this.props.openAssetSelectorFunc("featuredImage", false);
  }

  handleAssetSelected(assetID) {
    this.props.sceneSetAssetElement("featuredImage", assetID);
    this.props.storySetIsSaved(false);
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.changeLanguage(value);
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.storySetIsSaved(true);
    this.props.updateScene();
  }

  handleCloseClicked() {
    this.handleSceneExit(this.props.slugBase);
  }

  handlePrevNextClicked(direction) {
    const targetLink =
      direction === "prev" ? this.props.goPrevURL : this.props.goNextURL;
    this.handleSceneExit(targetLink);
  }

  handleSceneExit(targetLink) {
    if (this.state.isDirty) {
      this.setState({ tempLink: targetLink }, () =>
        this.openDisplayRevertCancel()
      );
    } else {
      this.proceedToLink(targetLink);
    }
  }

  handleRevertActionFromModal() {
    Logger.debug("TimelineEventEditor: handleRevertActionFromModal");
    this.closeDisplayRevertCancel();
    this.props.revertScene();
    this.proceedToLink(this.state.tempLink);
    this.setState({ tempLink: null });
  }

  handleInputChanges(baseParam, param, value) {
    /*
    The forms are bound to state.formData. Therefore, we have to keep
    state.formData up to date with changes made by the user in the forms.
    Otherwise, the user's changes will be overwritten.
    */
    const formData = _cloneDeep(this.state.formData);

    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    but we do want to convert the Quill empty string to an actual empty 
    string.
    */
    if (value === "<p><br></p>") {
      if (formData[param] === "") {
        return;
      }
      value = "";
    }

    /*
    If the value of the altered form input doesn't equal the previous
    form input value, update state.data and trigger an update to the 
    store. Note that select list values are of type 'object'
    */
    if (typeof value === "object") {
      formData[param] = { ...formData[param], ...value };
      this.props.storySetIsSaved(false);
      this.setState({ formData });
      this.props.handleInputChange(param, value, baseParam);
    } else {
      // const isRequired = this.props.forms.details[baseParam][param].isRequired;
      const previousValue = formData[param];
      // if (!isRequired || (isRequired && value !== "")) {
      if (previousValue !== value) {
        formData[param] = value;
        this.props.storySetIsSaved(false);
        this.setState({ formData });
        this.props.handleInputChange(param, value, baseParam);
      }
      // }
    }
  }

  handleTabChange(value) {
    this.setState({
      tab: value
    });
  }
}

TimelineEventEditor.contextTypes = {
  router: PropTypes.object
};

TimelineEventEditor.defaultProps = {};

TimelineEventEditor.propTypes = {
  activeEditingSubScene: PropTypes.object,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  elementID: PropTypes.string,
  scenes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugBase: PropTypes.string,
  type: PropTypes.string,
  openAssetSelectorFunc: PropTypes.func,
  openImageCropperFunc: PropTypes.func,
  selectedAsset: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return timelineEventEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    storyID,
    langID,
    sceneID,
    slugBase,
    activeEditingSubScene,
    dataReadyForSave,
    localizedElements,
    forms,
    cardBrowserConfig
  } = stateProps;
  const cardBrowserSelector = [
    "sceneTypes",
    "timeline-event",
    "cardBrowserConfig"
  ]; // immutable path to locate config
  return Object.assign({}, stateProps, {
    handleInputChange: (property, value, baseParam) => {
      dispatchProps.setElementInput(
        property,
        value,
        langID,
        activeEditingSubScene._id,
        false,
        false,
        localizedElements.includes(property),
        forms.details[baseParam]
      );
    },
    openAssetSelectorFunc: (param, multiple, selectedTab) => {
      const tab = selectedTab ? selectedTab.tabState : null;
      ownProps.openAssetSelectorFunc(param, multiple, tab);
    },

    deleteSubScene: selectedTab => {
      const { selected } = cardBrowserConfig;
      dispatchProps.deleteSubScene(activeEditingSubScene._id, selected, false);
    },

    /*
    Once the necessary work is done in the scene editor map
    state to props method to define activeSlideAssetCloudId
    (the url to the image) and percCropData (crop coordinates)
    for the timeline event featured image, then define a 
    handleEditClick propery on the primary image List component
    and set it to this.props.handleEditClick.
    */
    handleEditClick: () => {
      ownProps.openImageCropperFunc();
    },
    sceneSetAssetElement: (elementID, assetID) => {
      dispatchProps.sceneSetAssetElement(
        activeEditingSubScene._id,
        elementID,
        assetID
      );
    },
    storySetIsSaved: isSaved => {
      dispatchProps.setStoryBrowserIsSaved(isSaved);
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    updateScene: () => {
      const sceneType = "timeline-events";
      const subsceneType = "timeline-event";

      dispatchProps.updateScene(
        activeEditingSubScene._id,
        dataReadyForSave,
        forms,
        sceneType,
        subsceneType
      );
    },
    revertScene: () => {
      dispatchProps.revertScene();
    },
    backClickHandler: id => {
      dispatchProps.exclusiveSelectToggle(null, cardBrowserSelector);
    },
    selectHandler: id => {
      dispatchProps.selectToggle(id, cardBrowserSelector);
    },
    exclusiveSelectHandler: id => {
      dispatchProps.exclusiveSelectToggle(id, cardBrowserSelector);
    },
    editClickHandler: (id, coords) => {
      ownProps.openSceneEditorModalFunc(id, coords.left, coords.top);
    },
    setSubSceneOrder: (oldIndex, newIndex) =>
      dispatchProps.setSubSceneOrder(
        activeEditingSubScene._id,
        oldIndex,
        newIndex,
        false
      )
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps, { withRef: true })(
    TimelineEventEditor
  )
);

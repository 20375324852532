import React, {Component} from 'react';
import {connect} from 'react-redux';
import Logger from "utils/logger";

import { logoutUser } from 'actions';
import './Logout.scss';


class Logout extends Component {
    
    componentDidMount() {
        const {dispatch} = this.props;//only here if we connect to redux store
        
        Logger.debug({ props: this.props }, '[LOGOUT]:');
        dispatch(logoutUser());
    }
    
    render() {
        return (
            <div className="amc-logout">
                Logging user out.
            </div>
        );
    }
}


export default connect()(Logout);
//export default Logout;
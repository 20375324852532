import { localizeTextProps } from 'helpers/localizationHelper';
import {
  selectToggle,
  exclusiveSelectToggle,
  getVisibleItems,
  setSortByFilter,
  getSortedItems,
  setStoryBrowserSearch,
} from 'helpers/cardBrowserHelper';
import { Map } from 'immutable';
import _forEach from 'lodash/forEach';
import Logger from "utils/logger";

export const feedbackBrowserMapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  Logger.debug({ params }, '[FEEDBACK] feedbackBrowserMapStateToProps');
  const filterBySceneID = params.sceneID || null;
  const sidebarMode = `${state.getIn(['base', 'mainSidebar', 'mode'])}Extra`;

  const feedbackData = state.getIn(['data', 'feedback']).toJS();
  const feedback = {};

  if (Object.keys(feedbackData).length > 0) {
    let counter = 1;
    _forEach(feedbackData, (feedbackItem) => {
      if (filterBySceneID && feedbackItem.sceneID !== filterBySceneID) {
        // DO NOTHING
      } else {
        feedback[counter] = {
          id: feedbackItem._id,
          columns: {
            1: {
              id: 1,
              label: feedbackItem.elements.submitterName
                ? feedbackItem.elements.submitterName.text
                : '',
            },
            2: {
              id: 2,
              label: feedbackItem.elements.nameRecognized
                ? feedbackItem.elements.nameRecognized.text
                : '',
            },
            3: {
              id: 3,
              label: feedbackItem.elements.submissionDate
                ? feedbackItem.elements.submissionDate.text
                : '',
            },
          },
        };
      }
      counter++;
    });
  }

  return {
    slugBase: '/feedback',
    feedback,
    sidebarMode,
    sidebarProps: {
      title: 'Feedback',
      goBackAction: '',
    },
  };
};

export const feedbackViewerMapStateToProps = (state, ownProps) => {
  Logger.debug({ ownProps },'[FEEDBACK] feedbackViewerMapStateToProps');
  const sidebarMode = `${state.getIn(['base', 'mainSidebar', 'mode'])}`; // MediumExtra
  const storyEditor = state.getIn(['ui', 'storyEditor'], Map({})).toJS();
  const baseForms = state.getIn(['base', 'forms'], Map).toJS();
  const baseConfig = state.getIn(['base', 'config'], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ['title'];
  const slugBase = '/feedback';
  const feedbackID = ownProps.match.params ? ownProps.match.params.feedbackID : null;
  const allFeedbackData = state.getIn(['data', 'feedback']).toJS();
  let feedbackData =
    feedbackID && state.getIn(['data', 'feedback', feedbackID])
      ? state.getIn(['data', 'feedback', feedbackID]).toJS()
      : null;
  feedbackData = localizeTextProps(langID, localizedProps, feedbackData);
  const assetData =
    feedbackData && feedbackData.elements && feedbackData.elements.asset
      ? feedbackData.elements.asset
      : null;

  const allFeedbackArray = [];
  let index = 0;
  _forEach(allFeedbackData, (feedbackDataItem) => {
    allFeedbackArray[index] = feedbackDataItem;
    index++;
  });

  let myKey,
    goPrevURL,
    goNextURL,
    curSlideNo;
  _forEach(allFeedbackArray, (element, index) => {
    if (element._id === feedbackData._id) {
      myKey = index;
      curSlideNo = index + 1;
    }
  });
  if (myKey > 0) {
    goPrevURL = `${slugBase}/${allFeedbackArray[myKey - 1]._id}`;
  }
  if (myKey < allFeedbackArray.length - 1) {
    goNextURL = `${slugBase}/${allFeedbackArray[myKey + 1]._id}`;
  }

  // Getting crop data for both asset and feedback
  const assetCropData =
    assetData && assetData.assetConfig ? assetData.assetConfig.cropCoordinates : null;
  const feedbackCropData =
    assetData && assetData.feedbackCoordinates ? assetData.feedbackCoordinates : null;
  // Finding forms

  const form =
    baseForms && baseForms['select-asset-area'] ? baseForms['select-asset-area'].forms : null;
    Logger.debug({ form }, '[FEEDBACK] baseForms');
  if (feedbackData && feedbackData.elements) {
    _forEach(feedbackData.elements, (element, index) => {
      if (element.type === 'text') {
        feedbackData.elements[index] = feedbackData.elements[index].text;
      }
    });
  }

  Logger.debug({feedbackData}, '[FEEDBACK][MAP TO PROP] - feedbackGalleryEditorMapStateToProps');

  return {
    isLoading: typeof storyEditor.isLoading !== 'undefined' ? storyEditor.isLoading : true,
    slugBase,
    myKey,
    goPrevURL,
    goNextURL,
    curSlideNo,
    totalSlideNo: allFeedbackArray.length,
    assetData,
    assetCropData,
    feedbackCropData,
    history: ownProps.history,
    sidebarMode,
    form,
    feedbackData,
  };
};

/**
 * SCENE Map To Props
 */

import { Map } from "immutable";
import _forEach from "lodash/forEach";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _values from "lodash/values";
import _map from "lodash/map";
import _startCase from "lodash/startCase";
import moment from "moment";
import config from "config";
import * as selectors from "sagas/selectors";
import {
  localizeTextProps,
  localizeTextProps2
} from "helpers/localizationHelper";

/**
 * Build select options that are used in form select lists bound to a dynamic
 * data source (ex., categories).
 *
 * @param {object} forms - Pointer to forms object in scene-types document.
 * @param {string} langID - Two-character string representing current language
 * @param {object} state - Pointer to Redux state
 */
const buildSelectOptions = (forms, langID, state) => {
  for (const formType in forms) {
    for (const formGroup in forms[formType]) {
      for (const formElement in forms[formType][formGroup]) {
        const formElementConfig = forms[formType][formGroup][formElement];
        if (
          formElementConfig.type === "select" &&
          formElementConfig.options.length === 0
        ) {
          /*
          The assumption is that the source options for this select control
          will be in the Redux data node, and that the only options in that
          node are options relevant for the current story.
          */
          const sourceOptions = state
            .getIn(["data", formElementConfig.optionsSource], Map({}))
            .toJS();
          delete sourceOptions.revertCopy;

          /*
          The assumption is that the source options form will be in the 
          ui.storyEditor.forms object keyed off the element param and in 
          col1. If that ends up  not always being the case, the col1
          prop can be stored in the scene-type form element declaration.
          */
          const sourceOptionsForm = state
            .getIn(
              ["ui", "storyEditor", "forms", formElementConfig.param, "col1"],
              Map()
            )
            .toJS();

          // Now generate the select control options.
          const options = _map(sourceOptions, option => {
            const localizedOption = localizeTextProps2(
              langID,
              sourceOptionsForm,
              option
            );
            return {
              label: localizedOption.name,
              value: localizedOption._id
            };
          });
          // Alphabetize options by label prior to adding the default option
          // to the beginning of the list.
          options.sort((a, b) => {
            var nameA = a.label.toUpperCase(); // ignore upper and lowercase
            var nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          options.unshift({
            label: formElementConfig.defaultOptionLabel,
            value: ""
          });
          forms[formType][formGroup][formElement].options = options;
        }
      }
    }
  }

  return forms;
};

/**
 * sceneBrowserMapStateToProps
 * called on the init of the connected Module - SceneBrowser
 * may end up being used for all scene editors...
 * @param {object} state
 * @param {object} ownProps
 */
export const sceneBrowserMapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const { storyID, sceneID, elementID } = params;

  const userPreferencesSelector = ["storyManager", "slideEditor", storyID];
  const userPreferences = state
    .getIn(["ui", "userPreferences", ...userPreferencesSelector], Map({}))
    .toJS();
  const userID = state.getIn(["data", "user", "_id"]);
  const accountProjectDefaults = state
    .getIn(["data", "account", "defaults", "project"], Map({}))
    .toJS();

  const cardBrowserConfigUserPrefsSelector = [
    "storyManager",
    "sceneBrowser",
    "cardBrowserConfig"
  ];
  const cardBrowserConfigUserPrefs = state
    .getIn(
      ["ui", "userPreferences", ...cardBrowserConfigUserPrefsSelector],
      Map({})
    )
    .toJS();

  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const storyData = state.getIn(["data", "stories", storyID], Map([])).toJS();

  const { storyTypes } = storyEditor; // state.getIn(['base', 'storyTypes'], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];

  // convert ID in data to the param for targeting...
  const storyType = selectors.getStoryTypeParam(state, storyData.type);

  // Overwrite default card browser config values with
  // stored user preferences.
  const cardBrowserConfig =
    typeof storyTypes[storyType] !== "undefined"
      ? storyTypes[storyType].cardBrowserConfig
      : {};
  if (cardBrowserConfigUserPrefs.cardSizeMultiplier) {
    cardBrowserConfig.cardSizeMultiplier =
      cardBrowserConfigUserPrefs.cardSizeMultiplier;
  }

  const allScenes = state.getIn(["data", "scenes"], Map({})).toJS();

  // const assets = state.getIn(['data', 'assets'], Map({})).toJS();

  const goBackAction = `${storyEditor.to}/${storyID}`; // storyData._id
  const slugBase = `${goBackAction}/scenes`;

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`;
  const scenes = []; // only thr scenes for this story, assuming an array?
  if (storyData && allScenes) {
    // Converting scenes into array
    // make an array of JUST this storie's scenes (don't include the revertcopy)
    // this is used more than once, may want a function or pass it..
    _forEach(storyData.sceneIDs, (id, index) => {
      let scene = allScenes[id];
      if (scene) {
        scene.userID = userID;
        scene.userPreferences = userPreferences;
        scene.accountProjectDefaults = accountProjectDefaults;
        scene.storyID = storyID;
        scene.storyClasses = storyData.classes;
        scene.layoutName = selectors.getLayoutParam(state, scene.layoutID);
        scene.layout = state
          .getIn(["base", "layouts", scene.layoutName])
          .toJS();

        const tmp = {
          isCloudinary: true,
          cloudName: config("cloudName"),
          cloudPublicId:
            scene.layout &&
            scene.layout.assets &&
            scene.layout.assets.cmsThumb.cloudPublicId
              ? scene.layout.assets.cmsThumb.cloudPublicId
              : "system/scene_thumb_default"
        };
        // until we define in the data the way we store the thumbnails
        // we will target the scene.assets.cmsThumb eventually
        // for NOW the card browser expects al the props to be at top level of object
        if (!scene.background || !(scene.assets && scene.assets.cmsThumb)) {
          scene = { ...scene, ...tmp };
        }
        // covert type
        // scene.type = selectors.getSceneTypeParam(state, scene.type);
        scene.url = `${slugBase}/${scene._id}`;

        const sceneType = selectors.getSceneTypeParam(state, scene.type);
        const sceneLocalizedElements =
          storyEditor.sceneTypes[sceneType].localizedElements;
        _forEach(scene.elements, (element, index) => {
          if (element.type === "text") {
            /*
            Sometimes the text property evaluates as undefined
            on the first pass through this method, and then on
            subsequent passes will have the proper value. That's
            why we have to test for whether or not the text 
            property is defined.
            
            Also, inexplicably, when the Scene Background Editor
            is opened, the text property on text elements is 
            already localized, so we have to test for whether 
            or not the text property is an object before localizing,
            otherwise we blow out the values.
            */
            if (element.text && typeof element.text === "object") {
              scene.elements[index].text = element.text[langID];
            }
          } else if (element.type === "asset") {
            scene.elements[index].metaConfig = localizeTextProps(
              langID,
              sceneLocalizedElements,
              element.metaConfig
            );
          }
        });

        scenes[index] = localizeTextProps(langID, localizedProps, scene);
        scenes[index].title = index + 1;
      }
    });
  }
  let isLoading = true;

  if (
    storyData &&
    (!storyData.sceneIDs ||
      (storyData.sceneIDs &&
        scenes &&
        scenes.length === storyData.sceneIDs.length))
  ) {
    isLoading = false;
  }

  // TODO We need a way to connect storyType 'gallery' with sceneType 'slideshow-slide', meaning, the CMS must know
  // that for galleries he needs to create scenes 'slideshow-slide'. Probably not so bad having that logic just here
  // to simplify things
  let sceneType, sceneTypeID;
  if (storyType === "slideshow") {
    sceneType = "slideshow-slide";
    sceneTypeID = selectors.getSceneTypeId(state, sceneType);
  }

  return {
    userPreferences,
    userID,
    accountProjectDefaults,
    cardBrowserConfigUserPrefsSelector,
    cardBrowserConfig,
    navigateToNew: storyEditor.navigateToNew,
    goBackAction,
    isLoading,
    sidebarMode,
    storyID,
    slugBase,
    scenes,
    sceneTypeID,
    sidebarProps: {
      title: "Scenes",
      goBackAction
    },
    storyType,
    storyData
  };
};

/**
 * getSceneEditorMapStateToProps
 * called on the init of the connected Module - SceneEditor
 * may end up being used for all scene editors...
 * @param {object} state
 * @param {object} ownProps
 */
export const sceneEditorMapStateToProps = (state, ownProps) => {
  const { pathname } = ownProps.location;
  const isBasemap = pathname.indexOf("basemap") > -1;

  const { params, path } = ownProps.match; // this is accessible because of "withRouter"
  const {
    storyID,
    sceneID,
    elementID,
    subsceneID,
    galleryAssetSceneID,
    questionID,
    itemID,
    collectionID
  } = params;

  const accountId = state.getIn(["data", "account", "_id"]);
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];
  const allScenes = state.getIn(["data", "scenes"], Map({})).toJS();
  // const sceneTypes = state.getIn(['data', 'sceneTypes'], Map({})).toJS();
  // const styles = state.getIn(['data', 'styles'], Map({})).toJS(); // user themes
  const assets = state.getIn(["data", "assets"], Map({})).toJS();

  const dataReadyForSave =
    subsceneID && allScenes[subsceneID]
      ? _cloneDeep(allScenes[subsceneID].elements)
      : allScenes[sceneID]
      ? _cloneDeep(allScenes[sceneID].elements)
      : null;

  const galleryAssetDataReadyForSave =
    galleryAssetSceneID && allScenes[galleryAssetSceneID]
      ? _cloneDeep(allScenes[galleryAssetSceneID].elements)
      : null;

  let slugBase = collectionID
    ? `/collections/${collectionID}`
    : `${storyEditor.to}/${storyID}`; // could be /scenes if

  // target the scene being edited...
  if (_isEmpty(allScenes)) {
    return {};
  }
  const activeEditingScene = _cloneDeep(allScenes[sceneID || itemID]);

  const sceneType = isBasemap
    ? "map-basemap"
    : activeEditingScene
    ? selectors.getSceneTypeParam(state, activeEditingScene.type)
    : null;

  // we change the slugbase for certain scene types
  if (sceneType) {
    if (collectionID) {
      slugBase += "/items";
    } else {
      switch (sceneType.toLowerCase()) {
        case "slideshow-slide":
          slugBase += "/scenes";
          break;
        case "quiz-graded-questions":
          slugBase += `/questions/${sceneID}`;
          break;
        case "cover":
          slugBase += "/cover";
          break;
        case "timeline-events":
          slugBase += `/events/${sceneID}`;
          break;
        case "map-places":
          slugBase += `/places/${sceneID}`;
          break;
        case "gallery":
          break;
      }
    }
  }

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra
  // prepare variable to store information
  let editingForm,
    activeElement = null,
    activeSceneFormData,
    activeEditingSubScene,
    subsceneType,
    formType,
    activeSlideAssetCloudId,
    percCropData,
    isSceneElementEditorOpen;

  // ASSET POPULATION
  const tmp = {
    isCloudinary: true,
    cloudName: config("cloudName")
  };
  if (activeEditingScene && activeEditingScene.elements) {
    _forEach(activeEditingScene.elements, (element, index) => {
      if (element) {
        if (element.type === "asset") {
          activeEditingScene.elements[index].formType = element.type
            ? element.type === "asset"
              ? element.assetType
              : element.type
            : "asset";
        } else if (element.type === "text") {
          activeEditingScene.elements[index].formType =
            element.type === "asset" ? element.assetType : element.type;
        }
      }
    });
  }

  if (
    activeEditingScene &&
    typeof activeEditingScene.layoutID !== "undefined"
  ) {
    activeEditingScene.layout = selectors.getLayoutParam(
      state,
      activeEditingScene.layoutID
    );
    // store layout information...

    activeEditingScene.template = selectors.getLayoutTemplate(
      state,
      activeEditingScene.layoutID,
      activeEditingScene.layout
    );

    activeEditingScene.layoutElements = selectors.getLayoutElements(
      state,
      activeEditingScene.layoutID,
      activeEditingScene.layout
    );
  }

  const sceneLocalizedElements = storyEditor.sceneTypes[sceneType]
    ? storyEditor.sceneTypes[sceneType].localizedElements
    : null;

  if (elementID && activeEditingScene) {
    // We are editing an element within a slideshow scene, for example.
    isSceneElementEditorOpen = true;
    activeElement = elementID;

    // Preparing element data for the form. Make certain
    // to clone the original data so we don't end up modifying
    // the original data.
    activeSceneFormData =
      typeof activeEditingScene.elements !== "undefined"
        ? _cloneDeep(activeEditingScene.elements[elementID])
        : null;

    /*
    Localize the asset element meta config data and the text
    element text data. Move the asset meta config properties
    to the root of the activeSceneFormData object so the form
    component has proper access to the data.
    
    Also define percCropData (why can't it just be referenced 
    via activeSceneFormData?) and activeSlideAssetCloudId (why
    do we have to do this here?).
    
    NB: Account for the fact that for some inexplicable reason,
    there are times when the elements property on the 
    activeEditingScene object isn't defined.
    */
    if (activeEditingScene.elements) {
      if (activeEditingScene.elements[elementID].type === "asset") {
        activeSceneFormData.metaConfig = localizeTextProps(
          langID,
          sceneLocalizedElements,
          activeSceneFormData.metaConfig
        );
        activeSceneFormData = {
          ...activeSceneFormData,
          ...activeSceneFormData.metaConfig
        };
        delete activeSceneFormData.metaConfig;

        if (
          activeSceneFormData.assetConfig &&
          activeSceneFormData.assetConfig.cropCoordinates
        ) {
          percCropData = activeSceneFormData.assetConfig.cropCoordinates;
        }
        activeSlideAssetCloudId = `${location.protocol}//res.cloudinary.com/dgpzfuyzy/image/upload/c_crop,f_auto,q_auto/v1/${activeSceneFormData.cloudPublicId}`;
      } else {
        activeSceneFormData = localizeTextProps(
          langID,
          ["text"],
          activeSceneFormData
        );
      }
    }

    // Finding form for given element
    formType = activeSceneFormData ? activeSceneFormData.formType : null;
    // We introduce this logic to fetch slideshow form components for the cover scene
    // Alternatively we could define them, duplicated, under a 'cover' sceneType in 'ui'
    const sceneSelector = sceneType === "cover" ? "slideshow-slide" : sceneType;
    editingForm =
      formType &&
      storyEditor &&
      storyEditor.sceneTypes[sceneSelector] &&
      storyEditor.sceneTypes[sceneSelector].forms
        ? storyEditor.sceneTypes[sceneSelector].forms[formType]
        : null;
  }

  let subsceneLocalizedElements, elementToSelect;

  const getSceneData = sceneID => {
    const scene = _cloneDeep(allScenes[sceneID]);
    if (!scene) {
      return [];
    }

    const sceneType = selectors.getSceneTypeParam(state, scene.type);
    const localizedElements = storyEditor.sceneTypes[sceneType]
      ? storyEditor.sceneTypes[sceneType].localizedElements
      : null;

    // These values are only assigned specific values for
    // certain scene types.
    let elementToSelect,
      sceneFormData,
      cropCoordinates,
      editingForm,
      assetCloudId,
      isSceneElementEditorOpen;

    switch (sceneType) {
      case "viewer-asset":
      case "cargo-game":
      case "colonykit-game":
      case "game-asset":
        elementToSelect = "asset";

        // Retrieving data to populate the SUBSCENE EDIT form (modal)
        const elementData = scene.elements
          ? scene.elements[elementToSelect]
          : null;

        sceneFormData = elementData
          ? localizeTextProps(langID, localizedElements, elementData.metaConfig)
          : {};

        cropCoordinates =
          elementData.assetConfig && elementData.assetConfig.cropCoordinates
            ? elementData.assetConfig.cropCoordinates
            : null;

        // Use the viewer-asset form for the custom scene types
        // cargo-game, colonykit-game and game-asset.
        editingForm =
          storyEditor && storyEditor.sceneTypes["viewer-asset"]
            ? storyEditor.sceneTypes["viewer-asset"].forms[elementToSelect]
            : null;

        const assetID = elementData ? elementData.assetID : null;
        // If so, we need to populate Asset DATA in order to get it displayed
        if (assetID) {
          // const assetForScene = assets[assetID];
          sceneFormData._id = sceneID;
          sceneFormData.assetID = assetID; // adding this so the update method can pass to API
          sceneFormData.cloudPublicId = elementData
            ? elementData.cloudPublicId
            : null;
          sceneFormData.cloudName = elementData
            ? elementData.cloudName
            : config("cloudName");
          sceneFormData.isCloudinary = true;
          sceneFormData.width = elementData ? elementData.width : null;
          sceneFormData.height = elementData ? elementData.height : null;
          sceneFormData.assetType = elementData ? elementData.assetType : null;
          sceneFormData.config = elementData
            ? elementData.assetConfig
            : undefined;

          assetCloudId = elementData
            ? `${location.protocol}//res.cloudinary.com/dgpzfuyzy/image/upload/c_crop,f_auto,q_auto/v1/${elementData.cloudPublicId}`
            : undefined;
        }

        isSceneElementEditorOpen = true;
        break;

      case "quiz-graded-question":
        isSceneElementEditorOpen = false;
        sceneFormData = localizeTextProps(
          langID,
          subsceneLocalizedElements,
          scene.elements[elementToSelect]
        );
        break;
    } // end switch on type

    return [
      scene,
      sceneType,
      localizedElements,
      elementToSelect,
      sceneFormData,
      cropCoordinates,
      editingForm,
      assetCloudId,
      isSceneElementEditorOpen
    ];
  };

  if (subsceneID) {
    [
      activeEditingSubScene,
      subsceneType,
      subsceneLocalizedElements,
      elementToSelect,
      activeSceneFormData,
      percCropData,
      editingForm,
      activeSlideAssetCloudId,
      isSceneElementEditorOpen
    ] = getSceneData(subsceneID);
  }

  let galleryAssetScene,
    galleryAssetSceneType,
    galleryAssetSceneLocalizedElements,
    galleryAssetSceneElement,
    galleryAssetSceneFormData,
    galleryAssetScenePercCropData,
    galleryAssetSceneEditingForm,
    galleryAssetCloudId;

  if (galleryAssetSceneID) {
    [
      galleryAssetScene,
      galleryAssetSceneType,
      galleryAssetSceneLocalizedElements,
      galleryAssetSceneElement,
      galleryAssetSceneFormData,
      galleryAssetScenePercCropData,
      galleryAssetSceneEditingForm,
      galleryAssetCloudId,
      isSceneElementEditorOpen
    ] = getSceneData(galleryAssetSceneID);
  }

  // Setting isSaved flag
  // if(activeEditingSubScene) {
  //   activeEditingSubScene.isSaved = activeEditingSubScene.isSaved ? activeEditingSubScene.isSaved : true;
  // }
  //
  // if(activeEditingScene) {
  //   activeEditingScene.isSaved = activeEditingScene.isSaved ? activeEditingScene.isSaved : true;
  // }

  return {
    accountId,
    activeEditingScene, // reference to full scene
    activeEditingSubScene, // reference to full subscene
    activeElement,
    activeSlideAssetCloudId,
    activeSceneFormData, // reference to localized data that will populate form
    collectionID,
    dataReadyForSave,
    editingForm,
    elementID,
    goBackAction: slugBase,
    itemID,
    langID,
    languages,
    localizedElements: subsceneLocalizedElements || sceneLocalizedElements,
    percCropData,
    isSceneElementEditorOpen,
    sceneID,
    sceneType: collectionID ? "collection" : sceneType,
    sidebarMode,
    slugBase,
    storyID,
    subsceneElement: elementToSelect,
    subsceneID,
    subsceneType,
    galleryAssetSceneID,
    galleryAssetScene,
    galleryAssetDataReadyForSave,
    galleryAssetSceneType,
    galleryAssetSceneLocalizedElements,
    galleryAssetSceneElement,
    galleryAssetSceneFormData,
    galleryAssetScenePercCropData,
    galleryAssetSceneEditingForm,
    galleryAssetCloudId
  };
};

//---------------------------------
// Map To Props based on StoryTypes OR SceneTypes

/**
 * slideshowEditorMapStateToProps
 * called on the init of the connected Module - SlideShowEditor
 * may end up being used for all scene editors...
 * @param {object} state
 * @param {object} ownProps

 * NOTE: may not need this mapping, seems coudl all be handled at scene level....
 */
export const slideshowEditorMapStateToProps = (state, ownProps) => {
  const { storyID, sceneID, elementID, slugBase } = ownProps;

  const userPreferencesSelector = ["storyManager", "slideEditor", storyID];
  const userPreferences = state
    .getIn(["ui", "userPreferences", ...userPreferencesSelector], Map({}))
    .toJS();
  const accountID = state.getIn(["data", "account", "_id"]);
  const accountProjectDefaults = state
    .getIn(["data", "account", "defaults", "project"], Map({}))
    .toJS();
  const userID = state.getIn(["data", "user", "_id"]);

  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];
  const storyData = state.getIn(["data", "stories", storyID], Map([])).toJS();
  const storyClasses = state.getIn(["data", "stories", storyID, "classes"]);

  // passed down from sceneEditor

  const styleForms = storyEditor.sceneTypes["slideshow-slide"]
    ? storyEditor.sceneTypes["slideshow-slide"].forms.style
    : null;

  // let { activeEditingScene } = ownProps;
  let activeEditingScene = _cloneDeep(ownProps.activeEditingScene);

  activeEditingScene = localizeTextProps(
    langID,
    localizedProps,
    activeEditingScene
  );

  const sceneType = selectors.getSceneTypeParam(state, activeEditingScene.type);

  // Localize elements.
  if (activeEditingScene && activeEditingScene.elements) {
    const sceneLocalizedElements =
      storyEditor.sceneTypes[sceneType].localizedElements;
    _forEach(activeEditingScene.elements, (element, index) => {
      if (element.type === "text") {
        /*
        Sometimes the text property evaluates as undefined
        on the first pass through this method, and then on
        subsequent passes will have the proper value. That's
        why we have to test for whether or not the text 
        property is defined.
        
        Also, inexplicably, when the Scene Background Editor
        is opened, the text property on text elements is 
        already localized, so we have to test for whether 
        or not the text property is an object before localizing,
        otherwise we blow out the values.
        */
        if (element.text && typeof element.text === "object") {
          activeEditingScene.elements[index].text = element.text[langID];
        }
      } else if (element.type === "asset") {
        activeEditingScene.elements[index].metaConfig = localizeTextProps(
          langID,
          sceneLocalizedElements,
          element.metaConfig
        );
      }
    });
  }

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra
  // NOTE: we could also read the active scene's layout string, and use it to get the schema
  // the slide is using to know what component, or elments would visible

  // Calculating prev and next slide
  const storyScenesData = state
    .getIn(["data", "stories", storyID, "sceneIDs"], Map({}))
    .toJS();
  let myKey, goPrevURL, goNextURL, curSlideNo;

  if (sceneType !== "cover") {
    _forEach(storyScenesData, (element, index) => {
      if (element === sceneID) {
        myKey = index;
        curSlideNo = index + 1;
      }
    });

    if (myKey > 0) {
      goPrevURL = `${slugBase}/${scenes[storyScenesData[myKey - 1]]._id}`;
    }
    if (myKey < storyScenesData.length - 1) {
      goNextURL = `${slugBase}/${scenes[storyScenesData[myKey + 1]]._id}`;
    }
  }
  // Calculating goBackAction
  const goBackURL = sceneType === "cover" ? `/stories/${storyID}` : slugBase;

  return {
    storyData,
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    styleForms,
    userPreferencesSelector,
    userPreferences,
    accountID,
    accountProjectDefaults,
    userID,
    curSlideNo,
    goBackURL,
    sceneType,
    totalSlideNo: storyScenesData.length,
    goPrevURL,
    goNextURL,
    sidebarMode,
    // needsSaving: storyData
    //   ? typeof storyData.isSaved !== 'undefined' ? !storyData.isSaved : false
    //   : false,
    storyID,
    storyClasses,
    sceneID,
    elementID,
    activeEditingScene
  };
};

/**
 * Called on the init of the connected Module - GradedQuestionEditor
 * @param {object} state
 * @param {object} ownProps

 * NOTE: may not need this mapping, seems coudl all be handled at scene level....
 */
export const gradedQuestionEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps =
    storyEditor.sceneTypes["quiz-graded-question"].localizedElements;
  const { storyID, sceneID, elementID, slugBase, activeEditingScene } =
    ownProps;
  const dataReadyForSave = _cloneDeep(
    scenes[ownProps.activeEditingSubScene._id].elements
  );

  // const activeEditingSubScene = _cloneDeep(
  //   localizeTextProps(langID, localizedProps, scenes[ownProps.activeEditingSubScene._id]),
  // );

  const subsceneID = ownProps.match.params.subsceneID;
  let activeEditingSubScene = _cloneDeep(scenes[subsceneID]);
  activeEditingSubScene = localizeTextProps(
    langID,
    localizedProps,
    activeEditingSubScene
  );

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra

  // -------- Calculating prev and next slide
  const subScenesData = activeEditingScene.subScenes;
  let myKey, goPrevURL, goNextURL, curSlideNo;
  _forEach(subScenesData, (element, index) => {
    if (element === activeEditingSubScene._id) {
      myKey = index;
      curSlideNo = index + 1;
    }
  });
  if (myKey > 0) {
    goPrevURL = `${slugBase}/${scenes[subScenesData[myKey - 1]]._id}`;
  }
  if (myKey < subScenesData.length - 1) {
    goNextURL = `${slugBase}/${scenes[subScenesData[myKey + 1]]._id}`;
  }

  // Finding forms
  const forms = storyEditor.sceneTypes["quiz-graded-question"]
    ? storyEditor.sceneTypes["quiz-graded-question"].forms
    : null;

  // Finding Localized Elements
  const localizedElements = storyEditor.sceneTypes["quiz-graded-question"]
    ? storyEditor.sceneTypes["quiz-graded-question"].localizedElements
    : null;

  // Localizing titles/texts - converting data to form-readable
  if (activeEditingSubScene.elements) {
    activeEditingSubScene.elements = flattenElements(
      activeEditingSubScene.elements,
      localizedElements,
      langID
    );

    // Now flatten the title text element in the quiz
    // question options. This is highly custom :(
    if (
      activeEditingSubScene.elements.options &&
      activeEditingSubScene.elements.options.list
    ) {
      _forEach(activeEditingSubScene.elements.options.list, (option, index) => {
        if (
          activeEditingSubScene.elements.options.list[index] &&
          activeEditingSubScene.elements.options.list[index].title
        ) {
          if (localizedElements.includes("options")) {
            activeEditingSubScene.elements.options.list[index].title =
              localizeTextProps(
                langID,
                ["text"],
                activeEditingSubScene.elements.options.list[index].title
              );
            activeEditingSubScene.elements.options.list[index].title =
              activeEditingSubScene.elements.options.list[index].title.text;
            delete activeEditingSubScene.elements.options.list[index].elements;
          }
        }
      });
    }
  }

  const scene = scenes[sceneID];

  return {
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    slugBase,
    curSlideNo,
    totalSlideNo: subScenesData.length,
    goPrevURL,
    history: ownProps.history,
    goNextURL,
    sidebarMode,
    localizedElements,
    languages,
    langID,
    forms,
    // needsSaving: storyData
    //   ? typeof storyData.isSaved !== 'undefined' ? !storyData.isSaved : false
    //   : false,
    storyID,
    sceneID,
    dataReadyForSave,
    elementID,
    activeEditingSubScene,
    activeEditingScene // string id of the Scene being edited
  };
};

export const collectionItemEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const localizedProps = storyEditor.localizedProps || ["title"];
  const {
    storyID,
    match,
    slugBase,
    activeEditingScene,
    isSceneElementEditorOpen
  } = ownProps;

  const { params } = match;
  const { collectionID, itemID } = params;
  const storyData = state
    .getIn(["data", "stories", collectionID], Map({}))
    .toJS();
  const collectionSceneTypeParam = selectors.getSceneTypeParam(
    state,
    storyData.sceneTypeID
  );
  const collectionSceneType = storyEditor.sceneTypes[collectionSceneTypeParam];
  const dataReadyForSave = scenes[ownProps.activeEditingScene._id]
    ? _cloneDeep(scenes[ownProps.activeEditingScene._id].elements)
    : {};
  const { activeEditingSubScene } = ownProps;
  const cardBrowserConfig =
    typeof collectionSceneType !== "undefined" &&
    typeof collectionSceneType.cardBrowserConfig !== "undefined"
      ? collectionSceneType.cardBrowserConfig
      : {};

  // Finding Localized Elements
  const localizedElements = collectionSceneType
    ? collectionSceneType.localizedElements
    : null;

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra

  // -------- Calculating prev and next slide
  const subScenesData = storyData.sceneIDs;

  let myKey, goPrevURL, goNextURL, curSlideNo;
  if (subScenesData) {
    _forEach(subScenesData, (element, index) => {
      if (element === itemID) {
        myKey = index;
        curSlideNo = index + 1;
      }
    });
    if (myKey > 0) {
      goPrevURL = `${slugBase}/${scenes[subScenesData[myKey - 1]]._id}`;
    }
    if (myKey < subScenesData.length - 1) {
      goNextURL = `${slugBase}/${scenes[subScenesData[myKey + 1]]._id}`;
    }
  }

  // Finding forms
  const forms = collectionSceneType ? collectionSceneType.forms : null;

  // Localizing titles/texts - converting data to form-readable
  if (activeEditingScene.elements) {
    const localizedPropsElement = ["text", "title"];

    /*
    There are times when the elements are already flattened
    by this point. If this method executes independently of the
    sceneEditorMapStateToProps() method, which is responsible
    initializing the activeEditingScene object based on scene
    data in the global store, the data will already be flat.
    This seems to happen sometimes when the Collection Item Editor
    is mounted to edit an item, and then the user switches to the
    gallery tab and clicks the "Add New" button. But the behavior
    is inconsistent. To address this, we're setting a boolean
    flag on activeEditingScene to track whether or not the
    elements have been flattened. If the activeEditingScene
    has reinitialized the object, the flag won't exist.
    
    Ideally, we would have clarity on when these methods are
    called, and we wouldn't have to rely on flags. Ideally
    we would be able to assume activeEditingScene has always
    been reinitialized.
    */

    if (!activeEditingScene.areElementsFlattened) {
      activeEditingScene.elements = flattenElements(
        activeEditingScene.elements,
        localizedElements,
        langID
      );
      activeEditingScene.areElementsFlattened = true;
    }
  }
  const galleryData = {};
  if (activeEditingScene.subScenes) {
    _forEach(activeEditingScene.subScenes, (id, index) => {
      const subScene = scenes[id];
      const assetData =
        subScene && subScene.elements && subScene.elements.asset
          ? subScene.elements.asset
          : {};
      if (subScene) {
        galleryData[index] = { ...assetData, _id: id };
      }
    });
  }

  const rotationGalleryData = {};
  if (activeEditingScene.rotationSubScenes) {
    _forEach(activeEditingScene.rotationSubScenes, (id, index) => {
      const subScene = scenes[id];
      const assetData =
        subScene && subScene.elements && subScene.elements.asset
          ? subScene.elements.asset
          : {};
      if (subScene) {
        rotationGalleryData[index] = { ...assetData, _id: id };
      }
    });
  }

  const scene = scenes[itemID];

  return {
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    slugBase,
    galleryData,
    rotationGalleryData,
    curSlideNo,
    cardBrowserConfig,
    totalSlideNo: subScenesData ? subScenesData.length : 0,
    goPrevURL,
    localizedElements,
    dataReadyForSave,
    history: ownProps.history,
    goNextURL,
    isSceneElementEditorOpen,
    sidebarMode,
    languages,
    langID,
    forms,
    isGallerySupported: collectionSceneType
      ? collectionSceneType.isGallerySupported
      : false,
    isRotationGallerySupported: collectionSceneType
      ? collectionSceneType.isRotationGallerySupported
      : false,
    storyID,
    collectionID,
    isSaved: scenes[activeEditingScene._id]
      ? scenes[activeEditingScene._id].isSaved
        ? scenes[activeEditingScene._id].isSaved
        : true
      : true,
    itemID,
    collectionSceneType: collectionSceneTypeParam,
    activeEditingSubScene,
    activeEditingScene // string id of the Scene being edited
  };
};

export const gradedResultsEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const { storyID, sceneID, elementID, slugBase } = ownProps;
  const activeEditingScene = _cloneDeep(ownProps.activeEditingScene);
  const activeEditingSubScene = _cloneDeep(ownProps.activeEditingSubScene);

  const dataReadyForSave = _cloneDeep(scenes[activeEditingScene._id].elements);

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra

  // Finding forms
  const forms = storyEditor.sceneTypes["quiz-graded-results"]
    ? storyEditor.sceneTypes["quiz-graded-results"].forms
    : null;

  // Finding Localized Elements
  const localizedElements = storyEditor.sceneTypes["quiz-graded-results"]
    ? storyEditor.sceneTypes["quiz-graded-results"].localizedElements
    : null;

  // Localizing titles/texts - converting data to form-readable
  if (activeEditingScene && activeEditingScene.elements) {
    activeEditingScene.elements = flattenElements(
      activeEditingScene.elements,
      localizedElements,
      langID
    );

    if (
      activeEditingScene.elements.feedback &&
      activeEditingScene.elements.feedback.list
    ) {
      _forEach(activeEditingScene.elements.feedback.list, (option, index) => {
        if (
          typeof activeEditingScene.elements.feedback.list[index].title !==
          "undefined"
        ) {
          if (
            typeof activeEditingScene.elements.feedback.list[index].title ===
              "object" &&
            localizedElements.includes("feedback")
          ) {
            activeEditingScene.elements.feedback.list[index].title =
              localizeTextProps(
                langID,
                ["text"],
                activeEditingScene.elements.feedback.list[index].title
              );

            if (
              typeof activeEditingScene.elements.feedback.list[index].title
                .text !== "undefined"
            ) {
              activeEditingScene.elements.feedback.list[index].title =
                activeEditingScene.elements.feedback.list[index].title.text;
            } else {
              activeEditingScene.elements.feedback.list[index].title = "";
            }
          }
        }
        if (
          typeof activeEditingScene.elements.feedback.list[index].grade !==
          "undefined"
        ) {
          activeEditingScene.elements.feedback.list[index].grade =
            typeof activeEditingScene.elements.feedback.list[index].grade ===
            "number"
              ? activeEditingScene.elements.feedback.list[
                  index
                ].grade.toString()
              : activeEditingScene.elements.feedback.list[index].grade;
        }
      });
      activeEditingScene.elements.feedback =
        activeEditingScene.elements.feedback.list;
    }
  }

  return {
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    slugBase,
    sidebarMode,
    forms,
    languages,
    localizedElements,
    dataReadyForSave,
    langID,
    storyID,
    sceneID,
    elementID,
    activeEditingSubScene,
    activeEditingScene, // string id of the Scene being edited
    history: ownProps.history
  };
};

export const timelineEventEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];
  const { storyID, sceneID, elementID, slugBase, activeEditingScene } =
    ownProps;
  const subsceneID = ownProps.match.params.subsceneID;

  let activeEditingSubScene = _cloneDeep(scenes[subsceneID]);
  const dataReadyForSave = _cloneDeep(
    scenes[activeEditingSubScene._id].elements
  );
  // let { activeEditingSubScene } = ownProps;

  activeEditingSubScene = localizeTextProps(
    langID,
    localizedProps,
    activeEditingSubScene
  );

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra

  // Card Browser Config (for gallery)
  const cardBrowserConfig =
    storyEditor.sceneTypes["timeline-event"].cardBrowserConfig;

  const forms = storyEditor.sceneTypes["timeline-event"]
    ? buildSelectOptions(
        storyEditor.sceneTypes["timeline-event"].forms,
        langID,
        state
      )
    : null;

  // Finding Localized Elements
  const localizedElements = storyEditor.sceneTypes["timeline-event"]
    ? storyEditor.sceneTypes["timeline-event"].localizedElements
    : null;

  // -------- Calculating prev and next event
  const subSceneIds = activeEditingScene.subScenes;

  // First create an array of event scenes.
  const eventScenes = [];
  _forEach(subSceneIds, (subSceneId, index) => {
    eventScenes.push(scenes[subSceneId]);
  });

  // Now sort that array of event scenes.
  eventScenes.sort((sceneA, sceneB) => {
    const dateA = sceneA.elements.beginDate.date
      ? sceneA.elements.beginDate.date
      : 0;
    const dateB = sceneB.elements.beginDate.date
      ? sceneB.elements.beginDate.date
      : 0;
    return new Date(dateA) - new Date(dateB);
  });

  // Now determine which scene this is in the
  // sorted array and calculate prev/next.
  let myKey, goPrevURL, goNextURL, curSlideNo;
  _forEach(eventScenes, (scene, index) => {
    if (scene._id === activeEditingSubScene._id) {
      myKey = index;
      curSlideNo = index + 1;
    }
  });
  if (myKey > 0) {
    goPrevURL = `${slugBase}/${eventScenes[myKey - 1]._id}`;
  }
  if (myKey < subSceneIds.length - 1) {
    goNextURL = `${slugBase}/${eventScenes[myKey + 1]._id}`;
  }

  const galleryData = {};
  if (activeEditingSubScene.subScenes) {
    _forEach(activeEditingSubScene.subScenes, (id, index) => {
      const subScene = scenes[id];
      const assetData =
        subScene && subScene.elements && subScene.elements.asset
          ? subScene.elements.asset
          : {};
      if (subScene) {
        galleryData[index] = { ...assetData, _id: id };
      }
    });
  }

  // Localizing titles/texts - converting data to form-readable
  if (activeEditingSubScene && activeEditingSubScene.elements) {
    activeEditingSubScene.elements = flattenElements(
      activeEditingSubScene.elements,
      localizedElements,
      langID
    );
  }

  return {
    assets,
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    slugBase,
    curSlideNo,
    totalSlideNo: subSceneIds.length,
    goPrevURL,
    history: ownProps.history,
    goNextURL,
    sidebarMode,
    cardBrowserConfig,
    languages,
    localizedElements,
    dataReadyForSave,
    langID,
    forms,
    storyID,
    sceneID,
    elementID,
    activeEditingSubScene,
    galleryData
  };
};

export const mapPlaceEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const languages = state.getIn(["base", "languages"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];
  const { storyID, sceneID, elementID, slugBase, activeEditingScene } =
    ownProps;
  const dataReadyForSave = _cloneDeep(
    scenes[ownProps.activeEditingSubScene._id].elements
  );

  const activeEditingSubScene = _cloneDeep(
    localizeTextProps(
      langID,
      localizedProps,
      scenes[ownProps.activeEditingSubScene._id]
    )
  );
  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra

  // Card Browser Config (for gallery)
  const cardBrowserConfig =
    storyEditor.sceneTypes["map-place"].cardBrowserConfig;

  const forms = storyEditor.sceneTypes["map-place"]
    ? buildSelectOptions(
        storyEditor.sceneTypes["map-place"].forms,
        langID,
        state
      )
    : null;

  // Finding Localized Elements
  const localizedElements = storyEditor.sceneTypes["map-place"]
    ? storyEditor.sceneTypes["map-place"].localizedElements
    : null;

  // -------- Calculating prev and next slide
  const subScenesData = activeEditingScene.subScenes;
  let myKey, goPrevURL, goNextURL, curSlideNo;
  _forEach(subScenesData, (element, index) => {
    if (element === activeEditingSubScene._id) {
      myKey = index;
      curSlideNo = index + 1;
    }
  });
  if (myKey > 0) {
    goPrevURL = `${slugBase}/${scenes[subScenesData[myKey - 1]]._id}`;
  }
  if (myKey < subScenesData.length - 1) {
    goNextURL = `${slugBase}/${scenes[subScenesData[myKey + 1]]._id}`;
  }

  const galleryData = {};
  if (activeEditingSubScene.subScenes) {
    _forEach(activeEditingSubScene.subScenes, (id, index) => {
      const subScene = scenes[id];
      const assetData =
        subScene && subScene.elements && subScene.elements.asset
          ? subScene.elements.asset
          : {};
      if (subScene) {
        galleryData[index] = { ...assetData, _id: id };
      }
    });
  }

  // Localizing titles/texts - converting data to form-readable
  if (activeEditingSubScene && activeEditingSubScene.elements) {
    activeEditingSubScene.elements = flattenElements(
      activeEditingSubScene.elements,
      localizedElements,
      langID
    );
  }

  return {
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    slugBase,
    curSlideNo,
    totalSlideNo: subScenesData.length,
    goPrevURL,
    languages,
    langID,
    dataReadyForSave,
    history: ownProps.history,
    goNextURL,
    localizedElements,
    sidebarMode,
    cardBrowserConfig,
    forms,
    storyID,
    sceneID,
    elementID,
    activeEditingScene,
    activeEditingSubScene,
    galleryData
  };
};

export const basemapEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];
  const { storyID, sceneID, elementID, slugBase } = ownProps;
  let activeEditingScene = scenes[sceneID];

  // Finding forms
  const forms = storyEditor.sceneTypes["map-places"]
    ? storyEditor.sceneTypes["map-places"].forms
    : null;

  activeEditingScene = localizeTextProps(
    langID,
    localizedProps,
    activeEditingScene
  );
  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}Extra`; // MediumExtra

  return {
    activeEditingScene,
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    forms,
    slugBase,
    sidebarMode,
    sceneID,
    elementID
  };
};

/* sceneListBrowserMapStateToProps
 * called on the init of the connected Module - SceneBrowser
 * may end up being used for all scene editors...
 * @param {object} state
 * @param {object} ownProps
 */
export const sceneListBrowserMapStateToProps = (state, ownProps) => {
  const { params, path } = ownProps.match;
  const isFeedbackList = path.indexOf("feedback") > -1;
  const { storyID, sceneID, subsceneID } = params;
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const { sceneTypes } = storyEditor;

  const storyData = state.getIn(["data", "stories", storyID], Map([])).toJS();
  const storyType = storyData
    ? selectors.getStoryTypeParam(state, storyData.type)
    : null;

  const localizedProps = storyEditor.localizedProps || ["title"];

  const allScenes = state.getIn(["data", "scenes"], Map({})).toJS();
  let goBackAction = `${storyEditor.to}/${storyID}`;

  let pageTitle,
    tableScenes,
    sceneStoryTypeParam,
    slugBase,
    subSceneType,
    sceneLocalizedElements;
  switch (storyType) {
    case "timeline":
      pageTitle = "Timeline Events";
      sceneStoryTypeParam = "timeline-events";
      subSceneType = "timeline-event";
      slugBase = `${goBackAction}/events/${sceneID}`;
      break;

    case "map":
      pageTitle = "Map Places";
      sceneStoryTypeParam = "map-places";
      subSceneType = "map-place";
      slugBase = `${goBackAction}/places/${sceneID}`;
      break;

    case "quiz-graded":
      // For now graded-quiz-questions
      pageTitle = "Graded Quiz Questions";
      sceneStoryTypeParam = `${storyType}-questions`;
      subSceneType = `${storyType}-question`;
      slugBase = `${goBackAction}/questions/${sceneID}`;
      break;

    case "gallery":
      // It could be a Feedback item lists
      if (isFeedbackList) {
        goBackAction += `/scenes/${sceneID}/subscenes/${subsceneID}`;
        slugBase = `${goBackAction}/feedback`;
      }
      break;
  }

  const parentSceneType = selectors.getSceneTypeId(state, sceneStoryTypeParam);

  let tableGridItems = {},
    header = {},
    titleIsLocalized = false;

  let sceneType;
  switch (storyType) {
    case "timeline":
      sceneType = "event";
      break;
    case "quiz-graded":
      sceneType = "question";
      break;
    case "map":
      sceneType = "place";
      break;
    case "gallery":
      sceneType = "feedback item";
      break;
  }

  const addFormElements = {};

  // Build TableGridItems for Questions / Maps / Quizes / Feedback Items

  addFormElements.title = {
    type: "text",
    isRequired: true,
    value: "",
    inlineLabel: true,
    order: 1
  };

  if (isFeedbackList) {
    const sceneData = allScenes[subsceneID];
    tableScenes = sceneData ? sceneData.feedbackItems : null;
  } else {
    let tableScene;
    _forEach(storyData.sceneIDs, id => {
      if (allScenes[id] && allScenes[id].type === parentSceneType) {
        tableScene = allScenes[id];
      }
    });
    tableScenes = tableScene ? tableScene.subScenes : null;
  }
  if (storyType === "timeline") {
    // Events need sorting by beginDate

    tableScenes.sort((a, b) => {
      const tempA = allScenes[a];
      const tempB = allScenes[b];
      const dataKeyA =
        tempA &&
        tempA.elements &&
        tempA.elements.beginDate &&
        tempA.elements.beginDate.date
          ? tempA.elements.beginDate.date
          : 0;
      const dataKeyB =
        tempB &&
        tempB.elements &&
        tempB.elements.beginDate &&
        tempB.elements.beginDate.date
          ? tempB.elements.beginDate.date
          : 0;
      return new Date(dataKeyA) - new Date(dataKeyB);
    });
  }

  if (storyType === "timeline") {
    header = {
      1: {
        id: 1,
        label: "Date"
      },
      2: {
        id: 2,
        label: "Event Name"
      },
      3: {
        id: 3,
        label: "Short Description"
      },
      4: {
        id: 4,
        label: ""
      }
    };
  } else {
    header = {
      1: {
        id: 1,
        label: _startCase(sceneType)
      }
    };
  }

  // Create TableGridItems object used to populate the TableGrid.
  // Each scene is a row in the table.
  if (tableScenes) {
    _forEach(tableScenes, tableSceneId => {
      let tableScene = allScenes[tableSceneId];
      tableScene = localizeTextProps(langID, localizedProps, tableScene);
      tableGridItems[tableSceneId] = {
        id: tableSceneId,
        columns: {
          1: {
            id: 1,
            label: tableScene.title
          }
        }
      };
      if (storyType === "timeline") {
        const formElements = {
          ...storyEditor.sceneTypes["timeline-event"].forms.details.general,
          ...storyEditor.sceneTypes["timeline-event"].forms.details
            .extendedDescription
        };

        tableScene.elements = localizeTextProps2(
          langID,
          formElements,
          tableScene.elements
        );
        const { beginDate, customDate, description } = tableScene.elements;
        const formattedDate = !customDate
          ? beginDate.year
            ? beginDate.month
              ? beginDate.day
                ? moment(beginDate.date).format("MMMM Do, YYYY")
                : moment(beginDate.date).format("MMMM YYYY")
              : beginDate.year
            : "" // No year
          : customDate;

        tableGridItems[tableSceneId].columns = {
          1: {
            id: 1,
            label: formattedDate
          },
          2: {
            id: 2,
            label: tableScene.title
          },
          3: {
            id: 3,
            label: tableScene.elements.overview
          }
        };
      }
    });
  }

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}Extra`;

  let isLoading = true;

  if (storyData) {
    isLoading = false;
  }

  return {
    pageTitle,
    goBackAction,
    navigateToNew: storyEditor.navigateToNew,
    titleIsLocalized,
    isLoading,
    sidebarMode,
    storyID,
    slugBase,
    sceneID,
    addFormElements,
    scene: allScenes[sceneID],
    sceneType,
    sceneTypeParam: sceneStoryTypeParam,
    subSceneTypeParam: subSceneType,
    tableGridItems,
    storyType,
    header
  };
};

export const collectionItemsBrowserMapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const { collectionID } = params;

  const storyData = state
    .getIn(["data", "stories", collectionID], Map([]))
    .toJS();

  // Don't try to process anything else if the story data hasn't been acquired yet.
  if (!storyData.type) {
    return {};
  }

  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const { cardBrowserConfig, sceneTypes } = storyEditor;

  const allScenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const goBackAction = `/collections/${collectionID}`;

  const pageTitle = "Collection Items";
  const slugBase = `${goBackAction}/items`;
  const collectionSceneTypeParam = selectors.getSceneTypeParam(
    state,
    storyData.sceneTypeID
  );
  const collectionSceneType = sceneTypes[collectionSceneTypeParam];
  const sceneLocalizedElements = sceneTypes[collectionSceneTypeParam]
    ? sceneTypes[collectionSceneTypeParam].localizedElements
    : null;

  let tableGridItems = {},
    cardBrowserItems = [],
    header = {},
    titleIsLocalized = false;

  const sceneType = "item";

  // Acquire the form elements assigned to the first column
  // and sort them.
  const sceneForm = _values(collectionSceneType.forms.details["column-1"]).sort(
    (a, b) => a.order - b.order
  );
  titleIsLocalized = sceneLocalizedElements.includes("title"); // @todo: get from localizedProps //collectionSceneType.forms['column-1'].title.isLocalized;

  /*
  Certain elements have been flagged for display in the
  item browser. Create an array of form elements for which
  we need to create columns in the browser table.
  */
  const formArray = [];
  _forEach(sceneForm, formElement => {
    if (formElement.isBrowserColumnHeader) {
      formArray.push(formElement);
    }
  });

  /*
  Now create a header object that contains an object for
  each element that needs to be represented in the browser.
  This header object will be used to create the header
  above the table, including the labels displayed in the header.
  */
  let counter = 1,
    mainCounter;
  _forEach(formArray, (formArrayElement, index) => {
    header[counter] = {
      id: counter,
      label: formArrayElement.label
    };
    counter++;
  });

  /*
  Loop over the sceneIDs associated with this collection,
  and create a tableGridItems object.
  */

  if (storyData.manage && storyData.manage.useCardBrowser) {
    // Create card browser item objects used to create
    // cards for each collection item.
    _forEach(storyData.sceneIDs, sceneId => {
      // Get the scene to acquire the collection item title.
      // Get the subScene to acquire the first gallery image
      // to use as the card thumbnail.
      const scene = allScenes[sceneId];
      const subScene =
        scene.subScenes && scene.subScenes.length > 0
          ? allScenes[scene.subScenes[0]]
          : null;
      let item = {
        _id: sceneId,
        title: scene.elements.title.text[langID]
      };
      if (subScene) {
        item = { ...item, ...subScene.elements.asset };
      }
      cardBrowserItems.push(item);
    });
  } else {
    mainCounter = 1;
    _forEach(storyData.sceneIDs, tableSceneId => {
      const tableScene = allScenes[tableSceneId];

      if (!tableScene) return;

      // Create a columns object containing the values to be
      // displayed in each column for the scene.
      const columns = {};
      counter = 1;
      _forEach(formArray, formElement => {
        const sceneElement = tableScene.elements[formElement.param];

        let nonLocalizedLabel;
        // If this element is of type select, we need to grap
        // the label from the form select options based on the
        // selected value
        if (formElement.type === "select") {
          if (sceneElement.value) {
            nonLocalizedLabel = formElement.options.filter(
              option => option.value == sceneElement.value
            )[0].label;
          } else {
            nonLocalizedLabel = "";
          }
        } else {
          nonLocalizedLabel = sceneElement.text;
        }
        columns[counter] = {
          id: counter,
          label: sceneLocalizedElements.includes(formElement.param)
            ? sceneElement.text[langID]
            : nonLocalizedLabel
        };
        counter++;
      });
      tableGridItems[mainCounter] = {
        id: tableSceneId,
        columns
      };
      mainCounter++;
    });
  }

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}Extra`;

  let isLoading = true;

  if (storyData) {
    isLoading = false;
  }

  return {
    collectionID,
    pageTitle,
    titleIsLocalized,
    isLoading,
    sidebarMode,
    slugBase,
    collectionSceneTypeID: storyData.sceneTypeID,
    sceneType,
    header,
    tableGridItems,
    minCardSize: cardBrowserConfig.cardSizeMultiplier
      ? cardBrowserConfig.cardSizeMultiplier
      : 210,
    cardBrowserConfig,
    cardBrowserItems,
    navigateToNew: storyEditor.navigateToNew
  };
};

/**
 * galleryEditorMapStateToProps
 * called on the init of the connected Module - SceneBrowser
 * may end up being used for all scene editors...
 * @param {object} state
 * @param {object} ownProps
 */
export const galleryEditorMapStateToProps = (state, ownProps) => {
  // passed down from sceneEditor
  const { storyID, sceneID, elementID, activeEditingScene } = ownProps;

  const cardBrowserConfigUserPrefsSelector = [
    "storyManager",
    "galleryAssetBrowser",
    "cardBrowserConfig"
  ];
  const cardBrowserConfigUserPrefs = state
    .getIn(
      ["ui", "userPreferences", ...cardBrowserConfigUserPrefsSelector],
      Map({})
    )
    .toJS();

  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const { storyTypes, sceneTypes } = storyEditor;

  const sceneData = state.getIn(["data", "scenes", sceneID], Map([])).toJS(); // we get this for raw stored data , need type ID
  // const sceneTypes = state.getIn(['base', 'sceneTypes'], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;

  // TODO: read these from sceneType
  const localizedProps = [
    "title",
    "caption",
    "secondaryCaption",
    "acknowledgement"
  ];
  const sceneType = selectors.getSceneTypeParam(state, activeEditingScene.type); // param "friendly"

  // Finding Localized Elements
  const localizedElements = storyEditor.sceneTypes["viewer-asset"]
    ? storyEditor.sceneTypes["viewer-asset"].localizedElements
    : null;

  const cardBrowserConfig =
    typeof sceneTypes[sceneType] !== "undefined"
      ? sceneTypes[sceneType].cardBrowserConfig
      : {};
  if (cardBrowserConfigUserPrefs.cardSizeMultiplier) {
    cardBrowserConfig.cardSizeMultiplier =
      cardBrowserConfigUserPrefs.cardSizeMultiplier;
  }

  const goBackAction = `${storyEditor.to}/${storyID}`;
  const slugBase = `${goBackAction}/scenes/]`;

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra
  const scenes = []; // only the SUBSCENEs for this parentScene
  let isLoading = true,
    scenesWithFeedback = [];
  const allScenes = state.getIn(["data", "scenes"], Map({})).toJS();
  // we read from the subScenes property to build an array of scenes the browser will display
  if (activeEditingScene && activeEditingScene.subScenes && allScenes) {
    _forEach(activeEditingScene.subScenes, (id, index) => {
      const subScene = allScenes[id]; // subscene
      // Is it a scene with feedback?
      if (
        subScene &&
        subScene.feedbackItems &&
        subScene.feedbackItems.length > 0
      ) {
        scenesWithFeedback.push(id);
      }
      const assetData =
        subScene && subScene.elements && subScene.elements.asset
          ? subScene.elements.asset
          : null;
      let metadata = assetData ? subScene.elements.asset.metaConfig : {};
      const assetConfig = assetData ? subScene.elements.asset.assetConfig : {};
      metadata = localizeTextProps(langID, localizedElements, metadata);
      if (assetData) {
        metadata = { ...assetData, ...metadata, assetConfig, _id: id };
        scenes[index] = metadata;
      }
    });
  }

  // we know things are loaded IF the
  // there is an activeEditingScene object AND
  // scenes length is same as subScenes OR
  // if the subScenes is empty/non existent
  if (
    activeEditingScene && // is there even a active scene to check
    (!activeEditingScene.subScenes || // no subscenes to wait to load
      (activeEditingScene.subScenes && // there are subscenes and then length is the same as the scenes found in the store that match
        scenes.length === activeEditingScene.subScenes.length))
  ) {
    isLoading = false;
  }

  return {
    cardBrowserConfigUserPrefsSelector,
    cardBrowserConfig,
    goBackAction,
    isLoading,
    sidebarMode,
    storyID,
    sceneID,
    elementID,
    slugBase,
    scenesWithFeedback,
    scenes, // subscenes of this scene
    sidebarProps: {
      title: "Scenes",
      goBackAction
    },
    sceneType
  };
};

export const feedbackGalleryEditorMapStateToProps = (state, ownProps) => {
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const scenes = state.getIn(["data", "scenes"], Map({})).toJS();
  const assets = state.getIn(["data", "assets"], Map({})).toJS();
  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const { langID } = baseConfig;
  const localizedProps = storyEditor.localizedProps || ["title"];
  const {
    storyID,
    sceneID,
    elementID,
    activeEditingScene,
    feedbackID,
    activeEditingSubScene,
    percCropData
  } = ownProps;
  let { slugBase } = ownProps;
  slugBase += "/feedback";
  const { activeEditingFeedback } = ownProps;

  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}`; // MediumExtra

  // Finding forms
  const forms = storyEditor.sceneTypes["feedback-gallery-input"]
    ? storyEditor.sceneTypes["feedback-gallery-input"].forms
    : null;

  // -------- Calculating prev and next slide
  const subScenesData = activeEditingSubScene.feedbackItems;
  let myKey, goPrevURL, goNextURL, curSlideNo;
  _forEach(subScenesData, (element, index) => {
    if (element === activeEditingFeedback._id) {
      myKey = index;
      curSlideNo = index + 1;
    }
  });
  if (myKey > 0) {
    goPrevURL = `${slugBase}/${scenes[subScenesData[myKey - 1]]._id}`;
  }
  if (myKey < subScenesData.length - 1) {
    goNextURL = `${slugBase}/${scenes[subScenesData[myKey + 1]]._id}`;
  }

  if (activeEditingFeedback && activeEditingFeedback.elements) {
    _forEach(activeEditingFeedback.elements, (element, index) => {
      if (element.type === "text") {
        activeEditingFeedback.elements[index] =
          activeEditingFeedback.elements[index].text;
      }
    });
  }

  return {
    isLoading:
      typeof storyEditor.isLoading !== "undefined"
        ? storyEditor.isLoading
        : true,
    slugBase,
    curSlideNo,
    percCropData,
    totalSlideNo: subScenesData.length,
    goPrevURL,
    history: ownProps.history,
    goNextURL,
    sidebarMode,
    forms,
    storyID,
    sceneID,
    elementID,
    activeEditingFeedback
  };
};

export const localizeTextElements = (elements, localizedElements, langID) => {
  const newElements = _cloneDeep(elements);

  _forEach(newElements, (element, index) => {
    // For localized text elements, replace the entire element
    // with the appropriate text object property based on the
    // current language.

    if (element.type === "text" && localizedElements.includes(index)) {
      newElements[index] = localizeTextProps(langID, ["text"], element).text;
    }
  });

  return newElements;
};

/**
 * The ListItem form components require simple values, not element
 * objects (as they're stored in the database). Text input fields,
 * for example, require a simple string, not an element object. So
 * convert all the elements to simple values, localizing as necessary.
 *
 * Typical text element looks something like this before flattening:
 *
 *  title: {
 *    type: "text",
 *    use: "title",
 *    text: {
 *      en: "<p>My Title</p>"
 *    }
 *  }
 *
 * And then after flattening, the element will look like this:
 *  title: "<p>My Title</p>"
 *
 * @param  {object} elements - Object with elements to be flattened.
 * @param  {array}  localizedElements - Array of element names that
 *                      need to be localized.
 * @param  {string} langID - Two-character string representing current language.
 * @return {object} elements with text elements flattened.
 */
export const flattenElements = (elements, localizedElements, langID) => {
  // const newElements = _cloneDeep(elements);

  const newElements = localizeTextElements(elements, localizedElements, langID);

  _forEach(newElements, (element, index) => {
    switch (element.type) {
      case "text":
        /*
        If the text element hasn't been localized, it'll still 
        have a text propery. Go ahead and flatten non-localized
        text elements. The localizeTextElements() method removes 
        the text property.
        */
        if (typeof newElements[index].text !== "undefined") {
          newElements[index] = element.text;
        }
        break;

      // Why is the select list value being converted to a string?
      // Seems like it should be a number.
      case "select":
        newElements[index] = element.value.toString();
        break;

      case "checkbox":
      case "select-multiple":
        newElements[index] = element.value;
        break;
    }
  });

  return newElements;
};

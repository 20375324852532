import React from 'react';
import ContentContainer from 'modules/ContentContainer';
import './styles.scss'; // need to leverage the same selector actions from story

/**
 * StoryPreviewer
 */
class StoryPreviewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { sidebarMode } = this.props;

    return (
      <div className="story-previewer">
        <ContentContainer
          backgroundColor="white"
          className="content-container"
          sidebarMode={sidebarMode}
        >
          <div className="label-container">{/* Story Preview will go here */}</div>
        </ContentContainer>
      </div>
    );
  }
}

StoryPreviewer.defaultProps = {};

StoryPreviewer.propTypes = {};

export default StoryPreviewer;

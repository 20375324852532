import { fromJS, Map } from 'immutable';
import * as CONST from 'actions/action_constants';
import _map from 'lodash/map';
// import {loadSiteData} from "storelocalStorage";

/*
 * THEME EDITOR
 */

// reducer
const quizEditor = (state = Map({}), action) => {
  const { activeElement } = action;
  switch (action.type) {
    case CONST.APP_INIT_SUCCESS:
      const { quizEditor } = action; // ,
      return state.merge(fromJS(quizEditor));
    case CONST.QUIZ_EDITOR_REQUEST:
      return state.set('isLoading', true);
    case CONST.QUIZ_EDITOR_SUCCESS:
      return state.set('isLoading', false);
    case CONST.STORY_EDITOR_SET_ACTIVE_ELEMENT:
      return state.set('activeEditingElement', activeElement);
    case CONST.COPY_QUESTION:
      return state.set('editingQuestion', action.questionData.id);
    case CONST.PREPARE_ADD_QUESTION:
      return state.set('editingQuestion', null);
    default:
      return state;
  }
};

const populateForm = (form, data) => {
  form &&
    data &&
    _map(form, (item) => {
      if (typeof data[item.param] !== 'undefined') {
        item.value = data[item.param];
      }
    });
  return form;
};

/**
 * getQuizEditorProps
 * @description this mapProps function will get the props that are needed for the main themeEditor component
 * @param
 * @return Object
 */
export const getQuizEditorProps = (state, ownProps) => {

  const quizEditorData = state.get('quizEditor', Map([])).toJS();
  const navigation = quizEditorData.navigation;
  const baseScreen = quizEditorData.baseScreen;
  const section =
    typeof ownProps.match.params.section !== 'undefined' ? ownProps.match.params.section : null;

  const sectionData = state.getIn(['data', 'quiz'], Map({})).toJS();

  // Removing copied data to clean up
  if (typeof sectionData.revertCopy !== 'undefined') delete sectionData.revertCopy;

  const revertCopy = state.getIn(['data', 'quiz', 'revertCopy'], Map()).toJS();
  const sidebarMode = `${state.getIn(['base', 'mainSidebar', 'mode'])}Extra`;
  const goBackAction = section ? '/quiz' : '/';

  return {
    isLoading: typeof quizEditorData.isLoading !== 'undefined' ? quizEditorData.isLoading : true,
    sidebarMode,
    section,
    sectionData,
    quizQuestionForm: populateForm(quizEditorData.quizQuestionForm, revertCopy),
    responseFormElements: populateForm(quizEditorData.responseFormElements, revertCopy),
    questionData: revertCopy || null,
    mainSidebarProps: {
      title: baseScreen ? baseScreen.label : '',
      subheader: section ? section.charAt(0).toUpperCase() + section.slice(1) : null,
      navigation,
      slugBase: '/quiz',
      goBackAction,
    },
  };
};

export default quizEditor;

import * as CONST from "actions/action_constants";

export const handleCategoryBrowserMounted = storyID => ({
  type: CONST.STORY_CATEGORY_BROWSER_REQUEST,
  storyID
});

export const createCategory = storyID => ({
  type: CONST.STORY_CATEGORY_CREATE_REQUEST,
  storyID
});

export const deleteCategories = categoryIDs => ({
  type: CONST.STORY_CATEGORIES_DELETE_REQUEST,
  categoryIDs
});

export const handleEditorDataInitialized = categoryID => ({
  type: CONST.STORY_CATEGORY_EDITOR_DATA_INITIALIZED,
  categoryID
});

export const handleInputChange = (
  categoryID,
  property,
  propertyType,
  value,
  languageID
) => ({
  type: CONST.STORY_CATEGORY_EDITOR_DATA_CHANGED,
  categoryID,
  property,
  propertyType,
  value,
  languageID
});

export const handleRevertChanges = () => ({
  type: CONST.STORY_CATEGORY_EDITOR_DATA_REVERT_REQUEST
});

export const handleSaveRequest = categoryID => ({
  type: CONST.STORY_CATEGORY_EDITOR_SAVE_REQUEST,
  categoryID
});

export const changeLanguage = langID => ({
  type: CONST.CHANGE_LANGUAGE,
  langID
});
